import * as geometriesActions from '../geometries/geometriesActions';
import { convertArrayToMap } from '../../utility';
import * as partsActions from './partsActions';

const initialState = {};

const partsReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case partsActions.FETCH_PARTS__REQUEST:
      return initialState;
    case partsActions.FETCH_PARTS__SUCCESS:
      return convertArrayToMap(payload.parts);
    case geometriesActions.FETCH_GEOMETRIES__SUCCESS: {
      const { partIds = [] } = payload;
      const newState = { ...state };

      partIds.forEach(id => {
        newState[id].geometryDownloaded = true;
      });

      return newState;
    }
    case partsActions.STORE_EXTERNAL_PARTS: {
      const newState = { ...state };

      payload.parts.forEach(part => {
        newState[part._id] = part;
      });

      return newState;
    }
    default:
      return state;
  }
};

export default partsReducer;
