import { createSelector } from 'reselect';

export const selectSeeds = state => state.seed;

export const selectSeed = createSelector([selectSeeds], ({ currentId, list }) => list[currentId] || {});

export const selectSeedList = createSelector([selectSeeds], seeds => Object.values(seeds.list));
export const selectSeedIds = createSelector([selectSeeds], seeds => Object.keys(seeds.list));

export const selectSeedMainId = createSelector([selectSeeds], seeds => seeds.mainId);

export const selectSeedId = createSelector([selectSeed], seed => seed._id);
export const selectRootPartId = createSelector([selectSeed], seed => seed.rootPartId);

export const selectSeedPreviousId = createSelector([selectSeeds], seed => seed.previousMainId);

export const selectHasNewSeed = createSelector([selectSeedMainId, selectSeedPreviousId], (id, prevId) => id !== prevId);

export const selectRemoteControls = createSelector([selectSeed], seed => seed.remoteControls || []);
export const selectRemoteSwitches = createSelector([selectSeed], seed => seed.remoteSwitches || []);
export const selectSeedUpdatedAt = createSelector([selectSeed], seed => seed.updatedAt);
export const selectSeedsMaterials = createSelector([selectSeedList], (list = []) => {
  const allMaterials = [];

  list.forEach(({ materials = [] }) => allMaterials.push(...materials));

  return allMaterials;
});

export const selectIsSeedDeprecated = createSelector([selectSeed], seed => seed.deprecated);
export const selectLinkToNewProject = createSelector([selectSeed], seed => seed.linkToNewProject);
export const selectSeedName = createSelector([selectSeed], seed => seed.nameLong || seed.name);

export const selectMainSeed = createSelector([selectSeeds], ({ mainId, list }) => list[mainId] || {});
export const selectMainSeedName = createSelector([selectMainSeed], seed => seed.nameLong || seed.name);

export const selectIsMainSeed = createSelector([selectSeedId, selectSeedMainId], (seedId, mainId) => seedId === mainId);

export const selectSeedHistory = createSelector([selectSeeds], seeds => seeds.history);

export const selectSeedLastHistory = createSelector([selectSeedHistory], seedHistory => {
  if (seedHistory.length === 0) return undefined;

  return seedHistory[seedHistory.length - 1];
});

/** Returns asset urls and settings for all seeds  */
export const selectSeedAssetsUrls = createSelector(selectSeeds, seeds => {
  const result = [];

  Object.values(seeds.list).forEach(seed => {
    if (seed.assets?.enabled && Array.isArray(seed.assets.urls)) {
      const { urls } = seed.assets;

      result.push(...urls.map(url => ({ seedId: seed._id, url })));
    }
  });

  return result;
});
