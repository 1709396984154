import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import b from 'b_';
import { useDispatch, useSelector } from 'react-redux';
import { cameraActions, cameraSelectors } from '../../../store/camera';
import { uiSelectors } from '../../../modules/ui';
import Icon from '../../Icon';
import { useTranslate, useUiModes, useIsMobileCommentDialogMode } from '../../../utility/hooks';
import { settingsSelectors } from '../../../modules/settings';

import './ViewButtons.scss';

const viewButtonsStyle = b.with('view-buttons');

const DIRECTIONS = {
  UP: 'up',
  DOWN: 'down'
};

const getStyleMode = (isControlPanelVisible, isReviewTab, isMinimalUi) => {
  if (isReviewTab || isMinimalUi) {
    return 'review';
  }

  return isControlPanelVisible ? 'controls' : undefined;
};

const useViewsSwitcher = (views, currentIndex, setCameraView) => {
  const { isMinimalUi } = useUiModes();

  const [interacted, setInteracted] = useState(!isMinimalUi);
  const timerId = useRef();

  const onSetInteracted = useCallback(() => {
    setInteracted(true);

    clearTimeout(timerId.current);
  }, []);

  useEffect(() => {
    if (!interacted) {
      document.addEventListener('click', onSetInteracted);
    }

    return () => {
      document.removeEventListener('click', onSetInteracted);
    };
  }, [interacted, onSetInteracted]);

  useEffect(() => {
    if (!interacted) {
      const nextIndex = views.length - 1 === currentIndex ? 0 : currentIndex + 1;

      timerId.current = setTimeout(() => {
        setCameraView(views[nextIndex].name);
      }, 5000);
    }

    return () => {
      clearTimeout(timerId.current);
    };
  }, [interacted, currentIndex, setCameraView, views, views.length]);

  return isMinimalUi;
};

const ViewButtons = () => {
  const timerId = useRef();
  const [tooltip, setTooltip] = useState(undefined);
  const dispatch = useDispatch();
  const isControlPanelVisible = useSelector(uiSelectors.selectIsControlPanelVisible);
  const isReviewTab = useSelector(uiSelectors.getIsReviewTab);
  const isCommentingEnabled = useSelector(settingsSelectors.selectIsCommentingEnabled);
  const isMobileCommentDialog = useIsMobileCommentDialogMode();

  const translate = useTranslate();

  const views = useSelector(cameraSelectors.selectVisibleViews);
  const { name } = useSelector(cameraSelectors.selectCameraView);
  const isViewControlsHidden = useSelector(uiSelectors.selectCurrentTabViewButtonsHidden);
  const currentIndex = useMemo(() => views.findIndex(view => view.name === name), [name, views]);

  const setTooltipView = useCallback(viewName => {
    setTooltip(viewName);

    clearTimeout(timerId.current);

    timerId.current = setTimeout(() => {
      setTooltip(undefined);
    }, 3000);
  }, []);

  const setCameraView = useCallback(
    viewName => {
      dispatch(cameraActions.setView(viewName));
      dispatch(cameraActions.setMoved(false));
    },
    [dispatch]
  );

  const handleClick = useCallback(
    e => {
      const { viewName, direction } = e.currentTarget.dataset;

      if (viewName) {
        setCameraView(viewName);

        setTooltipView(viewName);

        return;
      }

      if (direction) {
        const newIndex = direction === DIRECTIONS.UP ? currentIndex - 1 : currentIndex + 1;

        setCameraView(views[newIndex].name);

        setTooltipView(views[newIndex].name);
      }
    },
    [currentIndex, setCameraView, setTooltipView, views]
  );

  useEffect(() => {
    return () => {
      clearTimeout(timerId.current);
    };
  }, []);

  const isMinimalUi = useViewsSwitcher(views, currentIndex, setCameraView);

  if (isViewControlsHidden || isMobileCommentDialog) {
    return null;
  }

  return (
    <div
      className={viewButtonsStyle({
        mode: getStyleMode(isControlPanelVisible, isReviewTab && !isCommentingEnabled, isMinimalUi)
      })}
    >
      <button
        type="button"
        className={viewButtonsStyle('button', { up: true })}
        disabled={currentIndex === 0}
        onClick={handleClick}
        data-direction={DIRECTIONS.UP}
        aria-label={translate('Previous view')}
        data-balloon-pos="right"
      >
        <Icon type="chevron-up" />
      </button>
      {views.map(view => {
        return (
          <button
            type="button"
            key={view.name}
            className={viewButtonsStyle('button', { active: view.name === name })}
            onClick={handleClick}
            data-view-name={view.name}
            aria-label={translate(view.description) || undefined}
            data-balloon-pos="right"
            data-balloon-visible={tooltip === view.name || undefined}
          >
            {view.displayName}
          </button>
        );
      })}
      <button
        type="button"
        className={viewButtonsStyle('button', { down: true })}
        disabled={currentIndex === views.length - 1}
        onClick={handleClick}
        data-direction={DIRECTIONS.DOWN}
        aria-label={translate('Next view')}
        data-balloon-pos="right"
      >
        <Icon type="chevron-down" />
      </button>
    </div>
  );
};

export default ViewButtons;
