/* 
Validator functions
    
Pattern is to return undefined in case of valid or a string in case of error message.
All validators will get two parameters - value of current input and whole form state.
*/

export const validateEmail = (value: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(value) ? undefined : 'Please enter a valid email address';
};

export const validateOptionalEmail = (value: string) =>
  value ? validateEmail(value) && 'The entered email address is not valid' : undefined;

export const validateRequired = (value: string, feedback = 'Please fill in this field') =>
  value ? undefined : feedback;

export const validatePasswordLength = (value = '') => {
  return value.length >= 8 ? undefined : 'Password must be at least 8 characters long';
};

export const validatePassword = validatePasswordLength;

export const validatePassword2 = (value: string, formState: { password: string }) =>
  value === formState.password ? undefined : 'Passwords must match';

export const validateConsent = (value: string) => (value ? undefined : 'Please agree to the terms');
