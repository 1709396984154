import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CommentType } from 'types';
import b from 'b_';
import { dialogActions } from '../../modules/dialog';
import { instanceSelectors } from '../../modules/instance';
import { useTranslate } from '../../utility/hooks';
import { Button, Divider } from '../Atoms';
import CommentBox from './CommentBox';
import './Comments.scss';

const commentsPanel = b.with('comments-panel');

const CommentsPanel = () => {
  const comments: CommentType[] | undefined = useSelector(instanceSelectors.selectInstanceComments);

  const translate = useTranslate();
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(dialogActions.openAddCommentDialog());
  }, [dispatch]);

  return (
    <section className={commentsPanel()}>
      <h3 className={commentsPanel('title')}>{translate('Comments')}</h3>
      <p className={commentsPanel('subtitle')}>{translate('List of comments for this design')}</p>
      {comments && comments.length ? (
        comments.map((comment, commentIndex) => (
          <CommentBox key={comment._id} comment={comment} index={commentIndex + 1} />
        ))
      ) : (
        <p className={commentsPanel('placeholder')}>{translate('No comments have been added')}</p>
      )}

      <Divider />
      <Button onClick={handleClick} color="accent-outline" block bold rounded>
        {translate('+ Add')}
      </Button>
    </section>
  );
};

export default CommentsPanel;
