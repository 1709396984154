import { HTMLAttributes, useCallback, useLayoutEffect, useRef } from 'react';
import { useTranslate, useFieldFeedback } from '../../../utility/hooks';
import FieldFeedback from '../FieldFeedback';
import Select, { SelectProps } from './Select';
import inputStyle from './inputStyle';

import './Field.scss';

interface CommonFieldProps {
  id?: string;
  name: string;
  label?: string;
  required?: boolean;
  bold?: boolean;
  mix?: string;
  description?: string;
  feedback?: string;
  disabled?: boolean;
}

interface InputFieldProps extends HTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'password' | 'email';

  value?: string | number;
}

interface SelectFieldProps extends SelectProps {
  type: 'select';
  onFocus?: never; // until implemented for Select
  onBlur?: never;
  value?: string;
}

interface TextAreaFieldProps extends HTMLAttributes<HTMLTextAreaElement> {
  type: 'textarea';
  value?: string;
  autoHeight?: boolean;
}

type FieldProps = CommonFieldProps & (SelectFieldProps | TextAreaFieldProps | InputFieldProps);

const TEXTAREA_PADDING = 10; // top and bottom padding together

const TextAreaField = ({ autoHeight = false, onChange, ...props }: Omit<TextAreaFieldProps, 'type'>) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  // calculates the height of the textarea
  useLayoutEffect(() => {
    const { current: textArea } = ref;

    if (textArea && autoHeight) {
      // first make it way too small so that scrollHeight would be udpated
      textArea.style.height = '5px';
      // next make the height correct by scrollHeight and padding
      textArea.style.height = `${textArea.scrollHeight + TEXTAREA_PADDING}px`;
    }

    return () => {
      if (textArea) {
        textArea.style.height = 'auto';
      }
    };
    // props.value needs to be in the list in order to update
    // when value changes.
  }, [autoHeight, props.value]);

  const handleChange = useCallback(
    e => {
      if (onChange) onChange(e);
    },
    [onChange]
  );

  return <textarea ref={ref} {...props} onChange={handleChange} />;
};

const Field = ({
  id = '',
  label = '',
  required = false,
  bold = false,
  name,
  mix = '',
  description = '',
  feedback,
  onFocus,
  onBlur,
  ...props
}: FieldProps) => {
  const translate = useTranslate();

  const itemId = id || `form-input-${name}`;

  const { isFeedbackShowing, handleBlur, handleFocus } = useFieldFeedback(feedback, onBlur, onFocus);

  const componentProps = {
    id: itemId,
    name,
    className: inputStyle('input', { type: props.type, bold }),
    required
  };

  let component;

  if (props.type === 'select') {
    const { type, ...rest } = props;

    component = <Select {...componentProps} {...rest} />;
  } else if (props.type === 'textarea') {
    const { type, autoHeight, ...rest } = props;

    // overwrite className to take autoHeight into account
    componentProps.className = inputStyle('input', { type, 'auto-height': autoHeight, bold });

    component = (
      <TextAreaField {...componentProps} {...rest} autoHeight={autoHeight} onBlur={handleBlur} onFocus={handleFocus} />
    );
  } else {
    const { ...rest } = props;

    component = <input {...componentProps} {...rest} onBlur={handleBlur} onFocus={handleFocus} />;
  }

  return (
    <div className={`${inputStyle('group')} ${mix}`}>
      <label className={inputStyle('label', { type: props.type })} htmlFor={itemId}>
        {label} {required ? <span className={inputStyle('indicator')}>({translate('required')})</span> : null}
      </label>
      {component}

      {isFeedbackShowing ? <FieldFeedback message={feedback} /> : null}
      <p className={inputStyle('description')}>{description}</p>
    </div>
  );
};

export default Field;
