import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '../../../utility/hooks';
import { Button } from '../../Atoms';
import { dialogActions } from '../../../modules/dialog';

const PasswordChangedFeedback = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const openLoginDialog = useCallback(() => {
    dispatch(dialogActions.openAuthDialog());
  }, [dispatch]);

  return (
    <>
      <p>{translate('Password has been reset successfully')}.</p>
      <Button label={translate('Login with new password')} onClick={openLoginDialog} block />
    </>
  );
};

export default PasswordChangedFeedback;
