const placeholder = `
  <svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="1920" height="1080" fill="white"/>
    <path d="M1072.5 602.5H897.5V427.5H1072.5V602.5ZM1072.5 402.5H897.5C890.87 402.5 884.511 405.134 879.822 409.822C875.134 414.511 872.5 420.87 872.5 427.5V602.5C872.5 609.13 875.134 615.489 879.822 620.178C884.511 624.866 890.87 627.5 897.5 627.5H1072.5C1079.13 627.5 1085.49 624.866 1090.18 620.178C1094.87 615.489 1097.5 609.13 1097.5 602.5V427.5C1097.5 420.87 1094.87 414.511 1090.18 409.822C1085.49 405.134 1079.13 402.5 1072.5 402.5ZM847.5 452.5H822.5V652.5C822.5 659.13 825.134 665.489 829.822 670.178C834.511 674.866 840.87 677.5 847.5 677.5H1047.5V652.5H847.5V452.5ZM1009.5 518.625L975.125 562.875L950.625 533.375L916.25 577.5H1053.75L1009.5 518.625Z" fill="#CCCCCC"/>
    <rect x="9" y="9" width="1902" height="1062" rx="45" stroke="#CCCCCC" stroke-width="10" stroke-dasharray="10 10"/>
  </svg>
`;

const placeholderURL = URL.createObjectURL(new Blob([placeholder], { type: 'image/svg+xml' }));

export default placeholderURL;
