import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { modelSettingsSelectors } from '../../../modules/settings';
import { uiSelectors } from '../../../modules/ui';
import { getAreaLabel } from '../../../utility';

import './Price.scss';

const priceStyle = b.with('price');

const Area = ({ totalArea, areaHidden, modelUnits }) => {
  if (totalArea !== null && totalArea !== undefined && !areaHidden) {
    const areaLabel = getAreaLabel(totalArea, modelUnits);

    return ` | ${areaLabel}`;
  }

  return null;
};

const Price = ({ priceHidden, areaHidden, totalPrice, totalArea }) => {
  const { currency, currencyFormatter } = useSelector(uiSelectors.selectPriceSettingsByPriceScheme);
  const modelUnits = useSelector(modelSettingsSelectors.selectModelUnits);

  if ((priceHidden && areaHidden) || totalPrice === null) return null;

  return (
    <span className={priceStyle()}>
      {!priceHidden ? `${currency} ${currencyFormatter(totalPrice)}` : null}
      {Area({ totalArea, areaHidden, modelUnits })}
    </span>
  );
};

Price.defaultProps = {
  priceHidden: false,
  areaHidden: false,
  totalPrice: null,
  totalArea: null
};

Price.propTypes = {
  priceHidden: PropTypes.bool,
  areaHidden: PropTypes.bool,
  totalPrice: PropTypes.number,
  totalArea: PropTypes.number
};

export default Price;
