import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import PreviewPart from './PreviewPart';

const HEADERS = {
  'Creator/partPreview': true,
  'Creator/seedPreview': true
};

const PARAM_PART = 'part';

const Preview = () => {
  const [parts, setParts] = useState([]);
  const [textures, setTextures] = useState([]);
  const [rootPartId, setRootPartId] = useState('');
  const [projectId, setProjectId] = useState('');

  const handleReceiveMessage = useCallback(({ data }) => {
    if (typeof data === 'object' && HEADERS[data.header]) {
      setParts(data.parts);
      setTextures(data.textures);
      setRootPartId(data.rootPartId);
      setProjectId(data.projectId);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleReceiveMessage, false);

    return () => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  }, [handleReceiveMessage]);

  const params = useParams();

  if (params.component === PARAM_PART) {
    return parts.length > 0 ? (
      <PreviewPart parts={parts} textures={textures} rootPartId={rootPartId} projectId={projectId} />
    ) : null;
  }

  return <div>This is development component preview page.</div>;
};

export default Preview;
