import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useControl from '../../../utility/hooks/useControl';
import { Dropdown as DropdownAtom } from '../../Atoms';
import ControlOptionCaption from '../ControlOptionCaption';
import { uiSelectors } from '../../../modules/ui';
import { DefaultControlProps } from '../DefaultControl/DefaultControl';
import { DefaultOptionProps } from '../DefaultControl/DefaultOption/DefaultOption';

const compareFunction = (current: DefaultOptionProps, item: DefaultOptionProps) => item.isSelected;
const disabledItemFunc = (item: DefaultOptionProps) => item?.option?.locked || item?.option?.fixed;
const inactiveItemFunc = (item: DefaultOptionProps) => item?.option?.inactive;

const Dropdown = ({ control, onOptionSelect, activeTags }: DefaultControlProps) => {
  const isMobileNavigation = useSelector(uiSelectors.selectIsMobileNavigation);
  const { options, selectedIndex } = useControl(control, onOptionSelect, activeTags);
  const handleDropdown = useCallback(
    item => {
      const { option } = item;

      onOptionSelect(control, option);
    },
    [control, onOptionSelect]
  );

  const itemRender = useCallback(
    (item, active, selected = false) => {
      const { option } = item;

      if (!option) return null;

      return <ControlOptionCaption type={control.type} option={option} active={active} ignoreImage={selected} />;
    },
    [control.type]
  );

  return (
    <DropdownAtom
      onChange={handleDropdown}
      options={options}
      itemRender={itemRender}
      value={options[selectedIndex]}
      compareFunction={compareFunction}
      disabledItemFunc={disabledItemFunc}
      inactiveItemFunc={inactiveItemFunc}
      useFixedPosition={isMobileNavigation}
      maxVisibleItems={7}
      unmountOnExit={false}
      useBackdrop
    />
  );
};

export default Dropdown;
