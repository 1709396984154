import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useActions, useLinkGenerators, useTranslate } from '../../../utility/hooks';
import { MenuSection, MenuItem, menuStyle } from '../../Menu';
import { instanceSelectors } from '../../../modules/instance';
import UnsavedIndicator from '../../UnsavedIndicator';
import { uiSelectors } from '../../../modules/ui';
import { useTabLink } from '../../TabLink';
import { authSelectors } from '../../../modules/auth';
import MenuDeprecationNotification from './MenuDeprecationNotification';

const MenuBody = ({ onCloseMenu }) => {
  const translate = useTranslate();
  const makeTabLink = useTabLink();
  const { actions, handleClickOnAction, DIALOGS, TYPES } = useActions('menu');
  const userEmail = useSelector(authSelectors.getUserEmail);
  const { getCustomLink } = useLinkGenerators();

  const designsCount = useSelector(instanceSelectors.selectUserInstancesCount);
  const isUnsaved = useSelector(uiSelectors.getIsUnsavedIndicatorVisible);

  const getDescription = useCallback(
    (description, dialog) => {
      if (dialog === DIALOGS.PROFILE) {
        return `(${designsCount})`;
      }

      if (dialog === DIALOGS.LOGOUT) {
        return userEmail;
      }

      return translate(description);
    },
    [DIALOGS.LOGOUT, DIALOGS.PROFILE, designsCount, userEmail, translate]
  );

  const handleClickOnTabLink = useCallback(
    e => {
      handleClickOnAction(e);
      onCloseMenu();
    },
    [handleClickOnAction, onCloseMenu]
  );

  return (
    <>
      <MenuSection type="deprecated">
        <MenuDeprecationNotification />
      </MenuSection>
      {actions.map(({ section, actions: sectionActions }) => (
        <MenuSection type={section} key={section}>
          {sectionActions.map(action => {
            if (action.type === TYPES.OPEN_DIALOG) {
              return (
                <MenuItem
                  key={action.name}
                  type={action.dialog === DIALOGS.SAVE && isUnsaved ? 'unsaved' : action.dialog}
                  onClick={handleClickOnAction}
                  name={translate(action.displayName)}
                  description={getDescription(action.description, action.dialog)}
                  action={action.name}
                >
                  {action.dialog === DIALOGS.SAVE ? (
                    <div className={menuStyle('unsaved-indicator')}>
                      <UnsavedIndicator border={false} />
                    </div>
                  ) : null}
                </MenuItem>
              );
            }

            if (action.type === TYPES.OPEN_TAB) {
              return (
                <Link
                  key={action.name}
                  to={makeTabLink(action.tab)}
                  className={menuStyle('item')}
                  onClick={handleClickOnTabLink}
                  data-action={action.name}
                >
                  {translate(action.displayName)}
                </Link>
              );
            }

            if (action.type === TYPES.OPEN_LINK) {
              return (
                <a
                  key={action.name}
                  href={getCustomLink(action.link)}
                  className={menuStyle('item')}
                  onClick={handleClickOnAction}
                  data-action={action.heap}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {translate(action.displayName)}
                </a>
              );
            }

            return null;
          })}
        </MenuSection>
      ))}
    </>
  );
};

MenuBody.propTypes = {
  onCloseMenu: PropTypes.func.isRequired
};

export default MenuBody;
