import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Vector3 } from 'three';
import { CommentType } from 'types';
import { cameraSelectors } from '../../../store/camera';
import { dialogActions } from '../../../modules/dialog';
import { instanceSelectors } from '../../../modules/instance';
import { uiActions } from '../../../modules/ui';
import { userInstancesActions } from '../../../modules/userInstances';
import { useTranslate } from '../../../utility/hooks';
import { Button, Field } from '../../Atoms';
import { MenuContent } from '../../Menu';
import MenuSection from '../../Menu/MenuSection';
import getThreeSetup from '../../Viewer/getThreeSetup';
import commentStyle from './commentStyle';
import LocationMarker from './LocationMarker';

interface AddCommentMenuProps {
  onSetClassName: (className: string) => void;
}

const AddCommentMenu = ({ onSetClassName }: AddCommentMenuProps) => {
  const translate = useTranslate();
  const { camera } = getThreeSetup();
  const instanceId = useSelector(instanceSelectors.selectCurrentInstanceId);

  const dispatch = useDispatch();
  const [content, setContent] = useState('');
  const view = useSelector(cameraSelectors.selectCameraViewName);
  const [position, setPosition] = useState<[number, number, number]>();
  const [partKey, setPartKey] = useState('');
  const [addingMode, setAddingMode] = useState(false);

  const [focused, setFocused] = useState(false);

  /** configure menu */

  useEffect(() => {
    onSetClassName(commentStyle('menu', { 'half-height': !focused }));

    return () => {
      onSetClassName('');
    };
  }, [focused, onSetClassName]);

  /** Want to close on next render not this so that the component which
   * shows all pins gets chance to be updated.
   */
  const [doClose, setDoClose] = useState(false);

  const onClose = useCallback(() => {
    dispatch(dialogActions.hideDialog());
  }, [dispatch]);

  useEffect(() => {
    if (doClose) {
      onClose();
    }
  }, [doClose, onClose]);

  const comments: CommentType[] | undefined = useSelector(instanceSelectors.selectInstanceComments);

  /** Find next id based on max number of current ids */
  const maxId = useMemo(() => {
    let max = 0;

    comments?.forEach(comment => {
      if (comment._id > max) max = comment._id;
    });

    return max;
  }, [comments]);

  const handleRemovePin = useCallback(() => {
    setPartKey('');
    setPosition(undefined);
  }, []);

  const handleAdd = useCallback(() => {
    const commentData: CommentType = {
      _id: maxId + 1,
      content,
      view,
      cameraMatrix: camera.matrix.toArray(),
      position,
      partKey,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    dispatch(userInstancesActions.addComment(commentData, instanceId));
    setDoClose(true);
  }, [camera.matrix, content, dispatch, instanceId, maxId, partKey, position, view]);

  const handleAddPin = useCallback((key: string, point?: Vector3) => {
    setPartKey(key);
    setPosition(point?.toArray());
  }, []);

  const handleSetTemporaryPinVisible = useCallback(
    (visible: boolean) => {
      dispatch(uiActions.setTemporaryCommentPin(visible ? position : undefined));
    },
    [dispatch, position]
  );

  useEffect(() => {
    if (position) dispatch(uiActions.setTemporaryCommentPin(position));

    return () => {
      dispatch(uiActions.resetTemporaryCommentPin());
    };
  }, [dispatch, position]);

  return (
    <MenuContent>
      <MenuSection type="add-comment" heading={translate('Add comment')} stickyHeader={!focused}>
        <Field
          name="content"
          type="textarea"
          required
          label={translate('Text')}
          onChange={e => setContent(e.currentTarget.value)}
          autoHeight
          value={content}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={addingMode}
        />
        <LocationMarker
          addingMode={addingMode}
          onSetAddingMode={setAddingMode}
          onSetTemporaryPinVisible={handleSetTemporaryPinVisible}
          empty={!position}
          onAddPin={handleAddPin}
          onRemovePin={handleRemovePin}
        />
      </MenuSection>
      <MenuSection type="comments-footer">
        <div className={commentStyle('footer')}>
          <Button mix={commentStyle('footer-btn')} size="lg" block rounded color="main-outline" bold onClick={onClose}>
            {translate('Cancel')}
          </Button>
          <Button
            mix={commentStyle('footer-btn')}
            size="lg"
            block
            onClick={handleAdd}
            bold
            rounded
            disabled={!content || addingMode}
          >
            {translate('Submit')}
          </Button>
        </div>
      </MenuSection>
    </MenuContent>
  );
};

export default AddCommentMenu;
