import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ZoomButtons from '../ZoomButtons';
import useOrbit from './useOrbit';
import useCameraCallbacks from './useCameraCallbacks';
import useCameraSettings from './useCameraSettings';
import useCamera from './useCamera';
import useCameraEffects from './useCameraEffects';

const Camera = ({ embeddedPreview }) => {
  // get camera coordinates
  const { cameraSettings, cameraPosition, cameraTarget, cameraViewName, cameraViewAnimationDisabled, cameraViewType } =
    useCameraSettings();

  const camera = useCamera(cameraViewType);

  // get callback functions to pass to orbit useCameraEffects
  const { updateCamera, reset, zoomIn, zoomOut, orbitHandler, isCameraMoved } = useCameraCallbacks(
    camera,
    cameraPosition,
    cameraTarget,
    cameraSettings,
    embeddedPreview
  );

  useCameraEffects(
    camera,
    updateCamera,
    cameraViewAnimationDisabled,
    cameraSettings,
    cameraViewName,
    isCameraMoved,
    reset,
    cameraPosition,
    cameraTarget
  );

  useOrbit(cameraViewName, cameraViewType, cameraSettings, orbitHandler);

  return <ZoomButtons onFocus={reset} onZoomIn={zoomIn} onZoomOut={zoomOut} />;
};

Camera.propTypes = {
  embeddedPreview: PropTypes.bool
};

Camera.defaultProps = {
  embeddedPreview: false
};

export default memo(Camera);
