import { HTMLAttributes, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { EquirectangularReflectionMapping, PerspectiveCamera, Scene, TextureLoader, WebGLRenderer } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import ResizeObserver from 'react-resize-observer';
import b from 'b_';
import './Panorama.scss';
import Icon from '../../Icon';

const panoramaStyles = b.with('panorama');

const textureLoader = new TextureLoader();

// set targetCube instead of targetCube.texture because scene background requires WebGLRenderTargetCube instead of CubeTexture
// setting environment map will use texture property of this WebGLRenderTargetCube

interface PanoramaProps extends HTMLAttributes<HTMLDivElement> {
  mix?: string;
  fullscreen?: boolean;
  src: string;
  imgRef?: MutableRefObject<HTMLDivElement>;
}
const Panorama = ({ src, mix = '', fullscreen = false, imgRef, ...rest }: PanoramaProps) => {
  const localPanoramaRef = useRef<HTMLDivElement>(null);
  const panoramaRef = imgRef || localPanoramaRef;

  const rendererRef = useRef<WebGLRenderer>();
  const cameraRef = useRef<PerspectiveCamera>();

  const [isIconShowing, setIconShowing] = useState(true);

  useEffect(() => {
    const { current: panorama } = panoramaRef;

    // fast return if no panorama
    if (!panorama) {
      return () => {
        // nothing to clean up
      };
    }

    const renderer = new WebGLRenderer();

    panorama.appendChild(renderer.domElement);
    renderer.setSize(panorama.clientWidth, panorama.clientHeight);

    rendererRef.current = renderer;
    const scene = new Scene();

    textureLoader.load(src, texture => {
      // eslint-disable-next-line no-param-reassign
      texture.mapping = EquirectangularReflectionMapping;
      scene.background = texture;
    });

    const camera = new PerspectiveCamera(80, panorama.clientWidth / panorama.clientHeight);

    cameraRef.current = camera;

    camera.position.set(0, 0, 1);

    const orbitControls = new OrbitControls(camera, renderer.domElement);

    orbitControls.enableDamping = true;
    orbitControls.rotateSpeed = -0.25;
    orbitControls.autoRotate = true;
    orbitControls.autoRotateSpeed = 0.2;

    const handleEnd = () => {
      orbitControls.autoRotate = false;
    };

    const handleStart = () => {
      setIconShowing(false);
    };

    orbitControls.addEventListener('end', handleEnd);
    orbitControls.addEventListener('start', handleStart);

    renderer.setAnimationLoop(() => {
      orbitControls.update();
      renderer.render(scene, camera);
    });

    return () => {
      renderer.setAnimationLoop(null);

      if (panorama) panorama.removeChild(renderer.domElement);
      renderer.dispose();

      if (orbitControls) orbitControls.dispose();
      rendererRef.current = undefined;
      cameraRef.current = undefined;
    };
  }, [panoramaRef, src]);

  const handleResize = useCallback(({ width, height }) => {
    if (rendererRef.current) {
      rendererRef.current.setSize(width, height);
    }

    if (cameraRef.current) {
      cameraRef.current.aspect = width / height;
      cameraRef.current.updateProjectionMatrix();
    }
  }, []);

  return (
    <div ref={panoramaRef} className={`${panoramaStyles({ fullscreen })} ${mix}`} {...rest}>
      <ResizeObserver onResize={handleResize} />
      {isIconShowing ? (
        <div className={panoramaStyles('orbit')}>
          <div className={panoramaStyles('orbit-wrapper')}>
            <div className={panoramaStyles('orbit-sonar')} />
            <Icon type="orbit" mix={panoramaStyles('orbit-icon')} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Panorama;
