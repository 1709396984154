import { useSelector } from 'react-redux';
import { interfaceSelectors } from '../../modules/settings';
import { summarySelectors, uiSelectors } from '../../modules/ui';

const useTotalPrice = () => {
  const { isSummaryPriceHidden: isPriceHidden } = useSelector(interfaceSelectors.selectInterfaceSettings);
  const { currency, priceFormatter } = useSelector(uiSelectors.selectPriceSettingsByPriceScheme);
  const rawTotalPrice = useSelector(summarySelectors.getTotalPrice);

  return { isPriceHidden, currency, rawTotalPrice, priceFormatter };
};

export default useTotalPrice;
