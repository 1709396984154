import React, { useCallback } from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';
import { uiSelectors } from '../../modules/ui';
import Actions from '../Actions';

import './MainActions.scss';

const mainActionsStyle = b.with('main-actions');

const MainActions = () => {
  const isSummaryTab = useSelector(uiSelectors.getIsSummaryTab);
  const isIndexTab = useSelector(uiSelectors.getIsIndexTab);

  const renderActions = useCallback(() => {
    if (isIndexTab) {
      return <Actions group="aboutTab" mix={mainActionsStyle('action', { about: true })} />;
    }

    if (isSummaryTab) {
      return <Actions group="summaryTab" mix={mainActionsStyle('action', { summary: true })} />;
    }

    return (
      <Actions group="overviewPanel" mix={mainActionsStyle('action', { overview: true })} rounded={false} size="md" />
    );
  }, [isIndexTab, isSummaryTab]);

  return <div className={mainActionsStyle()}>{renderActions()}</div>;
};

export default MainActions;
