import { createSelector } from 'reselect';
import { RootState } from '../configureStore';
import { materialStore, spriteMaterialStore } from './materials';

export const selectStateMaterials = (state: RootState) => state.materials;

const listToMap = <T extends { name: string } & Record<string, any> = { name: string }>(list: T[]) => {
  const map: Record<string, T> = {};

  list.forEach(item => {
    map[item.name] = item;
  });

  return map;
};

export const selectMaterials = createSelector(selectStateMaterials, ({ list }) => {
  return listToMap(Object.keys(list).map(name => materialStore[name]));
});

export const selectSpriteMaterials = createSelector(selectStateMaterials, ({ spriteMaterials }) =>
  listToMap(Object.keys(spriteMaterials).map(name => spriteMaterialStore[name]))
);
