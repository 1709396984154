import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { uiSelectors } from '../../../modules/ui';
import { MenuContent, MenuSection, menuStyle } from '../../Menu';
import { loaderSelectors } from '../../../modules/loader';
import useMenuClose from '../../Menu/useMenuClose';
import MenuBody from './MenuBody';
import MenuProject from './MenuProject';
import MenuLanguage from './MenuLanguage';

const MainMenu = ({ onSetHeader }) => {
  const handleClose = useMenuClose();
  const { headerLogo } = useSelector(uiSelectors.selectUiImages);
  const isSplashScreenActive = useSelector(loaderSelectors.getIsSplashScreenActive);

  useEffect(() => {
    if (isSplashScreenActive) {
      handleClose();
    }
  }, [handleClose, isSplashScreenActive]);

  const header = useMemo(() => {
    return headerLogo ? <img src={headerLogo} className={menuStyle('logo')} alt="Logo" /> : null;
  }, [headerLogo]);

  useEffect(() => {
    onSetHeader(header);

    return () => onSetHeader(null);
  }, [header, onSetHeader]);

  return (
    <>
      <MenuContent>
        <MenuSection type="header">
          <MenuLanguage />
          <MenuProject />
        </MenuSection>
        <MenuBody onCloseMenu={handleClose} />
      </MenuContent>
    </>
  );
};

MainMenu.propTypes = {
  onSetHeader: PropTypes.func.isRequired
};

export default MainMenu;
