import { useState, useEffect, useMemo, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { selectExtraInfoCollectionsMap } from '../../../modules/settings/settingsSelectors';
import { modelSettingsSelectors } from '../../../modules/settings';
import { useTranslate } from '../../../utility/hooks';
import { Gallery } from '../../Atoms';
import Icon from '../../Icon';
import MarkdownContent from '../../MarkdownContent';
import { extraInfoDialogStyle } from '../extraInfoStyle';

interface ExtraInfoProps {
  collectionName: string;
  children?: ReactNode;
  onToggleFullscreenImage?: (index: number) => void;
}

const ExtraInfo = ({ collectionName, children, onToggleFullscreenImage }: ExtraInfoProps) => {
  const [panelIndex, setPanelIndex] = useState(0);
  const collections = useSelector(selectExtraInfoCollectionsMap);
  const generateImageURL = useSelector(modelSettingsSelectors.selectImageUrlGenerator);

  const collection = collections[collectionName] || {};
  const translate = useTranslate();
  const { infoPanels = [], externalLink } = collection;

  const currentPanel = infoPanels[panelIndex];

  useEffect(() => {
    if (infoPanels.length <= panelIndex) {
      setPanelIndex(0);
    }
  }, [infoPanels.length, panelIndex]);

  const images = useMemo(
    () =>
      infoPanels
        .filter(({ image }) => Boolean(image?.fileName))
        .map(({ image }) => ({ original: generateImageURL(image.fileName), isPanorama: image.isPanorama })),
    [generateImageURL, infoPanels]
  );

  const textContent = useMemo(
    () => (currentPanel ? currentPanel.textHtml || (currentPanel.text || '').split('\n').join('<br/>') : ''),
    [currentPanel]
  );

  if (!currentPanel) return null;

  return (
    <section className={extraInfoDialogStyle('body')}>
      <h3 className={extraInfoDialogStyle('header')}>{translate(currentPanel.caption)}</h3>
      {images.length ? (
        <div className={extraInfoDialogStyle('gallery')}>
          <Gallery
            images={images}
            imagesMix={extraInfoDialogStyle('images')}
            showFullscreenButton
            onToggleFullscreen={onToggleFullscreenImage}
            onChangeActiveIndex={setPanelIndex}
          />
        </div>
      ) : null}
      <div className={extraInfoDialogStyle('info')}>
        <MarkdownContent html={translate(textContent)} />
      </div>
      {externalLink ? (
        <div className={extraInfoDialogStyle('links')}>
          {externalLink.url ? (
            <a
              href={externalLink.url}
              target="_blank"
              rel="noopener noreferrer"
              className={extraInfoDialogStyle('external-link')}
            >
              {translate(externalLink.label) || externalLink.url} <Icon type="open-in-new" scale={0.9} />
            </a>
          ) : null}
        </div>
      ) : null}

      {children}
    </section>
  );
};

export default ExtraInfo;
