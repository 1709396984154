import React from 'react';
import PropTypes from 'prop-types';
import { useCaveats } from '../../utility/hooks';
import CaveatItem from './CaveatItem';

const Caveats = ({ forceCollapse }) => {
  const caveats = useCaveats();

  return caveats.map(({ title, value, icon, image }) => (
    <CaveatItem title={title} value={value} icon={icon} key={title} forceCollapse={forceCollapse} image={image} />
  ));
};

Caveats.defaultProps = {
  forceCollapse: false
};

Caveats.propTypes = {
  forceCollapse: PropTypes.bool
};

export default Caveats;
