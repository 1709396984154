import React from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';
import { loaderSelectors } from '../../../modules/loader';
import { uiSelectors } from '../../../modules/ui';
import { interfaceSelectors } from '../../../modules/settings';
import placeholderURL from '../../../utility/galleryPlaceholderUrl';

import './AboutPageVideo.scss';

const aboutPageVideoStyle = b.with('about-page-video');

const AboutPageVideo = () => {
  const isSplashScreenActive = useSelector(loaderSelectors.getIsSplashScreenActive);
  const galleryImages = useSelector(uiSelectors.selectIntroImages);
  const isGalleryHidden = useSelector(interfaceSelectors.selectIsGalleryHidden);
  const introVideo = useSelector(uiSelectors.selectIntroVideo);

  if (isGalleryHidden || isSplashScreenActive) {
    return null;
  }

  return (
    <div className={aboutPageVideoStyle()}>
      <img
        src={galleryImages[0]?.original || placeholderURL}
        alt="Introduction video placeholder"
        className={aboutPageVideoStyle('placeholder')}
      />
      <iframe src={introVideo} frameBorder="0" className={aboutPageVideoStyle('frame')} title="Intro" height="340" />
    </div>
  );
};

export default AboutPageVideo;
