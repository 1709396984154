import React from 'react';
import PropTypes from 'prop-types';
import menuStyle from './style';
import MenuItemDescription from './MenuItemDescription';

const MenuItem = ({ onClick, name, type, description, disabled, children, action }) => (
  <button className={menuStyle('item', { disabled, type })} onClick={onClick} type="button" data-action={action}>
    <span className={menuStyle('item-name', { type })}>{name}</span>
    {description ? <MenuItemDescription>{description}</MenuItemDescription> : null}
    {children || null}
  </button>
);

MenuItem.defaultProps = {
  type: undefined,
  description: '',
  disabled: false,
  onClick: undefined,
  children: null,
  action: ''
};

MenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  action: PropTypes.string
};

export default MenuItem;
