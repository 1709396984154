import React from 'react';
import PropTypes from 'prop-types';
import { MenuSection } from '../../Menu';
import { useTranslate } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import { Button } from '../../Atoms';

const ConfirmMenu = ({ heading, description, label, onSubmit, onCancel, disabled, disabledLabel, cancelLabel }) => {
  const translate = useTranslate();

  return (
    <MenuContent>
      <MenuSection type="confirm-form" heading={translate(heading)} description={translate(description)}>
        <Button onClick={onCancel} color="main-outline" disabled={disabled}>
          {translate(cancelLabel)}
        </Button>
        <Button onClick={onSubmit} disabled={disabled}>
          {disabled ? translate(disabledLabel || label) : translate(label)}
        </Button>
      </MenuSection>
    </MenuContent>
  );
};

ConfirmMenu.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  heading: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
  disabledLabel: PropTypes.string,
  cancelLabel: PropTypes.string
};

ConfirmMenu.defaultProps = {
  heading: 'Are you sure you wish to continue with this action?',
  description: 'Click on Continue to proceed',
  label: 'Continue',
  cancelLabel: 'Cancel',
  disabledLabel: undefined,
  disabled: false
};

export default ConfirmMenu;
