import React from 'react';
import PropTypes from 'prop-types';
import { Matrix4 } from 'three';
import Icon from '../../../Icon';
import SceneButton from '../SceneButton';

const SceneButtons = ({ matrix, onMove, onCopy, onDelete, isDeleteButtonVisible }) => {
  return (
    <>
      {isDeleteButtonVisible ? (
        <SceneButton matrix={matrix} onClick={onDelete} type="delete">
          <Icon type="trash" />
        </SceneButton>
      ) : null}
      <SceneButton matrix={matrix} onClick={onCopy} type="copy">
        <Icon type="content-copy" />
      </SceneButton>
      <SceneButton matrix={matrix} onClick={onMove} type="move">
        <Icon type="open-with" />
      </SceneButton>
    </>
  );
};

SceneButtons.propTypes = {
  matrix: PropTypes.instanceOf(Matrix4).isRequired,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  isDeleteButtonVisible: PropTypes.bool.isRequired
};

export default SceneButtons;
