import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Icon from '../../Icon';
import galleryStyle from './style';
import { IMAGE_LIST_PROP_TYPE } from './galleryPropTypes';

const GalleryNavigationControls = ({ images, activeIndex, onPrevSlide, onNextSlide, type }) => {
  return images.length < 2 ? null : (
    <div className={galleryStyle('controls', { type })}>
      <Button color="main-outline" mix={galleryStyle('button', { type: 'prev' })} circular onClick={onPrevSlide}>
        <Icon type="chevron-left" />
      </Button>
      <span className={galleryStyle('indicator')}>
        {activeIndex + 1} / {images.length}
      </span>
      <Button color="main-outline" mix={galleryStyle('button', { type: 'next' })} circular onClick={onNextSlide}>
        <Icon type="chevron-right" />
      </Button>
    </div>
  );
};

GalleryNavigationControls.defaultProps = {
  type: 'gallery'
};

GalleryNavigationControls.propTypes = {
  images: IMAGE_LIST_PROP_TYPE.isRequired,
  activeIndex: PropTypes.number.isRequired,
  onPrevSlide: PropTypes.func.isRequired,
  onNextSlide: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['gallery', 'fullscreen'])
};

export default GalleryNavigationControls;
