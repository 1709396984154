import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { settingsSelectors } from '../../../modules/settings';
import { uiActions, uiSelectors } from '../../../modules/ui';
import MenuSwitcher from './MenuSwitcher';

const MenuLanguage = () => {
  const dispatch = useDispatch();
  const languages = useSelector(settingsSelectors.selectAvailableLanguages);
  const current = useSelector(uiSelectors.selectCurrentLanguage);
  const onClick = useCallback(
    close => e => {
      const { language } = e.currentTarget.dataset;

      dispatch(uiActions.selectLanguage(language));

      close();
    },
    [dispatch]
  );

  const renderList = useCallback(
    (close, className) =>
      languages.map(language => (
        <button key={language} className={className} type="button" data-language={language} onClick={onClick(close)}>
          {language}
        </button>
      )),
    [languages, onClick]
  );

  if (languages.length < 2) return null;

  return <MenuSwitcher title={current} description="Change language" renderList={renderList} />;
};

export default MenuLanguage;
