import * as dialogActions from './dialogActions';

const initialState = false;

const dialogReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case dialogActions.DIALOG__SHOW:
      return payload;
    case dialogActions.DIALOG__HIDE:
      return false;
    default:
      return state;
  }
};

export default dialogReducer;
