import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CameraState {
  isMoved: boolean; // is camera moved
  name: string; // camera view name as selected by client
  initialViewName: string; // camera view name as selected by tab or current control, fallback and reset target for 'name'
}

const initialState: CameraState = {
  isMoved: false,
  name: '',
  initialViewName: ''
};

const cameraSlice = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    setView: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setMoved: (state, action: PayloadAction<boolean>) => {
      state.isMoved = action.payload;
    },
    reset: state => {
      state.isMoved = false;
      state.name = state.initialViewName;
    },
    updateInitialCameraView: (
      state,
      { payload: { view, isNewControl } }: PayloadAction<{ view?: string; isNewControl?: boolean }>
    ) => {
      if (view) {
        if (isNewControl && view !== state.name) {
          state.isMoved = false;
        }

        state.initialViewName = view;
        state.name = view;
      }
    }
  }
});

export const { actions: cameraActions, reducer: cameraReducer } = cameraSlice;

export default cameraSlice.reducer;
