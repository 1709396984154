import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { DIALOGS } from '../../utility/dialogs';
import { dialogSelectors } from '../../modules/dialog';
import Menu, { menuStyle } from '../Menu';
import MainMenu from './MainMenu';
import ContactMenu from './ContactMenu';
import ShareMenu from './ShareMenu';
import ForgotPasswordMenu from './ForgotPasswordMenu';
import RegisterMenu from './RegisterMenu';
import ResetPasswordMenu from './ResetPasswordMenu';
import SavedDesignsMenu from './SavedDesignsMenu';
import SaveMenu from './SaveMenu';
import LoginMenu from './LoginMenu';
import { ConfirmDeleteInstanceMenu, ConfirmLogoutMenu } from './ConfirmMenu';
import MessageMenu from './MessageMenu';
import BrokenControlsMenu from './BrokenControlsMenu';
import SendToEmailMenu from './SendToEmailMenu';
import PDFMenu from './PDFMenu';
import OpenInstanceMenu from './OpenInstanceMenu';
import CloseChildInstanceMenu from './CloseChildInstanceMenu';
import ExportModelMenu from './ExportModelMenu';
import { AddCommentMenu, EditCommentMenu } from './CommentMenu';

/** Picks correct menu */
const MetaMenu = ({ dialog, onSetHeader, onSetClassName }) => {
  const { meta = {} } = dialog;

  switch (dialog.type) {
    case DIALOGS.LOGIN:
      return <LoginMenu title={meta.loginTitle} signUpTitle={meta.signUpTitle} />;
    case DIALOGS.REGISTER:
      return <RegisterMenu title={meta.signUpTitle} loginTitle={meta.loginTitle} />;
    case DIALOGS.SAVE:
      return <SaveMenu ignoreEmail={meta.ignoreEmail} />;
    case DIALOGS.SHARE:
      return <ShareMenu />;
    case DIALOGS.PROFILE:
      return <SavedDesignsMenu />;
    case DIALOGS.CONTACT:
      return <ContactMenu />;
    case DIALOGS.FORGOT_PASSWORD:
      return <ForgotPasswordMenu />;
    case DIALOGS.RESET_PASSWORD:
      return <ResetPasswordMenu token={meta} />;
    case DIALOGS.MENU:
      return <MainMenu onSetHeader={onSetHeader} />;
    case DIALOGS.CONFIRM_DELETE_INSTANCE:
      return <ConfirmDeleteInstanceMenu />;
    case DIALOGS.CONFIRM_LOGOUT:
      return <ConfirmLogoutMenu />;
    case DIALOGS.MESSAGE:
      return <MessageMenu heading={meta.heading} description={meta.description} content={meta.content} />;
    case DIALOGS.BROKEN_CONTROLS:
      return <BrokenControlsMenu />;
    case DIALOGS.SEND_TO_EMAIL:
      return <SendToEmailMenu />;
    case DIALOGS.OPEN_INSTANCE:
      return <OpenInstanceMenu />;
    case DIALOGS.PDF:
      return <PDFMenu isLoading={meta.isLoading} objectURL={meta.objectURL} filename={meta.filename} />;
    case DIALOGS.CLOSE_CHILD_INSTANCE:
      return <CloseChildInstanceMenu />;
    case DIALOGS.EXPORT_MODEL:
      return <ExportModelMenu />;
    case DIALOGS.EDIT_COMMENT:
      /**
       * Active comment can change when editing a comment. Since each comment also holds its value in state, the component must remount.
       * Using 'key' prop to force a remount.
       */
      return <EditCommentMenu onSetClassName={onSetClassName} commentId={meta.commentId} key={meta.commentId} />;
    case DIALOGS.ADD_COMMENT:
      return <AddCommentMenu onSetClassName={onSetClassName} />;
    default:
      return null;
  }
};

MetaMenu.propTypes = {
  dialog: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.string,
      meta: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})])
    })
  ]).isRequired,
  onSetHeader: PropTypes.func.isRequired,
  onSetClassName: PropTypes.func.isRequired
};

/** Creates slide transitions for changing from one menu to other */
const MenuWrapper = ({ children, type, disabled }) => {
  return (
    <SwitchTransition>
      <CSSTransition
        in={Boolean(type)}
        timeout={150}
        classNames={menuStyle('animation-children')}
        key={type}
        exit={!disabled}
        unmountOnExit
        appear
      >
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
};

MenuWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool
};
MenuWrapper.defaultProps = {
  type: '',
  disabled: false
};

const dialogsWithoutBackdrop = {
  [DIALOGS.ADD_COMMENT]: true,
  [DIALOGS.EDIT_COMMENT]: true
};

/** Creates transition animations for menu */
const MenuDialogs = () => {
  const dialog = useSelector(dialogSelectors.selectDialog);
  const open = Boolean(dialog);

  const [header, setHeader] = useState(null);

  const contentShown = !!dialogsWithoutBackdrop[dialog.type];
  const [className, setClassName] = useState('');

  return (
    <CSSTransition classNames={menuStyle('animation')} in={open} appear unmountOnExit timeout={200}>
      <Menu key={dialog.type} header={header} type={dialog.type} contentShown={contentShown} mix={className}>
        <MenuWrapper type={dialog.type} disabled={open}>
          <MetaMenu dialog={dialog} onSetHeader={setHeader} onSetClassName={setClassName} />
        </MenuWrapper>
      </Menu>
    </CSSTransition>
  );
};

export default MenuDialogs;
