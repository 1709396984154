import { useCallback } from 'react';
import useControl from '../../../utility/hooks/useControl';
import { Switch } from '../../Atoms';
import ControlOptionCaption from '../ControlOptionCaption';
import { optionStyle } from '../Control/style';
import { DefaultControlProps } from '../DefaultControl/DefaultControl';

const MINIMUM_OPTION_COUNT = 2;

const TOGGLE = {
  CHECKED: 1,
  UNCHECKED: 0
} as const;

const Toggle = ({ control, onOptionSelect, activeTags }: DefaultControlProps) => {
  const { options, selectedOption } = useControl(control, onOptionSelect, activeTags);
  const { list = [], name, value } = control;

  const checked = list[TOGGLE.CHECKED] && list[TOGGLE.CHECKED].name === value;

  const toggleHandler = useCallback(
    (controlName: string, newChecked: boolean) => {
      const selectedValue = list[newChecked ? TOGGLE.CHECKED : TOGGLE.UNCHECKED];

      onOptionSelect(control, selectedValue);
    },
    [control, list, onOptionSelect]
  );

  if (list.length < MINIMUM_OPTION_COUNT) return null;

  const { displayName } = selectedOption || list[TOGGLE.UNCHECKED];
  const { option: checkedOption } = options[TOGGLE.CHECKED];

  return (
    <Switch
      onChange={toggleHandler}
      name={name}
      type="toggle"
      checked={checked}
      inactive={checkedOption?.inactive}
      disabled={checkedOption?.locked || checkedOption?.fixed}
      mix={optionStyle({ type: control.type })}
    >
      <ControlOptionCaption type={control.type} option={checkedOption} displayName={displayName} />
    </Switch>
  );
};

export default Toggle;
