/*
  Keep all cookie values strings. That's how browsers like them.
*/

const COOKIES = {
  AGE: (365 * 24 * 60 * 60) / 2, // 6 months

  HAS_VISITED: 'CREATOMUS_CONFIGURATOR_HAS_VISITED',
  HAS_VISITED_VALUE: 'true',

  ALLOW_COOKIES: 'CREATOMUS_CONFIGURATOR_ALLOW_COOKIES',
  ALLOW_COOKIES_VALUE: 'allow',

  DISALLOW_COOKIES_VALUE: 'disallow',

  // cookie to be set if client has sent a request
  HAS_SENT_REQUEST: 'CREATOMUS_CONFIGURATOR_REQUEST_SENT',
  HAS_SENT_REQUEST_VALUE: 'true'
};

export default COOKIES;
