import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { uiSelectors } from '../../../modules/ui';
import { useOnClickOutside, useTranslate } from '../../../utility/hooks';
import tabNavigationStyle from './tabNavigationStyle';
import TabNavigationList from './TabNavigationList';

interface TabNavigationContentProps {
  onClose: () => void;
  isOpen: boolean;
}

const TabNavigationContent = ({ onClose, isOpen }: TabNavigationContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const isMobileNavigation = useSelector(uiSelectors.selectIsMobileNavigation);
  const translate = useTranslate();

  useOnClickOutside(contentRef, onClose, isOpen);

  const rootElement = document.getElementById('root');

  if (!rootElement) return null;

  return ReactDOM.createPortal(
    <>
      <div className={tabNavigationStyle('backdrop', { active: isOpen })} />
      <CSSTransition in={isOpen} timeout={200} classNames={tabNavigationStyle('content')} unmountOnExit>
        <div className={tabNavigationStyle('content', { mobile: isMobileNavigation })} ref={contentRef}>
          <span className={tabNavigationStyle('header')}>{translate('Select category')}</span>
          <div className={tabNavigationStyle('list')}>
            <TabNavigationList isMobileNavigation={isMobileNavigation} onClose={onClose} />
          </div>
        </div>
      </CSSTransition>
    </>,
    rootElement
  );
};

export default TabNavigationContent;
