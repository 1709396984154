import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PartNodeControlType, PartNodeOptionType } from 'types';
import { controlSelectors, priceSelectors } from '../../modules/model';
import { uiActions } from '../../modules/ui';
import { selectedOptionsActions } from '../../modules/selectedOptions';
import ExtraInfoDialog from '../ExtraInfoDialog';
import { useSelectTabWithControl } from '../../utility/hooks';
import Control from './Control';
import ControlsValidator from './ControlsValidator';

interface ControlsPanelProps {
  isMobileNavigation: boolean;
  isMobileViewport: boolean;
  isControlsPanelVisible: boolean;
  canvasContainerRef: React.RefObject<HTMLDivElement>;
}

interface ControlsProps {
  currentEIC: string;
  onCloseExtraInfo: () => void;
  onShowExtraInfo: (eic: string) => void;
  isMobileNavigation: boolean;
}

const Controls = ({ isMobileNavigation, currentEIC, onCloseExtraInfo, onShowExtraInfo }: ControlsProps) => {
  const dispatch = useDispatch();
  const selectTabWithControl = useSelectTabWithControl();
  const selectedControlGroup: PartNodeControlType | undefined = useSelector(controlSelectors.getSelectedControlGroup);

  /** Select control  */
  const handleSelectControl = useCallback(
    (control: PartNodeControlType) => {
      // if control does not bind EIC, close EIC, since active control changed
      if (control.treeName !== selectedControlGroup?.treeName && !control.bindExtraInfo) {
        onCloseExtraInfo();
      }
      selectTabWithControl(undefined, control.name);
    },
    [onCloseExtraInfo, selectTabWithControl, selectedControlGroup]
  );

  const visibleControls: PartNodeControlType[] = useSelector(priceSelectors.selectVisibleControls);
  const childControls: PartNodeControlType[] = useSelector(controlSelectors.selectChildTabControls);

  const handleSelectOption = useCallback(
    (control: PartNodeControlType, option: PartNodeOptionType) => {
      if (control && option && !option.locked) {
        dispatch(selectedOptionsActions.selectOption(control, option));

        // if option has EIC and an EIC is already opened, switch to it
        if (option.extraInfoCollection && currentEIC) {
          onShowExtraInfo(option.extraInfoCollection);
        }

        // if control changed, close EIC
        if (control.treeName !== selectedControlGroup?.treeName) {
          onCloseExtraInfo();
        }
      }
    },
    [currentEIC, dispatch, onCloseExtraInfo, onShowExtraInfo, selectedControlGroup]
  );

  let controls = childControls.length ? childControls : visibleControls;

  if (isMobileNavigation) {
    controls = controls.filter(({ treeName }) => selectedControlGroup?.treeName === treeName);
  }

  return (
    <>
      {controls.map(control => {
        const { treeName } = control;
        const isSelected = selectedControlGroup?.treeName === treeName;

        return (
          <Control
            control={control}
            key={treeName}
            isSelected={isSelected}
            onOptionSelect={handleSelectOption}
            onShowExtraInfo={onShowExtraInfo}
            onCloseExtraInfo={onCloseExtraInfo}
            onSelect={handleSelectControl}
            currentExtraInfoCollection={currentEIC}
            isMobileNavigation={isMobileNavigation}
          />
        );
      })}
    </>
  );
};

const ControlsPanel = ({
  canvasContainerRef,
  isMobileNavigation,
  isControlsPanelVisible,
  isMobileViewport
}: ControlsPanelProps) => {
  /** Extra info collection */
  const [currentEIC, setCurrentEIC] = useState('');
  const dispatch = useDispatch();

  const handleCloseExtraInfo = useCallback(() => {
    setCurrentEIC('');

    if (!isControlsPanelVisible) {
      dispatch(uiActions.showControlsPanel());
    }
  }, [dispatch, isControlsPanelVisible]);

  const handleShowExtraInfo = useCallback(
    (collection: string, manualTrigger = false) => {
      setCurrentEIC(collection);

      if (isMobileNavigation && manualTrigger) {
        dispatch(uiActions.hideControlsPanel());
      }
    },
    [dispatch, isMobileNavigation]
  );

  return (
    <>
      <ControlsValidator />
      <Controls
        onCloseExtraInfo={handleCloseExtraInfo}
        onShowExtraInfo={handleShowExtraInfo}
        isMobileNavigation={isMobileNavigation}
        currentEIC={currentEIC}
      />

      <ExtraInfoDialog
        collectionName={currentEIC}
        canvasContainerRef={canvasContainerRef}
        onClose={handleCloseExtraInfo}
      />
    </>
  );
};

export default ControlsPanel;
