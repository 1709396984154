import { createSelector } from 'reselect';

// todo switch selectors to authSlice

export const selectUser = state => state.authSlice.user;
export const selectLocalConsent = state => state.authSlice.localConsent;
export const selectEmailSent = state => state.authSlice.emailSent;
export const selectPasswordChanged = state => state.authSlice.passwordChanged;
export const selectTokenValid = state => state.authSlice.token.valid || true;
export const selectTokenChecked = state => state.authSlice.token.checked || true;

export const getLoggedIn = createSelector([selectUser], user => Boolean(user && user.uid));
export const getIsJWTAuth = createSelector([selectUser], user => user && user.jwtAuth);
export const getPartnerUserId = createSelector([selectUser], user => (user && user.jwtAuth ? user.userId : null));

export const getUserUid = createSelector([selectUser], user => (user && user.uid ? user.uid : null));
export const getUserEmail = createSelector([selectUser], user => (user ? user.email : null));
