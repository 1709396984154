import NOTIFICATION_MESSAGES from '../../utility/notificationMessages';

export const FETCH_USER_INSTANCES__REQUEST = 'FETCH_USER_INSTANCES__REQUEST';
export const FETCH_USER_INSTANCES__SUCCESS = 'FETCH_USER_INSTANCES__SUCCESS';
export const FETCH_USER_INSTANCES__FAILURE = 'FETCH_USER_INSTANCES__FAILURE';

export const DELETE_INSTANCE = 'DELETE_INSTANCE';
export const DELETE_INSTANCE__CONFIRM = 'DELETE_INSTANCE__CONFIRM';
export const DELETE_INSTANCE__SUCCESS = 'DELETE_INSTANCE__SUCCESS';
export const DELETE_INSTANCE__FAILURE = 'DELETE_INSTANCE__FAILURE';

export const CREATE_INSTANCE = 'CREATE_INSTANCE';
export const CREATE_INSTANCE__SUCCESS = 'CREATE_INSTANCE__SUCCESS';
export const CREATE_INSTANCE__FAILURE = 'CREATE_INSTANCE__FAILURE';

export const SAVE_INSTANCE = 'SAVE_INSTANCE';
export const SAVE_INSTANCE__SUCCESS = 'SAVE_INSTANCE__SUCCESS';
export const SAVE_INSTANCE__FAILURE = 'SAVE_INSTANCE__FAILURE';

export const SAVE_CHILD_INSTANCE = 'SAVE_CHILD_INSTANCE';
export const SAVE_CHILD_INSTANCE__SUCCESS = 'SAVE_CHILD_INSTANCE__SUCCESS';
export const SAVE_CHILD_INSTANCE__FAILURE = 'SAVE_CHILD_INSTANCE__FAILURE';

export const CREATE_CHILD_INSTANCE = 'CREATE_CHILD_INSTANCE';
export const CREATE_CHILD_INSTANCE__SUCCESS = 'CREATE_CHILD_INSTANCE__SUCCESS';
export const CREATE_CHILD_INSTANCE__FAILURE = 'CREATE_CHILD_INSTANCE__FAILURE';

export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';

export const fetchUserInstancesRequest = () => ({
  type: FETCH_USER_INSTANCES__REQUEST
});

export const fetchUserInstancesSuccess = instances => ({
  type: FETCH_USER_INSTANCES__SUCCESS,
  payload: { instances }
});

export const fetchUserInstancesFailure = error => ({
  type: FETCH_USER_INSTANCES__FAILURE,
  error
});

export const deleteInstance = (id, active, historyReplace) => ({
  type: DELETE_INSTANCE,
  payload: { id, active, historyReplace }
});

export const deleteInstanceConfirm = () => ({
  type: DELETE_INSTANCE__CONFIRM
});

export const deleteInstanceSuccess = id => ({
  type: DELETE_INSTANCE__SUCCESS,
  payload: { id },
  meta: {
    notification: true,
    message: NOTIFICATION_MESSAGES.DESIGN_DELETED_SUCCESSFUL
  }
});

export const deleteInstanceFailure = error => ({
  type: DELETE_INSTANCE__FAILURE,
  error
});

export const createInstance = (instanceName, formState = {}, ignoreEmail) => ({
  type: CREATE_INSTANCE,
  payload: { instanceName, formState, ignoreEmail }
});

export const createInstanceSuccess = (instance, email) => ({
  type: CREATE_INSTANCE__SUCCESS,
  payload: { instance, email },
  meta: {
    notification: Boolean(email),
    message: NOTIFICATION_MESSAGES.EMAIL_SENT_SUCCESSFUL
  }
});

export const createInstanceFailure = error => ({
  type: CREATE_INSTANCE__FAILURE,
  meta: {
    notification: true
  },
  error
});

export const saveInstance = (auto = false) => ({
  type: SAVE_INSTANCE,
  payload: { auto }
});

export const saveInstanceSuccess = instance => ({
  type: SAVE_INSTANCE__SUCCESS,
  payload: { instance }
});

export const saveInstanceFailure = error => ({
  type: SAVE_INSTANCE__FAILURE,
  error
});

export const saveChildInstance = () => ({ type: SAVE_CHILD_INSTANCE });
export const saveChildInstanceSuccess = instance => ({ type: SAVE_CHILD_INSTANCE__SUCCESS, payload: { instance } });
export const saveChildInstanceFailure = error => ({ type: SAVE_CHILD_INSTANCE__FAILURE, error });

export const createChildInstance = instanceName => ({
  type: CREATE_CHILD_INSTANCE,
  payload: { instanceName, ignoreEmail: true }
});
export const createChildInstanceSuccess = instance => ({ type: CREATE_CHILD_INSTANCE__SUCCESS, payload: { instance } });
export const createChildInstanceFailure = error => ({
  type: CREATE_CHILD_INSTANCE__FAILURE,
  meta: {
    notification: true
  },
  error
});

/** Comments
 * instanceId is needed to update unsavedInstances list in selectedOptionsReducer
 */
export const addComment = (comment, instanceId) => ({
  type: ADD_COMMENT,
  payload: { comment, instanceId },
  meta: { notification: true, message: NOTIFICATION_MESSAGES.COMMENT_ADDED }
});

export const removeComment = (commentId, instanceId) => ({
  type: REMOVE_COMMENT,
  payload: { commentId, instanceId },
  meta: { notification: true, message: NOTIFICATION_MESSAGES.COMMENT_REMOVED }
});

export const updateComment = (commentId, comment, instanceId) => ({
  type: UPDATE_COMMENT,
  payload: { commentId, comment, instanceId },
  meta: { notification: true, message: NOTIFICATION_MESSAGES.COMMENT_UPDATED }
});
