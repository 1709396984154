import { initialStateActions } from '../initialState';

const initialState = '';

function b64ToUtf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

function decodeTheme(arr) {
  try {
    return arr.reduce((result, theme) => `${result}${b64ToUtf8(theme)}`, '');
  } catch (err) {
    console.error('An error occurred during decoding the theme: ', err); // eslint-disable-line no-console

    return '';
  }
}

const themeReducer = (state = initialState, { type, payload = {} }) =>
  type === initialStateActions.FETCH_INITIAL_STATE__SUCCESS ? decodeTheme(payload.theme) : state;

export default themeReducer;
