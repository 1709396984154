import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../utility/hooks';
import { Button } from '../Atoms';
import { uiSelectors } from '../../modules/ui';
import Icon from '../Icon';

import './ExitFullscreenButton.scss';

const exitFullscreenButtonStyle = b.with('exit-fullscreen-button');

const ExitFullscreenButton = ({ onClick, type }) => {
  const translate = useTranslate();
  const isFullscreen = useSelector(uiSelectors.selectIsFullscreen);

  if (!isFullscreen) return null;

  return (
    <Button color="main-outline" mix={exitFullscreenButtonStyle({ type })} rounded bold size="lg" onClick={onClick}>
      <span className={exitFullscreenButtonStyle('caption')}>{translate('Exit fullscreen')}</span>
      <Icon type="fullscreen-exit" />
    </Button>
  );
};

ExitFullscreenButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default ExitFullscreenButton;
