import getThreeSetup from '../Viewer/getThreeSetup';
import useScene from '../../utility/hooks/viewerHooks/useScene';

const { model } = getThreeSetup();

const SceneGenerator = () => {
  useScene(model);

  return null;
};

export default SceneGenerator;
