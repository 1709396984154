import React, { useCallback } from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import AboutPageInfo from '../AboutPageInfo';
import PreferencesTable from '../PreferencesTable';
import SummaryDetails from '../SummaryDetails';
import { uiSelectors } from '../../../modules/ui';
import { instanceSelectors } from '../../../modules/instance';
import SummaryPageInfo from '../SummaryPageInfo';
import AboutPageVideo from '../AboutPageVideo';
import InfoPageGallery from '../InfoPageGallery';

import './PageContent.scss';

const pageContentStyle = b.with('page-content');

const PageContent = () => {
  const isMobileLayout = useMediaQuery({ maxWidth: 767 });
  const isSummaryTab = useSelector(uiSelectors.getIsSummaryTab);
  const instanceName = useSelector(instanceSelectors.getInstanceName);
  const introVideo = useSelector(uiSelectors.selectIntroVideo);
  const renderSummary = useCallback(
    () => (
      <div className={pageContentStyle('summary', { mobile: isMobileLayout })}>
        <SummaryDetails />
      </div>
    ),
    [isMobileLayout]
  );
  const summaryContentVisible = isSummaryTab || instanceName;

  return (
    <div className={pageContentStyle()}>
      <div className={pageContentStyle('body')}>
        <div className={pageContentStyle('media')}>
          {introVideo && !isSummaryTab ? <AboutPageVideo /> : <InfoPageGallery />}
        </div>
        {summaryContentVisible ? <SummaryPageInfo /> : null}
        {isMobileLayout ? renderSummary() : null}
        {summaryContentVisible ? <PreferencesTable /> : null}
        <AboutPageInfo />
      </div>
      {isMobileLayout ? null : renderSummary()}
    </div>
  );
};

export default PageContent;
