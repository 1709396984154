import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../../utility/hooks';
import { Button } from '../../Atoms';
import ControlFooter from './ControlFooter';
import { controlStyle } from './style';

const BrokenControl = ({ onClick, hasValidOptions, description, displayName }) => {
  const translate = useTranslate();

  return (
    <section className={controlStyle({ broken: true })}>
      <div className={controlStyle('header-wrapper')}>
        <h4 className={controlStyle('header')}>
          <span className={controlStyle('title')}>{translate(displayName)}</span>
        </h4>
      </div>
      <div className={controlStyle('broken-body')}>
        <h5 className={controlStyle('broken-header')}>{translate('The option you selected is no longer available')}</h5>
        {hasValidOptions ? (
          <>
            <span className={controlStyle('broken-message')}>{translate('Please select another one')}</span>
            <Button
              label={translate('Fix it')}
              onClick={onClick}
              color="accent-outline"
              mix={controlStyle('fix-button')}
            />
          </>
        ) : null}
      </div>
      <ControlFooter description={description} />
    </section>
  );
};

BrokenControl.defaultProps = {
  description: '',
  displayName: ''
};

BrokenControl.propTypes = {
  onClick: PropTypes.func.isRequired,
  hasValidOptions: PropTypes.bool.isRequired,
  description: PropTypes.string,
  displayName: PropTypes.string
};

export default BrokenControl;
