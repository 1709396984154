export const FETCH_TEXTURES = 'FETCH_TEXTURES';
export const FETCH_TEXTURES__REQUEST = 'FETCH_TEXTURES__REQUEST';
export const FETCH_TEXTURES__SUCCESS = 'FETCH_TEXTURES__SUCCESS';
export const FETCH_TEXTURES__FAILURE = 'FETCH_TEXTURES__FAILURE';

export const fetchTextures = () => ({
  type: FETCH_TEXTURES
});

export const fetchTexturesRequest = () => ({
  type: FETCH_TEXTURES__REQUEST
});

export const fetchTexturesSuccess = textures => ({
  type: FETCH_TEXTURES__SUCCESS,
  payload: { textures } // TODO get rid of it, we can get it via selectors
});

export const fetchTexturesFailure = error => ({
  type: FETCH_TEXTURES__FAILURE,
  error
});
