import { getSeedInitialState } from './seed/seedReducer';
import { getInstanceInitialState } from './instance/instanceReducer';
import { getSelectedOptionsInitialState } from './selectedOptions/selectedOptionsReducer';

export default function getInitialState(initialState) {
  if (!initialState) {
    return {};
  }

  // SSR
  const { type, seed, project, instance, baseInstance, theme, settings, childSeeds } = initialState;

  return {
    configuratorType: type,
    theme,
    project,
    settings,
    seed: getSeedInitialState(seed, childSeeds),
    instance: getInstanceInitialState(instance, baseInstance),
    selectedOptions: getSelectedOptionsInitialState(instance, baseInstance)
  };
}
