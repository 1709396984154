import { useEffect } from 'react';
import { usePrevious } from '../../../utility/hooks';

/** Sets up and updates Camera views on prop changes. */
const useCameraEffects = (
  camera,
  updateCamera,
  cameraViewAnimationDisabled,
  cameraSettings,
  cameraViewName,
  isMoved,
  reset,
  cameraPosition,
  cameraTarget
) => {
  useEffect(() => {
    // camera aspect is set up in useCanvasCallbacks

    /* eslint-disable no-param-reassign */
    camera.fov = cameraSettings.fov;
    camera.near = cameraSettings.near;
    camera.far = cameraSettings.far;
    /* eslint-enable no-param-reassign */
  }, [camera, cameraPosition, cameraSettings.far, cameraSettings.fov, cameraSettings.near, cameraTarget]);

  /* update camera position */
  const previousDisableAnimation = usePrevious(cameraViewAnimationDisabled);
  const previousCameraView = usePrevious(cameraViewName);

  useEffect(() => {
    if (cameraViewName && (previousCameraView !== cameraViewName || !isMoved)) {
      // animation is disabled to and from view if it's not orbitable or view props state so or there was no previous camera view
      const disableAnimation = !previousCameraView || cameraViewAnimationDisabled || previousDisableAnimation;

      reset(disableAnimation, true);
    }

    updateCamera();
  }, [
    cameraViewAnimationDisabled,
    cameraViewName,
    isMoved,
    previousCameraView,
    previousDisableAnimation,
    reset,
    updateCamera
  ]);
};

export default useCameraEffects;
