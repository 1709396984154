import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { settingsSelectors, interfaceSelectors } from '../../../modules/settings';
import { useLinkGenerators } from '../../../utility/hooks';
import MenuSwitcher from './MenuSwitcher';

const MenuProject = () => {
  const { header: headerText } = useSelector(interfaceSelectors.selectInterfaceSettings);
  const sisterConfigurations = useSelector(settingsSelectors.selectSisterConfigurators);
  const hasSisterConfigurators = sisterConfigurations.length > 0;
  const { getConfiguratorLink } = useLinkGenerators();

  const renderList = useCallback(
    (close, className) =>
      sisterConfigurations.map(({ link, displayName }) => (
        <a
          key={link}
          className={className}
          href={getConfiguratorLink(link)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={close}
        >
          {displayName}
        </a>
      )),
    [getConfiguratorLink, sisterConfigurations]
  );

  if (!headerText) {
    return null;
  }

  return (
    <MenuSwitcher
      title={headerText}
      description={hasSisterConfigurators ? 'Explore other house types' : ''}
      disabled={!hasSisterConfigurators}
      renderList={renderList}
    />
  );
};

export default MenuProject;
