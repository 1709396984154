import { createSelector } from 'reselect';
import { GEOMETRIES_STATUSES } from './geometriesReducer';

export const selectGeometries = state => state.geometries;
export const selectGeometriesStatus = state => state.geometries.status;

export const selectAreGeometriesPending = createSelector(selectGeometries, geometries => geometries.statusCounter < 0);

export const getIsGeometriesReceived = createSelector(
  [selectGeometriesStatus, selectAreGeometriesPending],
  (status, geometriesPending) => status === GEOMETRIES_STATUSES.SUCCESS && !geometriesPending
);
