import * as texturesActions from './texturesActions';

const initialState = {
  status: -1,
  list: []
};

export const TEXTURES_STATUSES = {
  LOADING: 0,
  SUCCESS: 1,
  FAILURE: 2
};

const texturesReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case texturesActions.FETCH_TEXTURES__REQUEST:
      return { ...state, status: TEXTURES_STATUSES.LOADING };
    case texturesActions.FETCH_TEXTURES__SUCCESS:
      return { ...state, status: TEXTURES_STATUSES.SUCCESS, list: payload.textures };
    default:
      return state;
  }
};

export default texturesReducer;
