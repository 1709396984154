import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Switch } from '../../../Atoms';
import { useTranslate } from '../../../../utility/hooks';
import menuStyle from '../../../Menu/style';
import { TYPE_FORM_STATE } from '../../../../utility/propTypes';
import { contactFormSelectors } from '../../../../modules/settings';

const MarketingConsent = ({ onChange, formState, onConsentLabelChange }) => {
  const translate = useTranslate();
  const label = useSelector(contactFormSelectors.selectContactFormConsentMessage);

  const consentLabel = useMemo(() => translate(label), [label, translate]);

  useEffect(() => {
    onConsentLabelChange(consentLabel);
  }, [consentLabel, onConsentLabelChange]);

  return (
    <Switch
      onChange={onChange}
      type="checkbox"
      name="consentGiven"
      checked={formState.consentGiven}
      caption={translate(label)}
      mix={menuStyle('marketing-consent')}
    />
  );
};

MarketingConsent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onConsentLabelChange: PropTypes.func.isRequired,
  formState: TYPE_FORM_STATE.isRequired
};

export default MarketingConsent;
