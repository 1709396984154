import { useMemo, useCallback, useState } from 'react';

const useFieldFeedback = (feedback, onBlur, onFocus) => {
  const [isFocused, setFocused] = useState(false);
  const [isDirty, setDirty] = useState(false);

  const handleBlur = useCallback(
    e => {
      setFocused(false);

      if (onBlur) onBlur(e);
    },
    [onBlur]
  );
  const handleFocus = useCallback(
    e => {
      setDirty(true);
      setFocused(true);

      if (onFocus) onFocus(e);
    },
    [onFocus]
  );

  const isFeedbackShowing = useMemo(() => feedback && !isFocused && isDirty, [feedback, isDirty, isFocused]);

  return { handleBlur, handleFocus, isFocused, isDirty, isFeedbackShowing };
};

export default useFieldFeedback;
