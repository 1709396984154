import React from 'react';
import PropTypes from 'prop-types';
import { optionStyle } from '../Control/style';
import Hint from '../Hint';
import Price from '../Price';
import Status from '../Status';
import { useTranslate } from '../../../utility/hooks';
import { TYPE_CONTROL_OPTION } from '../../../utility/propTypes';
import OptionImage from '../OptionImage';

const ControlOptionCaption = ({ option, type, active, displayName, ignoreImage }) => {
  const translate = useTranslate();
  const parsedType = (option.material || []).length > 0 && !ignoreImage ? `${type}Preview` : type;

  return (
    <div className={optionStyle('caption')}>
      {ignoreImage ? null : (
        <div className={optionStyle('dropdown-image-wrapper', { selected: active })}>
          <OptionImage option={option} />
        </div>
      )}
      <div className={optionStyle('content', { type: parsedType, inactive: option.inactive, locked: option.locked })}>
        <div className={optionStyle('info', { type: parsedType })}>
          <span className={optionStyle('label', { type: parsedType, active })}>
            {translate(displayName || option.displayName || option.name)}
          </span>
          <Hint optionHint={option.optionHint} optionHintHidden={option.optionHintHidden} />
        </div>
        <div className={optionStyle('data', { type: parsedType })}>
          <Price
            priceHidden={option.priceHidden}
            areaHidden={option.areaHidden}
            totalPrice={option.totalPrice}
            totalArea={option.totalArea}
          />
          <Status label={option.statusLabel} />
        </div>
      </div>
    </div>
  );
};

ControlOptionCaption.defaultProps = {
  active: false,
  displayName: '',
  option: {},
  ignoreImage: true
};

ControlOptionCaption.propTypes = {
  option: TYPE_CONTROL_OPTION, // cannot be required because a selected option might not exist
  type: PropTypes.string.isRequired,
  active: PropTypes.bool,
  displayName: PropTypes.string,
  ignoreImage: PropTypes.bool
};

export default ControlOptionCaption;
