import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_URL } from '../../../config';
import getThreeSetup from '../getThreeSetup';
import { cameraActions, cameraSelectors } from '../../../store/camera';
import getFrustum from '../../../utility/getFrustum';
import useCameraAnimation from './useCameraAnimation';
import useCameraControls from './useCameraControls';

/** Send back camera data to parent window via postMessage. */
const updateData = (camera, target) => {
  if (window.parent) {
    const { aspect, far, focus, fov, position } = camera;
    const info = {
      camera: {
        aspect,
        far,
        focus,
        fov,
        position
      },
      target: {
        position: target
      }
    };
    const frameData = {
      header: 'Configurator/ViewerData',
      info
    };

    window.parent.postMessage(frameData, ADMIN_URL);
  }
};

/** Returns callbacks which are used by Camera component. */
const useCameraCallbacks = (camera, cameraPosition, cameraTarget, cameraSettings, embeddedPreview) => {
  const { render, orbit } = getThreeSetup();

  const dispatch = useDispatch();

  const setMoved = useCallback(isMoved => dispatch(cameraActions.setMoved(isMoved)), [dispatch]);
  const isCameraMoved = useSelector(cameraSelectors.selectIsCameraMoved);

  const updateRendererData = useCallback(() => {
    if (embeddedPreview) {
      updateData(camera, orbit.target);
    }
  }, [camera, embeddedPreview, orbit.target]);

  const updateCamera = useCallback(() => {
    camera.updateProjectionMatrix();
    updateRendererData();
    render('camera');
  }, [camera, render, updateRendererData]);

  const set = useCallback(
    (position, target, rotation, zoom, frustum) => {
      camera.position.copy(position);

      /* Keep camera up */
      if (target) {
        orbit.target.copy(target);
        camera.lookAt(target.x, target.y, target.z);
      }

      if (rotation) {
        camera.quaternion.copy(rotation);
      }

      if (zoom) {
        // eslint-disable-next-line no-param-reassign
        camera.zoom = zoom;
      }

      if (frustum && camera.isOrthographicCamera) {
        Object.assign(camera, frustum);
      }
      updateCamera();
    },
    [camera, orbit.target, updateCamera]
  );

  const { interruptAnimation, setAnimated } = useCameraAnimation(camera, set);

  const reset = useCallback(
    (disableAnimation, setCameraMoved) => {
      let frustum;

      if (camera.isOrthographicCamera) {
        frustum = getFrustum(cameraPosition, cameraTarget, cameraSettings.fov, camera.userData.aspect);
      }

      if (disableAnimation) {
        interruptAnimation();

        set(cameraPosition, cameraTarget, undefined, 1, frustum);
      } else {
        setAnimated(cameraPosition, cameraTarget, undefined, 1, frustum);
      }

      if (setCameraMoved) {
        setMoved(false);
      }
    },
    [
      camera.isOrthographicCamera,
      camera.userData.aspect,
      cameraPosition,
      cameraSettings.fov,
      cameraTarget,
      interruptAnimation,
      set,
      setAnimated,
      setMoved
    ]
  );

  const orbitHandler = useCallback(() => {
    if (!isCameraMoved) {
      setMoved(true);
    }
    interruptAnimation();
    updateCamera();
  }, [interruptAnimation, isCameraMoved, setMoved, updateCamera]);

  const { zoomIn, zoomOut } = useCameraControls(camera, setAnimated);

  return {
    set,
    updateCamera,
    reset,
    setAnimated,
    interruptAnimation,
    zoomIn,
    zoomOut,
    orbitHandler,
    isCameraMoved
  };
};

export default useCameraCallbacks;
