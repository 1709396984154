import { useEffect } from 'react';
import getThreeSetup from '../getThreeSetup';
import VIEW_TYPES from '../../../utility/viewTypes';
import { useUiModes } from '../../../utility/hooks';

const ORBIT_VIEW_TYPES = {
  [VIEW_TYPES.PLAN_VIEW]: true,
  [VIEW_TYPES.VIEW]: true,
  [VIEW_TYPES.ORTHO]: true
};

/** Updates Orbit controls on prop changes. */
const useOrbit = (cameraViewName, cameraViewType, cameraSettings, onOrbit) => {
  const { orbit } = getThreeSetup();
  const { isMinimalUi } = useUiModes();

  // init orbit
  useEffect(() => {
    orbit.minDistance = cameraSettings.near;
    orbit.maxDistance = 0.8 * cameraSettings.far;
    orbit.update();
    orbit.addEventListener('change', onOrbit);

    return () => {
      orbit.removeEventListener('change', onOrbit);
    };
  }, [cameraSettings.far, cameraSettings.near, onOrbit, orbit]);

  // update orbit
  useEffect(() => {
    orbit.enabled = !cameraViewName || Boolean(ORBIT_VIEW_TYPES[cameraViewType]);
    orbit.screenSpacePanning = cameraViewType !== VIEW_TYPES.PLAN_VIEW;
  }, [cameraViewName, cameraViewType, orbit, orbit.enabled, orbit.screenSpacePanning]);

  useEffect(() => {
    orbit.enableZoom = !isMinimalUi;
  }, [isMinimalUi, orbit]);
};

export default useOrbit;
