import Icon from '../../Icon';
import inputStyle from '../Field/inputStyle';
import { useTranslate } from '../../../utility/hooks';
import { IconTypes } from '../../Icon/Icon';

interface FieldFeedbackProps {
  type?: 'warning' | 'error' | 'info';
  icon?: IconTypes;
  message?: string;
}

const FieldFeedback = ({ type = 'warning', message = '', icon = 'warning' }: FieldFeedbackProps) => {
  const translate = useTranslate();

  return (
    <p className={inputStyle('feedback', { type })}>
      {icon ? <Icon scale={0.9} type={icon} /> : null} {translate(message) || null}
    </p>
  );
};

export default FieldFeedback;
