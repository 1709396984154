import React from 'react';
import { useTranslate } from '../../../utility/hooks';

const TokenInvalidFeedback = () => {
  const translate = useTranslate();

  return <p>{translate('Password request token invalid or expired, please try to reset again.')}</p>;
};

export default TokenInvalidFeedback;
