import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { MenuSection } from '../../Menu';
import { useTranslate, useFormState } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import { Field, Button } from '../../Atoms';
import { userInstancesActions } from '../../../modules/userInstances';
import { authActions, authSelectors } from '../../../modules/auth';
import { loaderSelectors } from '../../../modules/loader';
import { settingsSelectors } from '../../../modules/settings';
import menuStyle from '../../Menu/style';
import { commonSelectors } from '../../../modules/model';
import { validateEmail, validateOptionalEmail, validateRequired } from '../../../utility/formValidators';

const initialFormState = {
  name: '',
  email: ''
};

const OrphanFields = ({ formState, onFieldChange, isEmailRequired, feedback }) => {
  const translate = useTranslate();

  return (
    <Field
      label={translate('Your email')}
      id="save-form-email"
      name="email"
      type="email"
      onChange={onFieldChange}
      value={formState.email}
      required={isEmailRequired}
      mix={menuStyle('field', { type: 'orphan-email' })}
      description={translate("We don't store this email, it will only be used to send you the link to your design")}
      feedback={feedback}
    />
  );
};

OrphanFields.propTypes = {
  formState: PropTypes.shape({
    email: PropTypes.string,
    generalConsent: PropTypes.bool
  }).isRequired,
  onFieldChange: PropTypes.func.isRequired,
  isEmailRequired: PropTypes.bool.isRequired,
  feedback: PropTypes.string
};

OrphanFields.defaultProps = {
  feedback: undefined
};

const SaveMenu = ({ ignoreEmail }) => {
  const translate = useTranslate();
  const isSaving = useSelector(loaderSelectors.selectIsInstanceSaving);
  const defaultInstanceName = useSelector(commonSelectors.selectDefaultInstanceName);
  const isEmailRequired = useSelector(settingsSelectors.getIsRequireEmailToSaveOrphanInstances);

  const { handleFormFieldChange, formState } = useFormState({
    ...initialFormState,
    name: defaultInstanceName
  });

  const isLoggedIn = useSelector(authSelectors.getLoggedIn);

  const dispatch = useDispatch();

  const handleSave = useCallback(
    e => {
      e.preventDefault();

      if (formState.name) {
        dispatch(
          authActions.updateConsent({ party: 'Creatomus', type: 'general', message: 'General consent message' })
        );
        dispatch(userInstancesActions.createInstance(formState.name, formState, ignoreEmail));
      }
    },
    [dispatch, formState, ignoreEmail]
  );

  const emailFeedback =
    !isLoggedIn && isEmailRequired ? validateEmail(formState.email) : validateOptionalEmail(formState.email);
  const nameFeedback = validateRequired(formState.name, 'Please enter a name for the configuration');

  const saveDisabled = isSaving || nameFeedback || emailFeedback;

  return (
    <MenuContent>
      <form onSubmit={handleSave}>
        <MenuSection type="save-form" heading={translate('Save your design')}>
          <Field
            label={translate('Design name')}
            id="save-form-name"
            name="name"
            type="text"
            onChange={handleFormFieldChange}
            value={formState.name}
            feedback={nameFeedback}
            required
          />
          {!isLoggedIn ? (
            <OrphanFields
              feedback={emailFeedback}
              formState={formState}
              onFieldChange={handleFormFieldChange}
              isEmailRequired={isEmailRequired}
            />
          ) : null}
        </MenuSection>
        <MenuSection type="save-submit">
          <Button type="submit" color="accent" block disabled={saveDisabled}>
            {translate(isSaving ? 'Saving...' : 'Save')}
          </Button>
        </MenuSection>
      </form>
    </MenuContent>
  );
};

SaveMenu.propTypes = {
  ignoreEmail: PropTypes.bool
};

SaveMenu.defaultProps = {
  ignoreEmail: false
};

export default SaveMenu;
