import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as queryString from 'query-string';
import { initialStateActions } from '../modules/initialState';
import { dialogActions } from '../modules/dialog';
import { DIALOGS } from '../utility/dialogs';
import { plotsActions } from '../modules/plots';
import { partsActions } from '../modules/parts';
import { uiActions, uiSelectors } from '../modules/ui';
import PATH from '../utility/paths';
import { CONFIGURATOR_TYPES } from '../modules/configuratorType/configuratorTypeReducer';
import { instanceActions } from '../modules/instance';
import Configurator from './Configurator';
import { NotFoundPage, ErrorPage } from './ServicePage';

const useResetPasswordMenu = (dispatch, search) => {
  useEffect(() => {
    const params = queryString.parse(search);

    if (params['reset-token']) {
      dispatch(dialogActions.showDialog(DIALOGS.RESET_PASSWORD, params['reset-token']));
    }
  }, [dispatch, search]);
};

const useFetchResources = (dispatch, instanceId, projectId, seedId, fetched, setFetched) => {
  useEffect(() => {
    const baseInstanceId = window.BASE_INSTANCE_ID;

    delete window.BASE_INSTANCE_ID;

    // eslint-disable-next-line no-underscore-dangle
    if (window.__NOT_FOUND__) {
      // eslint-disable-next-line no-underscore-dangle
      delete window.__NOT_FOUND__;

      dispatch(uiActions.showNotFoundPage());

      setFetched(projectId || instanceId || seedId);

      return;
    }

    // eslint-disable-next-line no-underscore-dangle
    if (window.__INITIAL_STATE__) {
      // eslint-disable-next-line no-underscore-dangle
      const childInstancesIds = window.__INITIAL_STATE__.childInstancesIds || [];

      // eslint-disable-next-line no-underscore-dangle
      delete window.__INITIAL_STATE__;

      dispatch(instanceActions.fetchChildInstances(childInstancesIds));
      dispatch(partsActions.fetchParts());
      dispatch(plotsActions.fetchPlots());

      setFetched(projectId || instanceId || seedId);

      return;
    }

    if (projectId && projectId !== fetched) {
      dispatch(initialStateActions.fetchInitialState(CONFIGURATOR_TYPES.PROJECT, projectId, baseInstanceId));

      setFetched(projectId);
    }

    if (instanceId && instanceId !== fetched) {
      dispatch(initialStateActions.fetchInitialState(CONFIGURATOR_TYPES.INSTANCE, instanceId));

      setFetched(instanceId);
    }

    if (seedId && seedId !== fetched) {
      dispatch(initialStateActions.fetchInitialState(CONFIGURATOR_TYPES.SEED, seedId, baseInstanceId));

      setFetched(seedId);
    }
  }, [dispatch, instanceId, projectId, seedId, fetched, setFetched]);
};

const PageWrapper = ({ match, location, history }) => {
  const [fetched, setFetched] = useState('');
  const dispatch = useDispatch();
  const { projectId, instanceId, seedId } = match.params;
  const notFoundPageVisible = useSelector(uiSelectors.selectNotFoundPageVisible);
  const newInstanceId = useSelector(uiSelectors.selectNewInstanceId);
  const errorPage = useSelector(uiSelectors.selectErrorPageState);

  useEffect(() => {
    if (newInstanceId) {
      setFetched(newInstanceId);
      dispatch(uiActions.resetNewInstanceId());

      history.push(`${PATH.INSTANCE.replace(':instanceId', newInstanceId)}${location.search}`);
    }
  }, [dispatch, history, location.search, newInstanceId]);

  useFetchResources(dispatch, instanceId, projectId, seedId, fetched, setFetched);

  useResetPasswordMenu(dispatch, location.search);

  if (errorPage && errorPage.displayed) {
    return <ErrorPage message={errorPage.message} />;
  }

  return notFoundPageVisible ? <NotFoundPage /> : <Configurator />;
};

PageWrapper.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      instanceId: PropTypes.string,
      projectId: PropTypes.string,
      seedId: PropTypes.string
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({})
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default PageWrapper;
