import { createSelector } from 'reselect';
import { getIsOwnLead } from '../instance/instanceSelectors';
import { validateEmail, validateRequired } from '../../utility/formValidators';
import { authSelectors } from '../auth';
import * as instanceSelectors from '../instance/instanceSelectors';
import { selectSettings } from './settingsSelectors';

export const FIELD_TYPES = {
  INPUT: {
    TEXT: 'text',
    NUMBER: 'number',
    EMAIL: 'email',
    PHONE: 'tel'
  },
  TEXTAREA: 'textarea',
  SELECT: 'select',
  COUNTRY_LIST: 'country-list',
  CHECKBOX: 'checkbox',
  CONSENT: 'consent',
  DIVIDER: '--- divider ---'
};

const selectContactFormSettings = createSelector([selectSettings], ({ contactForm = {} }) => contactForm);

export const selectContactFormTitle = createSelector(
  [selectContactFormSettings, getIsOwnLead],
  (contactForm, hasLead) => {
    const { title, titleContacted = title } = contactForm;

    return (hasLead ? titleContacted : title) || 'Ask a question';
  }
);

export const selectContactFormSubtitle = createSelector(
  [selectContactFormSettings],
  contactForm => contactForm.subtitle || 'Please fill in your details and we will be in touch shortly'
);

export const selectContactFormFields = createSelector([selectContactFormSettings], contactForm => {
  const fields = contactForm.fields || [];

  return fields.map((field, index) => {
    const response = { ...field };

    if ([FIELD_TYPES.INPUT.EMAIL, FIELD_TYPES.CONSENT, FIELD_TYPES.DIVIDER].includes(field.type)) {
      response.name = field.type;
    }

    if (response.name === FIELD_TYPES.INPUT.EMAIL) {
      response.required = true;
    }

    if ([FIELD_TYPES.CHECKBOX, FIELD_TYPES.CONSENT, FIELD_TYPES.DIVIDER].includes(field.type)) {
      response.required = false;
    }

    response.key = `${response.name}_${index}`;

    return response;
  });
});

export const selectContactFormSendPdf = createSelector([selectContactFormSettings], contactForm => contactForm.sendPdf);

export const selectContactFormRequestReceiverNotice = createSelector(
  [selectContactFormSettings],
  contactForm => contactForm.requestReceiverNotice
);

export const selectContactFormConsentMessage = createSelector(
  [selectContactFormSettings],
  contactForm => contactForm.consentMessage
);

export const selectContactFormValidationFields = createSelector([selectContactFormFields], fields => {
  const result = {};

  fields.forEach(field => {
    const { name } = field;

    result[name] = { name, validators: [] };

    if (name === FIELD_TYPES.CONSENT) {
      return;
    }

    if (name === FIELD_TYPES.INPUT.EMAIL) {
      result[name].validators.push(validateEmail);

      return;
    }

    if (field.required) {
      result[name].validators.push(validateRequired);
    }
  });

  return Object.values(result).filter(({ validators }) => validators.length !== 0);
});

export const selectContactFormInitialState = createSelector(
  [authSelectors.selectUser, instanceSelectors.selectInstance, selectContactFormFields],
  (currentUser, instance = {}, fields) => {
    const result = {};

    fields.forEach(item => {
      if (item.default) result[item.name] = item.default;
    });

    let email = '';

    if (currentUser) {
      email = currentUser.email;
    }

    const { lead = {} } = instance;

    lead.email = lead.email || email;

    return { ...result, ...lead };
  }
);
