import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { uiActions, uiSelectors } from '../../modules/ui';
import { setUtmParameters } from '../../api/instance';

const UTM_PARAMETERS = {
  SOURCE: 'utm_source',
  MEDIUM: 'utm_medium',
  CAMPAIGN: 'utm_campaign',

  // Optional
  TERM: 'utm_term',
  CONTENT: 'utm_content'
};

const LIST = new Set([...Object.values(UTM_PARAMETERS)]);

const useUtmParameters = parsed => {
  const dispatch = useDispatch();
  const parameters = useSelector(uiSelectors.selectUtmParameters);
  const initialized = useMemo(() => Object.keys(parameters).length !== 0, [parameters]);

  useEffect(() => {
    if (initialized) return;

    const newParameters = Object.keys(parsed).reduce((result, key) => {
      if (LIST.has(key)) {
        result[key] = parsed[key]; // eslint-disable-line no-param-reassign
      }

      return result;
    }, {});

    dispatch(uiActions.setUtmParameters(newParameters));

    setUtmParameters(queryString.stringify(newParameters));
  }, [dispatch, initialized, parsed]);

  return { parameters };
};

export default useUtmParameters;
