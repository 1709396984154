import { ReactNode, useCallback } from 'react';
import { PartNodeControlType } from 'types';

const TAB_KEY_CODE = 9;

interface ControlWrapperProps {
  children: ReactNode;
  className: string;
  onSelect: (control: PartNodeControlType) => void;
  control: PartNodeControlType;
}

const ControlWrapper = ({ children, className, onSelect, control }: ControlWrapperProps) => {
  const handleClick = useCallback(
    e => {
      onSelect(control);
    },
    [control, onSelect]
  );

  const onKeyUp = useCallback(
    e => {
      if (e.keyCode !== TAB_KEY_CODE) return;

      onSelect(control);
    },
    [control, onSelect]
  );

  /** data-name is used for individual styling. Do not remove */
  return (
    <section
      data-name={control.name}
      className={className}
      onClick={handleClick}
      onKeyUp={onKeyUp}
      tabIndex={0}
      role="button"
    >
      {children}
    </section>
  );
};

export default ControlWrapper;
