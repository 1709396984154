import type { ThunkDispatch } from 'redux-thunk';
import type { AnyAction } from 'redux';
import { AuthRequest, AuthResponse, RegisterResponse, ResetPasswordRequest, VerifyTokenRequest } from 'types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../config';
import { authSliceActions } from '../auth';
import NOTIFICATION_MESSAGES from '../../utility/notificationMessages';
import { notificationsActions } from '../../modules/notifications';

const setUser = async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  queryFulfilled: Promise<any>,
  suppressNotification?: boolean
) => {
  queryFulfilled
    .then(response => {
      dispatch(
        authSliceActions.setUser(
          { ...response.data },
          suppressNotification ? undefined : { message: NOTIFICATION_MESSAGES.LOGIN_SUCCESSFUL, notification: true }
        )
      );
    })
    .catch(failure => {
      let message = NOTIFICATION_MESSAGES.LOGIN_FAILED;

      if (failure?.error.data.isServer) {
        message = failure?.error.data.message;
      }

      dispatch(
        authSliceActions.unsetUser(
          null,
          suppressNotification ? undefined : { message, error: true, notification: true }
        )
      );
    });
};

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: API_URL, credentials: 'include' }),
  endpoints: build => ({
    // AUTH
    auth: build.mutation<AuthResponse, AuthRequest>({
      query: params => ({
        url: 'login',
        method: 'POST',
        body: params
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        setUser(dispatch, queryFulfilled);
      }
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: 'logout'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          authSliceActions.unsetUser(null, { message: NOTIFICATION_MESSAGES.LOGOUT_SUCCESSFUL, notification: true })
        );
      }
    }),
    facebookAuth: build.mutation<any, any>({
      query: params => ({
        url: 'facebook-login',
        method: 'POST',
        body: params
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        setUser(dispatch, queryFulfilled);
      }
    }),
    googleAuth: build.mutation<any, any>({
      query: params => ({
        url: 'google-login',
        method: 'POST',
        body: params
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        setUser(dispatch, queryFulfilled);
      }
    }),
    register: build.mutation<RegisterResponse, AuthRequest>({
      query: params => ({
        url: 'registration',
        method: 'POST',
        body: params
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        setUser(dispatch, queryFulfilled);
      }
    }),
    forgotPassword: build.mutation<void, ResetPasswordRequest>({
      query: params => ({
        url: 'forgot-password',
        method: 'POST',
        body: params
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const showNotificatipn = () => {
          dispatch(notificationsActions.showSuccessNotification(NOTIFICATION_MESSAGES.EMAIL_SENT_SUCCESSFUL));
        };

        queryFulfilled.then(showNotificatipn).catch(showNotificatipn);
      }
    }),
    resetPassword: build.mutation<void, ResetPasswordRequest>({
      query: params => ({
        url: 'reset-password',
        method: 'POST',
        body: params
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(notificationsActions.showSuccessNotification(NOTIFICATION_MESSAGES.PASSWORD_RESET_SUCCESSFUL));
          })
          .catch(failure => {
            dispatch(notificationsActions.showErrorNotification(failure?.error.data.message));
          });
      }
    }),
    verifyToken: build.mutation<void, VerifyTokenRequest>({
      query: params => ({
        url: 'verify-token',
        method: 'POST',
        body: params
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled;

        if (response.meta?.response?.ok) {
          dispatch(authSliceActions.setTokenValid(true));
          dispatch(authSliceActions.setTokenChecked(true));
        }
      }
    }),
    getUserInfo: build.query<AuthResponse, string>({
      query: userToken => {
        return {
          url: 'user-info',
          headers: { 'Creatomus-User-Token': userToken }
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        setUser(dispatch, queryFulfilled, true);
      }
    })
  })
});

export const {
  useAuthMutation,
  useResetPasswordMutation,
  useLogoutMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useVerifyTokenMutation,
  useFacebookAuthMutation,
  useGoogleAuthMutation,
  useGetUserInfoQuery
} = api;
export default api;
