import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from '../../Atoms';
import Icon from '../../Icon';
import { useLinkGenerators, useTranslate } from '../../../utility/hooks';
import { userInstancesActions } from '../../../modules/userInstances';
import { dialogActions } from '../../../modules/dialog';
import savedDesign from './style';

import './SavedDesign.scss';

const SavedDesign = ({ instanceId, projectName, active, name, lead, updatedAt, deprecated }) => {
  const translate = useTranslate();
  const history = useHistory();

  const dispatch = useDispatch();
  const { getLinkToSavedDesign } = useLinkGenerators();

  const handleDelete = useCallback(() => {
    dispatch(userInstancesActions.deleteInstance(instanceId, active, history.replace));
  }, [dispatch, instanceId, active, history.replace]);

  const handleClickOnLink = useCallback(() => {
    dispatch(dialogActions.hideDialog());
  }, [dispatch]);

  const renderBody = useCallback(() => {
    const date = new Date(updatedAt);

    return (
      <>
        <h4 className={savedDesign('header', { active, deprecated })}>{name}</h4>
        {projectName ? (
          <p className={savedDesign('paragraph')}>
            {translate('for')} <span className={savedDesign('project-name')}>{translate(projectName)}</span>
          </p>
        ) : null}
        <p className={savedDesign('paragraph')}>
          {translate('Last modified on')} {date.toDateString()} {translate('at')} {date.toLocaleTimeString()}
        </p>
        {lead ? (
          <p className={savedDesign('paragraph')}>
            <Icon type="check" scale={0.7} />
            {lead.downloadedPDF ? translate('PDF quote has been sent') : translate('Request has been sent')}
          </p>
        ) : null}
        {deprecated ? (
          <p className={savedDesign('paragraph', { deprecation: deprecated })}>
            <Icon type="cancel" scale={0.7} /> {translate('Old version')}
          </p>
        ) : null}
      </>
    );
  }, [updatedAt, active, deprecated, name, projectName, translate, lead]);

  return (
    <div className={savedDesign({ active, deprecated })}>
      {active ? (
        <span className={savedDesign('instance-info')}>{renderBody()}</span>
      ) : (
        <Link
          to={getLinkToSavedDesign(instanceId)}
          onClick={handleClickOnLink}
          className={savedDesign('instance-info', { deprecated })}
        >
          {renderBody()}
        </Link>
      )}
      <Button
        mix={savedDesign('delete-button')}
        onClick={handleDelete}
        color="icon"
        aria-label={translate('Delete')}
        data-balloon-pos="down"
        circular
      >
        <Icon type="trash" scale={0.8} />
      </Button>
    </div>
  );
};

SavedDesign.propTypes = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  instanceId: PropTypes.string.isRequired,
  projectName: PropTypes.string,
  lead: PropTypes.shape({ downloadedPDF: PropTypes.bool }),
  updatedAt: PropTypes.string.isRequired,
  deprecated: PropTypes.bool
};

SavedDesign.defaultProps = {
  active: false,
  lead: undefined,
  projectName: '',
  deprecated: false
};

export default SavedDesign;
