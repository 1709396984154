import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MenuSection } from '../../Menu';
import { useTranslate, useFormState, useLinkGenerators } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import { Field, Button } from '../../Atoms';
import menuStyle from '../../Menu/style';
import { dialogActions } from '../../../modules/dialog';
import { authSelectors } from '../../../modules/auth';

const INSTANCE_ID_LENGTH = 17;

const getInstanceId = str => {
  const matched = str.match(/\/id\/[A-z0-9]{17}/g);

  if (matched) {
    return matched[0].substr(4);
  }

  if (str.length === INSTANCE_ID_LENGTH) {
    return str;
  }

  return '';
};

const OpenInstanceMenu = () => {
  const history = useHistory();
  const translate = useTranslate();
  const isLoggedIn = useSelector(authSelectors.getLoggedIn);
  const { getLinkToSavedDesign } = useLinkGenerators();

  const { handleFormFieldChange, formState } = useFormState({ id: '' });

  const dispatch = useDispatch();

  const id = useMemo(() => getInstanceId(formState.id), [formState.id]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      const link = getLinkToSavedDesign(id);

      history.push(link);

      dispatch(dialogActions.hideDialog());
    },
    [dispatch, getLinkToSavedDesign, history, id]
  );

  const handleOpenProfileDialog = useCallback(() => {
    dispatch(dialogActions.openProfileDialog());
  }, [dispatch]);

  return (
    <MenuContent>
      <form onSubmit={handleSubmit}>
        <MenuSection
          type="open-instance-form"
          heading={translate('Open design')}
          description={translate('Use it if you have a unique ID')}
        >
          <Field
            label={translate('Design ID')}
            id="open-instance-id"
            name="id"
            onChange={handleFormFieldChange}
            value={formState.id}
            required
            mix={menuStyle('field', { type: 'instance-id' })}
          />
          <Button type="submit" rounded bold mix={menuStyle('button', { center: true })} disabled={!id}>
            {translate('Open')}
          </Button>
        </MenuSection>
        {isLoggedIn ? (
          <MenuSection type="open-my-designs" description={translate('or')}>
            <Button
              rounded
              bold
              mix={menuStyle('button', { center: true })}
              color="text-borderless"
              onClick={handleOpenProfileDialog}
            >
              {translate('Explore your saved designs')}
            </Button>
          </MenuSection>
        ) : null}
      </form>
    </MenuContent>
  );
};

export default OpenInstanceMenu;
