import React from 'react';
import Footer from '../Footer';
import MenuSection from './MenuSection';

const MenuFooter = () => (
  <MenuSection type="footer">
    <Footer />
  </MenuSection>
);

export default MenuFooter;
