import { initialStateActions } from '../initialState';
import { MOCK_FETCH_PROJECT__SUCCESS } from './projectActions';

const initialState = {};

const projectReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case initialStateActions.FETCH_INITIAL_STATE__SUCCESS:
    case MOCK_FETCH_PROJECT__SUCCESS:
      return { ...payload.project, previousId: state._id };
    default:
      return state;
  }
};

export default projectReducer;
