import { PartNodeControlType, PartNodeOptionType } from 'types';
import { useControl, useTranslate } from '../../../utility/hooks';
import { controlStyle } from '../Control/style';
import DefaultOption from './DefaultOption';
import { DefaultOptionProps } from './DefaultOption/DefaultOption';

export interface DefaultControlProps {
  control: PartNodeControlType;
  OptionComponent?: (props: DefaultOptionProps) => JSX.Element;
  onOptionSelect: (control: PartNodeControlType, option: PartNodeOptionType) => void;
  activeTags: string[];
}

const DefaultControl = ({
  control,
  OptionComponent = DefaultOption,
  onOptionSelect,
  activeTags
}: DefaultControlProps) => {
  const { options } = useControl(control, onOptionSelect, activeTags);
  const translate = useTranslate();

  return (
    <ul className={controlStyle('options', { type: control.type })}>
      {options.length === 0 ? (
        <span className={controlStyle('empty-list-message')}>{translate('Nothing selected')}</span>
      ) : (
        options.map(optionProps => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <OptionComponent key={optionProps.option.name} {...optionProps} />
        ))
      )}
    </ul>
  );
};

export default DefaultControl;
