import { createSelector } from 'reselect';

export const selectProject = state => state.project;

export const selectProjectId = createSelector([selectProject], project => project._id);
export const selectProjectLink = createSelector([selectProject], project => project.link);
export const selectProjectPreviousId = createSelector([selectProject], project => project.previousId);
export const selectHasNewProject = createSelector(
  [selectProjectId, selectProjectPreviousId],
  (id, prevId) => id !== prevId
);

export const selectProjectName = createSelector([selectProject], project => project.name);
export const selectAllowedDomains = createSelector([selectProject], project => project.allowedDomains || []);
