import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { Matrix4, Vector3 } from 'three';
import { CommentType } from 'types';
import { dialogActions } from '../../../modules/dialog';
import { instanceSelectors } from '../../../modules/instance';
import { uiSelectors } from '../../../modules/ui';
import { selectTemporaryCommentPin } from '../../../modules/ui/uiStateSelectors';
import getThreeSetup from '../getThreeSetup';
import SceneButton from '../InteractiveControls/SceneButton';
import { sceneButton } from '../InteractiveControls/SceneButton/SceneButton';

const reusableVector = new Vector3();

const BALLOON_BREAKPOINT = 20;
const BALLOON_MAX_LENGTH = 200;

const duration = 2000;

const Comments = () => {
  const comments: CommentType[] | undefined = useSelector(instanceSelectors.selectInstanceComments);
  const dispatch = useDispatch();
  const { render } = getThreeSetup();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Selector not converted
  const commentsShowing = useSelector(uiSelectors.selectAreCommentsShowing) as boolean;
  const temporaryPin = useSelector(selectTemporaryCommentPin);

  const commentDialogActive = useSelector(uiSelectors.selectIsCommentDialogActive) as boolean;
  const currentCommentId = useSelector(uiSelectors.selectCurrentCommentId) as number | undefined;

  const handleOpen = useCallback(
    (commentId: number) => {
      dispatch(dialogActions.openEditCommentDialog(commentId));
    },
    [dispatch]
  );

  // update temporary pin
  useEffect(() => {
    if (temporaryPin) {
      render();
    }
  }, [render, temporaryPin]);

  return (
    <>
      {comments
        ? comments.map((comment, index) => {
            if (!comment.position) return null;

            if (comment._id === temporaryPin?.id) return null;

            const matrix = new Matrix4().setPosition(reusableVector.fromArray(comment.position));

            const wrap = comment.content.length > BALLOON_BREAKPOINT;

            const clampedComment =
              comment.content.length > BALLOON_MAX_LENGTH
                ? `${comment.content.substring(0, BALLOON_MAX_LENGTH)}…`
                : comment.content;

            const disabled = commentDialogActive && comment._id !== currentCommentId;

            return (
              <CSSTransition
                enter={false}
                classNames={sceneButton({ type: 'comment' })}
                timeout={{ appear: 0, enter: 0, exit: duration }}
                in={commentsShowing}
                unmountOnExit
                mountOnEnter
                key={comment._id}
              >
                <SceneButton
                  aria-label={clampedComment}
                  data-balloon-pos="up"
                  data-balloon-length={wrap ? 'large' : undefined}
                  data-balloon-break={wrap}
                  matrix={matrix}
                  onClick={() => handleOpen(comment._id)}
                  type="comment"
                  circular={false}
                  color="icon"
                  disabled={disabled}
                >
                  {index + 1}
                  <div className={sceneButton('comment-shape', { disabled })} />
                </SceneButton>
              </CSSTransition>
            );
          })
        : null}
      {temporaryPin?.position && (
        <SceneButton
          matrix={new Matrix4().setPosition(reusableVector.fromArray(temporaryPin.position))}
          type="comment"
          circular={false}
          color="icon"
        >
          {temporaryPin.id || '?'}
          <div className={sceneButton('comment-shape')} />
        </SceneButton>
      )}
    </>
  );
};

export default Comments;
