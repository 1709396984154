import { createSelector } from 'reselect';
import { Matrix4 } from 'three';
import * as plotsSelectors from '../plots/plotsSelectors';
import * as partsSelectors from '../parts/partsSelectors';
import * as selectedOptionsSelectors from '../selectedOptions/selectedOptionsSelectors';
import * as seedSelectors from '../seed/seedSelectors';
import * as settingsSelectors from '../settings/settingsSelectors';
import * as optionStatusSelectors from '../settings/optionStatusSelectors';
import {
  CONTROL_TYPES,
  CONTROL_TYPES_LIST,
  INSTANCE_LOADER_SUFFIX,
  TRANSFORM_SUFFIX
} from '../../utility/controlDefinitions';
import * as uiStateSelectors from '../ui/uiStateSelectors';
import * as cameraStateSelectors from '../../store/camera/cameraStateSelectors';
import * as instanceSelectors from '../instance/instanceSelectors';

const identityMatrix = new Matrix4();

/*
  Merge collections defined in seed settings and parts
*/
export const selectInstanceLoaderOptionsBySeed = createSelector(
  [instanceSelectors.selectUserInstances],
  userInstances => {
    const list = {};

    userInstances.forEach(userInstance => {
      const { _id, seed, instanceName, updatedAt } = userInstance;

      if (seed.root) {
        list[seed.root] = list[seed.root] || { options: [] };

        const option = {
          name: _id,
          displayName: instanceName,
          optionHint: new Date(updatedAt).toLocaleDateString()
        };

        list[seed.root].options.push(option);
      }
    });

    return list;
  }
);

// Maps the remote controls by [controlName][partId], maps options list to [name]
// Currently only used by getPartNodesToRender
/*
 * Selects client settings for remote controls
 * Used by selectors>getPartNodesToRender to overlay controls
 */
const selectRemoteControlsMap = createSelector([seedSelectors.selectRemoteControls], (remote = []) =>
  remote.reduce((result, rc) => {
    // add indices for name and partId
    if (!result[rc.name]) result[rc.name] = {}; // eslint-disable-line no-param-reassign

    // eslint-disable-next-line no-param-reassign
    if (!result[rc.name][rc.partId]) result[rc.name][rc.partId] = { ...rc }; // Avoid mutating Redux state

    // also map list by name
    // eslint-disable-next-line no-param-reassign
    result[rc.name][rc.partId].mappedList = result[rc.name][rc.partId].list.reduce((optionResult, option) => {
      optionResult[option.name] = option; // eslint-disable-line no-param-reassign

      return optionResult;
    }, {});

    return result;
  }, {})
);

const selectRemoteSwitchesMap = createSelector([seedSelectors.selectRemoteSwitches], (remote = []) =>
  remote.reduce((result, rc) => {
    if (!result[rc.name]) result[rc.name] = {}; // eslint-disable-line no-param-reassign

    if (!result[rc.name][rc.partId]) result[rc.name][rc.partId] = { ...rc }; // eslint-disable-line no-param-reassign

    return result;
  }, {})
);

/* A lot of controls logic is not dependant on node tree -
 they stay the same always.
 This selector calculates those in order to reduce
calculation cost of getPartNodesToRender

* apply remote controls
* apply interaction groups
* apply type
* apply description (by remote controls)
* apply displayName (by remote controls)
* merge option, list and instanceLoaderOptions
* value from remote switches and remote controls and default field ( selected options will be set from getPartNodesToRender )

*/

const makeBaseControlList = (list, remote) => list.map(option => ({ ...option, ...remote?.mappedList?.[option.name] }));

const createNewControl = (control, remoteControls, remoteSwitches, partId, instanceLoaderOptions) => {
  const {
    remoteControlEnabled,
    remoteSwitchEnabled,
    name,
    displayName,
    description,
    type,
    interactionGroups: originalInteractionGroups,
    list = []
  } = control;

  const remoteControl = remoteControlEnabled && remoteControls[name] && remoteControls[name][partId];
  const remoteSwitch = remoteSwitchEnabled && remoteSwitches[name] && remoteSwitches[name][partId];

  const interactionGroups = originalInteractionGroups?.length > 0 ? originalInteractionGroups : [{ name }];

  return {
    ...control,
    displayName: remoteControl?.displayName !== undefined ? remoteControl.displayName : displayName,
    description: remoteControl?.description !== undefined ? remoteControl.description : description,
    type: CONTROL_TYPES_LIST.includes(type) ? type : CONTROL_TYPES.RADIO,
    interactionGroups,
    list: makeBaseControlList([...list, ...instanceLoaderOptions], remoteControl),
    // this is first place where value is set for control
    // can be overwritten later
    value: remoteControl?.value || remoteSwitch?.value || control.default // do not take into account original control's value, only default
  };
};

export const selectBaseControls = createSelector(
  [partsSelectors.selectParts, selectRemoteControlsMap, selectRemoteSwitchesMap, selectInstanceLoaderOptionsBySeed],
  (parts, remoteControls, remoteSwitches, instanceLoaderOptionsBySeed) => {
    const controlsMap = {};

    parts.forEach(part => {
      const { _id: partId, Controls = {} } = part;

      controlsMap[partId] = controlsMap[partId] || [];

      const { Object: controls = [] } = Controls;

      controls.forEach(control => {
        const { name, interactionGroups: originalInteractionGroups, interactive } = control;

        const interactionGroups = originalInteractionGroups?.length > 0 ? originalInteractionGroups : [{ name }];

        if (interactionGroups.length === 0 && interactive) {
          interactionGroups.push({ name });
        }

        let instanceLoaderOptions = [];

        if (control.instanceLoader?.enabled && control.instanceLoader.generateOptions) {
          const { seedId, optionImage } = control.instanceLoader;

          if (Array.isArray(instanceLoaderOptionsBySeed[seedId]?.options)) {
            instanceLoaderOptions = instanceLoaderOptionsBySeed[seedId].options.map(item => ({
              ...item,
              image: optionImage
            }));
          }
        }

        // @todo overlay extrainfo collection if it would select extrainfo from part and no collection is defined yet

        const newControl = createNewControl(control, remoteControls, remoteSwitches, partId, instanceLoaderOptions);

        // generate list map so that getSelectedOptionParams does not have to use find method
        newControl.listMap = {};
        newControl.list.forEach(item => {
          newControl.listMap[item.name] = item;
        });

        controlsMap[partId].push(newControl);
      });
    });

    return controlsMap;
  }
);

/** Selects values for given control type */
const selectInternalControlValues = createSelector(
  [
    cameraStateSelectors.selectCameraViewName,
    uiStateSelectors.selectSelectedControlGroupName,
    uiStateSelectors.selectSelectedTabName
  ],
  (selectedViewName, selectedControlName, selectedTabName) => ({
    [CONTROL_TYPES.VAR_SELECTED_CONTROL]: selectedControlName,
    [CONTROL_TYPES.VAR_SELECTED_TAB]: selectedTabName,
    [CONTROL_TYPES.VAR_SELECTED_VIEW]: selectedViewName
  })
);

const getOptions = (list, optionStatusMap, fixed, optionStatusFix) => {
  const result = [];
  const nameMap = {}; // for keeping list unique

  list.forEach(item => {
    if (nameMap[item.name]) {
      return;
    }

    const option = { ...item };

    // overlay control options per status.
    if (option.status && optionStatusMap[option.status]) {
      Object.assign(option, optionStatusMap[option.status]);
    }

    if (optionStatusFix && optionStatusFix[option.name] && optionStatusMap[optionStatusFix[option.name]]) {
      option.status = optionStatusFix[option.name];

      Object.assign(option, optionStatusMap[option.status]);
    }

    if (fixed) {
      option.fixed = fixed;
    }

    if (!option.disabled) {
      result.push(option);
      nameMap[option.name] = true;
    }
  });

  return result;
};

const sortByStatusOrder = (statusesOrder, statusesPriorityMap, aStatus, bStatus) => {
  if (!aStatus || statusesPriorityMap[aStatus] === undefined) {
    return statusesOrder.length - statusesPriorityMap[bStatus];
  }

  if (!bStatus || statusesPriorityMap[bStatus] === undefined) {
    return statusesPriorityMap[aStatus] - statusesOrder.length;
  }

  return statusesPriorityMap[aStatus] - statusesPriorityMap[bStatus];
};

// merge option and list for controls - option was used for material controls
// also applies remote controls
// used in getNodeControls
const getControlList = (list, optionStatusMap, fixed, optionStatusFix, statusesOrder = []) => {
  const result = getOptions(list, optionStatusMap, fixed, optionStatusFix);

  // Sorting options by statuses
  if (statusesOrder.length !== 0) {
    const statusesPriorityMap = statusesOrder.reduce((acc, status, index) => {
      // eslint-disable-next-line no-param-reassign
      acc[status] = index;

      return acc;
    }, {});

    result.sort(({ status: aStatus }, { status: bStatus }) =>
      sortByStatusOrder(statusesOrder, statusesPriorityMap, aStatus, bStatus)
    );
  }

  return result;
};

// get control value by first applying remote control value and then applying selected option
// used in getNodeControls
const getControlValue = (control, selectedOptions, parameters, internalControlValues) => {
  // value is set by default or remote controls/switches
  const { value, treeName, name, type } = control;

  // checking for commands like $fix in the parameters
  if (parameters.$fix[name]) {
    return { value: parameters.$fix[name], fixed: true };
  }

  // checking for control type based values
  if (internalControlValues[type]) {
    return { value: internalControlValues[type], fixed: false };
  }

  return { value: selectedOptions[treeName] === undefined ? value : selectedOptions[treeName], fixed: false };
};

function getControlTreeName(control, node) {
  const { isTreeNameStatic, name, instanceLoader } = control;

  if (isTreeNameStatic) return name;

  const treeName = `${node.treeId}_${name}`;

  if (instanceLoader?.enabled) return treeName + INSTANCE_LOADER_SUFFIX;

  return treeName;
}

// maping node controls to new object with tree id
// used by selectors>getNodeTree
function getNodeControls(node, controls, parameters, selectedOptions, optionStatusMap, internalControlValues) {
  if (Array.isArray(controls)) {
    return controls.map(originalControl => {
      // avoid mutating redux state
      const { list, matrix: controlMatrix } = originalControl;
      // apply remote control values to control.
      // needs to happen before calculating value because default value might change

      const treeName = getControlTreeName(originalControl, node);

      // each control will have a matrix calculated from parent and controls own
      const matrix = new Matrix4();

      if (controlMatrix?.Values?.length === 16) {
        matrix.set(...controlMatrix.Values);
      }
      // control matrix gets applied to part matrix;
      // so multiply
      matrix.premultiply(node.matrix);

      const control = {
        ...originalControl,
        treeName, // add treename
        key: node.key,
        matrix
      };

      const { value: newValue, fixed } = getControlValue(control, selectedOptions, parameters, internalControlValues); // applies defaults, remote controls and selected value

      control.list = getControlList(
        list,
        optionStatusMap,
        fixed,
        parameters[control.name]?.$optionStatusFix || {},
        control.statusesOrder
      );
      control.value = newValue;
      control.fixed = fixed;

      return control;
    });
  }

  return [];
}

// Also used by getPartNodesToRender to resolve plotfix
// used by selectors>getNodeTree

export const getControlsParams = (controls, prevParameters, childInstanceSavedStates = {}, selectedOptions = {}) => {
  // init parameters that will be updated by the loop
  const controlsParameters = {};
  let controlParamsEnabled = false;
  const instances = {};
  const fixes = {};
  let fixesEnabled = false;
  let instancesEnabled = false;
  const materialOverrides = {};
  let materialOverridesEnabled = false;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  controls.forEach(control => {
    const { listMap = {}, value, name, instanceLoader } = control;
    const option = listMap[value] || {};

    // to assign a value to a parameter check if there is an explicit parameter key or use the control name.
    // using the control name as parameter is how the implementation has been so far
    const parameter = control.parameter || name;

    // parameter gets added anyway even if only one option since otherwise we break existing instances
    controlsParameters[parameter] = value;
    controlParamsEnabled = true;

    // check it there is a fix field in the current option and turn it into a fix command parameters
    const { fix, optionStatusFix = {}, priceScheme } = option;

    // record price scheme in parameters
    // since priceScheme is only one value not an object, it gets added directly to controlsParameters
    // and no "enabled" flag is needed
    if (priceScheme) {
      controlsParameters.$priceScheme = priceScheme;
    }

    const optionStatusFixes = Object.keys(optionStatusFix);

    if (optionStatusFixes.length) {
      optionStatusFixes.forEach(key => {
        /*
          First apply anything that was added upper in the tree
          and then add everything that was applied with previous controls
        */
        controlsParameters[key] = {
          $optionStatusFix: { ...prevParameters[key]?.$optionStatusFix, ...controlsParameters[key]?.$optionStatusFix }
        };

        // finally add current controls'

        optionStatusFix[key].forEach(item => {
          controlsParameters[key].$optionStatusFix[item.value] = item.optionStatus;
        });
      });
    }

    // fixes
    if (fix) {
      const fixList = Object.keys(fix);

      if (fixList.length) {
        fixList.forEach(key => {
          fixes[key] = fix[key];
        });
        fixesEnabled = true;
      }
    }

    // instance loader
    if (instanceLoader?.enabled) {
      const { instanceToSeed } = childInstanceSavedStates;

      const seedId = instanceToSeed[control.value];

      instances[seedId] = control.value;
      instancesEnabled = true;
    }

    // check for material changes for each material change
    if (Array.isArray(option.material) && option.material.length > 0) {
      option.material.forEach(materialChange => {
        const { original, apply, isLocal } = materialChange;

        if (original && apply && isLocal) {
          materialOverrides[original] = apply;
          materialOverridesEnabled = true;
        }
      });
    }

    // check for transform parameters
    if (control?.transform?.enabled) {
      const { treeName } = control;
      const par = parameter || name;
      const transformTree = `${treeName}${TRANSFORM_SUFFIX}`;
      const transformName = `${par}${TRANSFORM_SUFFIX}`;

      if (selectedOptions[transformTree]) {
        controlsParameters[transformName] = selectedOptions[transformTree];
      }
    }
  });

  return {
    controlsParameters,
    controlParamsEnabled,
    instances,
    instancesEnabled,
    fixes,
    fixesEnabled,
    materialOverrides,
    materialOverridesEnabled
  };
};

const dummyEmptyObject = {};

const getTreeId = (isStatic, isTreeIdFlat, treeId, reference, option) =>
  isStatic || isTreeIdFlat ? `${treeId}|${reference}` : `${treeId}|${reference}^${option}`;

const reusableTransformMatrix = new Matrix4();

/*
Combining algorithm

1. split parent treeId by | to get last item
2. Split child treeName by | to get first item, last item and the rest
3.1 If only one value like Child_Root_control_name
  - Split parent last item by ^ to get Reference and potential Option like Child_root and Option_name
  - Substring the value by length of Child_root + _, the rest is control name
  - Join last part with Parent last item + _ + control name
  - Profit
3.2 If more than one value, replace
  - Remove first option from child's list
  - Join all items by "|"
  - Profit
*/

const combineTreeNames = (parentTreeId, childTreeName) => {
  const parentList = parentTreeId.split('|');
  const childList = childTreeName.split('|');

  if (childList.length === 1) {
    const parentLastItem = parentList.pop();
    const [childItem] = childList;
    const [reference] = parentLastItem.split('^');

    const controlName = childItem.slice(reference.length + 1); // +1 for "_"

    return `${[...parentList, parentLastItem].join('|')}_${controlName}`;
  }
  childList.shift();

  return [...parentList, ...childList].join('|');
};

const getChildSelectedOptions = (selectedOptions, childInstanceSavedStates, node, stemParameters) => {
  const { savedStates } = childInstanceSavedStates;

  const savedStateInstances = savedStates[node.seed];
  const remappedChildSavedState = {};

  if (savedStateInstances) {
    const { $instances: instancesIds } = stemParameters;

    // seedId: instanceId
    const instanceId = instancesIds[node.seed];

    if (savedStateInstances[instanceId]) {
      // recombine

      Object.entries(savedStateInstances[instanceId]).forEach(([childTreeName, childValue]) => {
        const treeName = combineTreeNames(node.treeId, childTreeName);

        remappedChildSavedState[treeName] = childValue;
      });
    }
    // find out which instance corresponds to current seed
  }

  return { ...selectedOptions, ...remappedChildSavedState };
};

/** Char to use as a node key ending marker */
const KEY_END_CHAR = ',';

// used by selectors>getPartNodesToRender
// eslint-disable-next-line sonarjs/cognitive-complexity
function getNodeTree(
  tree,
  stem,
  selectedOptions,
  parameters = {},
  partsMap,
  optionStatusMap,
  internalControlValues,
  siblingPartReferences,
  childInstanceSavedStates,
  currentSeedId,
  baseControls
) {
  // eslint-disable-next-line no-param-reassign
  stem.Controls = getNodeControls(
    stem,
    baseControls[stem._id],
    parameters,
    selectedOptions,
    optionStatusMap,
    internalControlValues
  );
  // eslint-disable-next-line no-param-reassign
  stem.isMainNode = currentSeedId === stem.seed;

  /*
    All controls parameters are fetched here
    Since some fields need updating deeper, we also figure out if they need to be updated (and cloned) or not.
    Theoretically the stemParameters should follow this as well but for now
    each node will shallow clone parameters anyway (implemented upwards)

  */
  const controlParams = getControlsParams(stem.Controls, parameters, childInstanceSavedStates, selectedOptions);

  const {
    controlsParameters,
    controlParamsEnabled,
    fixes,
    fixesEnabled,
    instances,
    instancesEnabled,
    materialOverrides,
    materialOverridesEnabled
  } = controlParams;

  let stemParameters = parameters;

  if (controlParamsEnabled || fixesEnabled || instancesEnabled || materialOverridesEnabled) {
    stemParameters = { ...stemParameters, ...controlsParameters };

    if (fixesEnabled) {
      stemParameters.$fix = { ...stemParameters.$fix, ...fixes };
    }

    if (instancesEnabled) {
      stemParameters.$instances = { ...stemParameters.$instances, ...instances };
    }

    if (materialOverridesEnabled) {
      stemParameters.$materialOverrides = { ...stemParameters.$materialOverrides, ...materialOverrides };
    }
  }

  stem.Parameters = stemParameters; // eslint-disable-line no-param-reassign
  tree.push(stem);
  const propagatedInteraction = stem?.Config?.interaction?.propagate && stem.Config.interaction;

  const { Position: positions = [] } = stem;

  if (positions.length > 0) {
    positions.forEach((pos, posIndex) => {
      const { Reference: positionReference } = pos;

      if (partsMap[positionReference]) {
        const option = stemParameters[positionReference];

        const isStatic = option === undefined;
        const protoNode = partsMap[positionReference].list[option] || partsMap[positionReference].default;
        const { Config: protoConfig = dummyEmptyObject } = protoNode;

        const { isTreeIdFlat = false } = protoConfig;

        const treeId = getTreeId(isStatic, isTreeIdFlat, stem.treeId, positionReference, option);

        // add a previous node's key, current position index and a trailing separator
        const key = `${stem.key}${posIndex}${KEY_END_CHAR}`;

        const positionMatrix = new Matrix4();

        if (pos?.Transform?.Matrix?.Values?.length === 16) {
          positionMatrix.set(...pos?.Transform?.Matrix?.Values);
        }

        positionMatrix.premultiply(stem.matrix);

        const transformReference = `${positionReference}${TRANSFORM_SUFFIX}`;

        if (stemParameters[transformReference]) {
          const matrixArray = JSON.parse(stemParameters[transformReference]);

          if (Array.isArray(matrixArray) && matrixArray.length === 16) {
            reusableTransformMatrix.fromArray(matrixArray);

            positionMatrix.multiply(reusableTransformMatrix);
          }
        }

        const node = {
          treeId,
          key,
          static: isStatic,
          ...protoNode,
          matrix: positionMatrix
        };

        // inject child instance selected options if seed changes

        const childSelectedOptions =
          node.seed !== stem.seed
            ? getChildSelectedOptions(selectedOptions, childInstanceSavedStates, node, stemParameters)
            : selectedOptions;

        // propagatedInteraction is before proto interaction so that values defined in current part would overwrite propagated values
        // create new Config object only if propagation happens
        if (propagatedInteraction) {
          node.Config = {
            ...node.Config,
            interaction: { ...propagatedInteraction, ...protoConfig.interaction }
          };
        }

        getNodeTree(
          tree,
          node,
          childSelectedOptions,
          stemParameters,
          partsMap,
          optionStatusMap,
          internalControlValues,
          siblingPartReferences,
          childInstanceSavedStates,
          currentSeedId,
          baseControls
        );
      }
    });
  }
}

// used by selectors>getControls
const getTreeControls = tree => {
  if (!tree) return [];

  const controls = [];

  tree.forEach(({ Controls: nodeControls = [] }) => {
    controls.push(...nodeControls);
  });

  return controls;
};

// helper function to generate plot fix object from given string. Used in getEnvControls

export const generatePlotFix = fix => {
  const fixes = fix.split(',');
  const result = {};

  fixes.forEach(fixPair => {
    const pair = fixPair.split(':');
    let key;
    let value;

    if (pair.length > 1) {
      [key, value] = pair;
    } else {
      key = 'Plot';
      [value] = pair;
    }
    result[key] = value;
  });

  return result;
};

// selector to produce controls that exist before root part or any parts.
// Like Plot selector that is generated based on a separate db query for plots.
// used by selectors>getPartNodesToRender, selectors>getControls
const ENV_PLOT_CONTROL_NAME = 'Env|Plot';

const getEnvControls = createSelector(
  [
    plotsSelectors.selectPlots,
    selectedOptionsSelectors.selectSelectedOptions,
    optionStatusSelectors.selectOptionStatusMap
  ],
  (plots, selectedOptions, optionStatusMap) => {
    const defaultPlot = plots
      .filter(plot => typeof plot === 'object' && plot.status === 'Released')
      .map(plot => plot.code)
      .shift(); // pick code of first Released plot
    const selectedPlot =
      ENV_PLOT_CONTROL_NAME in selectedOptions ? selectedOptions[ENV_PLOT_CONTROL_NAME] : defaultPlot;

    if (plots.length > 0) {
      const list = plots
        .map((plot, i) => {
          const { code, name, description, price, status, fix } = plot;

          return {
            name: code,
            displayName: name,
            optionHint: description,
            price,
            status,
            fix: typeof fix === 'string' ? generatePlotFix(fix) : { Plot: code },
            ...optionStatusMap[status]
          };
        })
        .filter(item => !item.disabled);

      return [
        {
          name: ENV_PLOT_CONTROL_NAME,
          treeName: ENV_PLOT_CONTROL_NAME,
          type: 'option',
          default: defaultPlot,
          value: selectedPlot,
          list,
          bindExtraInfo: true
        }
      ];
    }

    return [];
  }
);

// used by selectors>getControls, Viewer
const getPartNodesToRender = createSelector(
  [
    partsSelectors.selectRootPart,
    seedSelectors.selectSeedId,
    getEnvControls, // Currently returns one control "Env|Plot" only if plots are present
    selectedOptionsSelectors.selectSelectedOptions,
    optionStatusSelectors.selectOptionStatusMap,
    partsSelectors.selectPartsReferenceMap,
    selectInternalControlValues,
    partsSelectors.selectSiblingPartReferences,
    instanceSelectors.selectChildInstanceSavedStates,
    selectBaseControls
  ],
  (
    rootPart,
    currentSeedId,
    envControls,
    selectedOptions,
    optionStatusMap,
    partsMap,
    internalControlValues,
    siblingPartReferences,
    childInstanceSavedStates,
    baseControls
  ) => {
    if (!rootPart) return [];

    const rootNode = { ...rootPart, treeId: rootPart.ReferenceName, matrix: identityMatrix };

    // trailing key end char needs to be present to mark separation point from one key to another
    // so the keys would be like 0,1, and 0,19,
    // otherwise 0,1 will also match 0,19

    rootNode.key = `0${KEY_END_CHAR}`;

    // generate parameters from environment controls (ie. Env|Plot)
    const parameters = {
      $materialOverrides: {},
      $instances: {},
      $fix: {}
    };

    const { controlsParameters } = getControlsParams(envControls, parameters);

    Object.assign(parameters, controlsParameters);

    const tree = [];

    // This function fills the tree up with nodes
    getNodeTree(
      tree,
      rootNode,
      selectedOptions,
      parameters,
      partsMap,
      optionStatusMap,
      internalControlValues,
      siblingPartReferences,
      childInstanceSavedStates,
      currentSeedId,
      baseControls
    );

    return tree;
  }
);

/** Selects nodes which belong to current seed */
const selectMainPartNodesToRender = createSelector(
  [getPartNodesToRender, settingsSelectors.selectHideChildSeedUI],
  (tree, hide) => (hide ? tree.filter(node => node.isMainNode) : tree)
);

// used by selectors>getSelectedOptionsFull, selectors>getSelectedMaterialOptions, Controls, Summary

const getControls = createSelector([selectMainPartNodesToRender, getEnvControls], (partsToRender, envControls) => {
  if (!partsToRender) return [];

  const treeControls = getTreeControls(partsToRender);

  // merge all options' lists and options.
  return [...envControls, ...treeControls];
});

export { getControls, getPartNodesToRender, selectMainPartNodesToRender };
