export const SET_COOKIE_CONSENT = 'SET_COOKIE_CONSENT';
export const SET_COOKIE = 'SET_COOKIE';
export const TOGGLE_COOKIE_NOTIFICATION = 'TOGGLE_COOKIE_NOTIFICATION';

export const setCookieConsent = consent => ({
  type: SET_COOKIE_CONSENT,
  payload: { consent }
});

export const setCookie = cookie => ({
  type: SET_COOKIE,
  payload: { cookie }
});

export const toggleCookieNotification = open => ({
  type: TOGGLE_COOKIE_NOTIFICATION,
  payload: { open }
});
