const PATH = {
  DEFAULT: '/',
  INSTANCE: '/id/:instanceId',
  SEED: '/seed/:seedId',
  PROJECT: '/project/:projectId',
  DEV: '/dev/:component',
  DEV_NULIVING: '/test-nuliving-index'
} as const;

export default PATH;
