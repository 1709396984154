module.exports = {
  NODE_ENV: process.env.NODE_ENV,
  APP_ENV: process.env.APP_ENV,
  PORT: process.env.PORT,

  API_URL: process.env.REACT_APP_BACKEND_HOST,
  ASSETS_URL: process.env.REACT_APP_ASSETS_HOST,
  ADMIN_URL: process.env.REACT_APP_ADMIN_HOST || '',

  FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  HEAP_ANALYTICS_ID: process.env.REACT_APP_HEAP_ANALYTICS_ID,

  ACKEE_SERVER: process.env.REACT_APP_ACKEE_SERVER,
  ACKEE_DOMAIN_ID: process.env.REACT_APP_ACKEE_DOMAIN_ID,

  SERVER_THEME_STYLE_ID: 'server-theme',

  INITIAL_INSTANCE_ID: 'INITIAL',

  CONFIGURATOR_TYPES: {
    INSTANCE: 'instance',
    PROJECT: 'project',
    SEED: 'seed'
  }
};
