import * as instanceActions from '../instance/instanceActions';
import { INITIAL_INSTANCE_ID } from '../../config';
import { initialStateActions } from '../initialState';
import * as userInstancesActions from '../userInstances/userInstancesActions';
import * as selectedOptionsActions from './selectedOptionsActions';

export const getSelectedOptionsInitialState = (
  mainInstance = { _id: INITIAL_INSTANCE_ID, savedState: {} },
  baseInstance
) => {
  const instance = baseInstance ? { ...baseInstance, _id: INITIAL_INSTANCE_ID } : mainInstance;

  return {
    unsavedInstances: [],
    list: { [instance._id]: instance.savedState || {} }
  };
};

const selectedOptionsReducer = (state = getSelectedOptionsInitialState(), { type, payload = {} }) => {
  switch (type) {
    case initialStateActions.FETCH_INITIAL_STATE__SUCCESS:
    case instanceActions.RESET_INSTANCES: {
      const { instance, baseInstance } = payload;

      const newState = getSelectedOptionsInitialState(instance, baseInstance);

      return { ...newState, list: { ...state.list, ...newState.list } };
    }
    case userInstancesActions.CREATE_INSTANCE__SUCCESS:
    case userInstancesActions.CREATE_CHILD_INSTANCE__SUCCESS:
    case userInstancesActions.SAVE_CHILD_INSTANCE__SUCCESS:
    case selectedOptionsActions.RESET_SELECTED_OPTIONS: {
      const { savedState = {}, _id = INITIAL_INSTANCE_ID } = payload.instance;

      return {
        ...state,
        unsavedInstances: state.unsavedInstances.filter(id => id !== _id),
        list: { ...state.list, [_id]: savedState }
      };
    }
    case selectedOptionsActions.SET_OPTION: {
      const { controlTreeName, optionName, instanceId } = payload;

      return {
        ...state,
        unsavedInstances: state.unsavedInstances.includes(instanceId)
          ? state.unsavedInstances
          : [...state.unsavedInstances, instanceId],
        list: {
          ...state.list,
          [instanceId]: { ...state.list[instanceId], [controlTreeName]: optionName }
        }
      };
    }
    case instanceActions.FETCH_CHILD_INSTANCES__SUCCESS:
    case userInstancesActions.FETCH_USER_INSTANCES__SUCCESS: {
      const { instances = [] } = payload;

      const selectedOptions = {};

      instances.forEach(({ _id, savedState = {} }) => {
        selectedOptions[_id] = savedState;
      });

      return {
        ...state,
        list: { ...state.list, ...selectedOptions }
      };
    }
    case userInstancesActions.ADD_COMMENT:
    case userInstancesActions.UPDATE_COMMENT:
    case userInstancesActions.REMOVE_COMMENT: {
      const unsavedInstances = state.unsavedInstances.includes(payload.instanceId)
        ? state.unsavedInstances
        : [...state.unsavedInstances, payload.instanceId];

      return { ...state, unsavedInstances };
    }
    default:
      return state;
  }
};

export default selectedOptionsReducer;
