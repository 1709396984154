import { NOTIFICATION_TYPES } from '../../utility/propTypes';

export const NOTIFICATION__SHOW = 'NOTIFICATION__SHOW';
export const NOTIFICATION__HIDE = 'NOTIFICATION__HIDE';

export function showErrorNotification(message, showReloadLink) {
  return showNotification(message, NOTIFICATION_TYPES.ERROR, 30000, showReloadLink);
}

export function showSuccessNotification(message) {
  return showNotification(message, NOTIFICATION_TYPES.SUCCESS, 3000);
}

export function showNotification(message, type, timeout, showReloadLink) {
  return {
    type: NOTIFICATION__SHOW,
    payload: {
      message,
      type,
      timeout,
      showReloadLink
    }
  };
}

export function hideNotification(id) {
  return {
    type: NOTIFICATION__HIDE,
    payload: {
      id
    }
  };
}
