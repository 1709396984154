import { useCallback, useMemo } from 'react';
import Dropdown from '../Dropdown';
import inputStyle from './inputStyle';

export interface SelectProps {
  onChange: (name: string, v: string) => void;
  value?: string;
  name: string;
  options?: { label: string; value: string }[];
  className: string;
}
const Select = ({ onChange, value = '', name, options = [], className }: SelectProps) => {
  const changeHandler = useCallback(item => onChange(name, item.value), [name, onChange]);

  const itemRender = useCallback(
    (item, active) => <span className={inputStyle('select-option', { active })}>{item.label}</span>,
    []
  );
  const compareFunction = useCallback((currentValue, item) => {
    return currentValue.value === item.value;
  }, []);

  const listValue = useMemo(() => options.find(option => option.value === value), [options, value]);

  return (
    <Dropdown
      onChange={changeHandler}
      value={listValue}
      options={options}
      itemRender={itemRender}
      compareFunction={compareFunction}
      mix={className}
    />
  );
};

export default Select;
