import { ReactNode } from 'react';
import menuStyle from './style';
import MenuHeading from './MenuHeading';
import MenuItemDescription from './MenuItemDescription';

interface MenuSectionProps {
  children: ReactNode;
  type: string;
  heading?: ReactNode;
  description?: ReactNode;
  stickyHeader?: boolean;
}
const MenuSection = ({
  children = null,
  type,
  heading = null,
  description = null,
  stickyHeader = false
}: MenuSectionProps) => {
  return (
    <div className={menuStyle('section', { type })}>
      {heading ? <MenuHeading sticky={stickyHeader}>{heading}</MenuHeading> : null}
      {description ? <MenuItemDescription type="centered">{description}</MenuItemDescription> : null}
      {children}
    </div>
  );
};

export default MenuSection;
