import React, { useCallback } from 'react';
import b from 'b_';
import { useDispatch, useSelector } from 'react-redux';
import { footerSelectors } from '../../modules/settings';
import { useLinkGenerators, useTranslate } from '../../utility/hooks';
import { cookiesActions } from '../../modules/cookies';

import './Footer.scss';

const footerStyle = b.with('footer');

const Footer = () => {
  const dispatch = useDispatch();
  const links = useSelector(footerSelectors.selectFooterLinks);
  const { poweredByDisabled, poweredByLabel, poweredByLink } = useSelector(footerSelectors.selectPoweredBySettings);
  const translate = useTranslate();
  const showCookieNotification = useCallback(() => {
    dispatch(cookiesActions.toggleCookieNotification(true));
  }, [dispatch]);
  const { getCustomLink } = useLinkGenerators();

  return (
    <div className={footerStyle()}>
      <div className={footerStyle('links')}>
        {links.map(({ url, label }) => (
          <a
            href={getCustomLink(url)}
            key={label}
            target="_blank"
            rel="noreferrer noopener"
            className={footerStyle('link')}
          >
            <span className={footerStyle('item-name')}>{translate(label) || url}</span>
          </a>
        ))}
        <button type="button" className={footerStyle('link')} onClick={showCookieNotification}>
          {translate('Change cookie preferences')}
        </button>
      </div>
      {poweredByDisabled ? null : (
        <p className={footerStyle('powered-by')}>
          {translate('Powered by')}{' '}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={poweredByLink}
            className={footerStyle('link', { developer: true })}
          >
            {translate(poweredByLabel)}
          </a>
        </p>
      )}
    </div>
  );
};

export default Footer;
