import { all, call, put, takeLatest, select, take, race } from 'redux-saga/effects';
import { DIALOGS } from '../../utility/dialogs';
import { configuratorTypeSelectors } from '../configuratorType';
import { authActions, authSelectors } from '../auth';
import { instanceSelectors } from '../instance';
import { userInstancesActions } from '../userInstances';
import { commonSelectors } from '../model';
import Heap from '../../utility/heap';
import * as dialogActions from './dialogActions';
import { SAVE_DIALOG_TITLE } from './constants';

function* doOpenProfileDialogSaga() {
  yield put(dialogActions.showDialog(DIALOGS.PROFILE));
}

function* watchOpenProfileDialogSaga() {
  yield takeLatest(dialogActions.OPEN_PROFILE_DIALOG, doOpenProfileDialogSaga);
}

function* doOpenInstanceDialogSaga() {
  yield put(dialogActions.showDialog(DIALOGS.OPEN_INSTANCE));
}

function* watchOpenInstanceDialogSaga() {
  yield takeLatest(dialogActions.OPEN_INSTANCE_DIALOG, doOpenInstanceDialogSaga);
}

function* doOpenExportModelDialogSaga() {
  yield put(dialogActions.showDialog(DIALOGS.EXPORT_MODEL));
}

function* watchOpenExportModelDialogSaga() {
  yield takeLatest(dialogActions.OPEN_EXPORT_MODEL_DIALOG, doOpenExportModelDialogSaga);
}

function* doOpenAuthDialogSaga() {
  yield put(dialogActions.showDialog(DIALOGS.LOGIN));

  yield take([authActions.LOGIN__REQUEST, authActions.REGISTRATION__REQUEST]);

  const response = yield take([authActions.LOGIN__SUCCESS, authActions.REGISTRATION__SUCCESS]);

  if (response.type === authActions.LOGIN__FAILURE || response.type === authActions.REGISTRATION__FAILURE) {
    // keep dialog open, dont do anything else
    return;
  }

  const isInstanceConfiguratorType = yield select(configuratorTypeSelectors.getIsInstanceConfiguratorType);
  const isOwner = yield select(instanceSelectors.getIsOwner);

  if (isInstanceConfiguratorType && !isOwner) {
    yield put(dialogActions.showDialog(DIALOGS.SAVE, { title: SAVE_DIALOG_TITLE }));

    yield take(userInstancesActions.CREATE_INSTANCE__SUCCESS);
  }

  yield put(dialogActions.hideDialog());
}

function* watchOpenAuthDialogSaga() {
  yield takeLatest(dialogActions.OPEN_AUTH_DIALOG, function* saga() {
    yield race({
      task: call(doOpenAuthDialogSaga),
      cancel: take(dialogActions.DIALOG__HIDE)
    });
  });
}

function* doOpenSaveOrShareOrContactDialogSaga(action) {
  const { requireLogin } = action.payload;

  let isLoggedIn = yield select(authSelectors.getLoggedIn);

  if (!isLoggedIn && requireLogin) {
    const isProjectConfiguratorType = yield select(configuratorTypeSelectors.getIsProjectConfiguratorType);
    const signUpTitle = isProjectConfiguratorType ? 'Sign up to save your selections' : 'Sign up to save your copy';

    yield put(dialogActions.showDialog(DIALOGS.LOGIN, { loginTitle: 'Please log in to your account', signUpTitle }));

    yield take([authActions.LOGIN__SUCCESS, authActions.REGISTRATION__SUCCESS]);

    isLoggedIn = yield select(authSelectors.getLoggedIn);
  }

  const isOwner = yield select(instanceSelectors.getIsOwner);

  // Contact dialog
  if (action.type === dialogActions.OPEN_CONTACT_DIALOG) {
    if (isLoggedIn && !isOwner) {
      yield put(dialogActions.showDialog(DIALOGS.SAVE, { title: SAVE_DIALOG_TITLE, ignoreEmail: true }));

      yield take(userInstancesActions.CREATE_INSTANCE__SUCCESS);
    }

    yield put(dialogActions.showDialog(DIALOGS.CONTACT));

    return;
  }

  // Save dialog
  if (action.type === dialogActions.OPEN_SAVE_DIALOG) {
    yield put(dialogActions.showDialog(DIALOGS.SAVE));

    yield take(userInstancesActions.CREATE_INSTANCE__SUCCESS);
  }

  // Share dialog
  if (action.type === dialogActions.OPEN_SHARE_DIALOG && !isOwner) {
    const instanceName = yield select(commonSelectors.selectDefaultInstanceName);

    yield put(userInstancesActions.createInstance(instanceName));

    yield take(userInstancesActions.CREATE_INSTANCE__SUCCESS);
  }

  yield put(dialogActions.showDialog(DIALOGS.SHARE));
}

function* raceOpenSaveOrShareOrContactDialogSaga(action) {
  yield race({
    task: call(doOpenSaveOrShareOrContactDialogSaga, action),
    cancel: take(dialogActions.DIALOG__HIDE)
  });
}

function* watchOpenSaveOrShareOrContactDialogSaga() {
  yield takeLatest(
    [dialogActions.OPEN_SAVE_DIALOG, dialogActions.OPEN_SHARE_DIALOG, dialogActions.OPEN_CONTACT_DIALOG],
    raceOpenSaveOrShareOrContactDialogSaga
  );
}

function doShowDialogSaga(action) {
  const { payload } = action;

  Heap.track('SHOW_DIALOG', { dialogName: payload.type });
}

function* watchShowDialogSaga() {
  yield takeLatest(dialogActions.DIALOG__SHOW, doShowDialogSaga);
}

export default function* moduleSaga() {
  yield all([
    watchOpenProfileDialogSaga(),
    watchOpenInstanceDialogSaga(),
    watchOpenExportModelDialogSaga(),
    watchOpenAuthDialogSaga(),
    watchOpenSaveOrShareOrContactDialogSaga(),
    watchShowDialogSaga()
  ]);
}
