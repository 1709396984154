import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../utility/hooks';
import { summarySelectors } from '../../modules/ui';
import './SummaryInfo.scss';
import useOverviewMetric from '../OverviewPanel/OverviewMetric/useOverviewMetric';
import { TYPE_METRIC } from '../../utility/propTypes';

const summaryInfoStyle = b.with('summary-info');

const SummaryInfoMetric = ({ metric }) => {
  const { displayName, name } = metric;
  const formattedValue = useOverviewMetric(metric);
  const translate = useTranslate();

  return (
    <div className={summaryInfoStyle('item', { type: name })}>
      <span className={summaryInfoStyle('description')}>{translate(displayName)}</span>
      <span className={summaryInfoStyle('value')}>{formattedValue}</span>
    </div>
  );
};

SummaryInfoMetric.propTypes = {
  metric: TYPE_METRIC
};

SummaryInfoMetric.defaultProps = { metric: {} };

const SummaryInfo = ({ mode }) => {
  const metrics = useSelector(summarySelectors.selectMetrics);

  return (
    <div className={summaryInfoStyle({ mode })}>
      {metrics.map(metric => (
        <SummaryInfoMetric key={metric.name} metric={metric} />
      ))}
    </div>
  );
};

SummaryInfo.defaultProps = {
  mode: 'summary'
};

SummaryInfo.propTypes = {
  mode: PropTypes.oneOf(['summary', 'overview'])
};

export default SummaryInfo;
