import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_CONTROL_OPTION } from '../../../../../utility/propTypes';
import { useTranslate } from '../../../../../utility/hooks';
import preferencesTableStyle from '../../preferencesTableStyle';

const PreferenceLabel = ({ option, empty }) => {
  const translate = useTranslate();

  if (empty) return null;

  return (
    <div className={preferencesTableStyle('value')}>
      <div className={preferencesTableStyle('result')}>
        <span className={preferencesTableStyle('option-label')}>{translate(option.displayName)}</span>
      </div>
    </div>
  );
};

PreferenceLabel.propTypes = {
  option: TYPE_CONTROL_OPTION.isRequired,
  empty: PropTypes.bool.isRequired
};

export default PreferenceLabel;
