import { SyntheticEvent, useCallback, useMemo } from 'react';
import { PartNodeControlType, PartNodeOptionType } from 'types';
import { controlStyle } from '../style';
import Icon from '../../../Icon';
import { useTranslate } from '../../../../utility/hooks';
import MoreInfoButton from '../../MoreInfoButton';
import { Button } from '../../../Atoms';

interface ControlHeaderProps {
  selectedOption?: PartNodeOptionType;
  control: PartNodeControlType;
  isCollapsable: boolean;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  onOpenExtraInfo: (e: SyntheticEvent) => void;
  currentExtraInfoCollection: string;
  isSelected: boolean;
  onSelect: (control: PartNodeControlType) => void;
}
const ControlHeader = ({
  selectedOption,
  control,
  isCollapsable,
  isOpen,
  setOpen,
  onOpenExtraInfo,
  currentExtraInfoCollection,
  isSelected,
  onSelect
}: ControlHeaderProps) => {
  const translate = useTranslate();
  const isOptionValueShown = selectedOption && control.enableCollapse && !isOpen;

  const selectHeaderHandler = useCallback(
    e => {
      e.stopPropagation();

      onSelect(control);

      setOpen(isCollapsable ? !isOpen : true);
    },
    [onSelect, control, setOpen, isCollapsable, isOpen]
  );

  const isMoreInfoButtonVisible = selectedOption?.extraInfoCollection && !control.bindExtraInfo;
  const isMoreInfoSelected = useMemo(
    () => isSelected && selectedOption?.extraInfoCollection === currentExtraInfoCollection,
    [currentExtraInfoCollection, isSelected, selectedOption]
  );

  return (
    <div className={controlStyle('header-wrapper')}>
      <h4 className={controlStyle('header')}>
        <span className={controlStyle('title', { fixed: control.fixed })}>{translate(control.displayName)}</span>
        {isCollapsable ? (
          <Button mix={controlStyle('collapse-icon')} onClick={selectHeaderHandler} circular color="main-outline">
            <Icon type={`chevron-${isOpen ? 'down-up' : 'up-down'}`} />
          </Button>
        ) : null}
        {isMoreInfoButtonVisible && !isCollapsable ? (
          <MoreInfoButton onClick={onOpenExtraInfo} selected={isMoreInfoSelected} />
        ) : null}
      </h4>
      {isMoreInfoButtonVisible && isCollapsable && isOpen ? (
        <MoreInfoButton onClick={onOpenExtraInfo} selected={isMoreInfoSelected} />
      ) : null}
      {isOptionValueShown ? <strong>{selectedOption.displayName}</strong> : null}
    </div>
  );
};

export default ControlHeader;
