const VIEW_TYPES = {
  PLAN_VIEW: 'PlanView',
  VIEW: 'View',
  FIXED: 'Fixed',
  OTHER: 'other',
  ORTHO: 'Ortho',
  ORTHO_FIXED: 'OrthoFixed'
} as const;

export type CameraViewTypes = typeof VIEW_TYPES[keyof typeof VIEW_TYPES];

export const ORTHO_CAMERAS = {
  [VIEW_TYPES.ORTHO]: true,
  [VIEW_TYPES.ORTHO_FIXED]: true
};

export default VIEW_TYPES;
