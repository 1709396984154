import { initialStateActions } from '../initialState';
import * as userInstancesActions from '../userInstances/userInstancesActions';

export const CONFIGURATOR_TYPES = { PROJECT: 'project', INSTANCE: 'instance', SEED: 'seed' };

const configuratorTypeReducer = (state = null, { type, payload = {} }) => {
  switch (type) {
    case initialStateActions.FETCH_INITIAL_STATE__SUCCESS:
      return payload.type;
    case userInstancesActions.CREATE_INSTANCE__SUCCESS:
      return CONFIGURATOR_TYPES.INSTANCE;
    default:
      return state;
  }
};

export default configuratorTypeReducer;
