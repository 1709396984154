import { Color, DoubleSide, Material, MeshPhysicalMaterial, Shader, SpriteMaterial, Vector2 } from 'three';
import { ModelUnitsType, PartMaterialType, SpriteMaterialType } from 'types';
import textureStore, { TEXTURE_TYPE } from '../modules/textures/textureStore';
import shaders from './shaders';
import { BUMP_MODIFIER_IMPERIAL, BUMP_MODIFIER_METRIC, MODEL_UNITS } from './viewerSettings';

/** Returns bump modifier based on model units */
const getBumpModifier = (modelUnits: ModelUnitsType = MODEL_UNITS.METRIC) =>
  modelUnits === MODEL_UNITS.IMPERIAL ? BUMP_MODIFIER_IMPERIAL : BUMP_MODIFIER_METRIC;

/** Injects shader which colors backface to required color to current material */
const injectBackfaceShader = (material: Material, sectionCapColor: string) => {
  // replace fragment color with custom one if showing back face

  const color = new Color(sectionCapColor);

  color.convertSRGBToLinear();

  // define onBeforeCompile function which replaces a small part from Phong shader
  const onBeforeCompile = (shader: Shader) => {
    shader.uniforms.sectionCapColor = { value: color }; // eslint-disable-line no-param-reassign

    // Add uniform definition at the front of fragment shader
    const fragmentShader = `uniform vec3 sectionCapColor;
  ${shader.fragmentShader}`;

    // replace backface render program
    const replacedFragmentShader = fragmentShader.replace(shaders.FRAGMENT_CAPS, shaders.FRAGMENT_CAPS_REPLACE);

    shader.fragmentShader = replacedFragmentShader; // eslint-disable-line no-param-reassign
  };

  // eslint-disable-next-line no-param-reassign
  material.onBeforeCompile = onBeforeCompile;

  return material;
};

/**
 * Translates material definition to options which are used in MeshPhysicalMaterial
 * Also finds appropriate textures from texturesStore
 */

const getMaterialOptions = (
  {
    Name = '',
    ColorDiffuse = 'rgb(255, 255, 255)',
    ColorEmission = 'rgb(0, 0, 0)',
    BumpScale = 0,
    Gloss = 0,
    Opacity = 1,
    Metalness = 0,
    LightMapIntensity = 1,
    AmbientOcclusionMapIntensity = 1,
    NormalScale = { x: 1, y: 1 },
    Reflectivity = 0,
    Clearcoat = 0,
    ClearcoatRoughness = 0,
    EmissiveIntensity = 1,
    DisplacementScale = 1,
    DisplacementBias = 0,
    IndexOfRefraction = 1,
    Wireframe = false,
    Transparent = false
  }: PartMaterialType,
  modelUnits: ModelUnitsType
) => {
  const name = Name;
  const map = textureStore.getTexture(TEXTURE_TYPE.DIFFUSE, Name);
  const bumpMap = textureStore.getTexture(TEXTURE_TYPE.BUMP, Name);
  const alphaMap = textureStore.getTexture(TEXTURE_TYPE.TRANSPARENCY, Name);
  const metalnessMap = textureStore.getTexture(TEXTURE_TYPE.METALNESS, Name);
  const aoMap = textureStore.getTexture(TEXTURE_TYPE.AMBIENT_OCCLUSION, Name);
  const lightMap = textureStore.getTexture(TEXTURE_TYPE.LIGHT, Name);
  const normalMap = textureStore.getTexture(TEXTURE_TYPE.NORMAL, Name);
  const roughnessMap = textureStore.getTexture(TEXTURE_TYPE.ROUGHNESS, Name);
  const emissiveMap = textureStore.getTexture(TEXTURE_TYPE.EMISSIVE, Name);
  const displacementMap = textureStore.getTexture(TEXTURE_TYPE.DISPLACEMENT, Name);

  let metalness;

  if (Metalness !== undefined) {
    metalness = Metalness;
  } else if (metalnessMap) {
    metalness = 1;
  } else if (!metalnessMap) {
    metalness = 0;
  }

  const { x: normalX = 1, y: normalY = 1 } = NormalScale;

  return {
    name,
    /*
        This needs to be changed to straight ColorDiffuse but for legacy reasons we have materials
        where ColorDiffuse is something not white while map is defined. We need to update all materials to
        have white color if map is defined and then we can remove this here.
        In that case color will modulate the diffuse map.
      */
    color: map ? 0xffffff : ColorDiffuse,
    emissiveMap,
    emissive: ColorEmission,
    emissiveIntensity: EmissiveIntensity,
    map,
    bumpMap,
    bumpScale: bumpMap ? BumpScale * getBumpModifier(modelUnits) : 0,
    alphaMap,
    roughness: 1 - Gloss,
    roughnessMap,
    metalness,
    metalnessMap,
    aoMap,
    aoMapIntensity: AmbientOcclusionMapIntensity,
    lightMap,
    lightMapIntensity: LightMapIntensity,
    normalMap,
    normalScale: new Vector2(normalX, normalY),
    clearcoat: Clearcoat,
    clearcoatRoughness: ClearcoatRoughness,
    reflectivity: Reflectivity,
    displacementMap,
    displacementScale: DisplacementScale,
    displacementBias: DisplacementBias,
    refractionRatio: 1 / IndexOfRefraction,
    wireframe: Wireframe,
    opacity: Opacity,
    transparent: Transparent
  };
};

/**
 * Evaluates material definition to figure out correct
 * transparency options to pass to MeshPhysicalMaterial
 */

const getMaterialTransparencyOptions = ({
  Transparent,
  Opacity = 1,
  EnvironmentMapIntensity = 1,
  Name,
  AlphaTest = 0
}: PartMaterialType) => {
  /* This envMap will overwrite scene's environment if any is set */
  const envMap = textureStore.getTexture(TEXTURE_TYPE.ENVIRONMENT, Name) || null;

  const glassyValues: { alphaTest?: number; depthWrite?: boolean; clipShadows?: boolean } = {};

  if (Transparent) {
    const glassy = Opacity < 1;

    glassyValues.alphaTest = AlphaTest || (glassy ? 0 : 0.6);
    glassyValues.depthWrite = !glassy;
    glassyValues.clipShadows = false;
  }

  return {
    ...glassyValues,
    envMap,
    envMapIntensity: EnvironmentMapIntensity
  };
};

/**
 * Helper fn to determine if a material should cast shadow.
 * This value is later attached to the mesh which uses this material.
 */
export const hasMaterialShadow = (material: MeshPhysicalMaterial) =>
  !(material.opacity < 1 || material.alphaMap || material.transparent);

/**
 * Creates threejs MeshPhysicalMaterial from material definitions
 */
export const createPhysicalMaterial = (
  materialDef: PartMaterialType,
  sectionCapEnabled = false,
  sectionCapColor = '#000000',
  modelUnits: ModelUnitsType = 'metric'
) => {
  //
  const material = {
    side: DoubleSide,
    shadowSide: DoubleSide
  };

  const materialOptions = getMaterialOptions(materialDef, modelUnits);
  const transparencyOptions = getMaterialTransparencyOptions(materialDef);

  const coloredMaterial = { ...material, ...materialOptions, ...transparencyOptions };

  const threeMaterial = new MeshPhysicalMaterial(coloredMaterial);

  threeMaterial.userData.castShadow = hasMaterialShadow(threeMaterial);

  threeMaterial.color.convertSRGBToLinear();

  if (sectionCapEnabled && !materialDef.Transparent) {
    injectBackfaceShader(threeMaterial, sectionCapColor);
  }

  return threeMaterial;
};

/**
 * Creates threejs' SpriteMaterial from material definitions
 */
export const createSpriteMaterial = (materialDef: SpriteMaterialType) => {
  const { Name, ColorDiffuse = 0xffffff, SizeAttenuation = true, rotation = 0 } = materialDef;
  const map = textureStore.getTexture(TEXTURE_TYPE.DIFFUSE, Name);
  const alphaMap = textureStore.getTexture(TEXTURE_TYPE.TRANSPARENCY, Name);
  const material = {
    name: Name,
    map,
    alphaMap,
    color: ColorDiffuse,
    sizeAttenuation: SizeAttenuation,
    rotation
  };

  const threeMaterial = new SpriteMaterial(material);

  threeMaterial.color.convertSRGBToLinear();

  return threeMaterial;
};

/** Creates individial MeshPhysicalMaterial instances from map of material definitions */
export const createPhysicalMaterials = (materialsMap: Record<string, PartMaterialType>) => {
  return Object.keys(materialsMap).map(materialName => {
    const materialDef = materialsMap[materialName];

    return createPhysicalMaterial(materialDef);
  });
};

/** Creates individial MeshSpriteMaterial instances from map of material definitions */
export const createSpriteMaterials = (materialsMap: Record<string, PartMaterialType>) => {
  return Object.keys(materialsMap).map(materialName => {
    const materialDef = materialsMap[materialName];

    return createSpriteMaterial(materialDef);
  });
};
