import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { projectSelectors } from '../../modules/project';
import { seedSelectors } from '../../modules/seed';
import { controlSelectors } from '../../modules/model';
import { uiActions } from '../../modules/ui';
import { dialogActions } from '../../modules/dialog';
import { instanceActions } from '../../modules/instance';
import PATH from '../paths';

const useResetConfiguratorHandler = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const projectLink = useSelector(projectSelectors.selectProjectLink);
  const seedId = useSelector(seedSelectors.selectSeedId);
  const firstTabName = useSelector(controlSelectors.getFirstControlTab);

  return useCallback(() => {
    // add more dispatches here if needed
    dispatch(uiActions.selectTabAndControlGroup(firstTabName));
    dispatch(dialogActions.hideDialog());
    dispatch(instanceActions.resetInstances());

    let initialLink;

    if (projectLink) {
      initialLink = PATH.PROJECT.replace(':projectId', projectLink);
    } else {
      initialLink = PATH.SEED.replace(':seedId', seedId);
    }

    history.push(initialLink);
  }, [dispatch, firstTabName, history, projectLink, seedId]);
};

export default useResetConfiguratorHandler;
