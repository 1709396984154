import b from 'b_';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../../../utility/hooks';
import { commonSelectors } from '../../../../modules/model';
import './HomeButton.scss';
import Actions from '../../../Actions';

const homeButtonStyle = b.with('home-button');

const HomeButton = () => {
  const isChildModeEnabled = useSelector(commonSelectors.getIsChildModeEnabled);
  const translate = useTranslate();

  return (
    <Actions
      group="homeButton"
      color="text"
      size="md"
      rounded={false}
      noLabel
      mix={homeButtonStyle({ disabled: isChildModeEnabled })}
      customRender={(action, button) => (
        <span key={action.name} aria-label={translate(action.displayName)} data-balloon-pos="down">
          {button}
          <div className={homeButtonStyle('divider')} />
        </span>
      )}
    />
  );
};

export default HomeButton;
