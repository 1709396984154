import { ReactNode } from 'react';
import { ParsedActionType } from '../../modules/settings/actionsSelectors';
import { useActions, useLinkGenerators, useTranslate } from '../../utility/hooks';
import Button, { ButtonColorType, BUTTON_TYPES } from '../Atoms/Button/Button';
import { useTabLink } from '../TabLink';
import { ACTION_TYPES } from '../../utility/actionTypes';
import Icon from '../Icon';

interface ActionsProps {
  group: 'aboutTab' | 'reviewTab' | 'summaryTab' | 'overviewPanel' | 'shareDialog' | 'homeButton' | 'summaryPanel';
  mix?: string;
  block?: boolean;
  bold?: boolean;
  circular?: boolean;
  color?: ButtonColorType;
  rounded?: boolean;
  size?: 'xs' | 'md' | 'lg';
  displayIcons?: boolean;
  labelAfter?: boolean;
  wrapperClassName?: string;
  labelClassName?: string;
  customRender?: (action: ParsedActionType, node: ReactNode) => ReactNode;
  noLabel?: boolean;
}

const Actions = ({
  group,
  mix = '',
  size = 'lg',
  block = true,
  bold = true,
  rounded = true,
  circular = false,
  color,
  noLabel = false,
  customRender
}: ActionsProps) => {
  const translate = useTranslate();
  const makeTabLink = useTabLink();
  const { actions, handleClickOnAction } = useActions(group);

  const { getCustomLink } = useLinkGenerators();

  return (
    <>
      {actions.map(action => {
        const commonProps = {
          label: noLabel ? '' : translate(action.displayName),
          key: action.name,
          size,
          block,
          bold,
          rounded,
          circular,
          color: action.color || color,
          mix,
          children: action.icon ? <Icon type={action.icon} scale={1.1} /> : undefined
        };

        let button;

        if (action.type === ACTION_TYPES.OPEN_DIALOG) {
          button = (
            <Button
              {...commonProps}
              type={BUTTON_TYPES.BUTTON}
              onClick={handleClickOnAction}
              data-action={action.name}
              disabled={action.disabled}
            />
          );
        } else if (action.type === ACTION_TYPES.OPEN_TAB) {
          button = <Button {...commonProps} type={BUTTON_TYPES.LINK} to={makeTabLink(action.tab)} />;
        } else {
          button = <Button {...commonProps} type={BUTTON_TYPES.EXTERNAL_LINK} href={getCustomLink(action.link)} />;
        }

        if (customRender) {
          return customRender(action, button);
        }

        return button;
      })}
    </>
  );
};

export default Actions;
