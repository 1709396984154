export const SELECT_OPTION = 'SELECT_OPTION';
export const SET_OPTION = 'SET_OPTION';

export const RESET_SELECTED_OPTIONS = 'RESET_SELECTED_OPTIONS';

export const selectOption = (control, option) => ({
  type: SELECT_OPTION,
  payload: { control, option }
});

export const setOption = (controlTreeName, optionName, instanceId) => ({
  type: SET_OPTION,
  payload: { controlTreeName, optionName, instanceId }
});

export const resetSelectedOptions = instance => ({
  type: RESET_SELECTED_OPTIONS,
  payload: { instance }
});
