import React from 'react';
import PropTypes from 'prop-types';
import { Matrix4 } from 'three';
import Icon from '../../../Icon';
import SceneButton from '../SceneButton';

const CloseButton = ({ onClose, matrix }) => (
  <SceneButton size="lg" onClick={onClose} matrix={matrix} type="close" color="text">
    <Icon type="close" scale={1.2} />
  </SceneButton>
);

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
  matrix: PropTypes.instanceOf(Matrix4).isRequired
};

export default CloseButton;
