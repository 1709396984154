import { useCallback } from 'react';
import { Vector3 } from 'three';
import { ANIMATION as ANIMATION_SETTINGS } from '../../../utility/viewerSettings';
import getThreeSetup from '../getThreeSetup';

const ZOOM_COEFFICIENT = 0.8;

const reusableDeltaVector = new Vector3();
const reusablePositionVector = new Vector3();

/** Returns camera controls for zooming in and out. */
const useCameraControls = (camera, setAnimated) => {
  const { orbit } = getThreeSetup();

  const zoom = useCallback(
    factor => {
      const { position, isOrthographicCamera } = camera;

      if (isOrthographicCamera) {
        const targetZoom = camera.zoom / factor;

        setAnimated(camera.position, orbit.target, ANIMATION_SETTINGS.PAN_FRAME_COUNT, targetZoom);
      } else {
        const { target } = orbit;

        /* 
          1. Get current delta vector between target and position
          2. Multiply the delta by factor
          3. Add delta to current target to get new position
          4. If delta is not larger than maxDistance, animate
        */

        reusableDeltaVector.copy(position).sub(target).multiplyScalar(factor);

        if (reusableDeltaVector.length() < orbit.maxDistance) {
          reusablePositionVector.copy(target).add(reusableDeltaVector);

          setAnimated(reusablePositionVector, target, ANIMATION_SETTINGS.PAN_FRAME_COUNT);
        }
      }
    },
    [camera, orbit, setAnimated]
  );

  const zoomIn = useCallback(() => zoom(ZOOM_COEFFICIENT), [zoom]);
  const zoomOut = useCallback(() => zoom(1 / ZOOM_COEFFICIENT), [zoom]);

  return { zoomIn, zoomOut };
};

export default useCameraControls;
