class Heap {
  static track(eventName, eventProperties) {
    if (window.heap.loaded) {
      window.heap.track(eventName, eventProperties);
    }
  }

  static identify(id) {
    if (window.heap.loaded) {
      window.heap.identify(id);
    }
  }

  static addUserProperties(user) {
    if (window.heap.loaded) {
      window.heap.addUserProperties(user);
    }
  }

  static stopHeap() {
    window.heap.loaded = false;
  }

  static startHeap() {
    window.heap.loaded = true;
  }
}

export default Heap;
