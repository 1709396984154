import { createSelector } from 'reselect';
import { selectUiMode, selectIsFullscreen } from './uiStateSelectors';

export const UI_MODES = {
  MINIMAL_FULLSCREEN: 'minimal_fullscreen',
  MINIMAL_OPEN: 'minimal_open',

  MINIMAL_FULLSCREEN_ONLY: 'minimal_fullscreen_only',
  MINIMAL_OPEN_ONLY: 'minimal_open_only'
};

const MINIMAL_UI_MODES = new Set(Object.values(UI_MODES));

export const selectIsMinimalUi = createSelector(
  [selectUiMode, selectIsFullscreen],
  (uiMode, fullscreen) => MINIMAL_UI_MODES.has(uiMode) && !fullscreen
);

export const selectMinimalUiSettings = createSelector([selectUiMode], uiMode => ({
  isAdditionalButtonVisible: uiMode !== UI_MODES.MINIMAL_FULLSCREEN_ONLY && uiMode !== UI_MODES.MINIMAL_OPEN_ONLY,
  isMainFullscreen: uiMode === UI_MODES.MINIMAL_FULLSCREEN || uiMode === UI_MODES.MINIMAL_FULLSCREEN_ONLY
}));
