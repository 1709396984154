import { useMemo } from 'react';
import { useAnimated } from '../../../utility/hooks';

export const dummyFormatter = value => value;

function useOverviewMetric({ value, formatFunc = dummyFormatter, unitBeforeValue = false, unit = '' }) {
  const animatedValue = useAnimated(value);

  return useMemo(() => {
    const formattedValue = formatFunc(animatedValue);

    if (unitBeforeValue) {
      return `${unit} ${formattedValue}`;
    }

    return `${formattedValue} ${unit}`;
  }, [formatFunc, animatedValue, unitBeforeValue, unit]);
}

export default useOverviewMetric;
