import React, { useCallback } from 'react';
import b from 'b_';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from '../../Atoms';
import Icon from '../../Icon';
import { dialogActions } from '../../../modules/dialog';
import { DIALOGS } from '../../../utility/dialogs';
import { useTranslate, useUiModes } from '../../../utility/hooks';
import UnsavedIndicator from '../../UnsavedIndicator';

import './MenuButton.scss';

const menuButtonStyle = b.with('menu-button');

const MenuButton = ({ type }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const handleShowMenu = useCallback(() => {
    dispatch(dialogActions.showDialog(DIALOGS.MENU));
  }, [dispatch]);
  const { isMinimalUi } = useUiModes();

  if (isMinimalUi) return null;

  return (
    <Button
      mix={menuButtonStyle({ type })}
      onClick={handleShowMenu}
      circular
      color="text"
      size="lg"
      aria-label={translate('Menu')}
      data-balloon-pos="right"
    >
      <Icon type="menu" scale={1.4} />
      <div className={menuButtonStyle('indicator')}>
        <UnsavedIndicator />
      </div>
    </Button>
  );
};

MenuButton.defaultProps = {
  type: ''
};

MenuButton.propTypes = {
  type: PropTypes.string
};

export default MenuButton;
