import React, { useMemo } from 'react';
import { useTranslate } from '../../utility/hooks';
import { Button } from '../Atoms';
import ServicePage from './ServicePage';

const IframeDeniedPage = () => {
  const translate = useTranslate();
  const button = useMemo(
    () => (
      <Button
        rounded
        bold
        color="main-outline"
        label={translate('Open configurator on creatomus.com')}
        type="external-link"
        href={window.location.origin + window.location.pathname}
      />
    ),
    [translate]
  );

  return (
    <ServicePage
      title="Not allowed"
      subtitle="This configurator cannot be embedded"
      message="If you are the owner of this configurator, contact Creatomus team to solve this issue"
      button={button}
    />
  );
};

export default IframeDeniedPage;
