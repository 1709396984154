import { createSelector } from 'reselect';
import { authSelectors } from '../auth';
import { INITIAL_INSTANCE_ID } from '../../config';

export const selectInstances = state => state.instance;
export const selectInstanceList = state => state.instance.list;

export const selectCurrentInstanceId = createSelector([selectInstances], instances => instances.currentInstanceId);

export const selectInstance = createSelector([selectInstances], ({ currentInstanceId, list }) => {
  return list[currentInstanceId];
});
export const selectInstanceId = createSelector([selectInstance], instance => instance._id);

export const selectIsInitialInstance = createSelector(
  [selectInstanceId],
  instanceId => instanceId === INITIAL_INSTANCE_ID
);
export const selectInstanceLanguage = createSelector([selectInstance], instance => instance.lang);
export const selectInstancePdfGallery = createSelector([selectInstance], instance => instance.pdfGallery);
export const selectInstanceEmail = createSelector([selectInstances], instances => instances.email);

export const getIsOwner = createSelector(
  [selectInstance, authSelectors.getUserUid],
  (instance, uid) => instance.createdBy === uid
);

export const getIsOwnLead = createSelector(
  [selectInstance, getIsOwner],
  (instance, isOwnInstance) => instance.lead && isOwnInstance
);

export const getInstanceSnapshot = createSelector([selectInstance], instance => instance.snapshotId);

export const getInstanceName = createSelector([selectInstance], instance => instance.instanceName);

export const selectChildInstanceSavedStates = createSelector([selectInstances], instances => {
  // merge user instances and child instances to one list to be navigable
  const list = Object.values(instances.list);

  const savedStates = {};
  const instanceToSeed = {};

  list.forEach(({ seed, savedState, _id }) => {
    if (seed?.root) {
      savedStates[seed.root] = savedStates[seed.root] || {};
      savedStates[seed.root][_id] = savedState;
      instanceToSeed[_id] = seed.root;
    }
  });

  return { instanceToSeed, savedStates };
});

function compareInstances(a, b) {
  return new Date(b.updatedAt) - new Date(a.updatedAt);
}

export const selectUserInstances = createSelector(
  [selectInstanceList, authSelectors.getUserUid],
  (list, uid) =>
    Object.values(list)
      .filter(instance => instance.createdBy === uid)
      .sort(compareInstances) || []
);

export const selectUserInstancesWithoutChild = createSelector([selectUserInstances], list =>
  list.filter(instance => !instance.seed.parent)
);

export const selectUserInstancesCount = createSelector([selectUserInstancesWithoutChild], list => list.length);

export const selectInstanceById = createSelector(
  [selectInstanceList, (_, instanceId) => instanceId],
  (list, id) => list[id]
);

export const selectChildInstanceChoicesById = createSelector([selectInstanceById], instance => {
  const choices = [];

  if (Array.isArray(instance?.summary?.sections)) {
    instance.summary.sections.forEach(section => {
      const { choices: sectionChoices = [] } = section;

      sectionChoices.forEach(sectionChoice => {
        choices.push({ option: sectionChoice?.option, control: sectionChoice?.option?.control });
      });
    });
  }

  return choices;
});

/** Selects comments for current instance */

export const selectInstanceComments = createSelector([selectInstance], instance => {
  return instance.comments;
});
