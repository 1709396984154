import React, { useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import countryList from 'country-list';
import { Field, Button, Switch } from '../../Atoms';
import { useFormState, useTranslate } from '../../../utility/hooks';
import { MenuSection, menuStyle, formStyle } from '../../Menu';
import { contactFormSelectors } from '../../../modules/settings';
import { loaderSelectors } from '../../../modules/loader';
import { instanceActions } from '../../../modules/instance';
import MenuItemDescription from '../../Menu/MenuItemDescription';
import MarketingConsent from './MarketingConsent';

const { FIELD_TYPES } = contactFormSelectors;

const countries = countryList.getData().map(({ code, name }) => ({ value: code, label: name }));

const getFieldType = type => (type === FIELD_TYPES.COUNTRY_LIST ? FIELD_TYPES.SELECT : type);
const getFieldOptions = (type, options = []) => {
  if (type === FIELD_TYPES.COUNTRY_LIST) {
    return countries;
  }

  if (type === FIELD_TYPES.SELECT) {
    return options;
  }

  return null;
};

const ContactForm = () => {
  const [consentLabel, setConsentLabel] = useState('');
  const dispatch = useDispatch();
  const translate = useTranslate();
  const formTitle = useSelector(contactFormSelectors.selectContactFormTitle);
  const formDescription = useSelector(contactFormSelectors.selectContactFormSubtitle);
  const validationFields = useSelector(contactFormSelectors.selectContactFormValidationFields);
  const initialState = useSelector(contactFormSelectors.selectContactFormInitialState);
  const requestReceiverNotice = useSelector(contactFormSelectors.selectContactFormRequestReceiverNotice);

  const { handleFormFieldChange, handleFormSwitchChange, formState, formValidation } = useFormState(
    initialState,
    validationFields
  );

  const isSaving = useSelector(loaderSelectors.selectIsInstanceSaving);
  const fields = useSelector(contactFormSelectors.selectContactFormFields);
  const isSubmitDisabled = useMemo(
    () => Boolean(isSaving || Object.values(formValidation).length),
    [formValidation, isSaving]
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      const { consentGiven = false } = formState;

      const consent = {
        consentGiven,
        label: consentLabel
      };

      dispatch(instanceActions.submitQuestion(formState, consent));
    },
    [consentLabel, dispatch, formState]
  );

  return (
    <form onSubmit={handleSubmit}>
      <MenuSection heading={translate(formTitle)} description={translate(formDescription)} type="contact-form-header" />
      <MenuSection type="contact-form-section">
        {fields.map(field => {
          if (field.type === FIELD_TYPES.CONSENT) {
            return (
              <MarketingConsent
                key={field.key}
                onChange={handleFormSwitchChange}
                formState={formState}
                onConsentLabelChange={setConsentLabel}
              />
            );
          }

          if (field.type === FIELD_TYPES.CHECKBOX) {
            return (
              <Switch
                key={field.key}
                name={field.name}
                groupLabel={field.title}
                onChange={handleFormSwitchChange}
                type="checkbox"
                checked={formState[field.name]}
                caption={translate(field.label)}
              />
            );
          }

          if (field.type === FIELD_TYPES.DIVIDER) {
            return (
              <MenuItemDescription type="centered" divider>
                {field.label}
              </MenuItemDescription>
            );
          }

          return (
            <Field
              key={field.key}
              name={field.name}
              type={getFieldType(field.type)}
              value={formState[field.name]}
              label={translate(field.label)}
              onChange={
                getFieldType(field.type) === FIELD_TYPES.SELECT ? handleFormSwitchChange : handleFormFieldChange
              }
              options={getFieldOptions(field.type, field.options)}
              required={field.required}
              autoComplete={field.autocomplete}
              feedback={formValidation[field.name]}
            />
          );
        })}
      </MenuSection>

      <Button type="submit" block mix={menuStyle('submit-button')} disabled={isSubmitDisabled}>
        {isSaving ? 'Loading...' : translate('Send')}
      </Button>

      <span className={formStyle('footer')}>{translate(requestReceiverNotice)}</span>
    </form>
  );
};

export default ContactForm;
