import { InstancedMesh } from 'three';

class FlippableInstancedMesh extends InstancedMesh {
  flipIndices() {
    if (this.geometry?.index) {
      const indices = new Uint16Array(this.geometry.index.array);

      indices.reverse();
      this.geometry.index.set(indices);
    }

    return this;
  }
}

export default FlippableInstancedMesh;
