import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { modelSettingsSelectors } from '../../../modules/settings';
import { TYPE_IMAGE_OBJECT } from '../../../utility/propTypes';

function OverviewMetricCustomIcon({ image, alt }) {
  const urlGenerator = useSelector(modelSettingsSelectors.selectImageUrlGenerator);

  return <img src={urlGenerator(image.fileName)} alt={alt} />;
}
OverviewMetricCustomIcon.propTypes = {
  image: TYPE_IMAGE_OBJECT.isRequired,
  alt: PropTypes.string
};
OverviewMetricCustomIcon.defaultProps = {
  alt: 'Metric'
};

export default OverviewMetricCustomIcon;
