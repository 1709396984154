import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { dialogActions } from '../../modules/dialog';

const useMenuClose = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(dialogActions.hideDialog());
  }, [dispatch]);
};

export default useMenuClose;
