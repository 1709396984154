import React from 'react';
import PropTypes from 'prop-types';
import menuStyle from './style';

const MenuItemDescription = ({ children, type, divider }) => (
  <span className={menuStyle('item-description', { type, divider })}>{children}</span>
);

MenuItemDescription.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  divider: PropTypes.bool
};

MenuItemDescription.defaultProps = {
  children: null,
  type: 'left',
  divider: false
};

export default MenuItemDescription;
