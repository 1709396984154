import b from 'b_';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../../utility/hooks';
import { uiSelectors } from '../../../modules/ui';
import Actions from '../../Actions';
import './SummaryPageInfo.scss';

const summaryPageInfoStyle = b.with('summary-page-info');

const SummaryPageInfo = () => {
  const translate = useTranslate();
  const {
    title = 'Design is ready!',
    text = "Well done, now it's time to save and share your design. Explore the summary and make any changes if needed."
  } = useSelector(uiSelectors.selectSummaryPageSettings);

  return (
    <div className={summaryPageInfoStyle()}>
      <h4 className={summaryPageInfoStyle('title')}>{translate(title)}</h4>
      <div className={summaryPageInfoStyle('actions')}>
        <div className={summaryPageInfoStyle('actions-body')}>
          <Actions
            group="summaryPanel"
            circular
            noLabel
            labelClassName={summaryPageInfoStyle('action-name')}
            wrapperClassName={summaryPageInfoStyle('action')}
            customRender={(action, button) => (
              <span className={summaryPageInfoStyle('action')} key={action.name}>
                {button}
                <span className={summaryPageInfoStyle('action-name')}>{translate(action.displayName)}</span>
              </span>
            )}
          />
        </div>
      </div>
      <p className={summaryPageInfoStyle('description')}>{translate(text)}</p>
    </div>
  );
};

export default SummaryPageInfo;
