import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../api';
import NOTIFICATION_MESSAGES from '../utility/notificationMessages';

export const FETCH_INITIAL_STATE = 'FETCH_INITIAL_STATE';
const FETCH_INITIAL_STATE__SUCCESS = 'FETCH_INITIAL_STATE__SUCCESS';
const FETCH_INITIAL_STATE__FAILURE = 'FETCH_INITIAL_STATE__FAILURE';

const fetchInitialState = (type, param, baseInstanceId) => ({
  type: FETCH_INITIAL_STATE,
  payload: { type, param, baseInstanceId }
});

const fetchInitialStateSuccess = payload => ({
  type: FETCH_INITIAL_STATE__SUCCESS,
  payload
});

const fetchInitialStateFailure = error => ({
  type: FETCH_INITIAL_STATE__FAILURE,
  meta: {
    notification: true,
    error: true,
    message: NOTIFICATION_MESSAGES.FAILED_GET_MODEL
  },
  error
});

function* fetchInitialStateSaga({ payload }) {
  const { type, param, baseInstanceId } = payload;
  const { data, error } = yield call(api.fetchInitialState, type, param, baseInstanceId);

  if (error) {
    yield put(fetchInitialStateFailure(error));

    return;
  }

  yield put(fetchInitialStateSuccess(data));
}

function* watchFetchInitialStateSaga() {
  yield takeLatest(FETCH_INITIAL_STATE, fetchInitialStateSaga);
}

export default function* moduleSaga() {
  yield all([watchFetchInitialStateSaga()]);
}

export const initialStateActions = {
  FETCH_INITIAL_STATE,
  FETCH_INITIAL_STATE__SUCCESS,
  FETCH_INITIAL_STATE__FAILURE,
  fetchInitialState
};
