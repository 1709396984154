import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ControlNavigationPhaseType, ControlNavigationControlType } from 'types';
import { controlSelectors } from '../../../modules/model';
import { useTabLink } from '../../TabLink';
import Icon from '../../Icon';
import { useSelectTabWithControl, useTranslate } from '../../../utility/hooks';
import { REVIEW_TAB } from '../../../modules/ui/tabNames';
import tabNavigationStyle from './tabNavigationStyle';

const getControlName = (
  control: ControlNavigationControlType,
  controlIndex: number,
  tab: ControlNavigationPhaseType,
  translate: (s: string) => string
) => {
  if (tab.name === REVIEW_TAB) {
    return translate(control.displayName);
  }

  return (control.isEmpty ? '' : `${tab.index}.${controlIndex + 1}. `) + translate(control.displayName);
};

interface NavigationListProps {
  isMobileNavigation: boolean;
  onClose: () => void;
}
const TabNavigationList = ({ isMobileNavigation, onClose }: NavigationListProps) => {
  const makeTabLink = useTabLink();
  const translate = useTranslate();
  const selectTabWithControl = useSelectTabWithControl();
  const controlNavigationTree = useSelector(controlSelectors.getControlNavigationTree);
  const filteredControlNavigationTree: ControlNavigationPhaseType[] = useMemo(
    () => controlNavigationTree.filter(tab => tab.isVisibleInTabNavigationList),
    [controlNavigationTree]
  );

  const handleSelectControl = useCallback(
    e => {
      const { tab, control } = e.currentTarget.dataset;

      onClose();

      if (!control || !tab) {
        return;
      }

      selectTabWithControl(tab, control);
    },
    [onClose, selectTabWithControl]
  );

  if (isMobileNavigation) {
    return (
      <>
        {filteredControlNavigationTree.map(tab => (
          <React.Fragment key={tab.name}>
            <div className={tabNavigationStyle('group')}>
              {tab.name === REVIEW_TAB ? null : (
                <span className={tabNavigationStyle('group-name')}>
                  {tab.index}. {translate(tab.displayName)}
                </span>
              )}
            </div>
            {tab.controls.map((control, controlIndex) => (
              <button
                key={control.name}
                className={tabNavigationStyle('item', { active: control.active, empty: control.isEmpty })}
                type="button"
                onClick={handleSelectControl}
                data-tab={tab.name}
                data-control={control.name}
              >
                {control.active ? <Icon type="chevron-right" /> : null}
                {getControlName(control, controlIndex, tab, translate)}
              </button>
            ))}
          </React.Fragment>
        ))}
      </>
    );
  }

  return (
    <>
      {filteredControlNavigationTree.map(tab => (
        <Link
          to={makeTabLink(tab.name)}
          key={tab.name}
          className={tabNavigationStyle('item', { active: tab.active })}
          onClick={handleSelectControl}
        >
          {tab.active ? <Icon type="chevron-right" /> : null}
          {tab.isControlTab ? `${tab.index}.` : ''} {translate(tab.displayName)}
        </Link>
      ))}
    </>
  );
};

export default TabNavigationList;
