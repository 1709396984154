import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Button } from '../../Atoms';
import { useTranslate, useFormState } from '../../../utility/hooks';
import { dialogActions } from '../../../modules/dialog';
import { formStyle } from '../../Menu/style';
import { useAuthMutation } from '../../../store/api/api';

const initialFormState = {
  email: '',
  password: ''
};

const EmailLogin = () => {
  const dispatch = useDispatch();

  const { handleFormFieldChange, formState } = useFormState(initialFormState);
  const [login, state] = useAuthMutation();

  const isLoggingIn = state.isLoading;

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault(); // do not reload whole page

      await login({
        username: formState.email,
        password: formState.password
      }).unwrap();

      dispatch(dialogActions.hideDialog());
    },
    [dispatch, formState.email, formState.password, login]
  );

  const disabled = useMemo(() => {
    return !(formState.email && formState.password) || isLoggingIn;
  }, [formState.email, formState.password, isLoggingIn]);

  const translate = useTranslate();

  return (
    <form className={formStyle({ type: 'login' })} onSubmit={handleSubmit}>
      <Field
        id="login-email"
        type="email"
        value={formState.email}
        name="email"
        label={translate('Your email')}
        onChange={handleFormFieldChange}
        autoComplete="username"
      />
      <Field
        id="login-password"
        type="password"
        name="password"
        value={formState.password}
        label={translate('Password')}
        onChange={handleFormFieldChange}
        autoComplete="current-password"
      />
      <Button block type="submit" disabled={disabled}>
        {isLoggingIn ? 'Logging in...' : translate('Log in')}
      </Button>
    </form>
  );
};

export default EmailLogin;
