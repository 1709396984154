import b from 'b_';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../Atoms';
import Icon from '../../Icon';
import { uiActions, uiSelectors } from '../../../modules/ui';
import { useTranslate } from '../../../utility/hooks';
import { commonSelectors, controlSelectors } from '../../../modules/model';
import { dialogActions } from '../../../modules/dialog';
import { DIALOGS } from '../../../utility/dialogs';
import { seedActions } from '../../../modules/seed';
import { useTabLink } from '../../TabLink';

import './ControlsPanelCloseChildrenButton.scss';

const controlsPanelCloseChildrenButtonStyle = b.with('controls-panel-close-children-button');

const ControlsPanelCloseChildrenButton = ({ mode = 'default' }: { mode?: 'default' | 'icon' }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const makeTabLink = useTabLink();
  const history = useHistory();
  const parentControlName = useSelector(controlSelectors.selectChildTabParentControl);
  const parentControlTreeName = useSelector(controlSelectors.selectChildTabParentControlTreeName);
  const isChildTabOpened = useSelector(commonSelectors.getIsChildTabOpened);
  const isInstanceUnsaved = useSelector(uiSelectors.getIsUnsavedIndicatorVisible);

  const handleClose = () => {
    if (isChildTabOpened) {
      dispatch(uiActions.hideChildControls([parentControlTreeName]));
      dispatch(uiActions.selectTabAndControlGroup(undefined, parentControlName));

      return;
    }

    if (isInstanceUnsaved) {
      dispatch(dialogActions.showDialog(DIALOGS.CLOSE_CHILD_INSTANCE));

      return;
    }

    dispatch(seedActions.discardAndCloseCurrentSeed(makeTabLink, history.push));
  };

  if (mode === 'icon') {
    return (
      <Button
        onClick={handleClose}
        bold
        color="text-borderless"
        circular
        mix={controlsPanelCloseChildrenButtonStyle({ mode })}
      >
        <Icon type="close" scale={1.2} />
      </Button>
    );
  }

  return (
    <Button
      onClick={handleClose}
      bold
      color="main-outline"
      rounded
      block
      mix={controlsPanelCloseChildrenButtonStyle({ mode })}
    >
      <span className={controlsPanelCloseChildrenButtonStyle('name')}>{translate('Close')}</span>
    </Button>
  );
};

export default ControlsPanelCloseChildrenButton;
