/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_CONTROL_OPTION } from '../../../../utility/propTypes';
import OptionImage from '../../OptionImage';
import DefaultOption from '../../DefaultControl/DefaultOption';
import { optionStyle } from '../../Control/style';

const ColumnOption = props => {
  const { option } = props;

  return (
    <DefaultOption {...props}>
      {option.image?.fileName ? (
        <div className={optionStyle('image-wrapper')}>
          <OptionImage option={option} />
        </div>
      ) : null}
    </DefaultOption>
  );
};

ColumnOption.defaultProps = {
  children: null
};

ColumnOption.propTypes = {
  option: TYPE_CONTROL_OPTION.isRequired,
  children: PropTypes.node
};

export default ColumnOption;
