import { useTranslate } from '../../../utility/hooks';
import { Button } from '../../Atoms';
import Icon from '../../Icon';
import navigationButtonsStyle from './navigationButtonsStyle';

interface NavButtonLinkProps {
  to: string;
  type: 'link';
}

interface NavButtonProps {
  onClick: () => void;
  type?: 'button';
}

type CombinedButtonProps = { disabled?: boolean } & (NavButtonLinkProps | NavButtonProps);

export const PrevButton = (props: CombinedButtonProps) => {
  const translate = useTranslate();

  return (
    <Button
      bold
      size="lg"
      block
      rounded
      color="main-outline"
      mix={navigationButtonsStyle('button', { back: true })}
      {...props}
    >
      <span className={navigationButtonsStyle('icon')}>
        <Icon type="chevron-left" scale={1.4} />
      </span>
      <span className={navigationButtonsStyle('label', { back: true })}>{translate('Back')}</span>{' '}
    </Button>
  );
};

export const NextButton = ({ isFinishTab = false, ...props }: CombinedButtonProps & { isFinishTab: boolean }) => {
  const translate = useTranslate();

  return (
    <Button size="lg" block bold rounded mix={navigationButtonsStyle('button', { next: true })} {...props}>
      <span className={navigationButtonsStyle('label', { next: !isFinishTab })}>
        {translate(isFinishTab ? 'Finish' : 'Next')}
      </span>
      <span className={navigationButtonsStyle('icon')}>
        {isFinishTab ? <Icon type="check-bold" /> : <Icon type="chevron-right" scale={1.4} />}
      </span>
    </Button>
  );
};
