import React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import b from 'b_';
import { uiSelectors } from '../../../modules/ui';
import { Gallery } from '../../Atoms';
import Progress from '../../Progress';

import './InfoPageGallery.scss';

const infoPageGalleryStyle = b.with('info-page-gallery');

const InfoPageGallery = () => {
  const gallery = useSelector(uiSelectors.selectIntroOrSummaryGallery);
  const isLoading = useSelector(uiSelectors.getIsSummaryGalleryLoaderActive);

  if (gallery === false || !Array.isArray(gallery)) {
    return null;
  }

  return (
    <div className={infoPageGalleryStyle()}>
      <CSSTransition in={isLoading} timeout={400} classNames={infoPageGalleryStyle('loader')} unmountOnExit>
        <Progress />
      </CSSTransition>
      {isLoading ? null : <Gallery images={gallery} showFullscreenButton />}
    </div>
  );
};

export default InfoPageGallery;
