import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { ActionDefinitionType } from 'types';
import { getActions, selectMapActions } from '../../modules/settings/actionsSelectors';
import { dialogActions } from '../../modules/dialog';
import { instanceActions } from '../../modules/instance';
import Heap from '../heap';
import { seedSelectors } from '../../modules/seed';
import { uiActions } from '../../modules/ui';
import { ACTION_DIALOG_TYPES, ACTION_TYPES } from '../actionTypes';

const DIALOG_ACTIONS = {
  [ACTION_DIALOG_TYPES.AUTH]: dialogActions.openAuthDialog,
  [ACTION_DIALOG_TYPES.LOGOUT]: dialogActions.openLogoutDialog,
  [ACTION_DIALOG_TYPES.PROFILE]: dialogActions.openProfileDialog,
  [ACTION_DIALOG_TYPES.ADD_COMMENT]: dialogActions.openAddCommentDialog,

  [ACTION_DIALOG_TYPES.SAVE]: dialogActions.openSaveDialog,
  [ACTION_DIALOG_TYPES.SHARE]: dialogActions.openShareDialog,
  [ACTION_DIALOG_TYPES.CONTACT]: dialogActions.openContactDialog,

  [ACTION_DIALOG_TYPES.PDF]: instanceActions.requestDownloadPdf,

  [ACTION_DIALOG_TYPES.OPEN_INSTANCE]: dialogActions.openInstanceDialog,
  [ACTION_DIALOG_TYPES.EXPORT_MODEL]: dialogActions.openExportModelDialog
};

const FORBIDDEN_DIALOGS_FOR_DEPRECATED_SEED = {
  [ACTION_DIALOG_TYPES.SAVE]: true,
  [ACTION_DIALOG_TYPES.SHARE]: true,
  [ACTION_DIALOG_TYPES.CONTACT]: true,
  [ACTION_DIALOG_TYPES.PDF]: true
} as const;

const POTENTIAL_LOGIN_DIALOGS = {
  [ACTION_DIALOG_TYPES.SAVE]: true,
  [ACTION_DIALOG_TYPES.SHARE]: true,
  [ACTION_DIALOG_TYPES.CONTACT]: true
} as const;

const sendHeapEvent = (action: ActionDefinitionType, group: string) => {
  let target: string | undefined;

  if (action.type === ACTION_TYPES.OPEN_DIALOG) target = action.dialog;

  if (action.type === ACTION_TYPES.OPEN_TAB) target = action.tab;

  if (action.type === ACTION_TYPES.OPEN_LINK) target = action.link;

  Heap.track('UNIVERSAL_ACTION', { action_type: action.type, action_place: group, action_target: target });
};

const useActions = (group: string) => {
  const dispatch = useDispatch();
  const mapActions = useSelector(selectMapActions);
  const actions = useSelector(getActions);
  const deprecatedSeed = useSelector(seedSelectors.selectIsSeedDeprecated);

  const handleClickOnAction = useCallback(
    e => {
      const { action: actionName } = e.currentTarget.dataset;
      const action = mapActions[actionName];

      if (action?.type === ACTION_TYPES.OPEN_DIALOG) {
        const { dialog, requireLogin } = action;

        if (deprecatedSeed && dialog in FORBIDDEN_DIALOGS_FOR_DEPRECATED_SEED) {
          dispatch(uiActions.toggleDeprecationNotification(true));

          return;
        }

        if (dialog in POTENTIAL_LOGIN_DIALOGS) {
          dispatch(DIALOG_ACTIONS[action.dialog](requireLogin));
        } else {
          dispatch(DIALOG_ACTIONS[action.dialog]());
        }
      }

      if (action) {
        sendHeapEvent(action, group);
      }
    },
    [mapActions, deprecatedSeed, dispatch, group]
  );

  return {
    actions: actions[group] || [],
    handleClickOnAction,
    TYPES: ACTION_TYPES,
    DIALOGS: ACTION_DIALOG_TYPES
  };
};

export default useActions;
