import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { uiActions, uiSelectors } from '../modules/ui';
import * as interfaceSelectors from '../modules/settings/interfaceSelectors';
import { loaderSelectors } from '../modules/loader';
import { seedSelectors } from '../modules/seed';
import { useTabLink } from './TabLink';

const TabInterceptor = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const { controlName = '', hideChildControls } = location.state || {};
  const history = useHistory();

  const [parsedQuery, setParsedQuery] = useState({});

  useEffect(() => {
    setParsedQuery(queryString.parse(location.search));
  }, [location.search]);

  const isMainSeed = useSelector(seedSelectors.selectIsMainSeed);
  const tabs = useSelector(uiSelectors.getTabs);
  const firstTabName = useSelector(uiSelectors.selectFirstTabName);
  const currentSelectedTabName = useSelector(uiSelectors.selectSelectedTabName);
  const hiddenTabs = useSelector(interfaceSelectors.selectHiddenTabs);

  const enabledDefaultTabs = Object.values(hiddenTabs).filter(isOff => !isOff).length;

  const isPartsLoaded = useSelector(loaderSelectors.getIsPartsLoaded);
  const makeTabLink = useTabLink();

  const { name: selectedTabName } = useMemo(
    () => tabs.find(tab => tab.name.toLowerCase() === parsedQuery.tab) || {},
    [parsedQuery.tab, tabs]
  );

  useEffect(() => {
    if (!isPartsLoaded) return;

    if (selectedTabName && selectedTabName !== currentSelectedTabName) {
      dispatch(uiActions.selectTabAndControlGroup(selectedTabName, controlName, hideChildControls));
    }

    if ((!isMainSeed || !initialized) && !selectedTabName && tabs.length > enabledDefaultTabs) {
      history.push(makeTabLink(firstTabName));
      setInitialized(true);
    }
  }, [
    dispatch,
    history,
    makeTabLink,
    isPartsLoaded,
    controlName,
    firstTabName,
    selectedTabName,
    tabs.length,
    currentSelectedTabName,
    hideChildControls,
    initialized,
    isMainSeed,
    enabledDefaultTabs
  ]);

  return null;
};

export default TabInterceptor;
