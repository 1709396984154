import React from 'react';
import PropTypes from 'prop-types';
import { controlStyle } from '../style';
import { useTranslate } from '../../../../utility/hooks';

const ControlFooter = ({ description }) => {
  const translate = useTranslate();

  return (
    <div className={controlStyle('footer')}>
      {description ? <span className={controlStyle('description')}>{translate(description)}</span> : null}
    </div>
  );
};

ControlFooter.defaultProps = {
  description: ''
};

ControlFooter.propTypes = {
  description: PropTypes.string
};

export default ControlFooter;
