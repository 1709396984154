import { all, call, put, takeLatest, cancel, race, take } from 'redux-saga/effects';
import api from '../../api';
import * as dialogActions from '../dialog/dialogActions';
import { notificationsActions } from '../notifications';
import { DIALOGS } from '../../utility/dialogs';
import Heap from '../../utility/heap';
import NOTIFICATION_MESSAGES from '../../utility/notificationMessages';
import * as authActions from './authActions';

export function* cancelWorkerSaga(task) {
  yield cancel(task);
}

function* doLoginSaga(action) {
  yield put(authActions.loginRequest());

  const provider = 'email';
  const { username, password } = action.payload;
  const { data, error } = yield call(api.auth.login, username, password);

  if (error) {
    yield put(authActions.loginFailure(error, provider));
  } else {
    yield put(authActions.loginSuccess(data, NOTIFICATION_MESSAGES.LOGIN_SUCCESSFUL, provider));
  }
}

function* watchLoginSaga() {
  yield takeLatest(authActions.LOGIN, doLoginSaga);
}

function* doLoginFacebookSaga(action) {
  yield put(authActions.loginRequest());

  const provider = 'facebook';
  const { info } = action.payload;
  const { data, error } = yield call(api.auth.loginFacebook, info.accessToken);

  if (error) {
    yield put(authActions.loginFailure(error, provider));
  } else {
    yield put(authActions.loginSuccess(data, NOTIFICATION_MESSAGES.FACEBOOK_LOGIN_SUCCESSFUL, provider));
  }
}

function* watchLoginFacebook() {
  yield takeLatest(authActions.LOGIN_FACEBOOK, doLoginFacebookSaga);
}

function* doLoginGoogleSaga(action) {
  yield put(authActions.loginRequest());

  const provider = 'google';
  const { info } = action.payload;
  const { data, error } = yield call(api.auth.loginGoogle, info.tokenId);

  if (error) {
    yield put(authActions.loginFailure(error, provider));
  } else {
    yield put(authActions.loginSuccess(data, NOTIFICATION_MESSAGES.GOOGLE_LOGIN_SUCCESSFUL, provider));
  }
}

function* watchLoginGoogle() {
  yield takeLatest(authActions.LOGIN_GOOGLE, doLoginGoogleSaga);
}

function doLoginSuccessSaga({ payload }) {
  const { user, provider } = payload;

  Heap.identify(user.uid);
  Heap.addUserProperties(user);
  Heap.track('LOGIN__SUCCESS', { provider });
}

function* watchLoginSuccess() {
  yield takeLatest([authActions.LOGIN__SUCCESS], doLoginSuccessSaga);
}

function doLoginFailureSaga({ payload }) {
  const { provider } = payload;

  Heap.track('LOGIN__FAILURE', { provider });
}

function* watchLoginFailure() {
  yield takeLatest([authActions.LOGIN__FAILURE], doLoginFailureSaga);
}

export function* doLogoutSaga() {
  yield put(dialogActions.showDialog(DIALOGS.CONFIRM_LOGOUT));

  yield take(authActions.LOGOUT__CONFIRM);

  const { error } = yield call(api.auth.logout);

  if (error) {
    yield put(authActions.logoutFailure(error.message));
  } else {
    yield put(authActions.logoutSuccess());

    Heap.track('LOGOUT__SUCCESS');

    yield put(dialogActions.hideDialog());
  }
}

function* raceLogoutSaga(action) {
  yield race({
    task: call(doLogoutSaga, action),
    cancel: take(dialogActions.DIALOG__HIDE)
  });
}

export function* watchLogoutSaga() {
  yield takeLatest(dialogActions.OPEN_LOGOUT_DIALOG, raceLogoutSaga);
}

export function* doRegistrationSaga(action) {
  yield put(authActions.registrationRequest());

  const { username, password } = action.payload;
  const { data, error } = yield call(api.auth.registration, username, password);

  if (error) {
    yield put(authActions.registrationFailure(error));
  } else {
    yield put(authActions.registrationSuccess(data));
  }
}

export function* watchRegistrationSaga() {
  yield takeLatest(authActions.REGISTRATION, doRegistrationSaga);
}

export function* doForgotPasswordSaga(action) {
  const { username } = action.payload;
  const { error } = yield call(api.auth.forgotPassword, username);

  if (error) {
    yield put(authActions.forgotPasswordFailure(error));
  } else {
    yield put(authActions.forgotPasswordSuccess());

    Heap.track('FORGOT_PASSWORD');

    yield put(
      dialogActions.showDialog(DIALOGS.MESSAGE, {
        heading: 'Password recovery email sent',
        content: [
          'Password reset link has been sent successfully.',
          'You will receive an email from our software provider Creatomus Solutions.'
        ]
      })
    );
  }
}

export function* watchForgotPasswordSaga() {
  yield takeLatest(authActions.FORGOT_PASSWORD, doForgotPasswordSaga);
}

export function* doVerifyTokenSaga(action) {
  const { token } = action.payload;
  const { error } = yield call(api.auth.verifyToken, token);

  if (error) {
    yield put(authActions.verifyTokenFailure(error));
  } else {
    yield put(authActions.verifyTokenSuccess());
  }
}

export function* watchVerifyTokenSaga() {
  yield takeLatest(authActions.VERIFY_TOKEN, doVerifyTokenSaga);
}

export function* doResetPasswordSaga(action) {
  yield call(doVerifyTokenSaga, action);

  const { password, token } = action.payload;
  const { data, error } = yield call(api.auth.resetPassword, password, token);

  if (error) {
    yield put(authActions.resetPasswordFailure(error));
  } else {
    yield put(authActions.resetPasswordSuccess(data));

    Heap.track('RESET_PASSWORD');
  }
}

export function* watchResetPasswordSaga() {
  const workerTask = yield takeLatest(authActions.RESET_PASSWORD, doResetPasswordSaga);

  yield takeLatest(authActions.VERIFY_TOKEN__FAILURE, cancelWorkerSaga, workerTask);
}

export function* doGetUserInfoSaga({ type, payload }) {
  const { userToken } = payload;

  yield put(authActions.getUserInfoRequest());

  const { data, error } = yield call(api.auth.getUserInfo, userToken);

  if (error) {
    yield put(authActions.getUserInfoFailure());

    if (userToken) {
      console.error('Invalid user token: ', userToken); // eslint-disable-line no-console

      yield put(notificationsActions.showErrorNotification(NOTIFICATION_MESSAGES.INVALID_USER_TOKEN));
    }
  } else {
    yield put(authActions.getUserInfoSuccess(data));
  }
}

export function* watchGetUserInfoSaga() {
  yield takeLatest(authActions.GET_USER_INFO, doGetUserInfoSaga);
}

export default function* moduleSaga() {
  yield all([
    watchLoginSaga(),
    watchLoginFacebook(),
    watchLoginGoogle(),
    watchLoginSuccess(),
    watchLoginFailure(),
    watchLogoutSaga(),
    watchRegistrationSaga(),
    watchForgotPasswordSaga(),
    watchVerifyTokenSaga(),
    watchResetPasswordSaga(),
    watchGetUserInfoSaga()
  ]);
}
