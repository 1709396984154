import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { notificationsActions, notificationsSelectors } from '../../modules/notifications';
import { TYPE_NOTIFICATIONS } from '../../utility/propTypes';
import useTranslate from '../../utility/hooks/useTranslate';
import Toast from './Toast';
import toastsStyle from './style';

import './Toasts.scss';

const Toasts = ({ notifications, hide }) => {
  const translate = useTranslate();
  const handleHideNotification = useCallback(
    id => {
      hide(id);
    },
    [hide]
  );

  return (
    <TransitionGroup className={toastsStyle()}>
      {notifications.map(notification => (
        <CSSTransition timeout={500} key={notification.id} classNames={toastsStyle('toast')}>
          <Toast
            notification={{ ...notification, message: translate(notification.message) }}
            onHide={handleHideNotification}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

Toasts.defaultProps = {
  notifications: []
};

Toasts.propTypes = {
  notifications: TYPE_NOTIFICATIONS,
  hide: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  notifications: notificationsSelectors.selectVisibleNotifications(state)
});

const mapDispatchToProps = {
  hide: notificationsActions.hideNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
