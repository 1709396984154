/* eslint-disable max-classes-per-file */
import { Component, createRef, HTMLAttributes, RefObject } from 'react';
import { Matrix4 } from 'three';
import { createPortal } from 'react-dom';
import b from 'b_';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { Button } from '../../../Atoms';
import getThreeSetup from '../../getThreeSetup';
import './SceneButton.scss';
import { ButtonColorType } from '../../../Atoms/Button/Button';

export const sceneButton = b.with('scene-button');

/* Use React portal to put the DOM nodes to correct place
and use React's mounting-unmounting mechanism.
 */
class DumbCSS2DObject extends CSS2DObject {
  _listeners?: { removed: [() => void] };

  constructor(...args: ConstructorParameters<typeof CSS2DObject>) {
    super(...args);

    // eslint-disable-next-line no-underscore-dangle
    const callback = this._listeners?.removed[0];

    if (callback) this.removeEventListener('removed', callback);
  }
}

const { cssScene, cssRenderer, render } = getThreeSetup();

interface SceneButtonProps extends HTMLAttributes<HTMLButtonElement> {
  matrix: Matrix4;
  type?: string;
  circular?: boolean;
  color?: ButtonColorType;
  disabled?: boolean;
}

/**
 * Button that is added to the scene at specific coordinates.
 * Implemented as a class because functional component didn't work for some reason.
 * */
class SceneButton extends Component<SceneButtonProps> {
  button: RefObject<HTMLButtonElement>;

  object2D?: DumbCSS2DObject;

  constructor(props: SceneButtonProps) {
    super(props);

    this.button = createRef();
  }

  componentDidMount() {
    if (this.button.current) {
      this.object2D = new DumbCSS2DObject(this.button.current);

      this.object2D.matrix.copy(this.props.matrix);
      this.object2D.matrixAutoUpdate = false;

      cssScene.add(this.object2D);
      render();
    }
  }

  componentDidUpdate() {
    this.object2D?.matrix.copy(this.props.matrix);
    render();
  }

  componentWillUnmount() {
    if (this.object2D) cssScene.remove(this.object2D);
  }

  render() {
    const { circular = true, color = 'main-outline', type = '', matrix, ...buttonProps } = this.props;

    return createPortal(
      <Button ref={this.button} circular={circular} color={color} {...buttonProps} mix={sceneButton({ type })} />,
      cssRenderer.domElement
    );
  }
}

export default SceneButton;
