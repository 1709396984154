export const FETCH_PARTS = 'FETCH_PARTS';
export const FETCH_PARTS__REQUEST = 'FETCH_PARTS__REQUEST';
export const FETCH_PARTS__SUCCESS = 'FETCH_PARTS__SUCCESS';
export const FETCH_PARTS__FAILURE = 'FETCH_PARTS__FAILURE';

export const STORE_EXTERNAL_PARTS = 'STORE_EXTERNAL_PARTS';

export const fetchParts = () => ({
  type: FETCH_PARTS
});

export const fetchPartsRequest = () => ({
  type: FETCH_PARTS__REQUEST
});

export const fetchPartsSuccess = parts => ({
  type: FETCH_PARTS__SUCCESS,
  payload: { parts }
});

export const fetchPartsFailure = error => ({
  type: FETCH_PARTS__FAILURE,
  error
});

export const storeExternalParts = parts => ({
  type: STORE_EXTERNAL_PARTS,
  payload: { parts }
});
