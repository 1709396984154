import * as notificationsActions from './notificationsActions';

export const getNotificationId = () => `${new Date().getTime()}`;

const initialState = [];

const notificationsReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case notificationsActions.NOTIFICATION__SHOW: {
      const { message, timeout, showReloadLink } = payload;

      return [...state, { id: getNotificationId(), type: payload.type, message, timeout, showReloadLink }];
    }
    case notificationsActions.NOTIFICATION__HIDE: {
      return state.map(notification =>
        notification.id === payload.id ? { ...notification, hidden: true } : notification
      );
    }
    default:
      return state;
  }
};

export default notificationsReducer;
