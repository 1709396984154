import { useState } from 'react';
import { PartNodeControlType, PartNodeOptionType } from 'types';
import { CONTROL_TYPES } from '../../../../utility/controlDefinitions';
import BulkMaterials from '../../BulkMaterials';
import Slider from '../../Slider';
import Dropdown from '../../Dropdown';
import Toggle from '../../Toggle';
import ImageControl from '../../ImageControl';
import ColumnControl from '../../ColumnControl';
import DefaultControl from '../../DefaultControl';
import EmptyControl from '../../EmptyControl';
import { DefaultControlProps } from '../../DefaultControl/DefaultControl';
import ControlTags from './ControlTags';

const CONTROLS_MAP: Record<string, (props: DefaultControlProps) => JSX.Element | null> = {
  [CONTROL_TYPES.MATERIALS_BULK]: BulkMaterials,
  [CONTROL_TYPES.SLIDER]: Slider,
  [CONTROL_TYPES.DROPDOWN]: Dropdown,
  [CONTROL_TYPES.TOGGLE]: Toggle,
  [CONTROL_TYPES.IMAGES]: ImageControl,
  [CONTROL_TYPES.MATERIALS]: ImageControl,
  [CONTROL_TYPES.COLUMN]: ColumnControl,
  [CONTROL_TYPES.COLUMN_TWO]: ColumnControl,
  [CONTROL_TYPES.COLUMN_THREE]: ColumnControl,
  [CONTROL_TYPES.RADIO]: DefaultControl,
  [CONTROL_TYPES.EMPTY]: EmptyControl
};

interface ControlOptionsProps {
  control: PartNodeControlType;
  onOptionSelect: (control: PartNodeControlType, option: PartNodeOptionType) => void;
}

const ControlOptions = ({ control, onOptionSelect }: ControlOptionsProps) => {
  const { tags = {} } = control;
  const hasTags = (tags.list || []).length !== 0;
  const [activeTags, setActiveTags] = useState<string[]>([]);

  const ControlComponent = CONTROLS_MAP[control.type] || CONTROLS_MAP[CONTROL_TYPES.RADIO];

  return (
    <>
      {hasTags ? <ControlTags control={control} onChangeActiveTags={setActiveTags} /> : null}
      <ControlComponent control={control} onOptionSelect={onOptionSelect} activeTags={activeTags} />
    </>
  );
};

export default ControlOptions;
