import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { instanceSelectors } from '../../../../modules/instance';
import { useSwitch, useTranslate } from '../../../../utility/hooks';
import { TYPE_PART_CONTROL } from '../../../../utility/propTypes';
import Button from '../../../Atoms/Button';
import Icon from '../../../Icon';
import PreferenceChoice from '../PreferenceChoice';
import preferencesTableStyle from '../preferencesTableStyle';

const ChildInstancePreferences = ({ control, onUpdateHeight }) => {
  const { isOpen, toggle } = useSwitch();
  const translate = useTranslate();

  const handleToggle = useCallback(() => {
    toggle();
    onUpdateHeight();
  }, [onUpdateHeight, toggle]);

  const instanceChoices = useSelector(state => instanceSelectors.selectChildInstanceChoicesById(state, control.value));

  return (
    <div className={preferencesTableStyle('child-instance')}>
      {isOpen ? (
        <>
          {instanceChoices.map(({ option: instanceOption, control: instanceControl }, index) => (
            <PreferenceChoice
              key={instanceControl.treeName}
              control={instanceControl}
              option={instanceOption}
              index={index}
            />
          ))}

          <Button
            mix={preferencesTableStyle('toggle-child')}
            onClick={handleToggle}
            color="secondary-borderless"
            rounded
            regular
          >
            {translate('Show less')} <Icon type="chevron-down-up" />
          </Button>
        </>
      ) : (
        <Button
          mix={preferencesTableStyle('toggle-child')}
          onClick={handleToggle}
          color="secondary-borderless"
          rounded
          regular
        >
          {translate('Show more')}
          <Icon type="chevron-up-down" />
        </Button>
      )}
    </div>
  );
};

ChildInstancePreferences.propTypes = {
  control: TYPE_PART_CONTROL.isRequired,
  onUpdateHeight: PropTypes.func.isRequired
};

export default ChildInstancePreferences;
