import { SyntheticEvent } from 'react';
import { useLinkGenerators, useTranslate } from '../../../utility/hooks';
import Icon from '../../Icon';
import { Button } from '../../Atoms';
import { useTabLink } from '../../TabLink';
import navigationButtonsStyle from './navigationButtonsStyle';

interface FinishButtonProps {
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
  action: {
    name: string;
    displayName: string;
    type: 'open-dialog' | 'open-tab' | 'open-link';
    href?: string;
    to?: string;
    tab?: string;
    link?: string;
  };
}

const FinishButton = ({ action, onClick }: FinishButtonProps) => {
  const { getCustomLink } = useLinkGenerators();
  const makeTabLink = useTabLink();
  const translate = useTranslate();

  const { name, type, link, tab } = action;
  const mix = navigationButtonsStyle('button', { next: true, last: true });

  const children = (
    <>
      <span className={navigationButtonsStyle('label', { next: false })}>{translate(action.displayName)}</span>
      <span className={navigationButtonsStyle('icon')}>
        <Icon type="check-bold" />
      </span>
    </>
  );

  if (type === 'open-link') {
    return (
      <Button mix={mix} size="lg" rounded bold type="external-link" href={getCustomLink(link)}>
        {children}
      </Button>
    );
  }

  if (type === 'open-tab') {
    return (
      <Button mix={mix} size="lg" rounded bold type="link" to={makeTabLink(tab)}>
        {children}
      </Button>
    );
  }

  return (
    <Button mix={mix} size="lg" rounded bold onClick={onClick} data-action-name={name}>
      {children}
    </Button>
  );
};

export default FinishButton;
