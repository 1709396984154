import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useOnClickOutside, useSwitch, useTranslate } from '../../../utility/hooks';
import { MenuItem, menuStyle } from '../../Menu';

const MenuSwitcher = ({ title, description, disabled, renderList }) => {
  const listRef = useRef();
  const { open, isOpen, close } = useSwitch();
  const translate = useTranslate();

  useOnClickOutside(listRef, close, isOpen);

  return (
    <>
      <MenuItem
        onClick={open}
        name={title}
        description={translate(description)}
        disabled={disabled}
        type={`switcher${!disabled ? '-dropdown' : ''}`}
      />
      {disabled ? null : (
        <div className={menuStyle('switcher-list', { open: isOpen })} ref={listRef}>
          {renderList(close, menuStyle('switcher-list-item'))}
        </div>
      )}
    </>
  );
};

MenuSwitcher.defaultProps = {
  description: '',
  disabled: false,
  renderList: () => null
};

MenuSwitcher.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  renderList: PropTypes.func
};

export default MenuSwitcher;
