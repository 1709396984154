import { DIALOGS } from '../../utility/dialogs';

export const DIALOG__SHOW = 'DIALOG__SHOW';
export const DIALOG__HIDE = 'DIALOG__HIDE';

export const OPEN_PROFILE_DIALOG = 'OPEN_PROFILE_DIALOG';
export const OPEN_AUTH_DIALOG = 'OPEN_AUTH_DIALOG';
export const OPEN_LOGOUT_DIALOG = 'OPEN_LOGOUT_DIALOG';
export const OPEN_SHARE_DIALOG = 'OPEN_SHARE_DIALOG';
export const OPEN_SAVE_DIALOG = 'OPEN_SAVE_DIALOG';
export const OPEN_CONTACT_DIALOG = 'OPEN_CONTACT_DIALOG';
export const OPEN_INSTANCE_DIALOG = 'OPEN_INSTANCE_DIALOG';
export const OPEN_EXPORT_MODEL_DIALOG = 'OPEN_EXPORT_MODEL_DIALOG';

export const showDialog = (type: string, meta?: any) => ({
  type: DIALOG__SHOW,
  payload: {
    type,
    meta
  }
});

export const hideDialog = () => ({
  type: DIALOG__HIDE
});

export const openAuthDialog = () => ({
  type: OPEN_AUTH_DIALOG
});

export const openLogoutDialog = () => ({
  type: OPEN_LOGOUT_DIALOG
});

export const openProfileDialog = () => ({
  type: OPEN_PROFILE_DIALOG
});

export const openShareDialog = (requireLogin?: boolean) => ({
  type: OPEN_SHARE_DIALOG,
  payload: { requireLogin }
});

export const openSaveDialog = (requireLogin?: boolean) => ({
  type: OPEN_SAVE_DIALOG,
  payload: { requireLogin }
});

export const openContactDialog = (requireLogin?: boolean) => ({
  type: OPEN_CONTACT_DIALOG,
  payload: { requireLogin }
});

export const openInstanceDialog = () => ({
  type: OPEN_INSTANCE_DIALOG
});

export const openExportModelDialog = () => ({
  type: OPEN_EXPORT_MODEL_DIALOG
});

export const openAddCommentDialog = () => showDialog(DIALOGS.ADD_COMMENT);

export const openEditCommentDialog = (commentId: number) => showDialog(DIALOGS.EDIT_COMMENT, { commentId });
