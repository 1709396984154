import { createSelector } from 'reselect';
import COOKIE from './cookieNames';

export const selectCookies = state => state.cookies;
export const selectIsCookieNotificationOpen = state => selectCookies(state).showNotification;

export const selectSetCookies = createSelector([selectCookies], cookies => [
  { name: COOKIE.HAS_VISITED, value: COOKIE.HAS_VISITED_VALUE },
  ...Object.values(cookies.list)
]);

export const selectCookiesConsent = createSelector([selectCookies], cookies => cookies.consent);
