import { ReactNode, SyntheticEvent } from 'react';
import ReactDOM from 'react-dom';
import b from 'b_';
import Button from '../Button';

import './Notification.scss';

const notificationStyle = b.with('notification');

interface NotificationProps {
  type?: 'warning' | 'success';
  header?: string;
  children?: ReactNode;
  message?: string;
  mainButtonTitle?: string;
  secondaryButtonTitle?: string;
  onMainButtonClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onSecondaryButtonClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
}

const Notification = ({
  type = 'success',
  header = '',
  message = '',
  children = null,
  mainButtonTitle = '',
  secondaryButtonTitle = '',
  onMainButtonClick,
  onSecondaryButtonClick
}: NotificationProps) => {
  const rootElement = document.getElementById('root');

  if (!rootElement) return null;

  return ReactDOM.createPortal(
    <div className={notificationStyle()}>
      <div className={notificationStyle('container', { type })}>
        <div className={notificationStyle('body')}>
          <h3 className={notificationStyle('header')}>{header}</h3>
          <div className={notificationStyle('content')}>{message || children}</div>
          <div className={notificationStyle('actions')}>
            {secondaryButtonTitle ? (
              <Button
                onClick={onSecondaryButtonClick}
                label={secondaryButtonTitle}
                mix={notificationStyle('button')}
                rounded
                color="main-outline"
              />
            ) : null}
            {mainButtonTitle ? (
              <Button onClick={onMainButtonClick} label={mainButtonTitle} mix={notificationStyle('button')} rounded />
            ) : null}
          </div>
        </div>
      </div>
    </div>,
    rootElement
  );
};

export default Notification;
