import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';
import useTranslate from '../../../utility/hooks/useTranslate';

import './Status.scss';

const statusStyle = b.with('status');

const Status = ({ label }) => {
  const translate = useTranslate();

  return label ? <span className={statusStyle()}>{translate(label)}</span> : null;
};

Status.defaultProps = {
  label: ''
};

Status.propTypes = {
  label: PropTypes.string
};

export default Status;
