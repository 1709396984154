import { createSelector } from 'reselect';
import _mergeWith from 'lodash/mergeWith';
import { State, SettingsState, ExtraInfoCollectionType } from 'types';
import { Selector } from 'react-redux';
import * as seedSelectors from '../seed/seedSelectors';

function customizer(_: any, sourceValue: any) {
  return Array.isArray(sourceValue) ? sourceValue : undefined;
}

const selectMainSettings = (state: State) => state.settings;

export const selectSettings: Selector<State, SettingsState> = createSelector(
  [selectMainSettings, seedSelectors.selectIsMainSeed, seedSelectors.selectSeed],
  (mainSettings, isMainSeed, currentSeed) =>
    isMainSeed ? mainSettings : _mergeWith({}, mainSettings, currentSeed.settings || {}, customizer)
);

export const selectAvailableTranslations = createSelector([selectSettings], ({ translations = [] }) =>
  translations
    .filter(({ disabled }) => !disabled)
    .map(translation => ({
      name: translation.name,
      dictionary: (translation.definitions || []).reduce<Record<string, string>>((result, definition) => {
        // eslint-disable-next-line no-param-reassign
        result[definition.key] = definition.value;

        return result;
      }, {})
    }))
);

export const selectAvailableLanguages = createSelector([selectAvailableTranslations], translations =>
  translations.map(({ name }) => name)
);

export const selectExtraInfoCollections = createSelector([selectSettings], settings => settings.extraInfoCollections);

export const selectSisterConfigurators = createSelector([selectSettings], ({ sisterConfigurators = [] }) =>
  sisterConfigurators.map(({ link, displayName }) => ({ displayName, link }))
);

export const selectVariablePricesEnabled = createSelector([selectSettings], ({ price }) => price?.usePriceCodes);

export const selectPriceSchemes = createSelector(
  [selectSettings, selectVariablePricesEnabled],
  ({ price = {} }, enabled) => {
    if (enabled) {
      const { schemes = [] } = price;

      return schemes.filter(scheme => !scheme.disabled);
    }

    return [];
  }
);

export const selectPartnerSettings = createSelector([selectSettings], settings => settings.partner || {});

export const selectGeneralConsentMessage = createSelector(
  [selectPartnerSettings],
  (partnerSettings = {}) => partnerSettings.generalConsentMessage || 'I accept the Terms of Use and Privacy Policy'
);

export const selectCookieSettings = createSelector([selectSettings], ({ cookies = {} }) => ({
  header: cookies.header || 'House configurator uses cookies',
  message:
    cookies.message ||
    'We use cookies to personalise content, to provide social media features and to analyse our traffic. You can accept all cookies or only those necessary for website to work correctly.',
  policyLink: {
    text: cookies?.policyLink?.text || 'Read more:',
    link: cookies?.policyLink?.link || 'Privacy policy',
    url: cookies?.policyLink?.url || 'https://www.creatomus.com/privacy-policy'
  }
}));

export const selectSettingsIntro = createSelector([selectSettings], ({ intro }) => intro);

/*
  Skybox settings
*/
const selectSkyboxSettings = createSelector([selectSettings], settings => settings.skybox || {});
const selectSkyboxEquirectangular = createSelector(
  [selectSkyboxSettings],
  ({ equirectangular }) => equirectangular || {}
);

export const selectSkyboxEnabled = createSelector([selectSkyboxSettings], skybox => Boolean(skybox.enabled));

export const selectEnvMapEnabled = createSelector([selectSkyboxSettings], skybox => Boolean(skybox.useAsEnvMap));

export const selectSkyboxTextures = createSelector(
  [selectSkyboxEquirectangular],
  equirectangular => equirectangular.Texture?.fileName
);

export const selectSectionCapSettings = createSelector([selectSettings], settings => settings.sectionCap || {});
export const selectSectionCapEnabled = createSelector([selectSectionCapSettings], sectionCap =>
  Boolean(sectionCap.enabled)
);
export const selectSectionCapColor = createSelector(
  [selectSectionCapSettings],
  sectionCap => sectionCap.color || 'rgb(0, 0, 0)'
);

export const getIsRequireEmailToSaveOrphanInstances = createSelector(
  [selectPartnerSettings],
  partnerSettings => partnerSettings.requireEmailToSaveOrphanInstances
);

export const selectHideChildSeedUI = createSelector([selectSettings], ({ hideChildSeedUI = false }) => hideChildSeedUI);

export const selectMetricsList = createSelector([selectSettings], ({ metrics = [] }) => metrics);

export const selectUseMultiplePriceSchemes = createSelector([selectSettings], settings => {
  return settings?.price?.useMultiplePriceSchemes;
});

export const selectPriceSettings = createSelector([selectSettings], ({ price = {} }) => price);

export const selectDefaultPriceSettings = createSelector([selectPriceSettings], ({ defaults = {} }) => defaults);

export const selectIsCommentingEnabled = createSelector(selectSettings, settings => !!settings.comments?.enabled);

export const selectExtraInfoCollectionsMap = createSelector([selectExtraInfoCollections], (collections = []) => {
  const collectionsMap: Record<string, ExtraInfoCollectionType> = {};

  collections.forEach(collection => {
    collectionsMap[collection.name] = collection;
  });

  return collectionsMap;
});
