import { AckeeInstance, create } from 'ackee-tracker';
import { ACKEE_DOMAIN_ID, ACKEE_SERVER } from '../config';

/**
 * Takes care of tracking.
 * If server and domainId are not given, doesnt do anything
 */
class AckeeTracker {
  server?: string;

  domainId?: string;

  detailed: boolean;

  instance?: AckeeInstance;

  constructor(server?: string, domainId?: string) {
    this.server = server;
    this.domainId = domainId;
    this.detailed = false;

    this.instance = this.create();
  }

  create() {
    if (!(this.server && this.domainId)) {
      return undefined;
    }

    return create(this.server, {
      detailed: this.detailed
    });
  }

  record() {
    return this.instance && this.domainId ? this.instance?.record(this.domainId) : undefined;
  }

  stopDetailed() {
    if (this.detailed === false) {
      return;
    }

    this.detailed = false;
    this.instance = this.create();
  }

  startDetailed() {
    if (this.detailed === true) {
      return;
    }

    this.detailed = true;
    this.instance = this.create();
  }
}

const ackeeTracker = new AckeeTracker(ACKEE_SERVER, ACKEE_DOMAIN_ID);

export default ackeeTracker;
