import { Vector3, Plane } from 'three';
import getThreeSetup from '../getThreeSetup';

const { sceneTransform } = getThreeSetup();
const getClippingPlane = cameraView => {
  const { clippingPlane: plane, isRelative, parentMatrix } = cameraView;

  const origin = new Vector3().copy(plane.origin);
  const normal = new Vector3().copy(plane.normal);

  if (isRelative && parentMatrix) {
    origin.applyMatrix4(parentMatrix);
    normal.transformDirection(parentMatrix);
  }

  origin.applyMatrix4(sceneTransform);
  normal.applyMatrix4(sceneTransform);

  const clipPlane = new Plane();

  clipPlane.setFromNormalAndCoplanarPoint(normal.normalize().negate(), origin);

  return clipPlane;
};

export default getClippingPlane;
