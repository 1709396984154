import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { inIframe } from './utility/location';

const MESSAGE_TYPE = 'CHANGE_LOCATION';

const RouterInterceptor = () => {
  const location = useLocation();

  useEffect(() => {
    if (inIframe()) {
      const data = { messageType: MESSAGE_TYPE, ...location };

      window.parent.postMessage(JSON.stringify(data), '*');
    }
  }, [location]);

  return null;
};

export default RouterInterceptor;
