import { createSelector } from 'reselect';
import { INDEX_TAB, REVIEW_TAB, SUMMARY_TAB } from '../ui/tabNames';
import { selectSettings } from './settingsSelectors';

export const selectInterfaceSettings = createSelector([selectSettings], ({ interface: settings = {} }) => ({
  favicon: settings.favicon,
  header: settings.header || '',
  headerIcon: settings.headerIcon,

  currency: settings.currency || '€',
  priceRounding: settings.priceRounding ? Number(settings.priceRounding) : 1,
  priceCaveat: settings.priceCaveat,
  currencyFormat: settings.currencyFormat,

  isGalleryHidden: Boolean(settings.hideGallery),
  isSummaryPriceHidden: Boolean(settings.summaryPriceHidden),

  aboutTabHidden: Boolean(settings.aboutTabHidden),
  reviewTabHidden: Boolean(settings.reviewTabHidden),
  summaryTabHidden: Boolean(settings.summaryTabHidden)
}));

export const selectIsGalleryHidden = createSelector(
  [selectInterfaceSettings],
  ({ isGalleryHidden }) => isGalleryHidden
);

export const selectHiddenTabs = createSelector(
  [selectInterfaceSettings],
  ({ aboutTabHidden, reviewTabHidden, summaryTabHidden }) => ({
    [INDEX_TAB]: aboutTabHidden,
    [REVIEW_TAB]: reviewTabHidden,
    [SUMMARY_TAB]: summaryTabHidden
  })
);
