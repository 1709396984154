import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCreatomusConfiguratorOriginHeader } from '../../api/instance';
import { uiActions } from '../../modules/ui';
import { authSliceActions } from '../../store/auth';
import { useGetUserInfoQuery } from '../../store/api/api';

const KEYS = {
  BACKLINK_ORIGIN: 'backlink_origin',
  USER_TOKEN: 'user_token',
  UI_MODE: 'ui',
  LANG: 'lang',
  BASE_INSTANCE_ID: 'base_instance_id'
};

const useCheckInitialQueryParameters = () => {
  const [checked, setChecked] = useState(false);
  const [parameters, setParameters] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [skip, setSkip] = useState(true);
  const [token, setToken] = useState('');

  const { isUninitialized, isSuccess, isLoading } = useGetUserInfoQuery(token, {
    skip
  });

  useEffect(() => {
    dispatch(authSliceActions.setTokenValid(isSuccess));
    dispatch(authSliceActions.setTokenChecked(!isUninitialized && !isLoading));
  }, [dispatch, isLoading, isSuccess, isUninitialized]);

  useEffect(() => {
    if (checked) return;

    const parsed = queryString.parse(location.search);

    setCreatomusConfiguratorOriginHeader(parsed[KEYS.BACKLINK_ORIGIN]); // Set custom headers

    setToken(parsed[KEYS.USER_TOKEN]);
    setSkip(false);

    delete parsed[KEYS.BACKLINK_ORIGIN];
    delete parsed[KEYS.USER_TOKEN];

    if (parsed[KEYS.UI_MODE]) {
      dispatch(uiActions.setUiMode(parsed[KEYS.UI_MODE])); // Set UI mode
    }

    if (parsed[KEYS.LANG]) {
      dispatch(uiActions.selectLanguage(parsed[KEYS.LANG])); // Set language

      delete parsed[KEYS.LANG];
    }

    if (parsed[KEYS.BASE_INSTANCE_ID]) {
      window.BASE_INSTANCE_ID = parsed[KEYS.BASE_INSTANCE_ID]; // Set base instance id, needed only in development mode

      delete parsed[KEYS.BASE_INSTANCE_ID];
    }

    history.replace(`${location.pathname}?${queryString.stringify(parsed)}`);

    setChecked(true);
    setParameters(parsed);
  }, [checked, dispatch, history, location.pathname, location.search]);

  return { checked, parameters };
};

export default useCheckInitialQueryParameters;
