import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';
import { useTranslate } from '../../utility/hooks';

import './ServicePage.scss';

const servicePageStyle = b.with('service-page');

const ServicePage = ({ title, subtitle, message, button }) => {
  const translate = useTranslate();

  return (
    <div className={servicePageStyle()}>
      <div className={servicePageStyle('content')}>
        <h1 className={servicePageStyle('title')}>{translate(title)}</h1>
        <p className={servicePageStyle('paragraph')}>
          <strong>{translate(subtitle)}</strong>
        </p>
        <p className={servicePageStyle('paragraph')}>{translate(message)}</p>
        {button}
      </div>
    </div>
  );
};

ServicePage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  button: PropTypes.node.isRequired
};

export default ServicePage;
