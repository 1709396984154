import api from './instance';

const CONTENT_TYPES = {
  JSON: 'application/json',
  FORM_DATA: 'application/x-www-form-urlencoded',
  PDF: 'application/pdf'
};

export function getContentType(type = CONTENT_TYPES.JSON) {
  return { 'Content-Type': type };
}

export const SERVER_ROUTE = {
  LOGIN: 'login',
  REGISTRATION: 'registration',
  FORGOT_PASSWORD: 'forgot-password',
  VERIFY_TOKEN: 'verify-token',
  RESET_PASSWORD: 'reset-password',
  USER_INFO: 'user-info',
  LOGOUT: 'logout',
  LOGIN_FACEBOOK: 'facebook-login',
  LOGIN_GOOGLE: 'google-login',

  INITIAL_STATE: 'initial-state',

  PLOTS: 'plots',
  PROJECTS: 'projects',

  INSTANCES: 'instances',
  CHILD_INSTANCES: 'child-instances',
  ORPHAN_INSTANCES: 'orphan-instances',
  INSTANCE: id => `${SERVER_ROUTE.INSTANCES}/${id}`,
  INSTANCE_SNAPSHOT: id => `${SERVER_ROUTE.INSTANCES}/${id}/snapshot`,
  INSTANCE_PDF: id => `${SERVER_ROUTE.INSTANCES}/${id}/pdf`,
  INSTANCE_LEAD: id => `${SERVER_ROUTE.INSTANCES}/${id}/lead`,
  SEND_ORPHAN_INSTANCE_TO_EMAIL: id => `${SERVER_ROUTE.INSTANCES}/${id}/send-orphan`,
  ORPHAN_LEADS: 'orphan-leads',
  INSTANCE_PDF_GALLERY: `images`,

  SEEDS: 'seeds',
  PARTS: id => `${SERVER_ROUTE.SEEDS}/${id}/parts`,

  TEXTURE: (name, projectId, updatedAt) =>
    api.getAssetsUri({ url: `textures/${name}`, params: { projectId, updatedAt } }),
  IMAGE: (name, projectId, updatedAt) => api.getAssetsUri({ url: `images/${name}`, params: { projectId, updatedAt } }),
  MODELS: (name, projectId) => api.getAssetsUri({ url: `models/${name}`, params: { projectId } })
};

export const ASSETS_ROUTE = {
  PARTS: 'parts',
  PART: id => `${ASSETS_ROUTE.PARTS}/${id}`
};

const auth = {
  login: (username, password) =>
    api.post(SERVER_ROUTE.LOGIN, `username=${encodeURIComponent(username)}&password=${password}`, {
      headers: { ...getContentType(CONTENT_TYPES.FORM_DATA) }
    }),
  registration: (username, password) =>
    api.post(SERVER_ROUTE.REGISTRATION, `username=${encodeURIComponent(username)}&password=${password}`, {
      headers: { ...getContentType(CONTENT_TYPES.FORM_DATA) }
    }),
  loginFacebook: token =>
    api.post(SERVER_ROUTE.LOGIN_FACEBOOK, `access_token=${token}`, {
      headers: { ...getContentType(CONTENT_TYPES.FORM_DATA) }
    }),
  loginGoogle: token =>
    api.post(SERVER_ROUTE.LOGIN_GOOGLE, `id_token=${token}`, {
      headers: { ...getContentType(CONTENT_TYPES.FORM_DATA) }
    }),
  forgotPassword: username => api.post(SERVER_ROUTE.FORGOT_PASSWORD, { username }),
  verifyToken: token => api.post(SERVER_ROUTE.VERIFY_TOKEN, { token }),
  resetPassword: (password, token) => api.post(SERVER_ROUTE.RESET_PASSWORD, { password, token }),
  getUserInfo: (userToken = '') => api.get(SERVER_ROUTE.USER_INFO, { headers: { 'Creatomus-User-Token': userToken } }),
  logout: () => api.get(SERVER_ROUTE.LOGOUT)
};

const assets = {
  getPart: (id, updatedAt, type = 'geometry') => api.getAssets(ASSETS_ROUTE.PART(id), { params: { type, updatedAt } })
};

const routes = {
  auth,
  getPlots: projectId => api.get(SERVER_ROUTE.PLOTS, { params: { projectId } }),
  fetchInitialState: (type, param, baseInstanceId) =>
    api.get(SERVER_ROUTE.INITIAL_STATE, { params: { type, param, baseInstanceId } }),
  fetchChildInstances: (ids = []) => api.get(SERVER_ROUTE.CHILD_INSTANCES, { params: { ids } }),
  getUserInstances: () => api.get(SERVER_ROUTE.INSTANCES),
  createOrphanInstance: data => api.post(SERVER_ROUTE.ORPHAN_INSTANCES, data),
  sendOrphanInstanceToEmail: (id, email) => api.post(SERVER_ROUTE.SEND_ORPHAN_INSTANCE_TO_EMAIL(id), { email }),
  createInstance: (data, ignoreEmail) => api.post(SERVER_ROUTE.INSTANCES, data, { params: { ignoreEmail } }),
  deleteInstance: id => api.delete(SERVER_ROUTE.INSTANCE(id)),
  saveInstance: (id, data) => api.put(SERVER_ROUTE.INSTANCE(id), data),
  uploadSnapshot: (id, file) => {
    const body = new FormData();

    body.append('file', file, 'snapshot.png');

    return api.post(SERVER_ROUTE.INSTANCE_SNAPSHOT(id), body, {
      headers: { ...getContentType(CONTENT_TYPES.FORM_DATA) }
    });
  },
  uploadPdfGallery: (instanceId, files) => {
    const body = new FormData();

    files.forEach(({ blob, name }) => {
      body.append('file', blob, name);
    });

    return api.post(SERVER_ROUTE.INSTANCE_PDF_GALLERY, body, {
      headers: { ...getContentType(CONTENT_TYPES.FORM_DATA) },
      params: { instanceId }
    });
  },
  getPDF: id =>
    api.get(SERVER_ROUTE.INSTANCE_PDF(id), { responseType: 'blob', headers: { ...getContentType(CONTENT_TYPES.PDF) } }),
  createLead: (id, data) => api.post(SERVER_ROUTE.INSTANCE_LEAD(id), data),
  updateLead: (id, data) => api.put(SERVER_ROUTE.INSTANCE_LEAD(id), data),
  createOrphanLead: data => api.post(SERVER_ROUTE.ORPHAN_LEADS, data),
  getParts: seedId => api.get(SERVER_ROUTE.PARTS(seedId)),
  assets
};

export default routes;
