import * as partsActions from '../parts/partsActions';
import * as geometriesActions from './geometriesActions';

const initialState = {
  status: -1,
  statusCounter: 0
};

export const GEOMETRIES_STATUSES = {
  LOADING: 0,
  SUCCESS: 1,
  FAILURE: 2
};

const geometriesReducer = (state = initialState, { type }) => {
  switch (type) {
    case geometriesActions.FETCH_GEOMETRIES__REQUEST:
      return { ...state, status: GEOMETRIES_STATUSES.LOADING, statusCounter: state.statusCounter - 1 };
    case partsActions.FETCH_PARTS__REQUEST:
      return { ...state, status: GEOMETRIES_STATUSES.LOADING };
    case geometriesActions.FETCH_GEOMETRIES__SUCCESS:
      return { ...state, status: GEOMETRIES_STATUSES.SUCCESS, statusCounter: state.statusCounter + 1 };
    default:
      return state;
  }
};

export default geometriesReducer;
