import { useMemo, useEffect, useRef, useState, useCallback } from 'react';
import getEasingFrames from '../getEasingFrames';

const useAnimated = (target, frameCount = 30) => {
  const requestRef = useRef();
  const interpolation = useMemo(() => getEasingFrames(frameCount), [frameCount]);

  const [source, setSource] = useState(target);

  const difference = target - source;
  const steps = useMemo(
    () => interpolation.map(item => item * difference + source),
    [difference, interpolation, source]
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const forward = useCallback(() => {
    let index0;

    setCurrentIndex(index => {
      index0 = index + 1;

      return index0;
    });

    return index0;
  }, []);

  const animate = useCallback(() => {
    const index = forward();

    if (index < frameCount - 1) {
      requestRef.current = window.requestAnimationFrame(animate);
    }
  }, [forward, frameCount]);

  // main effect to start the animation
  // triggers if source is not target
  useEffect(() => {
    if (source !== target) requestRef.current = window.requestAnimationFrame(animate);

    return () => window.cancelAnimationFrame(requestRef.current);
  }, [animate, forward, source, target]);

  // effect to update source after animation end
  // is checked each time index changes, steps change or target changes
  useEffect(() => {
    if (steps[currentIndex + 1] === undefined) {
      setCurrentIndex(0);
      setSource(target);
    }
  }, [currentIndex, steps, target]);

  return source !== undefined && source !== null ? steps[currentIndex] : target;
};

export default useAnimated;
