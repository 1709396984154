import React, { useMemo } from 'react';
import { useTranslate } from '../../utility/hooks';
import { Button } from '../Atoms';
import ServicePage from './ServicePage';

const NotFoundPage = () => {
  const translate = useTranslate();
  const button = useMemo(
    () => (
      <Button rounded color="main-outline" size="lg" label={translate('See all configurators')} type="link" to="/" />
    ),
    [translate]
  );

  return (
    <ServicePage
      title="404"
      subtitle="Something went wrong"
      message="Please check that the URL was correct or see the full list of our configurators to find the right one"
      button={button}
    />
  );
};

export default NotFoundPage;
