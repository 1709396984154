import useControl from '../../../utility/hooks/useControl';
import ControlOptionCaption from '../ControlOptionCaption';
import DefaultOption from '../DefaultControl/DefaultOption';
import OptionImage from '../OptionImage';
import { controlStyle } from '../Control/style';
import { DefaultControlProps } from '../DefaultControl/DefaultControl';

const BulkMaterials = ({ control, onOptionSelect, activeTags }: DefaultControlProps) => {
  const { options, selectedOption } = useControl(control, onOptionSelect, activeTags);

  return (
    <>
      <div className={controlStyle('preview')}>
        <div className={controlStyle('preview-image')}>
          <OptionImage option={selectedOption} />
        </div>
      </div>
      <ControlOptionCaption type={control.type} option={selectedOption} />
      <ul className={controlStyle('options', { type: control.type })}>
        {options.map(optionProps => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <DefaultOption key={optionProps.option.name} {...optionProps} noCaption>
            <OptionImage option={optionProps.option} />
          </DefaultOption>
        ))}
      </ul>
    </>
  );
};

export default BulkMaterials;
