export const ACTION_DIALOG_TYPES = {
  AUTH: 'auth',
  LOGOUT: 'logout',
  PROFILE: 'profile',
  SAVE: 'save',
  SHARE: 'share',
  CONTACT: 'contact',
  PDF: 'pdf',
  OPEN_INSTANCE: 'open-instance',
  EXPORT_MODEL: 'export-model',
  ADD_COMMENT: 'add-comment'
} as const;

export const ACTION_TYPES = {
  OPEN_TAB: 'open-tab',
  OPEN_DIALOG: 'open-dialog',
  OPEN_LINK: 'open-link',
  OPEN_FIRST_CONTROL_TAB: 'open-first-control-tab',
  OPEN_LAST_CONTROL_TAB: 'open-last-control-tab'
} as const;

export type ActionDialogType = typeof ACTION_DIALOG_TYPES[keyof typeof ACTION_DIALOG_TYPES];
