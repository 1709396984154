import { createSelector } from 'reselect';
import { projectSelectors } from '../project';
import { seedSelectors } from '../seed';
import { getInstanceName, selectUserInstances } from '../instance/instanceSelectors';
import * as controlSelectors from './controlSelectors';

export const selectDefaultInstanceName = createSelector(
  [projectSelectors.selectProjectName, selectUserInstances],
  (projectName, userInstances) => {
    const count = userInstances.filter(instance => instance?.populated?.projectName === projectName).length;

    return `${projectName} #${count + 1}`;
  }
);

export const getActiveTab = createSelector([controlSelectors.getControlNavigationTree], controlNavigationTree =>
  controlNavigationTree.find(item => item.active)
);

export const getIsChildTabOpened = createSelector(
  [controlSelectors.selectChildTabControls],
  controls => controls.length > 0
);

export const getIsChildModeEnabled = createSelector(
  [getIsChildTabOpened, seedSelectors.selectIsMainSeed],
  (isChildTabOpened, isMainSeed) => isChildTabOpened || !isMainSeed
);

export const getChildModeName = createSelector(
  [seedSelectors.selectIsMainSeed, seedSelectors.selectSeedName, getInstanceName],
  (isMainSeed, seedName, instanceName) => (isMainSeed ? '' : instanceName || seedName)
);
