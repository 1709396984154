import { State } from 'types';

export const selectSelectedLanguage = (state: State) => state.ui.language;
export const selectControlsPanelVisible = (state: State) => state.ui.isControlsPanelVisible;
export const selectSelectedTabName = (state: State) => state.ui.selectedTabName;
export const selectSelectedControlGroupName = (state: State) => state.ui.selectedControlGroupName;
export const selectViewerInteracted = (state: State) => state.ui.viewerInteracted;
export const selectIsMobileNavigation = (state: State) => state.ui.isMobileNavigation;
export const selectIsMobileViewport = (state: State) => state.ui.isMobileViewport;
export const selectMenuVisible = (state: State) => state.ui.isMenuVisible;
export const selectNotFoundPageVisible = (state: State) => state.ui.isNotFoundPage;
export const selectNewInstanceId = (state: State) => state.ui.newInstanceId;
export const selectLocalSummaryGallery = (state: State) => state.ui.summaryGallery;
export const selectRenderingSummaryGallery = (state: State) => state.ui.renderingSummaryGallery;
export const selectSelectedParentControls = (state: State) => state.ui.selectedParentControls;
export const selectUtmParameters = (state: State) => state.ui.utmParameters;
export const selectUiMode = (state: State) => state.ui.uiMode;
export const selectIsFullscreen = (state: State) => state.ui.fullscreen;
export const selectIsPartInteractionEnabled = (state: State) => state.ui.partInteractionEnabled;
export const selectIsDeprecationNotificationVisible = (state: State) => state.ui.deprecationNotification;
export const selectPreviousParentControl = (state: State) => state.ui.previousParentControl;
export const selectErrorPageState = (state: State) => state.ui.errorPage;
export const selectTemporaryCommentPin = (state: State) => state.ui.temporaryCommentPin;
