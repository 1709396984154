import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { uiSelectors } from '../../modules/ui';
import { getLinkToConfigurator } from '../location';
import { instanceSelectors } from '../../modules/instance';
import PATH from '../paths';
import { INDEX_TAB } from '../../modules/ui/tabNames';

const useLinkGenerators = () => {
  const location = useLocation();
  const utmParameters = useSelector(uiSelectors.selectUtmParameters);
  const instanceId = useSelector(instanceSelectors.selectInstanceId);
  const utmParametersAsQuerystring = useMemo(() => queryString.stringify(utmParameters), [utmParameters]);
  const currentLink = useMemo(
    () => `${getLinkToConfigurator(location.pathname)}?${utmParametersAsQuerystring}`,
    [location.pathname, utmParametersAsQuerystring]
  );

  const getCustomLink = useCallback(
    link => (link ? `${link}${link.includes('?') ? '&' : '?'}${utmParametersAsQuerystring}` : undefined),
    [utmParametersAsQuerystring]
  );

  const shareLink = useMemo(() => {
    const path = `/id/${instanceId}`;

    return `${getLinkToConfigurator(path)}?${utmParametersAsQuerystring}`;
  }, [instanceId, utmParametersAsQuerystring]);

  const getConfiguratorLink = useCallback(
    link => `${getLinkToConfigurator(link)}?${utmParametersAsQuerystring}`,
    [utmParametersAsQuerystring]
  );

  const getLinkToSavedDesign = useCallback(
    id => ({
      pathname: PATH.INSTANCE.replace(':instanceId', id),
      search: `?tab=${INDEX_TAB}`
    }),
    []
  );

  return { getConfiguratorLink, shareLink, getCustomLink, getLinkToSavedDesign, currentLink };
};

export default useLinkGenerators;
