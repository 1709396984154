import React from 'react';
import b from 'b_';
import PageFooter from './PageFooter';
import PageHeader from './PageHeader';
import PageContent from './PageContent';

import './InfoPage.scss';

const infoPageStyle = b.with('info-page');

const InfoPage = () => {
  return (
    <div className={infoPageStyle()}>
      <PageHeader />
      <PageContent />
      <PageFooter />
    </div>
  );
};

export default InfoPage;
