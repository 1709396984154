import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Helmet as ReactHelmet } from 'react-helmet-async';
import { projectSelectors } from '../modules/project';
import { uiSelectors } from '../modules/ui';
import { themeSelectors } from '../modules/theme';
import { SERVER_THEME_STYLE_ID } from '../config';
import useTranslate from '../utility/hooks/useTranslate';

function hexToRgb(hex) {
  const replacedHex = hex.replace('#', '').trim();

  const r = parseInt(replacedHex.substring(0, 2), 16);
  const g = parseInt(replacedHex.substring(2, 4), 16);
  const b = parseInt(replacedHex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
}

const Helmet = () => {
  const translate = useTranslate();
  const mainColor = useRef('');
  const projectName = useSelector(projectSelectors.selectProjectName);
  const tabName = useSelector(uiSelectors.selectCurrentTabDisplayName);
  const { favicon } = useSelector(uiSelectors.selectUiImages);
  const theme = useSelector(themeSelectors.selectTheme);

  const onChange = useCallback(() => {
    const computedStyle = getComputedStyle(document.documentElement);

    const currentMainColor = computedStyle.getPropertyValue('--c-main-color');

    if (mainColor.current === currentMainColor) return;

    document.documentElement.style.setProperty('--c-main-decimal-color', hexToRgb(currentMainColor));

    mainColor.current = currentMainColor;
  }, []);

  return (
    <ReactHelmet onChangeClientState={onChange}>
      <title>{`${translate(projectName) || translate('Configurator')} | ${translate(tabName)}`}</title>
      <link rel="shortcut icon" type="image/png" href={favicon} />
      <meta name="description" content="modular house configurator" />
      <style id={SERVER_THEME_STYLE_ID} type="text/css" data-rh="true">
        {theme}
      </style>
    </ReactHelmet>
  );
};

export default Helmet;
