import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSwitch, useTranslate } from '../../../../utility/hooks';
import PreferenceChoice from '../PreferenceChoice';
import preferencesTableStyle, { MIN_HEIGHT } from '../preferencesTableStyle';
import Icon from '../../../Icon';

const PreferenceSection = ({ choices, index: sectionIndex, tab, onClick, initiallyClosed }) => {
  const translate = useTranslate();
  const { toggle, isOpen } = useSwitch(!initiallyClosed);
  const [updateNeeded, triggerUpdate] = useState({});
  const [height, setHeight] = useState(MIN_HEIGHT);
  const tabRef = useRef();

  const handleHeightChange = useCallback(() => {
    triggerUpdate({});
  }, []);

  const handleButtonClick = useCallback(() => {
    toggle();
  }, [toggle]);

  useEffect(() => {
    const { current: tabElement } = tabRef;

    const newHeight = isOpen ? `${tabElement.scrollHeight}px` : MIN_HEIGHT;

    setHeight(newHeight);
  }, [height, isOpen, updateNeeded]);

  return (
    <div className={preferencesTableStyle('tab')} ref={tabRef}>
      <button className={preferencesTableStyle('tab-name')} onClick={handleButtonClick} type="button">
        <span className={preferencesTableStyle('tab-icon', { hidden: !isOpen })}>
          <Icon type="menu-down" />
        </span>
        <span>{translate(tab.displayName)}</span>
      </button>
      <div className={preferencesTableStyle('controls', { hidden: !isOpen })} style={{ maxHeight: height || 'auto' }}>
        {choices.map(({ control, option, parents, empty }, choiceIndex) => (
          <PreferenceChoice
            key={control.treeName}
            control={control}
            option={option}
            parents={parents}
            sectionIndex={sectionIndex}
            index={choiceIndex}
            empty={empty}
            onClick={onClick}
            tab={tab}
            onUpdateHeight={handleHeightChange}
          />
        ))}
      </div>
    </div>
  );
};

PreferenceSection.propTypes = {
  index: PropTypes.number.isRequired,
  choices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func.isRequired,
  tab: PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string
  }).isRequired,
  initiallyClosed: PropTypes.bool.isRequired
};

export default PreferenceSection;
