/** Takes array of objects with _id field and makes a map out of it. */

function convertArrayToMap<T extends { _id: string }>(arr: T[]) {
  const result: Record<string, T> = {};

  arr.forEach(item => {
    result[item._id] = item;
  });

  return result;
}

export default convertArrayToMap;
