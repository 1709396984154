import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import getInitialState from '../modules/state';
import sagas from '../modules/sagas';
import plots from '../modules/plots';
import project from '../modules/project';
import instance from '../modules/instance/instanceReducer';
import selectedOptions from '../modules/selectedOptions';
import configuratorType from '../modules/configuratorType';
import parts from '../modules/parts/partsReducer';
import seed from '../modules/seed/seedReducer';
import theme from '../modules/theme';
import settings from '../modules/settings/settingsReducer';
import geometries from '../modules/geometries/geometriesReducer';
import textures from '../modules/textures/texturesReducer';
import notifications from '../modules/notifications';
import dialog from '../modules/dialog/dialogReducer';
import ui from '../modules/ui/uiReducer';
import loader from '../modules/loader';
import cookies from '../modules/cookies';
import camera from './camera/camera';
import authSlice from './auth';
import notification from './middleware/notification';
import authApi from './api/api';
import materials from './materials/materials';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    authSlice,
    camera,
    configuratorType,
    cookies,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Slice not converted
    dialog,
    geometries,
    instance,
    loader,
    materials,
    notifications,
    parts,
    plots,
    project,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Slice not converted
    seed,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Slice not converted
    selectedOptions,
    settings,
    textures,
    theme,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Slice not converted
    ui,
    [authApi.reducerPath]: authApi.reducer
  },
  // eslint-disable-next-line no-underscore-dangle
  preloadedState: getInitialState((window as any).__INITIAL_STATE__),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Slice not converted
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat([
      notification,
      sagaMiddleware,
      authApi.middleware
    ])
});

sagaMiddleware.run(sagas);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
