// different settings for imperial and metric calculations

const MODEL_UNITS = {
  METRIC: 'metric',
  IMPERIAL: 'imperial'
} as const;

const DEFAULT_CAMERA_METRIC = {
  cameraPosition: {
    x: 2000,
    y: 5000,
    z: 2000
  },
  cameraTarget: {
    x: 0,
    y: 0,
    z: 0
  },
  type: 'View',
  name: '_default-metric',
  hidden: true
} as const;

const DEFAULT_CAMERA_IMPERIAL = {
  cameraPosition: {
    x: 50,
    y: 125,
    z: 50
  },
  cameraTarget: {
    x: 0,
    y: 0,
    z: 0
  },
  type: 'View',
  name: '_default-imperial',
  hidden: true
} as const;

const CAMERA_IMPERIAL = {
  // aspect ratio is calculated
  fov: 45,
  near: 10,
  far: 10000
} as const;
const CAMERA_METRIC = {
  fov: 45,
  near: 100,
  far: 200000
} as const;

const SUN_SHADOW_IMPERIAL = {
  shadowCamera: {
    near: 10,
    visible: false
  },
  sunModel: {
    distance: 1000,
    altitude: 30,
    azimuth: 160
  }
} as const;
const SUN_SHADOW_METRIC = {
  sunModel: {
    distance: 25000,
    altitude: 30,
    azimuth: 160,
    near: 100
  },
  shadowCamera: {
    near: 100,
    visible: false
  }
} as const;

const BUMP_MODIFIER_METRIC = 10;
const BUMP_MODIFIER_IMPERIAL = 0.25;

const SKYBOX_DIMENSIONS_METRIC = {
  width: 30000000,
  height: 30000000,
  depth: 30000000
};

const SKYBOX_DIMENSIONS_IMPERIAL = {
  width: 1200000,
  height: 1200000,
  depth: 1200000
};

const ANIMATION = {
  VIEW_CHANGE_FRAME_COUNT: 45, // 0,75 second for 60fps
  PAN_FRAME_COUNT: 10
};

export {
  MODEL_UNITS,
  ANIMATION,
  CAMERA_METRIC,
  CAMERA_IMPERIAL,
  SUN_SHADOW_METRIC,
  SUN_SHADOW_IMPERIAL,
  SKYBOX_DIMENSIONS_IMPERIAL,
  SKYBOX_DIMENSIONS_METRIC,
  BUMP_MODIFIER_METRIC,
  BUMP_MODIFIER_IMPERIAL,
  DEFAULT_CAMERA_METRIC,
  DEFAULT_CAMERA_IMPERIAL
};
