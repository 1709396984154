import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { PerspectiveCamera, WebGLRenderer, Scene, Matrix4, sRGBEncoding, OrthographicCamera, Object3D } from 'three';

class ThreeSetup {
  renderer = new WebGLRenderer({ precision: 'highp', antialias: window.devicePixelRatio < 2, alpha: true });

  /** Scene is used for all non-transformed elements like threejs native helpers */
  scene = new Scene();

  /** Model is used for sprites and meshes, lights */
  model = new Object3D();

  sceneTransform = new Matrix4();

  sceneTransformInverse = new Matrix4();

  renderRequested = false;

  perspectiveCamera = new PerspectiveCamera();

  orthoCamera = new OrthographicCamera();

  orbit = new OrbitControls(this.perspectiveCamera, this.renderer.domElement);

  cssRenderer = new CSS2DRenderer();

  cssScene = new Scene();

  constructor() {
    this.setupRenderer();
    this.setupScene();
    this.setupOrbit();
    this.setRenderCamera(this.perspectiveCamera);
  }

  setupRenderer = () => {
    this.renderer.setClearColor(0xf9f9f9);
    this.renderer.shadowMap.enabled = true;
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.cssRenderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.outputEncoding = sRGBEncoding;
    this.renderer.debug.checkShaderErrors = false;
  };

  setupScene = () => {
    this.sceneTransform.set(1, 0, 0, 0, 0, 0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1);
    this.sceneTransformInverse.copy(this.sceneTransform).invert();
    this.model.applyMatrix4(this.sceneTransform);
    this.cssScene.applyMatrix4(this.sceneTransform);
    this.scene.add(this.model);
  };

  setupOrbit = () => {
    // default screenSpacePanning to true, PlanView will set it to false, other view types will set it to true.
    this.orbit.screenSpacePanning = true;
    this.orbit.maxPolarAngle = Math.PI / 2;
  };

  render = () => {
    this.renderer.render(this.scene, this.renderCamera);
    this.cssRenderer.render(this.cssScene, this.renderCamera);
    this.renderRequested = false;
  };

  requestRender = () => {
    if (!this.renderRequested) {
      this.renderRequested = true;
      window.requestAnimationFrame(this.render);
    }
  };

  handleResize = (width, height) => {
    const aspect = width / height;

    this.renderer.setSize(width, height);
    this.cssRenderer.setSize(width, height);

    this.orthoCamera.userData.aspect = aspect;
    this.perspectiveCamera.aspect = aspect;
    this.renderCamera.updateProjectionMatrix();

    this.orthoCamera.right = this.orthoCamera.top * aspect;
    this.orthoCamera.left = this.orthoCamera.bottom * aspect;

    this.requestRender('on resize');
  };

  setRenderCamera = camera => {
    this.renderCamera = camera;
    this.orbit.object = camera;
  };

  getPublicVariables = () => ({
    camera: this.renderCamera,
    orthoCamera: this.orthoCamera,
    perspectiveCamera: this.perspectiveCamera,
    renderer: this.renderer,
    cssRenderer: this.cssRenderer,
    cssScene: this.cssScene,
    scene: this.scene,
    render: this.requestRender,
    canvas: this.renderer.domElement,
    orbit: this.orbit,
    onResize: this.handleResize,
    sceneTransform: this.sceneTransform,
    sceneTransformInverse: this.sceneTransformInverse,
    model: this.model,
    setRenderCamera: this.setRenderCamera
  });
}

const setup = new ThreeSetup();

export default setup.getPublicVariables;
