import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../../../utility/hooks';
import preferencesTableStyle from '../preferencesTableStyle';
import { TYPE_CONTROL_OPTION, TYPE_PART_CONTROL, TYPE_PART_PHASE } from '../../../../utility/propTypes';
import ChildInstancePreferences from '../ChildInstancePreferences';
import { settingsSelectors } from '../../../../modules/settings';
import PreferenceButton from './PreferenceButton';
import PreferenceLabel from './PreferenceLabel';

const PreferenceChoice = props => {
  const { control, option, parents, empty, tab, index: choiceIndex, sectionIndex, onClick, onUpdateHeight } = props;

  const isChildSeedUiHidden = useSelector(settingsSelectors.selectHideChildSeedUI);

  const translate = useTranslate();

  const showInstanceLoader = control.instanceLoader?.enabled && isChildSeedUiHidden;

  /*
  If instance children will be loaded, it will have an additional wrapper
  with borders. Otherwise it will behave as before.
*/

  const children = (
    <div
      key={control.treeName}
      className={preferencesTableStyle('control', {
        price: Boolean(option?.totalPrice),
        child: parents.length > 0
      })}
    >
      <span className={preferencesTableStyle('name')}>{translate(control.displayName)}</span>
      {
        /*
If tab is provided, a button is created, otherwise a static label.
Child instance choices always get a label, main instance choices get a button
*/
        tab ? (
          <PreferenceButton
            empty={empty}
            onClick={onClick}
            choiceIndex={choiceIndex}
            sectionIndex={sectionIndex}
            control={control}
            option={option}
            tab={tab}
          />
        ) : (
          <PreferenceLabel option={option} empty={empty} />
        )
      }
    </div>
  );

  if (!showInstanceLoader) {
    return children;
  }

  return (
    <div
      key={control.treeName}
      className={preferencesTableStyle('wrapper', {
        'instance-loader': true
      })}
    >
      {children}
      <ChildInstancePreferences control={control} onUpdateHeight={onUpdateHeight} />
    </div>
  );
};

PreferenceChoice.propTypes = {
  control: TYPE_PART_CONTROL.isRequired,
  option: TYPE_CONTROL_OPTION.isRequired,
  empty: PropTypes.bool,
  parents: PropTypes.arrayOf(PropTypes.shape({})),
  sectionIndex: PropTypes.number,
  index: PropTypes.number.isRequired,
  tab: TYPE_PART_PHASE,
  onClick: PropTypes.func,
  onUpdateHeight: PropTypes.func
};

PreferenceChoice.defaultProps = {
  sectionIndex: 0,
  onClick: () => {},
  tab: undefined,
  empty: false,
  parents: [],
  onUpdateHeight: () => {}
};

export default PreferenceChoice;
