import { useCallback, useState } from 'react';

const useSwitch = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const toggle = useCallback(() => setIsOpen(current => !current), []);

  const open = useCallback(() => setIsOpen(true), []);

  const close = useCallback(() => setIsOpen(false), []);

  const set = useCallback((value = false) => setIsOpen(value), []);

  return { isOpen, toggle, open, close, set };
};

export default useSwitch;
