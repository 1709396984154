import { useCallback } from 'react';
import * as THREE from 'three';
import getThreeSetup from '../getThreeSetup';
import { ANIMATION as ANIMATION_SETTINGS } from '../../../utility/viewerSettings';
import getEasingFrames from '../../../utility/getEasingFrames';

/** Camera animation state */
// There is no need for component state update so these shouldn't be in state
const ANIMATION = {
  STEPS: [],
  INTERRUPTED: false,
  REQUEST: 0,
  STEP: 0
};

const resetAnimation = () => {
  ANIMATION.STEPS = [];
  ANIMATION.STEP = 0;
  ANIMATION.REQUEST = 0;
  ANIMATION.INTERRUPTED = false;
};

const setSteps = steps => {
  ANIMATION.STEPS = steps;
};

const setStep = step => {
  ANIMATION.STEP = step;
};

const setInterrupted = bool => {
  ANIMATION.INTERRUPTED = bool;
};

const setRequest = request => {
  ANIMATION.REQUEST = request;
};

const dummyCamera = new THREE.Camera(); // lookAt works differently for cameras and other objects
const defaultTarget = new THREE.Vector3();

/** Returns callbacks for starting and interrupting Camera animation. */
const useCameraAnimation = (camera, set) => {
  const { orbit } = getThreeSetup();

  const animate = useCallback(() => {
    if (ANIMATION.STEP < ANIMATION.STEPS.length && !ANIMATION.INTERRUPTED) {
      const currentStep = ANIMATION.STEPS[ANIMATION.STEP];

      set(currentStep.position, currentStep.target, currentStep.rotation, currentStep.zoom, currentStep.frustum);
      window.requestAnimationFrame(animate);

      setStep(ANIMATION.STEP + 1);
    } else {
      resetAnimation();
    }
  }, [set]);

  const initAnimation = useCallback(
    (newPosition, newTarget = defaultTarget, frameCount, zoom, frustum) => {
      const { target: startTarget } = orbit; // vector3
      const {
        position: startPosition,
        quaternion: startQuaternion,
        zoom: startZoom,
        left: startLeft,
        right: startRight,
        top: startTop,
        bottom: startBottom
      } = camera; // vector3

      // get target quaternion
      dummyCamera.position.copy(newPosition);
      dummyCamera.lookAt(newTarget.x, newTarget.y, newTarget.z);
      // init start and target rotations;
      const newQuaternion = dummyCamera.quaternion.clone().normalize();

      const easeFrames = getEasingFrames(frameCount);

      const animationSteps = easeFrames.map(multiplier => {
        const step = {
          position: startPosition.clone().lerp(newPosition, multiplier),
          target: startTarget.clone().lerp(newTarget, multiplier),
          rotation: startQuaternion.clone().slerp(newQuaternion, multiplier),
          zoom: startZoom + multiplier * (zoom - startZoom)
        };

        if (frustum) {
          step.frustum = {
            left: startLeft + multiplier * (frustum.left - startLeft),
            right: startRight + multiplier * (frustum.right - startRight),
            top: startTop + multiplier * (frustum.top - startTop),
            bottom: startBottom + multiplier * (frustum.bottom - startBottom)
          };
        }

        return step;
      });

      setSteps(animationSteps);

      if (ANIMATION.REQUEST) {
        window.cancelAnimationFrame(ANIMATION.REQUEST);
      }
      setRequest(window.requestAnimationFrame(animate));
    },
    [animate, camera, orbit]
  );

  const setAnimated = useCallback(
    (position, target, frameCount = ANIMATION_SETTINGS.VIEW_CHANGE_FRAME_COUNT, zoom, frustum) => {
      resetAnimation();
      initAnimation(position, target, frameCount, zoom, frustum);
    },
    [initAnimation]
  );
  const interruptAnimation = useCallback(() => setInterrupted(true), []);

  return { setAnimated, interruptAnimation };
};

export default useCameraAnimation;
