import { createSelector } from 'reselect';
import { MODEL_UNITS } from '../../utility/viewerSettings';
import { selectSeedUpdatedAt } from '../seed/seedSelectors';
import { selectProjectId } from '../project/projectSelectors';
import { SERVER_ROUTE } from '../../api';
import { selectSettings } from './settingsSelectors';

// eslint-disable-next-line import/prefer-default-export
export const selectModelSettings = createSelector([selectSettings], ({ model = {} }) => ({
  maxInstanceCount: model.maxInstanceCount,

  modelUnits: model.modelUnits || MODEL_UNITS.METRIC,
  ppsqmLayers: model.ppsqmLayers || [],

  areaUnit: model.areaUnit,
  netAreaCaveat: model.netAreaCaveat,
  netAreaLayers: model.netAreaLayers,

  forceFetchingAssets: Boolean(model.forceFetchingAssets)
}));

export const selectModelUnits = createSelector([selectModelSettings], settings => settings.modelUnits);

export const selectImageUrlGenerator = createSelector(
  [selectModelSettings, selectProjectId, selectSeedUpdatedAt],
  ({ forceFetchingAssets }, projectId, updatedAt) => {
    return (fileName, isTexture = false) => {
      const fn = isTexture ? SERVER_ROUTE.TEXTURE : SERVER_ROUTE.IMAGE;

      return fn(fileName, projectId, forceFetchingAssets ? updatedAt : undefined);
    };
  }
);
