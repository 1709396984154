import { createSelector } from 'reselect';
import { TEXTURES_STATUSES } from './texturesReducer';

export const selectTextures = state => state.textures;
export const selectTexturesStatus = state => state.textures.status;
export const selectTexturesList = state => state.textures.list;

export const getIsTexturesReceived = createSelector(
  selectTexturesStatus,
  status => status === TEXTURES_STATUSES.SUCCESS
);
