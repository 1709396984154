import React from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';
import { uiSelectors } from '../../../modules/ui';
import MenuButton from '../../MenuDialogs/MainMenu/MenuButton';

import './PageHeader.scss';

const pageHeaderStyle = b.with('page-header');

const PageHeader = () => {
  const title = useSelector(uiSelectors.selectIntroTitle);
  const { headerLogo } = useSelector(uiSelectors.selectUiImages);

  return (
    <div className={pageHeaderStyle()}>
      <MenuButton type="header" />
      <h1 className={pageHeaderStyle('title')}>{title}</h1>

      {headerLogo ? <img src={headerLogo} className={pageHeaderStyle('logo')} alt="Logo" /> : null}
    </div>
  );
};

export default PageHeader;
