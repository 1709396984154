import { createSelector } from 'reselect';
import { selectCurrentInstanceId } from '../instance/instanceSelectors';

export const selectStateSelectedOptions = state => state.selectedOptions;

export const selectSelectedOptions = createSelector(
  [selectStateSelectedOptions, selectCurrentInstanceId],
  (selectedOptions, instanceId) => {
    const { list } = selectedOptions;

    return list[instanceId];
  }
);

export const selectInstanceUnsaved = createSelector(
  [selectStateSelectedOptions, selectCurrentInstanceId],
  (selectedOptions, instanceId) => {
    const { unsavedInstances = [] } = selectedOptions;

    return unsavedInstances.includes(instanceId);
  }
);
