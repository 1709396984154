import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../../modules/dialog';
import { DIALOGS } from '../../../utility/dialogs';
import { useLogoutMutation } from '../../../store/api/api';
import ConfirmMenu from './ConfirmMenu';

const ConfirmLogoutMenu = () => {
  const [logout] = useLogoutMutation();
  const dispatch = useDispatch();
  const handleSubmit = useCallback(async () => {
    await logout().unwrap();
    dispatch(dialogActions.hideDialog());
  }, [dispatch, logout]);

  const handleCancel = useCallback(() => dispatch(dialogActions.showDialog(DIALOGS.MENU)), [dispatch]);

  return (
    <ConfirmMenu
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      label="Log out"
      disabledLabel="Logging out..."
      heading="Are you sure you want to log out?"
      description="Click on Log out to proceed"
    />
  );
};

export default ConfirmLogoutMenu;
