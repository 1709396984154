import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslate, usePageReload } from '../../utility/hooks';
import { Button } from '../Atoms';
import ServicePage from './ServicePage';

const ErrorPage = ({ message }) => {
  const translate = useTranslate();
  const reload = usePageReload();

  const button = useMemo(
    () => (
      <Button rounded color="main-outline" size="lg" label={translate('Try again')} type="button" onClick={reload} />
    ),
    [translate, reload]
  );

  return <ServicePage title="Error" subtitle="Something went wrong" message={message} button={button} />;
};

ErrorPage.propTypes = {
  message: PropTypes.string.isRequired
};

export default ErrorPage;
