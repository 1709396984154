import { ReactNode, useEffect, useRef, useState } from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';
import ProgressBar from '../ControlsPanel/ProgressBar';
import NavigationButtons from '../ControlsPanel/NavigationButtons';
import { uiSelectors } from '../../modules/ui';
import ControlsPanelToggle from '../ControlsPanelToggle';
import { commonSelectors } from '../../modules/model';
import TabNavigation from '../ControlsPanel/TabNavigation';
import * as controlSelectors from '../../modules/model/controlSelectors';
import { useTranslate } from '../../utility/hooks';
import ControlsPanelCloseChildrenButton from './ControlsPanelCloseChildrenButton';

import './ControlsPanelWrapper.scss';

const controlsPanelStyle = b.with('controls-panel');

const ControlsPanelWrapper = ({ children }: { children: ReactNode }) => {
  const isMobileViewport = useSelector(uiSelectors.selectIsMobileViewport);
  const [height, setHeight] = useState('');
  const bodyRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const translate = useTranslate();

  const isMobileNavigation = useSelector(uiSelectors.selectIsMobileNavigation);
  const isControlsPanelVisible = useSelector(uiSelectors.selectIsControlPanelVisible);
  const selectedTabName = useSelector(uiSelectors.selectCurrentTabName);
  const isChildModeEnabled = useSelector(commonSelectors.getIsChildModeEnabled);
  const childModeName = useSelector(commonSelectors.getChildModeName);
  const isChildTabOpened = useSelector(commonSelectors.getIsChildTabOpened);
  const childTabName = useSelector(controlSelectors.selectChildTabName);

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = 0;
    }
  }, [selectedTabName]);

  useEffect(() => {
    if (!isMobileViewport) {
      return;
    }

    if (panelRef.current) setHeight(isControlsPanelVisible ? '0' : `${panelRef.current.scrollHeight}px`);
  }, [isControlsPanelVisible, isMobileViewport]);

  const style = isMobileViewport ? { marginBottom: `-${height}` } : undefined;

  return (
    <>
      <div className={controlsPanelStyle('toggle', { type: 'show', hidden: isControlsPanelVisible })}>
        <ControlsPanelToggle show />
      </div>
      <aside className={controlsPanelStyle({ visible: isControlsPanelVisible })} ref={panelRef} style={style}>
        <div className={controlsPanelStyle('content')}>
          <div className={controlsPanelStyle('header', { child: isChildModeEnabled })}>
            <div className={controlsPanelStyle('header-content')}>
              <div className={controlsPanelStyle('tab-navigation')}>
                <TabNavigation />
              </div>
              <div className={controlsPanelStyle('toggle', { type: 'hide' })}>
                <ControlsPanelToggle />
              </div>
            </div>
            {isMobileNavigation ? null : <ProgressBar />}
          </div>
          <div className={controlsPanelStyle('child-wrapper', { active: isChildModeEnabled })}>
            {isChildModeEnabled ? (
              <div className={controlsPanelStyle('child-header')}>
                <div className={controlsPanelStyle('child-header-content')}>
                  <span className={controlsPanelStyle('child-mode-name')}>{childModeName}</span>
                  {isChildTabOpened ? (
                    <span className={controlsPanelStyle('child-tab-name')}>{translate(childTabName)}</span>
                  ) : (
                    <TabNavigation childNavigation />
                  )}
                </div>
                {isMobileNavigation || isChildTabOpened ? null : <ProgressBar childProgress />}
                <ControlsPanelCloseChildrenButton mode="icon" />
              </div>
            ) : null}
            <div className={controlsPanelStyle('body', { child: isChildModeEnabled })} ref={bodyRef}>
              <div className={controlsPanelStyle('controls')}>{children}</div>
            </div>
            <div className={controlsPanelStyle('footer')}>
              {!isMobileNavigation && isChildTabOpened ? <ControlsPanelCloseChildrenButton /> : null}
              <NavigationButtons isChildTabOpened={isChildTabOpened} isMobileNavigation={isMobileNavigation} />
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default ControlsPanelWrapper;
