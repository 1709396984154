import { all, put, select, take, takeLatest } from 'redux-saga/effects';
import * as instanceSelectors from '../instance/instanceSelectors';
import {
  savePdfGallery,
  SAVE_PDF_GALLERY__FAILURE,
  SAVE_PDF_GALLERY__SUCCESS,
  setCurrentInstance
} from '../instance/instanceActions';
import * as userInstancesActions from '../userInstances/userInstancesActions';
import { showChildControls } from '../ui/uiActions';
import * as uiSelectors from '../ui/uiSelectors';
import { selectedOptionsActions } from '../selectedOptions';
import { getSelectedControlGroup } from '../model/controlSelectors';
import { hideDialog } from '../dialog/dialogActions';
import {
  SET_CURRENT_SEED_BY_INSTANCE_ID,
  SAVE_AND_CLOSE_CURRENT_SEED,
  DISCARD_AND_CLOSE_CURRENT_SEED,
  CLOSE_CURRENT_SEED,
  setCurrentSeedId,
  closeCurrentSeed
} from './seedActions';
import { selectSeedLastHistory } from './seedSelectors';

function* doSetCurrentSeedByInstanceIdSaga(action) {
  const { instanceId } = action.payload;
  const { instanceToSeed } = yield select(instanceSelectors.selectChildInstanceSavedStates);

  const seedId = instanceToSeed[instanceId];

  if (seedId) {
    const selectedTabName = yield select(uiSelectors.selectSelectedTabName);
    const selectedControlGroup = yield select(getSelectedControlGroup);
    const selectedParentControls = yield select(uiSelectors.selectSelectedParentControls);

    yield all([
      put(setCurrentSeedId(seedId, selectedTabName, selectedControlGroup, selectedParentControls)),
      put(setCurrentInstance(instanceId))
    ]);
  }
}

function* watchSetCurrentSeedByInstanceIdSaga() {
  yield takeLatest(SET_CURRENT_SEED_BY_INSTANCE_ID, doSetCurrentSeedByInstanceIdSaga);
}

function* doDiscardAndCloseCurrentSeedSaga() {
  yield put(closeCurrentSeed());
}

function* watchDiscardAndCloseCurrentSeedSaga() {
  yield takeLatest(DISCARD_AND_CLOSE_CURRENT_SEED, doDiscardAndCloseCurrentSeedSaga);
}

function* doSaveAndCloseCurrentSeedSaga(action) {
  const { clone, instanceName } = action.payload;
  const isOwner = yield select(instanceSelectors.getIsOwner);

  if (clone || !isOwner) {
    yield put(userInstancesActions.createChildInstance(instanceName));
  } else {
    yield put(userInstancesActions.saveChildInstance());
  }

  const {
    payload: { instance }
  } = yield take([
    userInstancesActions.SAVE_CHILD_INSTANCE__SUCCESS,
    userInstancesActions.CREATE_CHILD_INSTANCE__SUCCESS
  ]);

  // update snapshots
  yield put(savePdfGallery());

  // wait until success or failure has happened before continuing
  yield take([SAVE_PDF_GALLERY__SUCCESS, SAVE_PDF_GALLERY__FAILURE]);

  yield put(hideDialog());
  yield put(closeCurrentSeed(instance._id));
}

function* watchSaveAndCloseCurrentSeedSaga() {
  yield takeLatest(SAVE_AND_CLOSE_CURRENT_SEED, doSaveAndCloseCurrentSeedSaga);
}

function* doCommonCloseCurrentSeedSaga(action) {
  const { historyPush, makeTabLink } = action.payload;
  const lastHistory = yield select(selectSeedLastHistory);
  const hasChildControls = lastHistory.lastParentControls?.length > 0;
  const currentInstanceId = yield select(instanceSelectors.selectCurrentInstanceId);

  // wait for closeCurrentSeed, take is blocking
  const {
    payload: { childInstanceId }
  } = yield take(CLOSE_CURRENT_SEED);

  const instances = yield select(instanceSelectors.selectInstanceList);
  const instance = instances[currentInstanceId];

  yield put(selectedOptionsActions.resetSelectedOptions(instance));

  historyPush(makeTabLink(lastHistory.lastTabName), {
    controlName: lastHistory.lastControl.name,
    hideChildControls: !hasChildControls
  });

  if (hasChildControls) {
    yield put(showChildControls(lastHistory.lastParentControls, true));
  }

  // select created child instance
  if (childInstanceId) {
    yield put(selectedOptionsActions.selectOption(lastHistory.lastControl, { name: childInstanceId }));
  }
}

function* watchCommonCloseCurrentSeedSaga() {
  yield takeLatest([SAVE_AND_CLOSE_CURRENT_SEED, DISCARD_AND_CLOSE_CURRENT_SEED], doCommonCloseCurrentSeedSaga);
}

export default function* moduleSaga() {
  yield all([
    watchSetCurrentSeedByInstanceIdSaga(),
    watchSaveAndCloseCurrentSeedSaga(),
    watchDiscardAndCloseCurrentSeedSaga(),
    watchCommonCloseCurrentSeedSaga()
  ]);
}
