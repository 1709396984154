import { useCallback, useMemo } from 'react';
import useControl from '../../../utility/hooks/useControl';
import { Slider as SliderAtom } from '../../Atoms';
import ControlOptionCaption from '../ControlOptionCaption';
import { DefaultControlProps } from '../DefaultControl/DefaultControl';

const Slider = ({ control, onOptionSelect, activeTags }: DefaultControlProps) => {
  const { options, selectedOption, selectedIndex } = useControl(control, onOptionSelect, activeTags);
  const { name } = control;
  const filteredOptions = useMemo(() => options.filter(({ option }) => !option.locked), [options]);
  const onChange = useCallback(
    value => {
      const { option } = filteredOptions[value] || {};

      onOptionSelect(control, option);
    },
    [control, filteredOptions, onOptionSelect]
  );

  if (filteredOptions.length <= 1) return null;

  return (
    <>
      <ControlOptionCaption type={control.type} option={selectedOption} />
      <SliderAtom onChange={onChange} value={selectedIndex} max={filteredOptions.length - 1} min={0} name={name} />
    </>
  );
};

export default Slider;
