import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeshPhysicalMaterial, SpriteMaterial } from 'three';
import { FETCH_INITIAL_STATE } from '../../modules/initialState';
import { createPhysicalMaterial, createSpriteMaterial } from '../../utility/createMaterials';

/** Default material parameters
 * Used to create initial sprite material and initial mesh material
 */
const defaultMaterial = {
  Name: 'default',
  ColorDiffuse: 'rgb(255, 255, 255)'
};

/**
 * Store for actual threejs materials
 * Selectors select materials list based on slice but the actual material
 * from this store.
 * */
export const materialStore: Record<string, MeshPhysicalMaterial> = {
  default: createPhysicalMaterial(defaultMaterial)
};
export const spriteMaterialStore: Record<string, SpriteMaterial> = {
  default: createSpriteMaterial(defaultMaterial)
};

interface MaterialsStateType {
  list: Record<string, { name: string }>;
  spriteMaterials: Record<string, { name: string }>;
}

/**
 * Initial state holds references to two "default" materials
 */
const initialState: MaterialsStateType = {
  list: {
    default: { name: materialStore.default.name }
  },
  spriteMaterials: {
    default: { name: spriteMaterialStore.default.name }
  }
};

/**
 * Material holds references to materials
 * Sprite materials and Mesh materials are stored separately.
 * Slice only stores names, real material instances are stored in global objects by name
 */
const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    setMaterials(
      state,
      {
        payload: { list, overwrite = false, spriteMaterials = [] }
      }: PayloadAction<{ list: MeshPhysicalMaterial[]; overwrite?: boolean; spriteMaterials?: SpriteMaterial[] }>
    ) {
      list.forEach(material => {
        const { name } = material;

        // write it only if it doesn't exist or overwrite is true
        // gltf materials will be set with overwrite "false"
        if (overwrite || !state.list[name]) {
          state.list[name] = { name };
          materialStore[name] = material; // store material in global store
        }
      });

      spriteMaterials.forEach(material => {
        const { name } = material;

        // write it only if it doesn't exist or overwrite is true
        // gltf materials will be set with overwrite "false"
        if (overwrite || !state.spriteMaterials[name]) {
          state.spriteMaterials[name] = { name };
          spriteMaterialStore[name] = material; // store material in global store
        }
      });
    }
  },
  extraReducers: builder => {
    /** If initial state is fetched, reset all materials */
    builder.addCase(FETCH_INITIAL_STATE, () => initialState);
  }
});

export const { actions: materialsActions } = materialsSlice;
export default materialsSlice.reducer;
