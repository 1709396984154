import { useCallback } from 'react';

const useScrollIntoView = () => {
  const scroll = useCallback(activeRef => {
    activeRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, []);

  return [scroll];
};

export default useScrollIntoView;
