import { ReactNode, SyntheticEvent, useCallback } from 'react';
import b from 'b_';

import './Switch.scss';

const switchStyle = b.with('switch');

const TYPE_CHECKBOX = 'checkbox';
const TYPE_TOGGLE = 'toggle';
const TYPE_RADIO = 'radio';

interface SwitchProps {
  name: string;
  onChange: (name: string, value: boolean) => void;
  type: typeof TYPE_CHECKBOX | typeof TYPE_TOGGLE | typeof TYPE_RADIO;

  value?: string | number;
  caption?: string;
  children?: ReactNode;
  checked?: boolean;
  disabled?: boolean;
  inactive?: boolean;
  hideIcon?: boolean;
  title?: string;
  divider?: string;
  mix?: string;
  onBlur?: (e: SyntheticEvent) => void;
  onFocus?: (e: SyntheticEvent) => void;
  groupLabel?: string;
}

const Switch = ({
  type,
  onChange,
  name,
  value,
  caption = '',
  children = null,
  checked = false,
  disabled = false,
  inactive = false,
  hideIcon = false,
  title = '',
  divider = '',
  mix = '',
  onBlur,
  onFocus,
  groupLabel = ''
}: SwitchProps) => {
  const isRadioType = type === TYPE_RADIO;
  const inputType = isRadioType ? type : TYPE_CHECKBOX;
  const id = name + divider + (value || '');

  const handleChange = useCallback(
    e => {
      onChange(e.currentTarget.name, isRadioType ? e.currentTarget.value : e.currentTarget.checked);
    },
    [isRadioType, onChange]
  );

  const handleClickOnLabel = useCallback(e => {
    e.stopPropagation();
  }, []);

  return (
    <>
      {groupLabel ? <span className={switchStyle('group-label')}>{groupLabel}</span> : null}
      <div className={`${switchStyle({ disabled, type, inactive, checked })} ${mix}`}>
        <input
          type={inputType}
          onChange={handleChange}
          id={id}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <label
          className={switchStyle('label')}
          htmlFor={id}
          title={title}
          onFocus={onFocus}
          onBlur={onBlur}
          tabIndex={-1}
          onClick={handleClickOnLabel}
        >
          <span className={switchStyle('icon', { type, checked, disabled, inactive, hidden: hideIcon })} />
          {caption ? <span className={switchStyle('caption')}>{caption}</span> : children}
        </label>
      </div>
    </>
  );
};

export default Switch;
