import b from 'b_';
import { SyntheticEvent } from 'react';
import { useLinkGenerators, useTranslate, useUiModes } from '../../utility/hooks';
import { Button } from '../Atoms';
import Icon from '../Icon';

import './MinimalUiButtons.scss';

const minimalUiButtonsStyle = b.with('minimal-ui-buttons');

interface MinimalUiButtonsProps {
  onSetFullscreen: (e: SyntheticEvent) => void;
}

/** Label constants. Can be later refactored into a separate file */
const labels = {
  BTN_CAPTION_OPEN_IN_NEW_TAB: 'Open in a new tab',
  BTN_CAPTION_OPEN_IN_FULLSCREEN: 'Open in fullscreen',
  BTN_CAPTION_START: 'Start'
} as const;

const MinimalUiButtons = ({ onSetFullscreen }: MinimalUiButtonsProps) => {
  const { minimalUiSettings } = useUiModes();
  const translate = useTranslate();
  const { isAdditionalButtonVisible, isMainFullscreen } = minimalUiSettings;
  const { currentLink } = useLinkGenerators();

  /**
   * If is mainFullScreen, then first button should open in new tab
   * If is not mainFullScreen, then first button should be full screen button
   */

  const firstButtonProps = isMainFullscreen
    ? ({
        type: 'external-link',
        href: currentLink,
        'aria-label': translate(labels.BTN_CAPTION_OPEN_IN_NEW_TAB)
      } as const)
    : ({
        type: 'button',
        onClick: onSetFullscreen,
        'aria-label': translate(labels.BTN_CAPTION_OPEN_IN_FULLSCREEN)
      } as const);

  const firstButton = (
    <Button
      circular
      color="text"
      mix={minimalUiButtonsStyle('icon-button')}
      data-balloon-pos="left"
      {...firstButtonProps}
    >
      <Icon type={isMainFullscreen ? 'open-in-new' : 'fullscreen'} />
    </Button>
  );

  const secondButtonProps = isMainFullscreen
    ? ({
        'aria-label': translate(labels.BTN_CAPTION_OPEN_IN_FULLSCREEN),
        type: 'button',
        onClick: onSetFullscreen
      } as const)
    : ({
        'aria-label': translate(labels.BTN_CAPTION_OPEN_IN_NEW_TAB),
        type: 'external-link',
        href: currentLink
      } as const);

  const secondButton = (
    <Button
      rounded
      bold
      size="lg"
      mix={minimalUiButtonsStyle('main-button')}
      data-balloon-pos="up"
      {...secondButtonProps}
    >
      <span className={minimalUiButtonsStyle('main-caption')}>{translate(labels.BTN_CAPTION_START)}</span>
      <Icon type={isMainFullscreen ? 'fullscreen' : 'open-in-new'} scale={0.9} />
    </Button>
  );

  return (
    <>
      {isAdditionalButtonVisible ? firstButton : null}
      {secondButton}
    </>
  );
};

export default MinimalUiButtons;
