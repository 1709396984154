import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';
import { useTranslate } from '../../../utility/hooks';
import { Button } from '../../Atoms';

import './MoreInfoButton.scss';

const moreInfoButtonStyle = b.with('more-info-button');

const MoreInfoButton = ({ onClick, selected }) => {
  const translate = useTranslate();

  return (
    <Button
      color={selected ? 'secondary-hovered' : 'secondary'}
      size="xs"
      rounded
      onClick={onClick}
      mix={moreInfoButtonStyle()}
    >
      {translate('More info')}
    </Button>
  );
};

MoreInfoButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
};

export default MoreInfoButton;
