import { useMemo } from 'react';
import { PartNodeControlType, PartNodeOptionType } from 'types';
import { CONTROL_TAGS, CONTROL_TYPES } from '../controlDefinitions';
import { DefaultOptionProps } from '../../components/ControlsPanel/DefaultControl/DefaultOption/DefaultOption';

type OnOptionSelectType = (control: PartNodeControlType, option: PartNodeOptionType) => void;

const useControl = (control: PartNodeControlType, onOptionSelect: OnOptionSelectType, activeTags: string[]) => {
  const { list = [], value, tags = {} } = control;

  const controlHasTags = (tags.list || []).length !== 0;
  const isAllTagSelected = activeTags[0] === CONTROL_TAGS.ALL;

  /*
  Map all needed props for options
  These are fed to useControlOption hook as well
*/
  const options = useMemo(
    () =>
      list.reduce<DefaultOptionProps[]>((result, option) => {
        const { tags: optionTags = [] } = option;
        const isSelected = option.name === value;

        const isOptionFiltered =
          !controlHasTags ||
          isAllTagSelected ||
          activeTags.some(tag => optionTags.includes(tag)) ||
          (isSelected && control.type === CONTROL_TYPES.DROPDOWN);

        if (isOptionFiltered) {
          result.push({
            control,
            option,
            onOptionSelect,
            isSelected
          });
        }

        return result;
      }, []),
    [control, onOptionSelect, activeTags, list, controlHasTags, isAllTagSelected, value]
  );

  const selectedIndex = options.findIndex(({ isSelected }) => isSelected);
  const selectedOption = options[selectedIndex] && options[selectedIndex].option;

  return {
    options,
    selectedIndex,
    selectedOption
  };
};

export default useControl;
