import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { seedSelectors } from '../../../modules/seed';
import { MenuItem, menuStyle } from '../../Menu';
import { useTranslate } from '../../../utility/hooks';
import { uiActions } from '../../../modules/ui';

const MenuDeprecationNotification = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const isDeprecated = useSelector(seedSelectors.selectIsSeedDeprecated);
  const handleOpen = useCallback(() => {
    dispatch(uiActions.toggleDeprecationNotification(true));
  }, [dispatch]);

  return isDeprecated ? (
    <MenuItem onClick={handleOpen} name={translate('Update to new version')} type="deprecated">
      <div className={menuStyle('deprecation-indicator')} />
    </MenuItem>
  ) : null;
};

export default MenuDeprecationNotification;
