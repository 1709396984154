import { useSelector } from 'react-redux';
import { ControlNavigationPhaseType } from 'types';
import { useSwitch, useTranslate } from '../../../utility/hooks';
import { Button } from '../../Atoms';
import { uiSelectors } from '../../../modules/ui';
import { commonSelectors } from '../../../modules/model';
import HomeButton from '../NavigationButtons/HomeButton';
import TabNavigationContent from './TabNavigationContent';
import tabNavigationStyle from './tabNavigationStyle';
import './TabNavigation.scss';

const TabNavigation = ({ childNavigation = false }) => {
  const { toggle, isOpen, close } = useSwitch();
  const translate = useTranslate();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: selector not converted to TS yet
  const activeTab = useSelector(commonSelectors.getActiveTab) as ControlNavigationPhaseType;

  const isSpecialTab: boolean = useSelector(uiSelectors.selectIsSpecialTab) as boolean;

  let title = '';

  if (activeTab)
    title = isSpecialTab ? translate(activeTab.displayName) : `${activeTab.index}. ${translate(activeTab.displayName)}`;

  const isChildModeEnabled = useSelector(commonSelectors.getIsChildModeEnabled);
  const disabled = isChildModeEnabled && !childNavigation;

  return (
    <>
      <div className={tabNavigationStyle('button', { disabled, child: childNavigation })}>
        {childNavigation ? null : <HomeButton />}
        <Button
          mix={tabNavigationStyle('current', { mode: childNavigation ? 'child' : 'default' })}
          onClick={toggle}
          aria-label={translate('Categories')}
          color="text"
          size="md"
          data-balloon-pos="down"
          label={title}
          disabled={disabled}
        />
      </div>
      <TabNavigationContent onClose={close} isOpen={isOpen} />
    </>
  );
};

export default TabNavigation;
