import React from 'react';
import MenuContent from '../../Menu/MenuContent';
import ContactForm from './ContactForm';

const ContactMenu = () => (
  <MenuContent>
    <ContactForm />
  </MenuContent>
);

export default ContactMenu;
