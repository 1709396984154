import { Vector3, Triangle, MeshBasicMaterial } from 'three';
import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { hasMaterialShadow } from '../../utility/createMaterials';
import makeBufferGeometry from '../../utility/makeBufferGeometry';
import FlippableInstancedMesh from '../../utility/MirrorableInstancedMesh';
import meshStore from './MeshStore';

// reuse
const reusableTrianglePointA = new Vector3();
const reusableTrianglePointB = new Vector3();
const reusableTrianglePointC = new Vector3();
const reusableTriangle = new Triangle(reusableTrianglePointA, reusableTrianglePointB, reusableTrianglePointC);

export const calculateArea = (vertices = [], faceIndices = []) => {
  let area = 0;

  for (let faceIndex = 0; faceIndex < faceIndices.length; faceIndex += 3) {
    const vertex1 = faceIndices[faceIndex] * 3;
    const vertex2 = faceIndices[faceIndex + 1] * 3;
    const vertex3 = faceIndices[faceIndex + 2] * 3;

    reusableTrianglePointA.x = vertices[vertex1];
    reusableTrianglePointA.y = vertices[vertex1 + 1];
    reusableTrianglePointA.z = vertices[vertex1 + 2];
    reusableTrianglePointB.x = vertices[vertex2];
    reusableTrianglePointB.y = vertices[vertex2 + 1];
    reusableTrianglePointB.z = vertices[vertex2 + 2];
    reusableTrianglePointC.x = vertices[vertex3];
    reusableTrianglePointC.y = vertices[vertex3 + 1];
    reusableTrianglePointC.z = vertices[vertex3 + 2];

    const added = reusableTriangle.getArea();

    area += added;
  }

  return Math.round(area);
};

/*
  This function groups all objects within part geometry which have the same material and makes a unified mesh out of it

  1. map all materials
  2. for each mesh, fill out meshes object
  3. For each mesh vertices etc. make a mesh
  4. Store meshes in object grouped by id.
*/

const DEFAULT_MATERIAL_NAME = 'default';

export const mergePartData = (partObject = []) => {
  const areas = {
    byLayers: {},
    byMaterials: {}
  }; // areas is object with keys being layer names.
  // All areas for each layer for this part are added together in loop

  const meshDataByMaterial = partObject.reduce((result, meshData) => {
    const {
      Type: type,
      Object: meshObject,
      Material: materialName = DEFAULT_MATERIAL_NAME,
      Name: layerName
    } = meshData;

    if (type === 'Geometry' && meshObject?.data) {
      const { vertices, faces } = meshObject.data;

      // eslint-disable-next-line no-param-reassign
      result[materialName] = result[materialName] || [];
      result[materialName].push(meshObject.data);

      const area = calculateArea(vertices, faces);

      areas.byLayers[layerName] = areas.byLayers[layerName] || 0;
      areas.byLayers[layerName] += area;
      areas.byMaterials[materialName] = areas.byMaterials[materialName] || 0;
      areas.byMaterials[materialName] += area;
    }

    return result;
  }, {});

  return { areas, meshes: meshDataByMaterial };
};

const partAreas = {};

const basicMaterial = new MeshBasicMaterial();

const setGeometries = (parts, model, settingsMaxInstanceCount = 128) => {
  parts.forEach(part => {
    const maxInstanceCount = part.maxInstanceCount || settingsMaxInstanceCount;
    const { meshes, areas } = mergePartData(part.Object);
    const threeMeshes = Object.entries(meshes).map(([materialName, meshesAttributes], index) => {
      const bufferGeometries = meshesAttributes.map(({ faces, vertices, normals, vertexUVs, vertexUVs2 }) => {
        // eslint-disable-next-line no-param-reassign
        vertexUVs2 = vertexUVs2 || vertexUVs;
        const expectedVertexUvsLength = (vertices.length / 3) * 2;

        // fill veretexuvs with 0-s so we have at least some data - needed for exporters
        if (vertexUVs.length !== expectedVertexUvsLength) {
          const currentLength = vertexUVs.length;

          // eslint-disable-next-line no-param-reassign
          vertexUVs.length = expectedVertexUvsLength;
          vertexUVs.fill(0, currentLength, expectedVertexUvsLength);
        }

        if (vertexUVs2.length !== expectedVertexUvsLength) {
          const currentLength = vertexUVs2.length;

          // eslint-disable-next-line no-param-reassign
          vertexUVs2.length = expectedVertexUvsLength;
          vertexUVs2.fill(0, currentLength, expectedVertexUvsLength);
        }

        return makeBufferGeometry(faces, vertices, normals, vertexUVs, vertexUVs2);
      });
      const mergedGeometry = mergeBufferGeometries(bufferGeometries);

      const mesh = new FlippableInstancedMesh(mergedGeometry, basicMaterial, maxInstanceCount);

      // disable frustum culling since it's probable that the object is sent to gpu anyway
      mesh.frustumCulled = false;
      mesh.matrixAutoUpdate = false;
      mesh.name = part._id;
      mesh.receiveShadow = true;

      mesh.count = 0;
      mesh.visible = false;

      mesh.userData.materialName = materialName;
      mesh.userData.meshIndex = index;
      mesh.userData.excludeFromSnapshots = part.excludeFromSnapshots;
      model.add(mesh);

      return mesh;
    });

    if (threeMeshes.length) {
      meshStore.initPartMeshes(part._id, threeMeshes);

      // partInteractionMeshes[part._id] = interactionMesh;
      // partMeshes[part._id] = threeMeshes;
      partAreas[part._id] = areas;
    }
  });
};

// grouped meshes
const defaultPartArea = {
  byLayers: {},
  byMaterials: {}
};
const getPartAreas = id => partAreas[id] || defaultPartArea;

/**
 * Take threejs representation of GLTF and turn it into parts with ReferenceName, Option, _id and seed fields
 * _id is taken from mesh uuid
 * ReferenceName is taken from mesh name
 * Every mesh in converted into InstancedMesh
 * returns materials and partIds
 */

export const setGLTFGeometries = (asset, model, maxInstanceCount = 128, seedId = '') => {
  const parts = [];

  const memoizedMaterials = {};

  // asset.scene is a group
  // each child will be a part
  asset.scene.children.forEach(child => {
    // traverse each child to get geometry and materials for that child
    // flatten list of children to one array
    const meshes = [];

    child.updateMatrixWorld();

    child.traverse(item => {
      const geometry = item?.geometry?.clone();

      if (!geometry) return;

      // move to correct position

      geometry.applyMatrix4(item.matrixWorld); // update position
      geometry.scale(1000, 1000, 1000); // scale to mm

      const material = memoizedMaterials[item.material.name] || item.material.clone();

      material.userData.castShadow = hasMaterialShadow(material);

      memoizedMaterials[material.name] = material;

      const mesh = new FlippableInstancedMesh(geometry, material, maxInstanceCount);

      mesh.frustumCulled = false;
      mesh.matrixAutoUpdate = false;
      mesh.name = child.uuid;

      // add materialName to userdata so that material changes work
      mesh.userData.materialName = material.name;

      // if shadows are baked, sunlight must be turned off so there are no
      // shadow emitters
      // if shadows are not baked, they must receive shadows
      mesh.receiveShadow = true;
      mesh.castShadow = true;

      mesh.count = 0;

      model.add(mesh);

      meshes.push(mesh);
    });

    parts.push({ _id: child.uuid, ReferenceName: child.name, Option: 'Default', seed: seedId });

    meshStore.initPartMeshes(child.uuid, meshes);
  });

  return { materials: Object.values(memoizedMaterials), parts };
};

export { setGeometries, getPartAreas };
