import { SyntheticEvent, useCallback, useEffect } from 'react';
import b from 'b_';

import './Slider.scss';

const slider = b.with('slider');

interface SliderProps {
  name?: string;
  onChange: (v: number, id: string) => void;
  min?: number;
  max?: number;
  value?: number;
  step?: number;
}

const Slider = ({ name = '', onChange, min = 1, max = 100, value, step = 1 }: SliderProps) => {
  const handleChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      onChange(Number(e.currentTarget.value), e.currentTarget.id);
    },
    [onChange]
  );

  useEffect(() => {
    if (value === undefined || value < min) {
      onChange(min, name);

      return;
    }

    if (value > max) {
      onChange(max, name);
    }
  }, [max, min, name, onChange, value]);

  return (
    <div className={slider()}>
      <input
        className={slider('range')}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        id={name}
      />
    </div>
  );
};

export default Slider;
