import NOTIFICATION_MESSAGES from '../../utility/notificationMessages';

export const SAVE_SNAPSHOT = 'SAVE_SNAPSHOT';
export const SAVE_SNAPSHOT__SUCCESS = 'SAVE_SNAPSHOT__SUCCESS';
export const SAVE_SNAPSHOT__FAILURE = 'SAVE_SNAPSHOT__FAILURE';

export const CREATE_LEAD = 'CREATE_LEAD';
export const CREATE_LEAD__SUCCESS = 'CREATE_LEAD__SUCCESS';
export const CREATE_LEAD__FAILURE = 'CREATE_LEAD__FAILURE';

export const UPDATE_LEAD = 'UPDATE_LEAD';

export const CREATE_ORPHAN_LEAD = 'CREATE_ORPHAN_LEAD';
export const CREATE_ORPHAN_LEAD__SUCCESS = 'CREATE_ORPHAN_LEAD__SUCCESS';
export const CREATE_ORPHAN_LEAD__FAILURE = 'CREATE_ORPHAN_LEAD__FAILURE';

export const RESET_INSTANCES = 'RESET_INSTANCES';

export const SUBMIT_QUESTION = 'SUBMIT_QUESTION';

export const REQUEST_DOWNLOAD_PDF = 'REQUEST_DOWNLOAD_PDF';

export const SEND_INSTANCE_TO_EMAIL = 'SEND_INSTANCE_TO_EMAIL';
export const SEND_INSTANCE_TO_EMAIL__REQUEST = 'SEND_INSTANCE_TO_EMAIL__REQUEST';
export const SEND_INSTANCE_TO_EMAIL__SUCCESS = 'SEND_INSTANCE_TO_EMAIL__SUCCESS';
export const SEND_INSTANCE_TO_EMAIL__FAILURE = 'SEND_INSTANCE_TO_EMAIL__FAILURE';

export const SAVE_PDF_GALLERY = 'SAVE_PDF_GALLERY';
export const SAVE_PDF_GALLERY__SUCCESS = 'SAVE_PDF_GALLERY__SUCCESS';
export const SAVE_PDF_GALLERY__FAILURE = 'SAVE_PDF_GALLERY__FAILURE';

export const SET_CURRENT_INSTANCE = 'SET_CURRENT_INSTANCE';

export const FETCH_CHILD_INSTANCES = 'FETCH_CHILD_INSTANCES';
export const FETCH_CHILD_INSTANCES__SUCCESS = 'FETCH_CHILD_INSTANCES__SUCCESS';
export const FETCH_CHILD_INSTANCES__FAILURE = 'FETCH_CHILD_INSTANCES__FAILURE';

export const saveSnapshot = () => ({
  type: SAVE_SNAPSHOT
});

export const saveSnapshotSuccess = instance => ({
  type: SAVE_SNAPSHOT__SUCCESS,
  payload: { instance }
});

export const saveSnapshotFailure = error => ({
  type: SAVE_SNAPSHOT__FAILURE,
  error
});

export const createLead = lead => ({
  type: CREATE_LEAD,
  payload: { lead }
});

export const createLeadSuccess = instance => ({
  type: CREATE_LEAD__SUCCESS,
  payload: { instance },
  meta: {
    notification: true,
    message: NOTIFICATION_MESSAGES.CONTACT_MESSAGE_SUCCESSFUL
  }
});

export const createLeadFailure = error => ({
  type: CREATE_LEAD__FAILURE,
  meta: {
    notification: true,
    message: NOTIFICATION_MESSAGES.CONTACT_MESSAGE_FAILED
  },
  error
});

export const updateLead = lead => ({
  type: UPDATE_LEAD,
  payload: { lead }
});

export const createOrphanLead = lead => ({
  type: CREATE_ORPHAN_LEAD,
  payload: { lead }
});

export const createOrphanLeadSuccess = instance => ({
  type: CREATE_ORPHAN_LEAD__SUCCESS,
  payload: { instance },
  meta: {
    notification: true,
    message: NOTIFICATION_MESSAGES.CONTACT_MESSAGE_SUCCESSFUL
  }
});

export const createOrphanLeadFailure = error => ({
  type: CREATE_ORPHAN_LEAD__FAILURE,
  meta: {
    notification: true,
    message: NOTIFICATION_MESSAGES.CONTACT_MESSAGE_FAILED
  },
  error
});

export const resetInstances = () => ({
  type: RESET_INSTANCES
});

export const submitQuestion = (formState, consent) => ({
  type: SUBMIT_QUESTION,
  payload: { formState, consent }
});

export const requestDownloadPdf = requireLogin => ({
  type: REQUEST_DOWNLOAD_PDF,
  payload: { requireLogin }
});

export const sendInstanceToEmail = email => ({
  type: SEND_INSTANCE_TO_EMAIL,
  payload: { email }
});

export const sendInstanceToEmailRequest = () => ({
  type: SEND_INSTANCE_TO_EMAIL__REQUEST
});

export const sendInstanceToEmailSuccess = () => ({
  type: SEND_INSTANCE_TO_EMAIL__SUCCESS,
  meta: {
    notification: true,
    message: NOTIFICATION_MESSAGES.EMAIL_SENT_SUCCESSFUL
  }
});

export const sendInstanceToEmailFailure = error => ({
  type: SEND_INSTANCE_TO_EMAIL__FAILURE,
  error
});

export const savePdfGallery = () => ({
  type: SAVE_PDF_GALLERY
});

export const savePdfGallerySuccess = (gallery = []) => ({
  type: SAVE_PDF_GALLERY__SUCCESS,
  payload: { gallery }
});

export const savePdfGalleryFailure = error => ({
  type: SAVE_PDF_GALLERY__FAILURE,
  error
});

export const setCurrentInstance = instanceId => ({
  type: SET_CURRENT_INSTANCE,
  payload: { instanceId }
});

export const fetchChildInstances = ids => ({
  type: FETCH_CHILD_INSTANCES,
  payload: { ids }
});

export const fetchChildInstancesSuccess = instances => ({
  type: FETCH_CHILD_INSTANCES__SUCCESS,
  payload: { instances }
});

export const fetchChildInstancesFailure = error => ({
  type: FETCH_CHILD_INSTANCES__FAILURE,
  meta: {
    notification: true,
    message: NOTIFICATION_MESSAGES.FAILED_GET_CHILD_INSTANCES
  },
  error
});
