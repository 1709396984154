import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { uiSelectors } from '../../modules/ui';

import './UnsavedIndicator.scss';

const unsavedIndicatorStyle = b.with('unsaved-indicator');

const UnsavedIndicator = ({ border }) => {
  const visible = useSelector(uiSelectors.getIsUnsavedIndicatorVisible);

  return visible ? <div className={unsavedIndicatorStyle({ border })} /> : null;
};

UnsavedIndicator.defaultProps = {
  border: true
};

UnsavedIndicator.propTypes = {
  border: PropTypes.bool
};

export default UnsavedIndicator;
