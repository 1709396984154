import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TYPE_CONTROL_OPTION } from '../../../utility/propTypes';
import { selectOptionImageSelector } from '../../../modules/model/controlSelectors';
import { useTranslate } from '../../../utility/hooks';
import { optionStyle } from '../Control/style';

const getClassName = option =>
  optionStyle('material', { disabled: option.locked || option.fixed, inactive: option.inactive });

const OptionImage = ({ option }) => {
  const imageSelector = useSelector(selectOptionImageSelector);
  const image = useMemo(() => imageSelector(option), [imageSelector, option]);
  const translate = useTranslate();

  if (!image) return null;

  const title = translate(option.displayName);

  return image.url ? (
    <img crossOrigin="anonymous" className={getClassName(option)} src={image.url} alt={title} title={title} />
  ) : (
    <div className={getClassName(option)} style={{ backgroundColor: image.color, padding: '50% 0' }} title={title} />
  );
};

OptionImage.propTypes = {
  option: TYPE_CONTROL_OPTION.isRequired
};

export default OptionImage;
