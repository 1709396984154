import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import meshStore from '../../../modules/geometries/MeshStore';
import { texturesSelectors } from '../../../modules/textures';

const useHoverMaterials = parts => {
  const texturesDone = useSelector(texturesSelectors.getIsTexturesReceived);

  useEffect(() => {
    if (texturesDone) {
      parts.forEach(part => {
        const hoverMaterial = part.Config?.interaction?.hoverMaterial;

        meshStore.setPartHoverMaterial(part._id, part.key, hoverMaterial);
      });
    }
  }, [parts, texturesDone]);
};

export default useHoverMaterials;
