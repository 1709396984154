import { RepeatWrapping, sRGBEncoding, Cache, EquirectangularReflectionMapping } from 'three';
import getThreeSetup from '../../components/Viewer/getThreeSetup';

const { renderer } = getThreeSetup();
const maxAnisotropy = renderer.capabilities.getMaxAnisotropy();
const ANISOTROPY_LIMIT = 4;

Cache.enabled = true;

export const TEXTURE_TYPE = {
  DIFFUSE: 'Diffuse',
  BUMP: 'Bump',
  TRANSPARENCY: 'Transparency',
  ENVIRONMENT: 'Environment',
  ROUGHNESS: 'Roughness',
  NORMAL: 'Normal',
  METALNESS: 'Metalness',
  AMBIENT_OCCLUSION: 'AmbientOcclusion',
  LIGHT: 'Light',
  EMISSIVE: 'Emissive',
  DISPLACEMENT: 'Displacement'
};

export const TEXTURE_TYPES = Object.values(TEXTURE_TYPE);

/*
  Color corrected texture types are those which contain color information and
  will need to take gamma into account.

  Other texture types contain linearly defined data such as normal direction or
  how rough the surface is and should not take gamma correction into account.
*/
export const COLOR_CORRECTED_TEXTURE_TYPES = {
  [TEXTURE_TYPE.DIFFUSE]: true,
  [TEXTURE_TYPE.ENVIRONMENT]: true,
  [TEXTURE_TYPE.EMISSIVE]: true
};

class TextureStore {
  store = {};

  registry = {};

  constructor() {
    TEXTURE_TYPES.reduce((result, type) => {
      result[type] = {}; // eslint-disable-line no-param-reassign

      return result;
    }, this.registry);
  }

  serializeId = (fileName, type, matrix, flipY = false) => {
    let textureType = 'linear';

    if (type === TEXTURE_TYPE.ENVIRONMENT) {
      textureType = 'environment';
    } else if (COLOR_CORRECTED_TEXTURE_TYPES[type]) {
      textureType = 'sRGB';
    }

    return `${fileName}|${textureType}|${JSON.stringify(matrix)}|${flipY}`;
  };

  // retrieveTexture and storeTexture are for reusing textures at textureSaga

  /** Returns texture or textureCube for env maps.
   * Applies encoding, wrapping, anisotropy etc.
   */
  static applyTextureParameters(texture, textureName, type, matrix, flipY) {
    /* eslint-disable no-param-reassign */
    texture.name = textureName;

    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.matrixAutoUpdate = false;
    texture.anisotropy = maxAnisotropy <= ANISOTROPY_LIMIT ? maxAnisotropy : ANISOTROPY_LIMIT;

    if (COLOR_CORRECTED_TEXTURE_TYPES[type]) {
      texture.encoding = sRGBEncoding;
    }

    texture.flipY = flipY;

    if (matrix) {
      texture.matrix.copy(matrix);
    }

    /* eslint-enable no-param-reassign */

    if (type === TEXTURE_TYPE.ENVIRONMENT) {
      // eslint-disable-next-line no-param-reassign
      texture.mapping = EquirectangularReflectionMapping;
    }

    return texture;
  }

  /** Store and register texture  */
  storeTexture = (texture, textureId, textureName, type, materialName, matrix, flipY) => {
    const result = TextureStore.applyTextureParameters(texture, textureName, type, matrix, flipY);

    renderer.initTexture(result);

    this.store[textureId] = result;

    this.setTexture(result, type, materialName);

    return result;
  };

  /** Try fetching texture with given parameters */
  retrieveTexture = textureId => {
    return this.store[textureId];
  };

  // setTexture and getTexture are for materials
  /** Registers texture to its type/materialName registry */
  setTexture = (texture, type = TEXTURE_TYPE.DIFFUSE, materialName) => {
    if (this.registry[type]) this.registry[type][materialName] = texture;
  };

  /** Fetch a texture for given type and materialName */
  getTexture = (type = TEXTURE_TYPE.DIFFUSE, materialName) => {
    return this.registry[type]?.[materialName] || null;
  };
}

const textureStore = new TextureStore();

export default textureStore;
