import { CommentType } from 'types';
import b from 'b_';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '../../utility/hooks';
import Icon from '../Icon';
import { Button } from '../Atoms';
import { dialogActions } from '../../modules/dialog';

interface CommentBoxProps {
  comment: CommentType;
  index: number;
}

const commentBox = b.with('comment-box');
const CommentBox = ({ comment, index }: CommentBoxProps) => {
  const translate = useTranslate();

  const { createdAt, content, position } = comment;

  const date = createdAt ? new Date(createdAt).toLocaleString() : 'Unknown';

  const dispatch = useDispatch();
  const handleEditComment = useCallback(() => {
    dispatch(dialogActions.openEditCommentDialog(comment._id));
  }, [comment._id, dispatch]);

  return (
    <Button block color="text-light" mix={commentBox()} onClick={handleEditComment}>
      <div className={commentBox('content')}>
        <p className={commentBox('title')}>
          {index}. {content}
        </p>
        <p className={commentBox('subtitle')}>
          {translate('Created')}: {date}
        </p>
      </div>
      <div className={commentBox('location', { enabled: !!position })}>
        <Icon type="map-marker" />
      </div>
    </Button>
  );
};

export default CommentBox;
