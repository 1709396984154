import { Route, Switch } from 'react-router-dom';
import { NuIndex, CommonIndex } from './components/indexes';
import Preview from './components/preview/Preview';
import PageWrapper from './components/PageWrapper';
import { NotFoundPage } from './components/ServicePage';
import PATH from './utility/paths';

const Routes = () => {
  return (
    <Switch>
      <Route exact path={PATH.DEFAULT} component={CommonIndex} />
      <Route exact path={PATH.DEV_NULIVING} component={NuIndex} />

      <Route path={PATH.INSTANCE} component={PageWrapper} />
      <Route path={PATH.PROJECT} component={PageWrapper} />
      <Route path={PATH.SEED} component={PageWrapper} />

      <Route path={PATH.DEV} exact component={Preview} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
