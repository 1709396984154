import { all, takeEvery, takeLatest, select, call, put, delay } from 'redux-saga/effects';
import Heap from '../../utility/heap';
import { cameraActions, cameraSelectors } from '../../store/camera';
import renderSnapshot from '../../components/SceneGenerator/renderSnapshot';
import { selectModelUnits } from '../settings/modelSettingsSelectors';
import { priceSelectors } from '../model';
import flattenControlKeys from '../../utility/flattenControlKeys';
import { FETCH_PARTS__FAILURE } from '../parts/partsActions';
import { FETCH_GEOMETRIES__FAILURE } from '../geometries/geometriesActions';
import {
  selectMergedVisibleControls,
  selectChildTabView,
  getSelectedControlGroupName
} from '../model/controlSelectors';
import * as uiSelectors from './uiSelectors';
import {
  setRenderingSummaryGallery,
  setSummaryGallery,
  setTabAndControlGroup,
  hideChildControls,
  showChildControls,
  RENDER_SUMMARY_GALLERY,
  SELECT_TAB_AND_CONTROL_GROUP,
  SET_TAB_AND_CONTROL_GROUP,
  SELECT_CONTROL_BY_MODEL_INTERACTION,
  selectTabAndControlGroup,
  showErrorPage
} from './uiActions';
import { selectLocalSummaryGallery, selectSelectedTabName, getTabs } from './uiSelectors';
import { selectControlsParentsMap } from './summarySelectors';
import { selectSelectedParentControls } from './uiStateSelectors';

function doSelectTabSaga(action) {
  const { payload } = action;

  if (payload.tabName !== undefined) {
    Heap.track('SELECT_TAB', { tab: payload.tabName });
  }
}

function* watchSelectTabSaga() {
  yield takeEvery(SET_TAB_AND_CONTROL_GROUP, doSelectTabSaga);
}

function* doGenerateSummaryGallerySaga(action) {
  const { payload } = action;

  const views = yield select(cameraSelectors.getSummaryGalleryViews);
  const modelUnits = yield select(selectModelUnits);

  if (!payload.isSummaryTab) {
    yield put(setRenderingSummaryGallery(views.length !== 0));

    return;
  }

  if (views.length) {
    yield put(setRenderingSummaryGallery(true));

    yield delay(500);

    const result = yield all([...views.map(view => call(renderSnapshot, view, modelUnits, true))]);
    const urls = [];

    result.forEach(({ blob }) => {
      if (blob) {
        urls.push(URL.createObjectURL(blob));
      }
    });

    const localGallery = yield select(selectLocalSummaryGallery);

    yield put(setSummaryGallery(urls));

    localGallery.forEach(url => {
      URL.revokeObjectURL(url);
    });
  } else {
    yield put(setRenderingSummaryGallery(false));
  }
}

function* watchGenerateSummaryGallerySaga() {
  yield takeLatest(RENDER_SUMMARY_GALLERY, doGenerateSummaryGallerySaga);
}

function getTab(action, controlsMap, currentTabName, tabs) {
  const { tabName = currentTabName } = action.payload;
  let { controlGroupName } = action.payload;

  const selectedTab = tabs.find(tab => tab.name === tabName) || {};
  const { controls = [] } = selectedTab;

  if (!controlGroupName) {
    const [firstControl] = controls.filter(({ name }) => controlsMap[name] && !controlsMap[name].disabled);

    controlGroupName = firstControl?.name || '';
  }

  const isSameTab = currentTabName === tabName;

  return { selectedTab, tabName, isSameTab };
}

function* doSelectTabAndControlGroupSaga(action) {
  const { controlGroupName } = action.payload;
  const controlsMap = yield select(priceSelectors.selectPricedControlsMap);

  const currentTabName = yield select(selectSelectedTabName);
  const tabs = yield select(getTabs);

  const { selectedTab, tabName, isSameTab } = getTab(action, controlsMap, currentTabName, tabs);

  const currentControlGroupName = yield select(getSelectedControlGroupName);
  const previousParentControl = yield select(uiSelectors.selectPreviousParentControl);
  const mergedVisibleControls = yield select(selectMergedVisibleControls);
  const selectedChildTabView = yield select(selectChildTabView);

  const parentControls = yield select(selectSelectedParentControls);

  /** Last item in parents array is current parent control */
  const [currentParentControl] = parentControls.slice(-1);

  const { view: selectedTabView } = selectedTab || {};

  const newControl = mergedVisibleControls.find(({ name }) => name === controlGroupName);
  const newControlView = newControl?.view;

  const currentControlView = controlsMap[currentControlGroupName]?.view;
  const isSameParent = currentParentControl === previousParentControl;

  let view = newControlView || selectedChildTabView || selectedTabView;

  if (isSameTab && isSameParent && !currentControlView && !newControlView) {
    view = undefined;
  }

  // update tab and control
  yield put(setTabAndControlGroup(isSameTab ? undefined : tabName, controlGroupName));

  // update camera view
  yield put(
    cameraActions.updateInitialCameraView({ view, isNewControl: currentControlGroupName !== controlGroupName })
  );

  const selectedParentControls = yield select(uiSelectors.selectSelectedParentControls);

  // https://creatomus.atlassian.net/browse/CRV-1735 Select control on the same tab when the child tab is opened
  if (action.payload.hideChildControls && selectedParentControls.length !== 0) {
    // Summary, About, Review
    if (!controlGroupName) {
      yield put(hideChildControls());

      return;
    }

    const { treeName } = controlsMap[controlGroupName];
    const parentsMap = yield select(selectControlsParentsMap);

    if (parentsMap[treeName] && parentsMap[treeName].parents.length === 0) {
      yield put(hideChildControls());
    }
  }
}

function* watchSelectTabAndControlGroupSaga() {
  yield takeLatest(SELECT_TAB_AND_CONTROL_GROUP, doSelectTabAndControlGroupSaga);
}

function* doSelectControlByModelInteractionSaga(action) {
  const { controlName, local = false, key, historyPush, makeTabLink } = action.payload;

  const map = yield select(selectControlsParentsMap);
  const currentTabName = yield select(selectSelectedTabName);

  let result;

  if (local) {
    const keysMap = yield select(priceSelectors.selectPricedControlsKeyMap);

    // merges together whole tree which matches up and down
    // identical function in priceSelectors
    const keys = flattenControlKeys(keysMap, key);

    const controlTreeName = keys[controlName];

    result = map[controlTreeName];
  } else {
    result = Object.values(map).find(item => item.controlName === controlName);
  }

  if (!result) return;

  const { tabName, parents } = result;

  const isChildTab = Array.isArray(parents) && parents.length > 0;

  if (isChildTab) {
    yield put(showChildControls(parents, true));
  }

  if (tabName !== currentTabName) {
    historyPush(makeTabLink(tabName), { controlName, hideChildControls: !isChildTab });
  } else {
    yield put(selectTabAndControlGroup(undefined, controlName, !isChildTab));
  }
}

function* watchSelectControlByModelInteractionSaga() {
  yield takeLatest(SELECT_CONTROL_BY_MODEL_INTERACTION, doSelectControlByModelInteractionSaga);
}

function* doShowPartsError() {
  yield put(showErrorPage('Parts were not loaded correctly'));
}

function* watchPartsLoadFail() {
  yield takeEvery(FETCH_PARTS__FAILURE, doShowPartsError);
}

function* doShowGeometryError() {
  yield put(showErrorPage('Geometries were not loaded correctly'));
}

function* watchGeometriesLoadFail() {
  yield takeEvery(FETCH_GEOMETRIES__FAILURE, doShowGeometryError);
}

export default function* moduleSaga() {
  yield all([
    watchSelectTabSaga(),
    watchGenerateSummaryGallerySaga(),
    watchSelectTabAndControlGroupSaga(),
    watchSelectControlByModelInteractionSaga(),
    watchPartsLoadFail(),
    watchGeometriesLoadFail()
  ]);
}
