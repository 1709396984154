export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';
export const VIEWER_INTERACTION = 'VIEWER_INTERACTION';
export const SET_MOBILE_NAVIGATION = 'SET_MOBILE_NAVIGATION';
export const SET_MOBILE_VIEWPORT = 'SET_MOBILE_VIEWPORT';

export const HIDE_CONTROLS_PANEL = 'HIDE_CONTROLS_PANEL';
export const SHOW_CONTROLS_PANEL = 'SHOW_CONTROLS_PANEL';

export const HIDE_MENU = 'HIDE_MENU';
export const SHOW_MENU = 'SHOW_MENU';

export const SHOW_NOT_FOUND_PAGE = 'SHOW_NOT_FOUND_PAGE';
export const RESET_NEW_INSTANCE_ID = 'RESET_NEW_INSTANCE_ID';

export const SET_SUMMARY_GALLERY = 'SET_SUMMARY_GALLERY';
export const RENDER_SUMMARY_GALLERY = 'RENDER_SUMMARY_GALLERY';
export const SET_RENDERING_SUMMARY_GALLERY = 'SET_RENDERING_SUMMARY_GALLERY';

export const SELECT_CONTROL_BY_MODEL_INTERACTION = 'SELECT_CONTROL_BY_MODEL_INTERACTION';

export const SELECT_TAB_AND_CONTROL_GROUP = 'SELECT_TAB_AND_CONTROL_GROUP';

export const SET_TAB_AND_CONTROL_GROUP = 'SET_TAB_AND_CONTROL_GROUP';

export const SHOW_CHILD_CONTROLS = 'SHOW_CHILD_CONTROLS';
export const HIDE_CHILD_CONTROLS = 'HIDE_CHILD_CONTROLS';

export const SET_UTM_PARAMETERS = 'SET_UTM_PARAMETERS';

export const SET_UI_MODE = 'SET_UI_MODE';

export const SET_FULLSCREEN = 'SET_FULLSCREEN';

export const SET_PART_INTERACTION_ENABLED = 'SET_PART_INTERACTION_ENABLED';

export const TOGGLE_DEPRECATION_NOTIFICATION = 'TOGGLE_DEPRECATION_NOTIFICATION';

export const SHOW_ERROR_PAGE = 'SHOW_ERROR_PAGE';

export const SET_TEMPORARY_COMMENT_PIN = 'SET_TEMPORARY_COMMENT_PIN';
export const RESET_TEMPORARY_COMMENT_PIN = 'RESET_TEMPORARY_COMMENT_PIN';

export const hideControlsPanel = () => ({
  type: HIDE_CONTROLS_PANEL
});

export const showControlsPanel = () => ({
  type: SHOW_CONTROLS_PANEL
});

export const hideMenu = () => ({
  type: HIDE_MENU
});

export const showMenu = () => ({
  type: SHOW_MENU
});

export const setViewerInteracted = () => ({
  type: VIEWER_INTERACTION
});

export const selectLanguage = language => ({
  type: SELECT_LANGUAGE,
  payload: {
    language
  }
});

export const setMobileNavigation = isMobileNavigation => ({
  type: SET_MOBILE_NAVIGATION,
  payload: { isMobileNavigation }
});

export const setMobileViewport = isMobileViewport => ({
  type: SET_MOBILE_VIEWPORT,
  payload: { isMobileViewport }
});

export const showNotFoundPage = () => ({
  type: SHOW_NOT_FOUND_PAGE
});

export const showErrorPage = message => ({
  type: SHOW_ERROR_PAGE,
  payload: { message }
});

export const resetNewInstanceId = () => ({
  type: RESET_NEW_INSTANCE_ID
});

export const setSummaryGallery = urls => ({
  type: SET_SUMMARY_GALLERY,
  payload: { urls }
});

export const renderSummaryGallery = isSummaryTab => ({
  type: RENDER_SUMMARY_GALLERY,
  payload: { isSummaryTab }
});

export const setRenderingSummaryGallery = active => ({
  type: SET_RENDERING_SUMMARY_GALLERY,
  payload: { active }
});

export const selectTabAndControlGroup = (tabName, controlGroupName = '', hideChildControls = true) => ({
  type: SELECT_TAB_AND_CONTROL_GROUP,
  payload: { tabName, controlGroupName, hideChildControls }
});

export const setTabAndControlGroup = (tabName, controlGroupName) => ({
  type: SET_TAB_AND_CONTROL_GROUP,
  payload: { tabName, controlGroupName }
});

export const showChildControls = (parentControlTreeNames, replace = false) => ({
  type: SHOW_CHILD_CONTROLS,
  payload: { parentControlTreeNames, replace }
});

export const hideChildControls = (parentControlTreeNames = []) => ({
  type: HIDE_CHILD_CONTROLS,
  payload: { parentControlTreeNames }
});

export const setUtmParameters = utmParameters => ({
  type: SET_UTM_PARAMETERS,
  payload: { utmParameters }
});

export const setUiMode = uiMode => ({
  type: SET_UI_MODE,
  payload: { uiMode }
});

export const setFullscreen = fullscreen => ({
  type: SET_FULLSCREEN,
  payload: { fullscreen }
});

export const setPartInteractionEnabled = enabled => ({ type: SET_PART_INTERACTION_ENABLED, payload: { enabled } });

export const toggleDeprecationNotification = visible => ({
  type: TOGGLE_DEPRECATION_NOTIFICATION,
  payload: { visible }
});

export const selectControlByModelInteraction = (controlName, local, key, historyPush, makeTabLink) => ({
  type: SELECT_CONTROL_BY_MODEL_INTERACTION,
  payload: { controlName, local, key, historyPush, makeTabLink }
});

/**
 * position is array of [x, y, z]
 * id is optional and is used when moving existing pin
 */
export const setTemporaryCommentPin = (position, id) => ({
  type: SET_TEMPORARY_COMMENT_PIN,
  payload: { position, id }
});

export const resetTemporaryCommentPin = () => ({ type: RESET_TEMPORARY_COMMENT_PIN });
