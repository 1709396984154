export const FETCH_GEOMETRIES__REQUEST = 'FETCH_GEOMETRIES__REQUEST';
export const FETCH_GEOMETRIES__SUCCESS = 'FETCH_GEOMETRIES__SUCCESS';
export const FETCH_GEOMETRIES__FAILURE = 'FETCH_GEOMETRIES__FAILURE';

export const fetchGeometriesRequest = () => ({
  type: FETCH_GEOMETRIES__REQUEST
});

export const fetchGeometriesSuccess = partIds => ({
  type: FETCH_GEOMETRIES__SUCCESS,
  payload: { partIds }
});

export const fetchGeometriesFailure = error => ({
  type: FETCH_GEOMETRIES__FAILURE,
  error
});
