import { all, takeEvery, select, put } from 'redux-saga/effects';
import Heap from '../../utility/heap';
import { selectCurrentInstanceId } from '../instance/instanceSelectors';
import { SELECT_OPTION, setOption } from './selectedOptionsActions';

function* doSelectedOptionsSaga(action) {
  const { control, option } = action.payload;

  const instanceId = yield select(selectCurrentInstanceId);

  yield put(setOption(control.treeName, option.name, instanceId));

  Heap.track('SELECT_OPTION', { control: control.treeName, type: control.type, option: option.name });
}

function* watchSelectedOptionsSaga() {
  yield takeEvery(SELECT_OPTION, doSelectedOptionsSaga);
}

export default function* moduleSaga() {
  yield all([watchSelectedOptionsSaga()]);
}
