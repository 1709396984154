import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Vector3 } from 'three';
import { CommentType } from 'types';
import { cameraActions, cameraSelectors } from '../../../store/camera';
import { dialogActions } from '../../../modules/dialog';
import { instanceSelectors } from '../../../modules/instance';
import { resetTemporaryCommentPin, setTemporaryCommentPin } from '../../../modules/ui/uiActions';
import { userInstancesActions } from '../../../modules/userInstances';
import { useTranslate } from '../../../utility/hooks';
import { Button, Field } from '../../Atoms';
import { MenuContent } from '../../Menu';
import MenuSection from '../../Menu/MenuSection';
import getThreeSetup from '../../Viewer/getThreeSetup';
import commentStyle from './commentStyle';
import LocationMarker from './LocationMarker';

const CommentEditor = ({
  comment,
  onSetClassName
}: {
  comment: CommentType;
  onSetClassName: (className: string) => void;
}) => {
  const { camera } = getThreeSetup();
  const translate = useTranslate();

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    onSetClassName(commentStyle('menu', { 'half-height': !focused }));

    return () => {
      onSetClassName('');
    };
  }, [focused, onSetClassName]);

  const instanceId = useSelector(instanceSelectors.selectCurrentInstanceId);

  const [stateComment, setStateComment] = useState(comment);

  const [addingMode, setAddingMode] = useState(false);

  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(dialogActions.hideDialog());
  }, [dispatch]);

  // reset comment pin when unmounting
  useEffect(() => {
    return () => {
      dispatch(resetTemporaryCommentPin());
    };
  }, [dispatch]);

  const currentView = useSelector(cameraSelectors.selectCameraViewName);

  const handleAddPin = useCallback(
    (key: string, point?: Vector3) => {
      setStateComment(current => ({
        ...current,
        position: point?.toArray(),
        view: currentView,
        cameraMatrix: camera.matrix.toArray()
      }));
      dispatch(setTemporaryCommentPin(point?.toArray(), comment._id));
    },
    [camera.matrix, comment._id, currentView, dispatch]
  );

  const handleRemovePin = useCallback(() => {
    setStateComment(current => {
      const newComment = { ...current };

      delete newComment.position;

      return newComment;
    });
    dispatch(setTemporaryCommentPin(undefined, comment._id));
  }, [comment._id, dispatch]);

  const handleSetTemporaryPinVisible = useCallback(
    (visible: boolean) => {
      dispatch(setTemporaryCommentPin(visible ? comment.position : undefined, comment._id));
    },
    [comment._id, comment.position, dispatch]
  );

  const handleSetContent = useCallback((e: SyntheticEvent<HTMLTextAreaElement>) => {
    const content = e.currentTarget.value;

    setStateComment(current => ({ ...current, content }));
  }, []);

  useEffect(() => {
    // update camera view and set camera position
    dispatch(cameraActions.setView(stateComment.view));
  }, [stateComment, dispatch]);

  const handleSubmit = useCallback(() => {
    dispatch(userInstancesActions.updateComment(comment._id, stateComment, instanceId));
    onClose();
  }, [comment._id, dispatch, instanceId, onClose, stateComment]);

  const handleDeleteComment = useCallback(() => {
    dispatch(userInstancesActions.removeComment(comment._id, instanceId));
    onClose();
  }, [comment._id, dispatch, instanceId, onClose]);

  return (
    <>
      <div className={commentStyle('content')}>
        <MenuSection type="edit-comment" heading={translate('Edit comment')} stickyHeader={!focused}>
          <Field
            name="content"
            required
            type="textarea"
            autoHeight
            label={translate('Text')}
            value={stateComment.content}
            onChange={handleSetContent}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            disabled={addingMode}
          />
          <LocationMarker
            empty={!stateComment.position}
            onSetTemporaryPinVisible={handleSetTemporaryPinVisible}
            onAddPin={handleAddPin}
            onRemovePin={handleRemovePin}
            addingMode={addingMode}
            onSetAddingMode={setAddingMode}
          />
        </MenuSection>
        <MenuSection type="remove-comment">
          <Button color="error-borderless" bold onClick={handleDeleteComment} disabled={addingMode}>
            {translate('Delete comment')}
          </Button>
        </MenuSection>
      </div>
      <MenuSection type="comments-footer">
        <div className={commentStyle('footer')}>
          <Button mix={commentStyle('footer-btn')} size="lg" rounded block color="main-outline" bold onClick={onClose}>
            {translate('Cancel')}
          </Button>
          <Button
            mix={commentStyle('footer-btn')}
            disabled={addingMode}
            size="lg"
            rounded
            block
            color="main"
            bold
            onClick={handleSubmit}
          >
            {translate('Submit')}
          </Button>
        </div>
      </MenuSection>
    </>
  );
};

interface EditCommentMenuProps {
  commentId: number;
  onSetClassName: (className: string) => void;
}

const EditCommentMenu = ({ commentId, onSetClassName }: EditCommentMenuProps) => {
  const translate = useTranslate();
  const comments: CommentType[] = useSelector(instanceSelectors.selectInstanceComments);
  const comment = comments.find(({ _id }) => _id === commentId);

  return (
    <MenuContent>
      {comment ? (
        <CommentEditor onSetClassName={onSetClassName} comment={comment} />
      ) : (
        <p>{translate('No comment with such id found')}</p>
      )}
    </MenuContent>
  );
};

export default EditCommentMenu;
