import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { CSSTransition } from 'react-transition-group';
import ReactDOM from 'react-dom';
import { useOnClickOutside, useSwitch } from '../../../utility/hooks';
import Icon from '../../Icon';

import './Dropdown.scss';

const dropdownStyle = b.with('dropdown-v2');

const defaultItemRender = (item, active) => <span className={dropdownStyle('caption', { active })}>{item}</span>;
const defaultCompareFunction = (currentValue, item) => currentValue === item;

const DefaultButtonRender = ({ disabled, toggle, itemRef, itemRender, value }) => (
  <button className={dropdownStyle('button', { disabled })} onClick={toggle} type="button" disabled={disabled}>
    <div className={dropdownStyle('item', { active: true, selected: true })} ref={itemRef}>
      {itemRender(value, false, true)}
    </div>
  </button>
);

DefaultButtonRender.propTypes = {
  disabled: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  itemRef: PropTypes.shape({}).isRequired,
  itemRender: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired
};

DefaultButtonRender.defaultProps = {
  disabled: false,
  itemRender: defaultItemRender
};

const BORDER_WIDTH = 4;

const useCalculateStyles = (isOpen, itemRef, maxVisibleItems, minVisibleItems, useFixedPosition, setStyle) => {
  useEffect(() => {
    if (isOpen && itemRef.current) {
      const style = {};
      const { clientHeight } = itemRef.current;
      const { top, width, bottom, left } = itemRef.current.parentElement.getBoundingClientRect();
      const newMaxHeight = clientHeight * maxVisibleItems + BORDER_WIDTH * 2;
      const newMinHeight = clientHeight * minVisibleItems + BORDER_WIDTH * 2;
      const bottomSpace = window.innerHeight - top;
      const topSpace = bottom;
      const up = topSpace > bottomSpace;

      style.maxHeight = `${Math.min(newMaxHeight, Math.max(newMinHeight, bottomSpace, topSpace))}px`;

      if (up) {
        style.transform = `translate(0, -100%)`;
      } else {
        style.top = 0;
      }

      if (useFixedPosition) {
        style.left = `${left}px`;
        style.top = `${up ? bottom : top}px`;
        style.width = `${width}px`;
      }

      setStyle(style);
    }
  }, [isOpen, itemRef, maxVisibleItems, minVisibleItems, setStyle, useFixedPosition]);
};

const Dropdown = ({
  onChange,
  itemRender,
  compareFunction,
  options,
  value,
  disabled,
  maxVisibleItems,
  minVisibleItems,
  ButtonRender,
  mix,
  disabledItemFunc,
  inactiveItemFunc,
  useFixedPosition,
  useBackdrop,
  unmountOnExit
}) => {
  const dropdownRef = useRef();
  const [style, setStyle] = useState({});
  const itemRef = useRef();
  const bodyRef = useRef();
  const { toggle, isOpen, close } = useSwitch();
  const handleSelectItem = useCallback(
    e => {
      const { index } = e.currentTarget.dataset;

      const selected = options[index];

      if (selected) {
        onChange(selected);
        close();
      }
    },
    [close, onChange, options]
  );

  const renderBody = useCallback(
    () => (
      <CSSTransition
        in={isOpen}
        timeout={200}
        classNames={dropdownStyle('content')}
        unmountOnExit={unmountOnExit}
        mountOnEnter
      >
        <div className={dropdownStyle('content', { fixed: useFixedPosition })} style={style} ref={bodyRef}>
          {options.map((item, index) => {
            const active = compareFunction(value, item);

            return (
              /* eslint-disable react/no-array-index-key */
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                className={dropdownStyle('item', {
                  active,
                  list: true,
                  disabled: disabledItemFunc(item),
                  inactive: inactiveItemFunc(item)
                })}
                key={index}
                data-index={index}
                onClick={handleSelectItem}
              >
                {active ? (
                  <span className={dropdownStyle('active-icon')}>
                    <Icon type="chevron-right" />
                  </span>
                ) : null}
                {itemRender(item, active, false)}
              </div>
            );
          })}
        </div>
      </CSSTransition>
    ),
    [
      compareFunction,
      disabledItemFunc,
      handleSelectItem,
      inactiveItemFunc,
      isOpen,
      itemRender,
      options,
      style,
      unmountOnExit,
      useFixedPosition,
      value
    ]
  );

  useCalculateStyles(isOpen, itemRef, maxVisibleItems, minVisibleItems, useFixedPosition, setStyle);

  useOnClickOutside(bodyRef, close, isOpen);

  return (
    <>
      {useBackdrop ? <div className={dropdownStyle('backdrop', { active: isOpen })} /> : null}
      <div className={`${dropdownStyle({ active: isOpen })} ${mix}`} ref={dropdownRef}>
        <ButtonRender disabled={disabled} toggle={toggle} itemRef={itemRef} itemRender={itemRender} value={value} />
        {useFixedPosition ? ReactDOM.createPortal(renderBody(), document.getElementById('root')) : renderBody()}
      </div>
    </>
  );
};

Dropdown.defaultProps = {
  ButtonRender: DefaultButtonRender,
  itemRender: defaultItemRender,
  compareFunction: defaultCompareFunction,
  disabled: false,
  maxVisibleItems: 5,
  minVisibleItems: 2,
  value: '',
  mix: '',
  disabledItemFunc: () => false,
  inactiveItemFunc: () => false,
  useFixedPosition: false,
  useBackdrop: false,
  unmountOnExit: true
};

Dropdown.propTypes = {
  ButtonRender: PropTypes.func,
  itemRender: PropTypes.func,
  compareFunction: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.arrayOf(PropTypes.string)])
    .isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]), // does not need to be required for dropdown menus
  disabled: PropTypes.bool,
  maxVisibleItems: PropTypes.number,
  minVisibleItems: PropTypes.number,
  mix: PropTypes.string,
  disabledItemFunc: PropTypes.func,
  inactiveItemFunc: PropTypes.func,
  useFixedPosition: PropTypes.bool,
  useBackdrop: PropTypes.bool,
  unmountOnExit: PropTypes.bool
};

export default Dropdown;
