import NOTIFICATION_MESSAGES from '../../utility/notificationMessages';

export const EXPORT_MODEL = 'EXPORT_MODEL';
export const EXPORT_MODEL__SUCCESS = 'EXPORT_MODEL__SUCCESS';
export const EXPORT_MODEL__FAILURE = 'EXPORT_MODEL__FAILURE';

export const exportModel = type => ({
  type: EXPORT_MODEL,
  payload: { type }
});

export const exportModelSuccess = () => ({ type: EXPORT_MODEL__SUCCESS });
export const exportModelFailure = error => ({
  type: EXPORT_MODEL__FAILURE,
  meta: {
    notification: true,
    message: NOTIFICATION_MESSAGES.EXPORT_MODEL_FAILED
  },
  error
});
