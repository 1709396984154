import React from 'react';
import { TYPE_PART_CONTROL } from '../../../utility/propTypes';

const EmptyControl = props => {
  const { control } = props;

  return (
    // eslint-disable-next-line react/no-danger
    <ul className="markdown-content" dangerouslySetInnerHTML={{ __html: control.bodyHtml }} />
  );
};

EmptyControl.propTypes = {
  control: TYPE_PART_CONTROL.isRequired
};

export default EmptyControl;
