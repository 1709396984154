import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { cookieNames, cookiesActions, cookiesSelectors } from '../../modules/cookies';
import { settingsSelectors } from '../../modules/settings';
import { Notification } from '../Atoms';
import { useTranslate } from '../../utility/hooks';
import { NODE_ENV } from '../../config';
import Heap from '../../utility/heap';
import ackeeTracker from '../../utility/ackeeTracker';

const OPTIONS = {
  path: '/',
  ...(NODE_ENV === 'production' ? { secure: true, sameSite: 'none' } : { sameSite: 'lax' })
};

const useBrowserCookieConsent = cookies => {
  const dispatch = useDispatch();
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    const consent = cookies[cookieNames.ALLOW_COOKIES];

    if (!rendered) {
      Heap.stopHeap();
    }

    dispatch(cookiesActions.setCookieConsent(consent));

    if (consent === cookieNames.ALLOW_COOKIES_VALUE) {
      Heap.startHeap();
      ackeeTracker.startDetailed();
    } else if (!rendered) {
      dispatch(cookiesActions.toggleCookieNotification(true));
    }

    setRendered(true);
  }, [cookies, dispatch, rendered]);
};

const useApplicationStoreCookie = (setCookie, removeCookie) => {
  const cookiesConsent = useSelector(cookiesSelectors.selectCookiesConsent);
  const cookies = useSelector(cookiesSelectors.selectSetCookies);

  useEffect(() => {
    cookies.forEach(({ name, value }) => {
      if (cookiesConsent === cookieNames.ALLOW_COOKIES_VALUE) {
        if (name && value) {
          setCookie(name, value, OPTIONS);
        }
      } else {
        removeCookie(name, OPTIONS);
      }
    });
  }, [cookies, cookiesConsent, removeCookie, setCookie]);
};

const useCookieConsentActions = (setCookie, removeCookie) => {
  const dispatch = useDispatch();

  const handleAccept = useCallback(() => {
    setCookie(cookieNames.ALLOW_COOKIES, cookieNames.ALLOW_COOKIES_VALUE, { maxAge: cookieNames.AGE, ...OPTIONS });

    dispatch(cookiesActions.toggleCookieNotification(false));
  }, [dispatch, setCookie]);

  const handleReject = useCallback(() => {
    Heap.stopHeap();
    ackeeTracker.stopDetailed();

    removeCookie(cookieNames.ALLOW_COOKIES, OPTIONS);

    dispatch(cookiesActions.toggleCookieNotification(false));
  }, [dispatch, removeCookie]);

  return { handleAccept, handleReject };
};

const useSyncCookies = (onAccept, onReject) => {
  const handleReceiveMessage = useCallback(
    ({ data }) => {
      if (!data || data.messageType !== 'UPDATE_COOKIES_ACCEPTANCE') return;

      if (data.accept) {
        onAccept();
      } else {
        onReject();
      }
    },
    [onAccept, onReject]
  );

  useEffect(() => {
    window.addEventListener('message', handleReceiveMessage, false);

    return () => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  }, [handleReceiveMessage]);
};

const Cookies = () => {
  const translate = useTranslate();

  const isNotificationVisible = useSelector(cookiesSelectors.selectIsCookieNotificationOpen);
  const cookieSettings = useSelector(settingsSelectors.selectCookieSettings);

  const [browserCookies, setCookie, removeCookie] = useCookies([cookieNames.ALLOW_COOKIES]);

  const { handleAccept, handleReject } = useCookieConsentActions(setCookie, removeCookie);

  useSyncCookies(handleAccept, handleReject);
  useBrowserCookieConsent(browserCookies);
  useApplicationStoreCookie(setCookie, removeCookie);

  return isNotificationVisible ? (
    <Notification
      header={translate(cookieSettings.header)}
      mainButtonTitle={translate('Accept all cookies')}
      onMainButtonClick={handleAccept}
      secondaryButtonTitle={translate('Accept only necessary cookies')}
      onSecondaryButtonClick={handleReject}
    >
      <>
        <p>{translate(cookieSettings.message)}</p>
        {cookieSettings.policyLink ? (
          <p>
            {translate(cookieSettings.policyLink.text)}{' '}
            <a href={cookieSettings.policyLink.url} target="_blank" rel="noopener noreferrer">
              {translate(cookieSettings.policyLink.link)}
            </a>
          </p>
        ) : null}
      </>
    </Notification>
  ) : null;
};

export default Cookies;
