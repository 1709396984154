import { notificationsActions } from '../../modules/notifications';

const notification = (store: any) => (next: any) => (action: any) => {
  const { dispatch } = store;

  if (action.meta) {
    const { meta } = action;

    if (meta && meta.message && meta.notification) {
      const error = !!meta.error;

      if (error) {
        dispatch(notificationsActions.showErrorNotification(meta.message));
      } else {
        dispatch(notificationsActions.showSuccessNotification(meta.message));
      }
    }
  }

  return next(action);
};

export default notification;
