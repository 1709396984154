import { ReactNode, useCallback } from 'react';
import { PartNodeControlType, PartNodeOptionType } from 'types';
import { Switch } from '../../../Atoms';
import ControlOptionCaption from '../../ControlOptionCaption';
import { CONTROL_TYPES } from '../../../../utility/controlDefinitions';
import { optionStyle } from '../../Control/style';

export interface DefaultOptionProps {
  isSelected?: boolean;
  children?: ReactNode;
  noCaption?: boolean;
  control: PartNodeControlType;
  option: PartNodeOptionType;
  onOptionSelect: (control: PartNodeControlType, option: PartNodeOptionType) => void;
}

const DefaultOption = (props: DefaultOptionProps) => {
  const { option, control, isSelected, children, noCaption, onOptionSelect } = props;

  const selectHandler = useCallback(() => {
    onOptionSelect(control, option);
  }, [control, onOptionSelect, option]);

  return (
    <Switch
      onChange={selectHandler}
      name={control.name}
      type="radio"
      checked={isSelected}
      value={option.name}
      disabled={option.locked || option.fixed}
      inactive={option.inactive}
      divider="^"
      hideIcon={control.type !== CONTROL_TYPES.RADIO}
      mix={optionStyle({ type: control.type, checked: isSelected, inactive: option.inactive })}
    >
      {children}
      {noCaption ? null : <ControlOptionCaption type={control.type} option={option} />}
    </Switch>
  );
};

export default DefaultOption;
