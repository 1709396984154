import b from 'b_';
import './PreferencesTable.scss';

const preferencesTableStyle = b.with('preferences-table');

export const MIN_HEIGHT = '0px';
export const MAX_TOTAL_CONTROLS = 30;
export const MAX_CONTROLS_PER_TAB = 5;

export default preferencesTableStyle;
