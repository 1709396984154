import React from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';
import { loaderSelectors } from '../../modules/loader';
import { useTranslate } from '../../utility/hooks';

import './SplashScreen.scss';

const splashScreenStyle = b.with('splash-screen');

const SplashScreen = () => {
  const isActive = useSelector(loaderSelectors.getIsSplashScreenActive);
  const translate = useTranslate();

  if (!isActive) {
    return null;
  }

  return (
    <div className={splashScreenStyle()}>
      <div className={splashScreenStyle('spinner')} />
      <p className={splashScreenStyle('text')}>{translate('Loading')}</p>
    </div>
  );
};

export default SplashScreen;
