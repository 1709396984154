import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { seedActions } from '../../../../modules/seed';
import { useTranslate } from '../../../../utility/hooks';
import { TYPE_PART_CONTROL } from '../../../../utility/propTypes';
import { Button } from '../../../Atoms';
import Icon from '../../../Icon';
import { controlStyle } from '../style';

const ControlInstanceLoaderButton = ({ control }) => {
  const translate = useTranslate();
  const { value, instanceLoader = {} } = control;

  const dispatch = useDispatch();
  const { label: instanceLoaderLabel = 'Configure module' } = instanceLoader;

  const handleClick = useCallback(() => {
    dispatch(seedActions.setCurrentSeedByInstanceId(value));
  }, [dispatch, value]);

  return (
    <Button mix={controlStyle('instance-loader-button')} onClick={handleClick} block bold>
      <span className={controlStyle('instance-loader-button-name')}>{translate(instanceLoaderLabel)}</span>
      <Icon type="chevron-up-down" />
    </Button>
  );
};

ControlInstanceLoaderButton.propTypes = {
  control: TYPE_PART_CONTROL.isRequired
};

export default ControlInstanceLoaderButton;
