import { TextureLoader } from 'three';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { selectModelTexturesList } from '../model/modelSelectors';
import { modelSettingsSelectors } from '../settings';
import { notificationsActions } from '../notifications';
import { partsSelectors } from '../parts';
import { materialsActions } from '../../store/materials/materials';
import { createPhysicalMaterials, createSpriteMaterials } from '../../utility';
import textureStore from './textureStore';
import { FETCH_TEXTURES, fetchTexturesRequest, fetchTexturesSuccess } from './texturesActions';

const loader = new TextureLoader();

const fetchAndCreateTexture = ({ materialName, textureName, textureType, transform, flipY = true }, generateImageURL) =>
  new Promise((resolve, reject) => {
    // if texture has already been used, reuse it
    const imageUrl = generateImageURL(textureName, true);

    const textureId = textureStore.serializeId(imageUrl, textureType, transform?.elements, flipY);

    const storedTexture = textureStore.retrieveTexture(textureId);

    if (storedTexture) {
      textureStore.setTexture(storedTexture, textureType, materialName);

      resolve(storedTexture);

      return;
    }

    loader.load(
      imageUrl,
      texture => {
        const result = textureStore.storeTexture(
          texture,
          textureId,
          textureName,
          textureType,
          materialName,
          transform,
          flipY
        );

        resolve(result);
      },
      () => {},
      err => {
        reject(new Error(`Could not load texture ${textureName}`));
      }
    );
  })
    .then(data => ({ data }))
    .catch(error => ({ error }));

function* fetchTexturesSaga(textures) {
  yield put(fetchTexturesRequest());
  const generateImageURL = yield select(modelSettingsSelectors.selectImageUrlGenerator);

  const result = yield all([...textures.map(texture => call(fetchAndCreateTexture, texture, generateImageURL))]);

  // possible now to create materials
  // Record<string, PartMaterialType>
  const partMaterialMap = yield select(partsSelectors.selectMaterialsMap);
  const spriteMaterialMap = yield select(partsSelectors.selectSpriteMaterialMap);

  const partMaterials = createPhysicalMaterials(partMaterialMap);
  const spriteMaterials = createSpriteMaterials(spriteMaterialMap);

  yield put(materialsActions.setMaterials({ list: partMaterials, overwrite: true, spriteMaterials }));
  yield put(fetchTexturesSuccess(textures));
  const hasError = result.find(item => Boolean(item.error));

  if (hasError) {
    yield put(notificationsActions.showErrorNotification('Warning. Textures were not loaded correctly'));
  }
}

function* doFetchTexturesSaga() {
  const textures = yield select(selectModelTexturesList);

  yield call(fetchTexturesSaga, textures);
}

function* watchFetchTexturesSaga() {
  yield takeLatest(FETCH_TEXTURES, doFetchTexturesSaga);
}

export default function* moduleSaga() {
  yield all([watchFetchTexturesSaga()]);
}
