import { useCallback, useEffect } from 'react';

const useOnClickOutside = (ref, callback, isOpen) => {
  const listener = useCallback(
    event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      callback(event);
    },
    [callback, ref]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    } else {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    }

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, isOpen, listener]);
};

export default useOnClickOutside;
