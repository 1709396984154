import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import * as instanceSelectors from '../instance/instanceSelectors';
import * as projectSelectors from '../project/projectSelectors';
import EXPORTERS from '../../utility/exporterTypes';
import { exportModelSuccess, EXPORT_MODEL, exportModelFailure } from './modelActions';

const loadPrepareModel = () =>
  new Promise(resolve => {
    import('./prepareModel').then(prepareModel => {
      resolve(prepareModel);
    });
  });

function* doExportModelSaga(action) {
  try {
    const { default: prepareModel } = yield call(loadPrepareModel);

    const { type = EXPORTERS.OBJ } = action.payload;
    const instanceId = yield select(instanceSelectors.selectInstanceId);
    const projectName = yield select(projectSelectors.selectProjectName);

    const model = yield call(prepareModel, type);

    const filename = `${projectName} - Model ${instanceId}.${type === EXPORTERS.GLTF ? 'glb' : 'zip'}`;

    FileSaver.saveAs(model, filename);

    yield put(exportModelSuccess());
  } catch (error) {
    yield put(exportModelFailure(error));
  }
}

function* watchExportModelSaga() {
  yield takeEvery(EXPORT_MODEL, doExportModelSaga);
}

export default function* moduleSaga() {
  yield all([watchExportModelSaga()]);
}
