import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export const useTabLink = () => {
  const location = useLocation();
  const parsedQuery = useMemo(() => queryString.parse(location.search), [location.search]);

  return useCallback(
    name => `?${queryString.stringify({ ...parsedQuery, tab: (name || '').toLowerCase() })}`,
    [parsedQuery]
  );
};

const TabLink = ({ children }) => children({ makeTabLink: useTabLink() });

TabLink.propTypes = {
  children: PropTypes.func.isRequired
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

// HOC
// eslint-disable-next-line react/no-multi-comp
export const withTabLink = TargetComponent => {
  class Component extends React.PureComponent {
    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <TabLink>{({ makeTabLink }) => <TargetComponent {...this.props} makeTabLink={makeTabLink} />}</TabLink>;
    }
  }

  Component.displayName = `WithTabLink(${getDisplayName(TargetComponent)})`;

  return Component;
};

export default TabLink;
