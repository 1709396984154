import React, { useCallback, useState, useEffect } from 'react';
import b from 'b_';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../Atoms';
import Icon from '../Icon';
import { uiActions, uiSelectors } from '../../modules/ui';
import { useTranslate } from '../../utility/hooks';

import './ControlsPanelToggle.scss';

const controlsPanelToggleStyle = b.with('controls-panel-toggle');

const ControlsPanelToggle = ({ show }) => {
  const [tooltipVisible, setTooltipVisible] = useState(undefined);

  const dispatch = useDispatch();
  const translate = useTranslate();
  const isOpen = useSelector(uiSelectors.selectControlsPanelVisible);

  const toggleControlsPanel = useCallback(() => {
    dispatch(show ? uiActions.showControlsPanel() : uiActions.hideControlsPanel());
  }, [dispatch, show]);

  useEffect(() => {
    let timerId;

    if (!isOpen && show) {
      setTooltipVisible('');

      timerId = setTimeout(() => {
        setTooltipVisible(undefined);
      }, 3000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [isOpen, show]);

  return (
    <Button color="text" circular onClick={toggleControlsPanel} mix={controlsPanelToggleStyle()} size="lg">
      {show ? (
        <>
          <div
            className={controlsPanelToggleStyle('icon', { desktop: true })}
            aria-label={translate('Back to editing')}
            data-balloon-pos="right"
            data-balloon-visible={tooltipVisible}
          >
            <Icon type="chevron-double-right" scale={1.4} />
          </div>
          <div
            className={controlsPanelToggleStyle('icon', { mobile: true })}
            aria-label={translate('Back to editing')}
            data-balloon-pos="left"
            data-balloon-visible={tooltipVisible}
          >
            <Icon type="chevron-double-up" scale={1.4} />
          </div>
        </>
      ) : (
        <>
          <div
            className={controlsPanelToggleStyle('icon', { desktop: true })}
            aria-label={translate('Hide panel')}
            data-balloon-pos="right"
          >
            <Icon type="chevron-double-left" scale={1.4} />
          </div>
          <div className={controlsPanelToggleStyle('icon', { mobile: true })}>
            <Icon type="chevron-double-down" scale={1.4} />
          </div>
        </>
      )}
    </Button>
  );
};

ControlsPanelToggle.defaultProps = {
  show: false
};

ControlsPanelToggle.propTypes = {
  show: PropTypes.bool
};

export default ControlsPanelToggle;
