import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTabLink } from '../../components/TabLink';
import { uiActions, uiSelectors } from '../../modules/ui';

// similar to useSelectTabWithControl but tabname is not known
// also supports local controls
export const useSelectTabWithControlByInteraction = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const makeTabLink = useTabLink();

  return useCallback(
    (controlName, local = false, nodeKey) => {
      dispatch(uiActions.selectControlByModelInteraction(controlName, local, nodeKey, history.push, makeTabLink));
    },
    [dispatch, history.push, makeTabLink]
  );
};

const useSelectTabWithControl = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const makeTabLink = useTabLink();
  const currentTabName = useSelector(uiSelectors.selectCurrentTabName);

  return useCallback(
    (tabName, controlName = '', hideChildControls) => {
      if (!tabName || currentTabName === tabName) {
        dispatch(uiActions.selectTabAndControlGroup(undefined, controlName, hideChildControls));

        return;
      }

      history.push(makeTabLink(tabName), { controlName, hideChildControls });
    },
    [currentTabName, dispatch, history, makeTabLink]
  );
};

export default useSelectTabWithControl;
