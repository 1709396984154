import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import b from 'b_';
import { Switch } from '../../Atoms';
import { useTranslate, useFieldFeedback } from '../../../utility/hooks';
import { footerSelectors, settingsSelectors } from '../../../modules/settings';
import FieldFeedback from '../../Atoms/FieldFeedback';

const consent = b.with('consent');

const GeneralConsent = ({ onChange, checked, name, required, feedback }) => {
  const translate = useTranslate();

  const generalConsentMessage = useSelector(settingsSelectors.selectGeneralConsentMessage);
  const links = useSelector(footerSelectors.selectCreatomusFooterLinks);

  const { isFeedbackShowing, handleBlur, handleFocus } = useFieldFeedback(feedback);

  return (
    <>
      <Switch
        onChange={onChange}
        type="checkbox"
        name={name}
        checked={checked}
        caption={translate(generalConsentMessage)}
        required={required}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {isFeedbackShowing ? <FieldFeedback message={feedback} /> : null}
      <p className={consent('links')}>
        {links.map((link, index) => (
          <React.Fragment key={link.url}>
            {index !== 0 ? ' | ' : null}
            <a href={link.url} rel="noreferrer noopener" target="_blank" className={consent('link')}>
              {translate(link.label)}
            </a>
          </React.Fragment>
        ))}
      </p>
    </>
  );
};

GeneralConsent.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
  feedback: PropTypes.string
};

GeneralConsent.defaultProps = {
  name: 'generalConsent',
  required: false,
  feedback: undefined
};

export default GeneralConsent;
