import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Viewer from '../Viewer/Viewer';
import { uiActions } from '../../modules/ui';
import { cameraActions } from '../../store/camera';
import { partsActions } from '../../modules/parts';
import { seedActions } from '../../modules/seed';
import { TYPE_PART_RAW } from '../../utility/propTypes';
import { SUMMARY_TAB } from '../../modules/ui/tabNames';
import { projectActions } from '../../modules/project';

const PreviewPart = ({ parts, rootPartId = '', projectId }) => {
  const dispatch = useDispatch();

  const getPartsDone = useCallback(() => dispatch(partsActions.fetchPartsSuccess(parts)), [dispatch, parts]);
  const selectSummaryTab = useCallback(() => dispatch(uiActions.setTabAndControlGroup(SUMMARY_TAB)), [dispatch]);

  /*
  Once part preview is implemented in admin we need to switch from checking root to specifying it in seed.
  Creator creates the root field itself, so we continue to use it until we can.
  */
  const rootPart = useMemo(() => parts.find(({ _id }) => _id === rootPartId), [parts, rootPartId]);

  const fetchSeedSuccess = useCallback(() => {
    const seedResponse = {
      seed: { _id: 'test', rootPartId: rootPart?._id }
    };

    dispatch(seedActions.mockFetchSeedSuccess(seedResponse));
  }, [dispatch, rootPart]);

  const fetchProjectSuccess = useCallback(() => {
    dispatch(projectActions.mockFetchProjectSuccess({ _id: projectId }));
  }, [dispatch, projectId]);

  const setCameraView = useCallback(() => {
    if (rootPart?.Views && Array.isArray(rootPart.Views.list)) {
      const [currentView] = rootPart.Views.list;

      dispatch(cameraActions.setView(currentView.name));
    }
  }, [dispatch, rootPart]);

  useEffect(() => {
    fetchSeedSuccess();
    fetchProjectSuccess();
    getPartsDone();
    selectSummaryTab();
    setCameraView();
  }, [fetchProjectSuccess, fetchSeedSuccess, getPartsDone, selectSummaryTab, setCameraView]);

  const canvasContainerRef = useRef();

  return <Viewer embeddedPreview ref={canvasContainerRef} />;
};

PreviewPart.propTypes = {
  parts: PropTypes.arrayOf(TYPE_PART_RAW).isRequired,
  // eslint-disable-next-line react/require-default-props
  rootPartId: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  projectId: PropTypes.string
};

export default PreviewPart;
