import React from 'react';
import PropTypes from 'prop-types';
import { useActions, useTranslate } from '../../../utility/hooks';

const SummarySaveButton = ({ mix }) => {
  const translate = useTranslate();
  const {
    actions: [action],
    handleClickOnAction
  } = useActions('summarySaveButton');

  if (!action) return null;

  return (
    <>
      {' '}
      (
      <button type="button" onClick={handleClickOnAction} className={mix} data-action={action.name}>
        {translate(action.displayName)}
      </button>
      )
    </>
  );
};

SummarySaveButton.propTypes = {
  mix: PropTypes.string.isRequired
};

export default SummarySaveButton;
