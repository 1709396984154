import { createSelector } from 'reselect';
import {
  DialogState,
  ActionDefinitionType,
  VisibilityFlagType,
  LastControlTabActionDefinitionType,
  FirstControlTabActionDefinitionType,
  DialogActionDefinitionType,
  LinkActionDefinitionType,
  TabActionDefinitionType,
  ActionGroupType
} from 'types';
import { authSelectors } from '../auth';
import { instanceSelectors } from '../instance';
import { configuratorTypeSelectors } from '../configuratorType';
import { uiSelectors } from '../ui';
import { getFirstControlTab, getLastControlTab } from '../model/controlSelectors';
import * as dialogSelectors from '../dialog/dialogSelectors';
import { DIALOGS } from '../../utility/dialogs';
import { ACTION_TYPES } from '../../utility/actionTypes';
import { selectSettings } from './settingsSelectors';
import * as interfaceSelectors from './interfaceSelectors';

interface ParsedActionStateType {
  firstControlTabName: string;
  lastControlTabName: string;
  dialog: DialogState;
}

const MENU_GROUP = 'menu';

const ARRAY_GROUPS = ['aboutTab', 'reviewTab', 'summaryTab', 'overviewPanel', 'shareDialog', 'summaryPanel'] as const;

const SINGLE_GROUPS = ['summarySaveButton', 'finishButton'] as const;

type VisibilityEntryType<T extends keyof VisibilityFlagType = keyof VisibilityFlagType> = [T, VisibilityFlagType[T]];

const COMMENT_DIALOGS = {
  [DIALOGS.EDIT_COMMENT]: true,
  [DIALOGS.ADD_COMMENT]: true
};

/**
 * After parsing each type might change slightly. These
 * types show how the parsed actions look like.
 */

type ParsedControlTabActionType = (FirstControlTabActionDefinitionType | LastControlTabActionDefinitionType) & {
  tab: string;
  type: typeof ACTION_TYPES.OPEN_TAB; // rewrites type field
};

interface ParsedDialogActionType extends DialogActionDefinitionType {
  disabled: boolean;
}

export type ParsedActionType = ActionGroupType &
  (ParsedDialogActionType | TabActionDefinitionType | LinkActionDefinitionType | ParsedControlTabActionType);

/**
 * Changes first and last control tab actions to tab actions with targets calculated accordingly
 * Disables comment dialog actions if dialog already open
 */
const parseAction = (
  item: ActionGroupType,
  map: Record<string, ActionDefinitionType>,
  { firstControlTabName, lastControlTabName, dialog }: ParsedActionStateType
): ParsedActionType | undefined => {
  const action = map[item.name];

  if (!action) return undefined;

  if (action.type === ACTION_TYPES.OPEN_FIRST_CONTROL_TAB || action.type === ACTION_TYPES.OPEN_LAST_CONTROL_TAB) {
    return {
      ...item,
      ...action,
      tab: action.type === ACTION_TYPES.OPEN_FIRST_CONTROL_TAB ? firstControlTabName : lastControlTabName,
      type: ACTION_TYPES.OPEN_TAB
    };
  }

  if (action.type === ACTION_TYPES.OPEN_DIALOG) {
    const disabled = action.dialog in COMMENT_DIALOGS && dialog && dialog.type in COMMENT_DIALOGS;

    return { ...item, ...action, disabled };
  }

  return { ...item, ...action };
};

/** Parses list of actions. */
const parseActions = (
  list: ActionGroupType[] = [],
  map: Record<string, ActionDefinitionType>,
  state: ParsedActionStateType
): ParsedActionType[] => list.map(action => parseAction(action, map, state)).filter(Boolean) as ParsedActionType[];

/**
 * Filters actions based on visibility flags.
 * Open tab actions are removed if the tab is active
 */
const filterActions =
  (visibilityFlags: VisibilityEntryType[], hiddenTabs: Record<string, boolean>, currentTab: string) =>
  (list: ParsedActionType[]) =>
    list.filter(action => {
      if (visibilityFlags.some(([key, value]) => action[key] === value)) {
        return false;
      }

      return !(
        action.type === ACTION_TYPES.OPEN_TAB &&
        action.tab &&
        (action.tab === currentTab || hiddenTabs[action.tab])
      );
    });

const selectStateActions = createSelector([selectSettings], ({ actions }) => actions);

/** Creates map of actions for loop-free access */
export const selectMapActions = createSelector([selectStateActions], actions => {
  const list = actions?.list || [];

  return list.reduce<Record<string, ActionDefinitionType>>((result, action) => {
    result[action.name] = action; // eslint-disable-line no-param-reassign

    return result;
  }, {});
});

// menu has a different signature than the rest of the actions
export type ParsedActionsType = { menu?: { section: string; actions: ParsedActionType[] }[] } & Record<
  string,
  ParsedActionType[]
>;

/** Selects actions and parses them with current state */
const selectParsedActions = createSelector(
  [selectStateActions, selectMapActions, getFirstControlTab, getLastControlTab, dialogSelectors.selectDialog],
  (
    actions,
    map: Record<string, ActionDefinitionType>,
    firstControlTabName: string,
    lastControlTabName: string,
    dialog: DialogState
  ) => {
    const groups = actions?.groups || {};

    const state = { firstControlTabName, lastControlTabName, dialog };

    const result: ParsedActionsType = {};

    // parse menu
    if (groups.menu) {
      result.menu = groups.menu.map(section => ({ ...section, actions: parseActions(section.actions, map, state) }));
    }

    // parse arrays
    ARRAY_GROUPS.forEach(key => {
      if (groups[key]) {
        result[key] = parseActions(groups[key], map, state);
      }
    });

    // parse singles

    if (groups.homeButton) {
      result.homeButton = parseActions([{ name: groups.homeButton, icon: 'home-outline' }], map, state);
    }

    SINGLE_GROUPS.forEach(key => {
      const groupName = groups[key];

      if (groupName) {
        result[key] = parseActions([{ name: groupName }], map, state);
      }
    });

    return result;
  }
);

/** Selects state for visibility flags */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: Selector not yet converted
const selectActionsVisibilityFlags = createSelector(
  [
    authSelectors.getLoggedIn,
    instanceSelectors.getIsOwnLead,
    instanceSelectors.getIsOwner,
    configuratorTypeSelectors.getIsInstanceConfiguratorType
  ],
  (isLoggedIn: boolean, isOwnLead: boolean, isOwner: boolean, isInstanceMode: boolean) =>
    [
      ['hidingByAuth', isLoggedIn ? 'authorized' : 'unauthorized'],
      ['hidingBySaving', isOwner ? 'saved' : 'unsaved'],
      ['hidingByRequest', isOwnLead ? 'withRequest' : 'withoutRequest'],
      ['hidingByConfiguratorMode', isInstanceMode ? 'instance' : 'noInstance']
    ] as VisibilityEntryType[]
);

/** Selects actions, parses and filters them. Returns object with keys for each action group */
export const getActions = createSelector(
  [
    selectParsedActions,
    selectActionsVisibilityFlags,
    interfaceSelectors.selectHiddenTabs,
    uiSelectors.selectCurrentTabName
  ],
  (actions, visibilityFlags, hiddenTabs, currentTab) => {
    const filter = filterActions(visibilityFlags, hiddenTabs, currentTab);

    return Object.keys(actions).reduce<ParsedActionsType>((result, group) => {
      if (group === MENU_GROUP) {
        if (actions.menu) {
          // eslint-disable-next-line no-param-reassign
          result.menu = actions.menu
            .map(section => ({ ...section, actions: filter(section.actions) }))
            .filter(section => section.actions.length);
        }

        return result;
      }

      // eslint-disable-next-line no-param-reassign
      result[group] = filter(actions[group]);

      return result;
    }, {});
  }
);
