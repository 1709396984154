import React from 'react';
import b from 'b_';
import Icon from '../Icon';

import './Progress.scss';

const progress = b.with('progress');

const Progress = () => {
  return (
    <div className={progress()}>
      <div className={progress('icon')}>
        <Icon type="loading" scale={2.4} />
      </div>
    </div>
  );
};

export default Progress;
