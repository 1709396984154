export const MOCK_FETCH_SEED__SUCCESS = 'MOCK_FETCH_SEED__SUCCESS'; // for part preview

export const SET_CURRENT_SEED = 'SET_CURRENT_SEED';
export const SET_CURRENT_SEED_BY_INSTANCE_ID = 'SET_CURRENT_SEED_BY_INSTANCE_ID';
export const CLOSE_CURRENT_SEED = 'CLOSE_CURRENT_SEED';
export const SAVE_AND_CLOSE_CURRENT_SEED = 'SAVE_AND_CLOSE_CURRENT_SEED';
export const DISCARD_AND_CLOSE_CURRENT_SEED = 'DISCARD_AND_CLOSE_CURRENT_SEED';

export const mockFetchSeedSuccess = ({ seed, settings }) => ({
  type: MOCK_FETCH_SEED__SUCCESS,
  payload: { seed, settings }
});

export const setCurrentSeedId = (seedId, lastTabName, lastControl, lastParentControls) => ({
  type: SET_CURRENT_SEED,
  payload: { seedId, lastTabName, lastControl, lastParentControls }
});

export const setCurrentSeedByInstanceId = instanceId => ({
  type: SET_CURRENT_SEED_BY_INSTANCE_ID,
  payload: { instanceId }
});

export const closeCurrentSeed = childInstanceId => ({ type: CLOSE_CURRENT_SEED, payload: { childInstanceId } });

export const saveAndCloseCurrentSeed = (instanceName, clone, makeTabLink, historyPush) => ({
  type: SAVE_AND_CLOSE_CURRENT_SEED,
  payload: { instanceName, clone, makeTabLink, historyPush }
});

export const discardAndCloseCurrentSeed = (makeTabLink, historyPush) => ({
  type: DISCARD_AND_CLOSE_CURRENT_SEED,
  payload: { makeTabLink, historyPush }
});
