import { useState, useCallback, useEffect } from 'react';
import getThreeSetup from '../getThreeSetup';
import { ORTHO_CAMERAS } from '../../../utility/viewTypes';
import { usePrevious } from '../../../utility/hooks';
import getFrustum from '../../../utility/getFrustum';

const useCamera = cameraViewType => {
  const { setRenderCamera, camera, orthoCamera, perspectiveCamera, orbit } = getThreeSetup();

  const previousCameraViewTypeOrtho = usePrevious(Boolean(ORTHO_CAMERAS[cameraViewType]));

  // this is in order to trigger state update
  const [renderCamera, setStateRenderCamera] = useState(camera);

  const setCamera = useCallback(
    cam => {
      setStateRenderCamera(cam);
      setRenderCamera(cam);
    },
    [setRenderCamera]
  );

  useEffect(() => {
    if (ORTHO_CAMERAS[cameraViewType]) {
      if (!previousCameraViewTypeOrtho) {
        orthoCamera.position.copy(perspectiveCamera.position);
        orthoCamera.quaternion.copy(perspectiveCamera.quaternion);
        const frustum = getFrustum(
          perspectiveCamera.position,
          orbit.target,
          perspectiveCamera.fov,
          perspectiveCamera.aspect
        );

        Object.assign(orthoCamera, frustum);
        orthoCamera.updateProjectionMatrix();
      }

      setCamera(orthoCamera);
    } else {
      if (previousCameraViewTypeOrtho) {
        perspectiveCamera.position.copy(orthoCamera.position);
        perspectiveCamera.quaternion.copy(orthoCamera.quaternion);
        perspectiveCamera.updateProjectionMatrix();
      }
      setCamera(perspectiveCamera);
    }
  }, [cameraViewType, orbit.target, orthoCamera, perspectiveCamera, previousCameraViewTypeOrtho, setCamera]);

  return renderCamera;
};

export default useCamera;
