import { useCallback, useRef, useEffect, useState, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useEscListener } from '../../utility/hooks';
import { DIALOGS, DialogType } from '../../utility/dialogs';
import { selectIsMobileNavigation, selectIsMobileViewport } from '../../modules/ui/uiStateSelectors';
import menuStyle from './style';
import useMenuClose from './useMenuClose';
import MenuHeader from './MenuHeader';
import MenuFooter from './MenuFooter';

import './Menu.scss';

/** Dialogs which dont close on clicking backdrop */
// must be of type string[] not actual values to work with .includes
const PERSISTENT_DIALOGS: string[] = [
  DIALOGS.AUTH,
  DIALOGS.SAVE,
  DIALOGS.CONTACT,
  DIALOGS.LOADING,
  DIALOGS.LOGIN,
  DIALOGS.REGISTER,
  DIALOGS.RESET_PASSWORD,
  DIALOGS.FORGOT_PASSWORD,
  DIALOGS.SEND_TO_EMAIL
];

interface MenuProps {
  children: ReactNode;
  header?: ReactNode;
  type: DialogType;

  /**
   * contentShown props controls if 3D content is shown.
   * If false, backdrop is used.
   * If true, backdrop is not used and on mobile the dialog extends to max 50% height of screen.
   */
  contentShown?: boolean;
  mix?: string;
}

const Menu = ({ type, children, header = null, contentShown = false, mix = '' }: MenuProps) => {
  const bodyRef = useRef<HTMLDivElement>(null);

  const [contentHidden] = useState(!contentShown); // in useState in order to keep same value until component is unmounted by transition

  const isMobileNavigation = useSelector(selectIsMobileNavigation);
  const isMobileViewport = useSelector(selectIsMobileViewport);
  const isDesktop = !isMobileNavigation;

  const [halfInMobile] = useState(contentShown && isMobileViewport);

  const handleClose = useMenuClose();

  useEscListener(handleClose);

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = 0;
    }
  }, []);

  const handleClickOnMenu = useCallback(
    e => {
      if (bodyRef.current?.contains(e.target)) {
        return;
      }

      if (PERSISTENT_DIALOGS.includes(type)) {
        return;
      }

      handleClose();
    },
    [handleClose, type]
  );

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    <div
      className={`${menuStyle({
        backdrop: contentHidden,
        'half-height': halfInMobile
      })} ${mix}`}
      onClick={handleClickOnMenu}
      data-heap={type}
    >
      <div className={menuStyle('body')} ref={bodyRef}>
        {(contentHidden || isDesktop) && <MenuHeader>{header}</MenuHeader>}

        {children}
        {/* If content is shown there is less room so that we hide footer */}
        {contentShown ? null : <MenuFooter />}
      </div>
    </div>
  );
};

export default Menu;
