import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../Atoms';
import { controlStyle } from '../style';
import Icon from '../../../Icon';
import { uiActions } from '../../../../modules/ui';
import { TYPE_PART_CONTROL } from '../../../../utility/propTypes';
import { priceSelectors } from '../../../../modules/model';
import { useTranslate } from '../../../../utility/hooks';
import flattenControlKeys from '../../../../utility/flattenControlKeys';

const ControlChildTabButton = ({ control }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const controlsMap = useSelector(priceSelectors.selectPricedControlsMap);
  const controlsTreeMap = useSelector(priceSelectors.selectPricedControlsTreeMap);
  const firstChildControl = useMemo(
    () => control.childControls.find(({ name }) => controlsMap[name] && !controlsMap[name].disabled) || {},
    [control.childControls, controlsMap]
  );
  const controlsKeyMap = useSelector(priceSelectors.selectPricedControlsKeyMap);

  const handleClick = useCallback(
    e => {
      e.stopPropagation();

      dispatch(uiActions.showChildControls([control.treeName]));
      dispatch(uiActions.selectTabAndControlGroup(undefined, firstChildControl.name, false));
    },
    [dispatch, control.treeName, firstChildControl.name]
  );

  const flatControlMap = useMemo(() => flattenControlKeys(controlsKeyMap, control.key), [control.key, controlsKeyMap]);

  const hasControls = useMemo(() => {
    return control.childControls.some(({ name, local }) => {
      if (local) {
        const treeName = flatControlMap[name];

        return controlsTreeMap[treeName] && !controlsTreeMap[treeName].disabled;
      }

      return controlsMap[name] && !controlsMap[name].disabled;
    });
  }, [control.childControls, controlsMap, controlsTreeMap, flatControlMap]);

  if (!hasControls) return null;

  return (
    <Button mix={controlStyle('child-tab-button')} block bold onClick={handleClick}>
      <span className={controlStyle('child-tab-button-name')}>{translate(control.childTabName)}</span>
      <Icon type="chevron-up-down" />
    </Button>
  );
};

ControlChildTabButton.propTypes = {
  control: TYPE_PART_CONTROL.isRequired
};

export default ControlChildTabButton;
