import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { usePrevious } from '../../../utility/hooks';
import { authSelectors } from '../../../modules/auth';
import PasswordChangedFeedback from './PasswordChangedFeedback';
import PasswordForm from './PasswordForm';

const PasswordReset = ({ token }) => {
  const passwordChanged = useSelector(authSelectors.selectPasswordChanged);
  const prevPasswordChanged = usePrevious(passwordChanged);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!prevPasswordChanged && passwordChanged) {
      history.replace(location.pathname);
    }
  }, [history, location.pathname, passwordChanged, prevPasswordChanged]);

  return passwordChanged ? <PasswordChangedFeedback /> : <PasswordForm token={token} />;
};

PasswordReset.propTypes = {
  token: PropTypes.string.isRequired
};

export default PasswordReset;
