import React, { forwardRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useSelector } from 'react-redux';
import ExtraInfoDialog from '../ExtraInfoDialog';
import Progress from '../Progress';
import { loaderSelectors } from '../../modules/loader';
import useSkybox from '../../utility/hooks/viewerHooks/useSkybox';
import SceneGenerator from '../SceneGenerator';
import { uiSelectors } from '../../modules/ui';
import ExitFullscreenButton from '../ExitFullscreenButton';
import { controlSelectors } from '../../modules/model';
import Camera from './Camera';
import Renderer from './Renderer';
import ViewButtons from './ViewButtons';
import getThreeSetup from './getThreeSetup';
import InteractiveControls from './InteractiveControls';
import TransformControls from './TransformControls';
import Comments from './Comments';
import './Viewer.scss';

const viewerStyle = b.with('viewer');

const Viewer = forwardRef(({ embeddedPreview, onExitFullscreen }, canvasContainerRef) => {
  const areInteractiveControlsEnabled = useSelector(controlSelectors.selectAreInteractiveControlsEnabled);
  const areTransformControlsEnabled = useSelector(controlSelectors.selectAreTransformControlsEnabled);

  const isProgressActive = useSelector(loaderSelectors.getProgressActiveStatus);
  const isReviewTab = useSelector(uiSelectors.getIsReviewTab);
  const [currentExtraInfoCollection, showExtraInfoCollection] = useState('');

  const closeExtraInfoCollectionHandler = useCallback(() => showExtraInfoCollection(''), []);

  const renderedExtraInfo = useMemo(() => {
    return currentExtraInfoCollection ? (
      <ExtraInfoDialog
        onClose={closeExtraInfoCollectionHandler}
        collectionName={currentExtraInfoCollection}
        canvasContainerRef={canvasContainerRef}
      />
    ) : null;
  }, [canvasContainerRef, closeExtraInfoCollectionHandler, currentExtraInfoCollection]);

  const { scene } = getThreeSetup();

  useSkybox(scene);

  return (
    // id is used by ExtraInfoDialog to get access to this node to render the dialog
    <div className={viewerStyle({ embedded: embeddedPreview })} id="viewer">
      {embeddedPreview ? <SceneGenerator /> : null}
      <Renderer
        ref={canvasContainerRef}
        embeddedPreview={embeddedPreview}
        showExtraInfoCollection={showExtraInfoCollection}
      />
      <Camera embeddedPreview={embeddedPreview} />
      {renderedExtraInfo}
      {isReviewTab ? null : <ExitFullscreenButton onClick={onExitFullscreen} type="viewer" />}
      <ViewButtons />
      {isProgressActive ? <Progress /> : null}
      {areInteractiveControlsEnabled && !isProgressActive ? <InteractiveControls /> : null}
      {areTransformControlsEnabled && !isProgressActive ? <TransformControls /> : null}
      {!isProgressActive ? <Comments /> : null}
    </div>
  );
});

Viewer.propTypes = {
  embeddedPreview: PropTypes.bool, // if embedded in iframe, will try to send messages
  onExitFullscreen: PropTypes.func
};

Viewer.defaultProps = {
  embeddedPreview: false,
  onExitFullscreen: () => {}
};

export default Viewer;
