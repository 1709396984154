import React from 'react';
import b from 'b_';
import Footer from '../../Footer';

import './PageFooter.scss';

const pageFooterStyle = b.with('page-footer');

const PageFooter = () => {
  return (
    <div className={pageFooterStyle()}>
      <Footer />
    </div>
  );
};

export default PageFooter;
