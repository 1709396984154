import React from 'react';
import PropTypes from 'prop-types';
import { MenuSection } from '../../Menu';
import { useTranslate } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import { Button } from '../../Atoms';
import useMenuClose from '../../Menu/useMenuClose';

const MessageMenu = ({ heading, content, description }) => {
  const translate = useTranslate();

  const handleClose = useMenuClose();

  return (
    <MenuContent>
      <MenuSection type="message" heading={translate(heading)} description={description}>
        {content.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <p key={index}>{translate(item)}</p>
        ))}

        <Button onClick={handleClose}>{translate('Close')}</Button>
      </MenuSection>
    </MenuContent>
  );
};

MessageMenu.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.node)
};
MessageMenu.defaultProps = {
  content: [],
  description: '',
  heading: ''
};

export default MessageMenu;
