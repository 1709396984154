import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';

import './MarkdownContent.scss';

const markdownContentStyle = b.with('markdown-content');

const MarkdownContent = ({ html }) => (
  // eslint-disable-next-line react/no-danger
  <p className={markdownContentStyle()} dangerouslySetInnerHTML={{ __html: html }} />
);

MarkdownContent.propTypes = {
  html: PropTypes.string
};

MarkdownContent.defaultProps = {
  html: ''
};

export default MarkdownContent;
