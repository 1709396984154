import React from 'react';
import PropTypes from 'prop-types';
import { Matrix4 } from 'three';
import SceneButton from '../SceneButton';
import Icon from '../../../Icon';
import CloseButton from '../CloseButton';

const ControlTargets = ({ list, onClick, onClose, sourceControl }) => (
  <>
    <CloseButton onClose={onClose} matrix={sourceControl.matrix} />
    {list.map(({ treeName, matrix }, index) => {
      return (
        <SceneButton key={treeName} matrix={matrix} data-target={index} onClick={onClick}>
          <Icon type="target" />
        </SceneButton>
      );
    })}
  </>
);

ControlTargets.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      treeName: PropTypes.string,
      matrix: PropTypes.instanceOf(Matrix4)
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  sourceControl: PropTypes.shape({
    matrix: PropTypes.instanceOf(Matrix4)
  }).isRequired
};

export default ControlTargets;
