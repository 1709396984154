import { useEffect } from 'react';
import getThreeSetup from '../getThreeSetup';
import getClippingPlane from './getClippingPlane';

const useRenderer = (
  canvasContainerRef,
  canvasMouseDownHandler,
  canvasMouseUpHandler,
  canvasMouseMoveHandler,
  cameraView
) => {
  const { renderer, render, canvas, cssRenderer } = getThreeSetup();

  // add canvas to render target container;
  useEffect(() => {
    const container = canvasContainerRef.current;

    if (canvas && container) {
      container.appendChild(cssRenderer.domElement);
      container.appendChild(canvas);

      canvas.addEventListener('pointerup', canvasMouseUpHandler);
      canvas.addEventListener('pointerdown', canvasMouseDownHandler);
      canvas.addEventListener('mousemove', canvasMouseMoveHandler);

      canvas.classList.add('render-canvas');
      cssRenderer.domElement.classList.add('interactive-controls');

      render('renderer update');

      return () => {
        container.removeChild(cssRenderer.domElement);
        container.removeChild(canvas);

        canvas.removeEventListener('pointerup', canvasMouseUpHandler);
        canvas.removeEventListener('pointerdown', canvasMouseDownHandler);
        canvas.removeEventListener('mousemove', canvasMouseMoveHandler);

        canvas.classList.remove('render-canvas');
        cssRenderer.domElement.classList.remove('interactive-controls');
      };
    }

    return () => null;
  }, [
    canvas,
    canvasContainerRef,
    canvasMouseDownHandler,
    canvasMouseMoveHandler,
    canvasMouseUpHandler,
    cssRenderer,
    cssRenderer.domElement,
    render
  ]);

  // clipping planes
  useEffect(() => {
    if (cameraView?.clippingPlane?.enabled) {
      // somewhere y and z are swapped, maybe in camera, maybe in scene.
      const clipPlane = getClippingPlane(cameraView);

      // clipping planes is an array of planes, we use one, so construct array of one.
      renderer.clippingPlanes = [clipPlane];
      render('clip update');
    }

    return () => {
      renderer.clippingPlanes = [];
      render('clip update');
    };
  }, [cameraView, render, renderer, renderer.clippingPlanes]);
};

export default useRenderer;
