import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { controlSelectors } from '../../../modules/model';
import { selectOption } from '../../../modules/selectedOptions/selectedOptionsActions';
import { summarySelectors, uiActions, uiSelectors } from '../../../modules/ui';
import { useSelectTabWithControl, useSwitch } from '../../../utility/hooks';
import getThreeSetup from '../getThreeSetup';
import { selectCurrentTabName } from '../../../modules/ui/uiSelectors';
import { hideChildControls, showChildControls } from '../../../modules/ui/uiActions';
import CloseButton from './CloseButton';
import SceneButtons from './SceneButtons';
import ControlTargets from './ControlTargets';

const useInteractiveControls = () => {
  const { isOpen: areTargetsShowing, open: showTargets, close: hideTargets } = useSwitch(false);
  const { render } = getThreeSetup();
  const sourceControl = useSelector(controlSelectors.getSelectedControlGroup);
  const targetControls = useSelector(controlSelectors.selectInteractiveTargetControls);
  const parentControls = useSelector(uiSelectors.selectSelectedParentControls);
  const controlParents = useSelector(summarySelectors.selectControlsParentsMap);

  const selectTabWithControl = useSelectTabWithControl();
  const currentTabName = useSelector(selectCurrentTabName);

  const isCurrentValueDeleted = sourceControl?.value === sourceControl?.deleteValue;
  const dispatch = useDispatch();

  useEffect(() => {
    render();
  }, [sourceControl, targetControls, areTargetsShowing, isCurrentValueDeleted, render, currentTabName]);

  // hide targets if source control treeName changes (so another control is selected)
  useEffect(() => {
    hideTargets();
  }, [hideTargets, sourceControl.treeName]);

  useEffect(() => {
    dispatch(uiActions.setPartInteractionEnabled(!areTargetsShowing));

    return () => dispatch(uiActions.setPartInteractionEnabled(true));
  }, [areTargetsShowing, dispatch]);

  // interactive control is currently selected control
  const handleClose = useCallback(
    targetControl => {
      // if in child controls, close
      // if  target tab is given, change tab
      if (sourceControl.closeTarget) {
        selectTabWithControl(sourceControl.closeTarget, targetControl.name);
      }

      // close last parent controls if they exist
      if (parentControls.length) {
        dispatch(hideChildControls([parentControls[parentControls.length - 1]]));
      }
    },
    [dispatch, parentControls, selectTabWithControl, sourceControl.closeTarget]
  );

  const handleDelete = useCallback(() => {
    const option = sourceControl?.list.find(({ name }) => name === sourceControl.deleteValue);

    if (option) dispatch(selectOption(sourceControl, option));

    const targetControl = sourceControl.closeTarget === currentTabName && sourceControl;

    hideTargets();
    handleClose(targetControl);
  }, [currentTabName, dispatch, handleClose, hideTargets, sourceControl]);

  const copy = useCallback(
    e => {
      const { target } = e.currentTarget.dataset;
      const targetControl = targetControls[Number(target)];

      if (targetControl) {
        const option = targetControl.list.find(({ name }) => name === sourceControl?.value);

        if (option) dispatch(selectOption(targetControl, option));

        if (controlParents[targetControl.treeName]) {
          selectTabWithControl(controlParents[targetControl.treeName].tabName, targetControl.name);

          dispatch(showChildControls(controlParents[targetControl.treeName].parents, true));
        }
      }
      hideTargets();
    },
    [controlParents, dispatch, hideTargets, selectTabWithControl, sourceControl, targetControls]
  );

  const move = useCallback(
    e => {
      const { target } = e.currentTarget.dataset;
      const targetControl = targetControls[Number(target)];

      if (targetControl) {
        const option = targetControl.list.find(({ name }) => name === sourceControl?.value);

        if (option) {
          const sourceOption = sourceControl?.list.find(({ name }) => name === targetControl.value);

          dispatch(selectOption(targetControl, option));

          if (controlParents[targetControl.treeName]) {
            selectTabWithControl(controlParents[targetControl.treeName].tabName, targetControl.name);

            dispatch(showChildControls(controlParents[targetControl.treeName].parents, true));
          }

          if (sourceOption) dispatch(selectOption(sourceControl, sourceOption));
        }
      }
      hideTargets();
    },
    [controlParents, dispatch, hideTargets, selectTabWithControl, sourceControl, targetControls]
  );

  const [handleCurrentAction, setCallback] = useState(() => move);

  const handleMove = useCallback(() => {
    showTargets();
    setCallback(() => move);
  }, [move, showTargets]);

  const handleCopy = useCallback(() => {
    showTargets();
    setCallback(() => copy);
  }, [copy, showTargets]);

  const isDeleteButtonVisible = Boolean(sourceControl.deleteValue);

  return {
    isCurrentValueDeleted,
    areTargetsShowing,
    hideTargets,
    sourceControl,
    handleClose,
    handleDelete,
    handleCopy,
    targetControls,
    handleCurrentAction,
    handleMove,
    isDeleteButtonVisible
  };
};

const InteractiveControls = () => {
  const {
    isCurrentValueDeleted,
    areTargetsShowing,
    sourceControl,
    handleClose,
    handleDelete,
    handleCopy,
    targetControls,
    handleCurrentAction,
    handleMove,
    hideTargets,
    isDeleteButtonVisible
  } = useInteractiveControls();

  const closeButton = sourceControl.closeTarget ? (
    <CloseButton onClose={handleClose} matrix={sourceControl.matrix} />
  ) : null;

  if (isCurrentValueDeleted) return closeButton;

  if (!areTargetsShowing) {
    return (
      <>
        {closeButton}
        <SceneButtons
          matrix={sourceControl.matrix}
          onMove={handleMove}
          onDelete={handleDelete}
          onCopy={handleCopy}
          isDeleteButtonVisible={isDeleteButtonVisible}
        />
      </>
    );
  }

  return (
    <ControlTargets
      list={targetControls}
      onClick={handleCurrentAction}
      sourceControl={sourceControl}
      onClose={hideTargets}
    />
  );
};

export default InteractiveControls;
