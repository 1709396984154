import * as userInstancesActions from '../userInstances/userInstancesActions';
import * as seedActions from '../seed/seedActions';
import { initialStateActions } from '../initialState';
import { INITIAL_INSTANCE_ID } from '../../config';
import { convertArrayToMap } from '../../utility';
import * as instanceActions from './instanceActions';

export const getInstanceInitialState = (mainInstance = { _id: INITIAL_INSTANCE_ID }, baseInstance) => {
  const instance = baseInstance ? { ...baseInstance, _id: INITIAL_INSTANCE_ID } : mainInstance;

  return {
    mainInstanceId: instance._id,
    currentInstanceId: instance._id,
    list: { [instance._id]: instance },
    // Send to my email (orphans)
    email: ''
  };
};

function getUpdatedList(state, instance) {
  return { ...state.list, [instance._id]: instance };
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const instanceReducer = (state = getInstanceInitialState(), { type, payload = {} }) => {
  switch (type) {
    case initialStateActions.FETCH_INITIAL_STATE__SUCCESS:
    case instanceActions.RESET_INSTANCES: {
      const { instance, baseInstance } = payload;

      const newState = getInstanceInitialState(instance, baseInstance);

      return { ...newState, list: { ...state.list, ...newState.list } };
    }
    case instanceActions.SAVE_SNAPSHOT__SUCCESS:
    case instanceActions.CREATE_LEAD__SUCCESS: {
      const { instance } = payload;

      return {
        ...state,
        list: getUpdatedList(state, instance),
        mainInstanceId: instance._id,
        currentInstanceId: instance._id
      };
    }
    case instanceActions.SAVE_PDF_GALLERY__SUCCESS: {
      const instance = { ...state.list[state.currentInstanceId], pdfGallery: payload.gallery };

      return {
        ...state,
        list: getUpdatedList(state, instance)
      };
    }
    case userInstancesActions.CREATE_INSTANCE__SUCCESS: {
      const { instance, email = '' } = payload;

      return {
        ...state,
        list: getUpdatedList(state, instance),
        email,
        currentInstanceId: state.mainInstanceId === state.currentInstanceId ? instance._id : state.currentInstanceId,
        mainInstanceId: instance._id // update main instance id
      };
    }
    case instanceActions.FETCH_CHILD_INSTANCES__SUCCESS:
    case userInstancesActions.FETCH_USER_INSTANCES__SUCCESS: {
      const { instances = [] } = payload;

      return { ...state, list: { ...state.list, ...convertArrayToMap(instances) } };
    }
    case userInstancesActions.DELETE_INSTANCE__SUCCESS: {
      const { id } = payload;
      const newList = { ...state.list };

      delete newList[id];

      const mainInstanceId = id === state.mainInstanceId ? INITIAL_INSTANCE_ID : state.mainInstanceId;
      const currentInstanceId = id === state.currentInstanceId ? INITIAL_INSTANCE_ID : state.currentInstanceId;

      return { ...state, list: newList, currentInstanceId, mainInstanceId };
    }
    case userInstancesActions.SAVE_INSTANCE__SUCCESS:
    case userInstancesActions.SAVE_CHILD_INSTANCE__SUCCESS:
    case userInstancesActions.CREATE_CHILD_INSTANCE__SUCCESS: {
      const { instance } = payload;

      return { ...state, list: getUpdatedList(state, instance) };
    }
    case instanceActions.SET_CURRENT_INSTANCE: {
      const { instanceId } = payload;

      return { ...state, currentInstanceId: instanceId };
    }
    case seedActions.CLOSE_CURRENT_SEED: {
      return { ...state, currentInstanceId: state.mainInstanceId };
    }

    case userInstancesActions.ADD_COMMENT: {
      const { comment } = payload;

      const currentInstance = { ...state.list[state.currentInstanceId] };

      const comments = currentInstance.comments ? [...currentInstance.comments, comment] : [comment];

      currentInstance.comments = comments;

      return { ...state, list: { ...state.list, [state.currentInstanceId]: currentInstance } };
    }

    case userInstancesActions.REMOVE_COMMENT: {
      const { commentId } = payload;

      const currentInstance = { ...state.list[state.currentInstanceId] };

      if (currentInstance.comments) {
        currentInstance.comments = currentInstance.comments.filter(comment => comment._id !== commentId);
      }

      return { ...state, list: { ...state.list, [state.currentInstanceId]: currentInstance } };
    }
    case userInstancesActions.UPDATE_COMMENT: {
      const { commentId, comment } = payload;

      const currentInstance = { ...state.list[state.currentInstanceId] };

      if (currentInstance.comments) {
        const commentIndex = currentInstance.comments.findIndex(({ _id }) => _id === commentId);

        if (commentIndex !== -1) {
          currentInstance.comments = [...currentInstance.comments];
          currentInstance.comments[commentIndex] = comment;
        }
      }

      return { ...state, list: { ...state.list, [state.currentInstanceId]: currentInstance } };
    }
    default:
      return state;
  }
};

export default instanceReducer;
