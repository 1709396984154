import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { summarySelectors } from '../../modules/ui';
import useAnimated from './useAnimated';

const useNetArea = () => {
  const netArea = useSelector(summarySelectors.getNetArea);

  const animatedArea = useAnimated(netArea?.area);

  return useMemo(() => {
    if (!netArea?.area) {
      return null;
    }

    return `${Math.round(animatedArea)} ${netArea.unit}`;
  }, [animatedArea, netArea]);
};

export default useNetArea;
