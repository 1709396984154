import React from 'react';
import PropTypes from 'prop-types';
import { useNetArea, useTotalPrice, useTranslate, useAnimated } from '../../utility/hooks';
import overviewPanelStyle from './style';

const OVERVIEW_TEXT = 'Overview';

const OverviewPanelHeader = ({ isOpen }) => {
  const { rawTotalPrice, priceFormatter, currency, isPriceHidden } = useTotalPrice();
  const area = useNetArea();
  const translate = useTranslate();

  const animatedPrice = useAnimated(rawTotalPrice);

  if (isOpen) {
    return translate(OVERVIEW_TEXT);
  }

  return (
    <>
      {area ? <span className={overviewPanelStyle('area')}>{area}</span> : null}
      {isPriceHidden ? null : (
        <span className={overviewPanelStyle('price')}>
          {currency}
          {priceFormatter(animatedPrice)}
        </span>
      )}
      {!area && isPriceHidden ? translate(OVERVIEW_TEXT) : null}
    </>
  );
};

OverviewPanelHeader.propTypes = {
  isOpen: PropTypes.bool.isRequired
};

export default OverviewPanelHeader;
