import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { dialogActions } from '../../modules/dialog';
import { instanceActions, instanceSelectors } from '../../modules/instance';
import { DIALOGS } from '../dialogs';

const useSendToEmail = () => {
  const dispatch = useDispatch();
  const isSendToEmailHidden = useSelector(instanceSelectors.getIsOwner);
  const email = useSelector(instanceSelectors.selectInstanceEmail);

  const handleSendToEmail = useCallback(() => {
    if (email) {
      dispatch(instanceActions.sendInstanceToEmail(email));
    } else {
      dispatch(dialogActions.showDialog(DIALOGS.SEND_TO_EMAIL));
    }
  }, [dispatch, email]);

  return { handleSendToEmail, isSendToEmailHidden };
};

export default useSendToEmail;
