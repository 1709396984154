import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MenuSection } from '../../Menu';
import { useTranslate } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import { authSelectors } from '../../../modules/auth';
import { useVerifyTokenMutation } from '../../../store/api/api';
import TokenInvalidFeedback from './TokenInvalidFeedback';
import PasswordReset from './PasswordReset';

/* Same functionality as before in ResetPasswordDialog */
const Loader = ({ loading, children }) => (loading ? null : children);

const ResetPasswordMenu = ({ token }) => {
  const [doVerifyToken] = useVerifyTokenMutation();

  const translate = useTranslate();
  const tokenValid = useSelector(authSelectors.selectTokenValid);

  const tokenChecked = useSelector(authSelectors.selectTokenChecked);
  const verifyToken = useCallback(() => {
    doVerifyToken({ token });
  }, [doVerifyToken, token]);

  useEffect(() => {
    verifyToken(token);
  }, [token, verifyToken]);

  return (
    <MenuContent>
      <MenuSection type="reset-password" heading={translate('Reset password')} />
      <MenuSection type="forgot-form">
        <Loader loading={!tokenChecked}>
          {tokenValid ? <PasswordReset token={token} /> : <TokenInvalidFeedback />}
        </Loader>
      </MenuSection>
    </MenuContent>
  );
};

ResetPasswordMenu.propTypes = {
  token: PropTypes.string.isRequired
};

export default ResetPasswordMenu;
