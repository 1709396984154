import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dialogActions } from '../../modules/dialog';
import { DIALOGS } from '../../utility/dialogs';
import { priceSelectors } from '../../modules/model';

const ControlsValidator = () => {
  const prev = useRef(0);
  const dispatch = useDispatch();
  const removedOptions = useSelector(priceSelectors.selectRemovedOptionSections);

  useEffect(() => {
    if (removedOptions.length && removedOptions.length > prev.current) {
      dispatch(dialogActions.showDialog(DIALOGS.BROKEN_CONTROLS));
    }

    prev.current = removedOptions.length;
  }, [dispatch, removedOptions]);

  return null;
};

export default ControlsValidator;
