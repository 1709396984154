import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { modelSelectors } from '../../../modules/model';
import { settingsSelectors } from '../../../modules/settings';

const useSkybox = scene => {
  const skyboxEnabled = useSelector(settingsSelectors.selectSkyboxEnabled);
  const envMap = useSelector(modelSelectors.selectEnvMap);
  const useSkyboxAsEnv = useSelector(settingsSelectors.selectEnvMapEnabled);

  useEffect(() => {
    if (skyboxEnabled) {
      // eslint-disable-next-line no-param-reassign
      scene.background = envMap;
    }

    if (envMap && useSkyboxAsEnv) {
      // eslint-disable-next-line no-param-reassign
      scene.environment = envMap;
    }

    return () => {
      // eslint-disable-next-line no-param-reassign
      scene.background = null;
      // eslint-disable-next-line no-param-reassign
      scene.environment = null;
    };
  }, [envMap, scene, scene.background, scene.environment, skyboxEnabled, useSkyboxAsEnv]);
};

export default useSkybox;
