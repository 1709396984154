import { Matrix4 } from 'three';
import getThreeSetup from '../../components/Viewer/getThreeSetup';
import MeshContainer from './MeshContainer';

/** Stores all MeshContainers
 * Methods for interactivity and removing/adding instances to the scene.
 */
class MeshStore {
  static transferMatrix = new Matrix4();

  constructor(model) {
    /** Hold references to MeshContainers */
    this.meshes = {};
    this.materials = {};
    this.model = model;
    this.overrides = {};
    this.interaction = {
      partId: null,
      key: null
    };
    this.interactions = {};
  }

  updateMaterialReferences(materials) {
    this.materials = materials;
    Object.values(this.meshes).forEach(meshContainer => {
      meshContainer.updateMaterials(materials);
    });
  }

  updateMaterialOverrides(materials) {
    this.overrides = materials;
  }

  // by partId
  initPartMeshes(partId, meshes) {
    this.meshes[partId] = new MeshContainer(partId, meshes, this.model);
  }

  // by partId, key
  setPartMeshes(partId, key, matrix, localMaterialOverrides) {
    const meshContainer = this.meshes[partId];

    const materialOverrides = { ...this.overrides, ...localMaterialOverrides };

    if (meshContainer) meshContainer.setMatrix(key, matrix, materialOverrides);
  }

  resetPartMeshes(partId, key) {
    if (this.meshes[partId]) {
      this.resetInteraction();
      this.meshes[partId].resetMatrix(key);
    }
  }

  setPartHovering(partId, key) {
    const mesh = this.meshes[partId];

    if (mesh) mesh.setHovering(key);
    this.interaction.partId = partId;
    this.interaction.key = key;
  }

  resetPartHovering(partId, key) {
    const mesh = this.meshes[partId];

    if (mesh) mesh.resetHovering(key);
    this.interaction.key = null;
    this.interaction.partId = null;
  }

  resetInteraction() {
    const { partId, key, sprite } = this.interaction;

    if (partId && key) {
      this.resetPartHovering(partId, key);
    }

    if (sprite?.userData?.originalMaterial) {
      sprite.material = sprite.userData.originalMaterial;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleSpriteHover({ object: sprite }) {
    this.resetInteraction();
    const { interaction } = sprite.userData;

    if (interaction?.enabled) {
      // eslint-disable-next-line no-param-reassign
      sprite.material = sprite.userData.hoverMaterial || sprite.material;
      this.interaction = { sprite };
    }
  }

  handleObjectHover(interaction) {
    if (!interaction) {
      this.resetInteraction();

      return false;
    }

    const { object, instanceId } = interaction;

    if (object.isSprite) {
      this.handleSpriteHover(interaction);

      return true;
    }

    if (!(object.isInstancedMesh || object.userData?.isInteractionMesh)) {
      this.resetInteraction();

      return false;
    }

    const partInteraction = this.getPartInteraction(object, instanceId);

    if (!partInteraction?.enabled) {
      this.resetInteraction();

      return false;
    }

    const { isInteractionMesh } = object.userData;

    if (isInteractionMesh) {
      return true;
    }

    // object was regular mesh
    // show previous
    this.resetInteraction();
    // interact with this one
    const { name: partId, isInstancedMesh } = object;

    if (isInstancedMesh /* && is interactive mesh */) {
      const key = object.userData.indices[instanceId];

      this.setPartHovering(partId, key);
    }

    return false;
  }

  setPartInteraction = (partKey, interaction) => {
    this.interactions[partKey] = interaction;
  };

  setPartHoverMaterial(partId, key, materialName) {
    const meshes = this.meshes[partId];

    if (meshes && materialName) {
      meshes.setHoverMaterial(key, materialName);
    }
  }

  getPartInteraction(object, instanceId) {
    const { isInteractionMesh } = object.userData;

    let key;

    if (isInteractionMesh) {
      key = object.userData.key;
    } else {
      key = object.userData.indices[instanceId];
    }

    return this.interactions[key];
  }
}

const { model } = getThreeSetup();

const meshStore = new MeshStore(model);

export default meshStore;
