import { Sprite } from 'three';
import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { geometriesSelectors } from '../../../modules/geometries';
import { loaderSelectors, loaderActions } from '../../../modules/loader';
import { selectSpriteMaterials } from '../../../store/materials/materialSelectors';
import { texturesSelectors } from '../../../modules/textures';
import meshStore from '../../../modules/geometries/MeshStore';

const defaultSpriteTransformMatrix = [2000, 0, 0, 0, 0, 2000, 0, 0, 0, 0, 2000, 0, 0, 0, 0, 1];

const getSpritesToAdd = (part, spriteMaterials, matrix) => {
  const { Sprites: partSprites = {}, Config } = part;
  const { list = [] } = partSprites;

  return list.map(({ material, transform, interaction, center = {} }) => {
    const spriteMaterial = spriteMaterials[material];
    const { x: centerX = 0.5, y: centerY = 0.5 } = center;
    const threeSprite = new Sprite(spriteMaterial);

    threeSprite.center.set(centerX, centerY);

    if (interaction) {
      threeSprite.userData.interaction = interaction;
      threeSprite.userData.originalMaterial = spriteMaterial;
      threeSprite.userData.hoverMaterial = spriteMaterials[interaction.hoverMaterial];
    }

    threeSprite.userData.key = part.key;
    threeSprite.userData.excludeFromSnapshots = Config?.excludeFromSnapshots;

    const transformMatrix = transform?.Matrix?.Values || defaultSpriteTransformMatrix;

    threeSprite.matrix.set(...transformMatrix);
    threeSprite.matrix.premultiply(matrix);
    threeSprite.matrixAutoUpdate = false;

    return threeSprite;
  });
};

const useParts = (parts, model) => {
  const geomsDone = useSelector(geometriesSelectors.getIsGeometriesReceived);
  const texturesDone = useSelector(texturesSelectors.getIsTexturesReceived);
  const partsDone = useSelector(loaderSelectors.getIsPartsLoaded);
  const spriteMaterials = useSelector(selectSpriteMaterials);

  const dispatch = useDispatch();
  const { current: reusableGroups } = useRef({});

  useEffect(() => {
    if (geomsDone && texturesDone && partsDone) {
      const sprites = [];

      parts.forEach(part => {
        meshStore.setPartMeshes(part._id, part.key, part.matrix, part.Parameters.$materialOverrides);
        meshStore.setPartInteraction(part.key, part.Config?.interaction);

        const partSprites = getSpritesToAdd(part, spriteMaterials, part.matrix);

        if (partSprites.length) sprites.push(...partSprites);
      });

      if (sprites.length) model.add(...sprites);

      dispatch(loaderActions.setSceneLoaded());

      return () => {
        parts.forEach(({ _id, key }) => {
          meshStore.resetPartMeshes(_id, key);
        });

        if (sprites.length) model.remove(...sprites);
      };
    }

    return () => null;
  }, [dispatch, geomsDone, parts, partsDone, reusableGroups, model, spriteMaterials, texturesDone]);
};

export default useParts;
