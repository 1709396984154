import { useSelector } from 'react-redux';
import { uiSelectors, uiModesSelectors } from '../../modules/ui';

const useUiModes = () => {
  const uiMode = useSelector(uiSelectors.selectUiMode);
  const isMinimalUi = useSelector(uiModesSelectors.selectIsMinimalUi);
  const minimalUiSettings = useSelector(uiModesSelectors.selectMinimalUiSettings);

  return { UI_MODES: uiModesSelectors.UI_MODES, isMinimalUi, minimalUiSettings, uiMode };
};

export default useUiModes;
