import React from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../../utility/hooks';
import SummaryInfo from '../../SummaryInfo';
import Caveats from '../../Caveats';
import { uiSelectors } from '../../../modules/ui';
import { instanceSelectors } from '../../../modules/instance';
import MainActions from '../../MainActions';
import SummarySaveButton from './SummarySaveButton';

import './SummaryDetails.scss';

const summaryDetailsStyle = b.with('summary-details');

const SummaryDetails = () => {
  const translate = useTranslate();
  const isSummaryTab = useSelector(uiSelectors.getIsSummaryTab);
  const instanceName = useSelector(instanceSelectors.getInstanceName);
  const isOwner = useSelector(instanceSelectors.getIsOwner);

  return (
    <div className={summaryDetailsStyle()}>
      <div className={summaryDetailsStyle('header')}>
        <span className={summaryDetailsStyle('title')}>{translate('Summary')}</span>
        <div className={summaryDetailsStyle('instance-info')}>
          {instanceName ? (
            <>
              <strong className={summaryDetailsStyle('instance-name')}>«{instanceName}»</strong>
              {isOwner ? null : <SummarySaveButton mix={summaryDetailsStyle('save-btn')} />}
            </>
          ) : null}
          {!instanceName && isSummaryTab ? (
            <>
              <strong className={summaryDetailsStyle('unsaved-mark')}>*</strong>
              <strong className={summaryDetailsStyle('instance-name')}>«{translate('Untitled design')}»</strong>
              <SummarySaveButton mix={summaryDetailsStyle('save-btn')} />
            </>
          ) : null}
        </div>
      </div>
      <div className={summaryDetailsStyle('info')}>
        <SummaryInfo />
      </div>
      <div className={summaryDetailsStyle('caveats')}>
        <Caveats />
      </div>
      <div className={summaryDetailsStyle('actions')}>
        <MainActions />
      </div>
    </div>
  );
};

export default SummaryDetails;
