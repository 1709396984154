/* eslint-disable no-param-reassign */
import {
  Vector2,
  AmbientLight,
  DirectionalLight,
  HemisphereLight,
  RectAreaLight,
  SpotLight,
  PointLight,
  Object3D,
  Matrix4
} from 'three';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { radians } from '../../radians';
import { LIGHTS } from '../../lightDefinitions';
import { modelSelectors } from '../../../modules/model';

const defaultShadowMapSize = new Vector2(1024, 1024);

const setShadowParameters = (light, shadowCamera, castShadow = false, shadowMapSize = defaultShadowMapSize) => {
  if (castShadow) {
    Object.assign(light, { castShadow, depthBias: 0.01 });
    Object.assign(light.shadow, { bias: -0.001, mapSize: shadowMapSize });

    if (shadowCamera) {
      Object.assign(light.shadow.camera, shadowCamera);
    }
  }
};

const createThreeLight = {
  [LIGHTS.AMBIENT]: ({ color, intensity }) => new AmbientLight(color, intensity),
  [LIGHTS.DIRECTIONAL]: ({ color, intensity, shadowCamera, castShadow, shadowMapSize }) => {
    const light = new DirectionalLight(color, intensity);

    setShadowParameters(light, shadowCamera, castShadow, shadowMapSize);

    return light;
  },
  [LIGHTS.HEMI]: ({ color, secondaryColor, intensity }) => new HemisphereLight(color, secondaryColor, intensity),
  [LIGHTS.RECT]: ({ color, intensity, width, height }) => new RectAreaLight(color, intensity, width, height),
  [LIGHTS.SPOT]: ({ color, intensity, distance, angle, penumbra, decay, castShadow, shadowCamera, shadowMapSize }) => {
    const light = new SpotLight(color, intensity, distance, radians(angle), penumbra, decay);

    setShadowParameters(light, shadowCamera, castShadow, shadowMapSize);

    return light;
  },
  [LIGHTS.POINT]: ({ color, intensity, distance, decay }) => new PointLight(color, intensity, distance, decay)
};

const reusableTranslationMatrix = new Matrix4();

const updatePositionAndLookAt = (light, position, lookAt, parentPart) => {
  if (position) {
    light.matrix.copy(reusableTranslationMatrix.makeTranslation(position.x, position.y, position.z));
  }

  if (lookAt) {
    const target = light.target || new Object3D();

    target.name = `${light.name}-targetDummy`;
    target.position.copy(lookAt);
    // eslint-disable-next-line no-param-reassign
    light.target = target;
    light.add(light.target);
  }

  if (parentPart) light.applyMatrix4(parentPart.matrix);
};

const useLights = (model, parts) => {
  // get sunlight
  const lightsToRender = useSelector(modelSelectors.selectLightsToRender);
  const cache = useRef({});

  // effect to create lights

  // All lights get cached based on their name, type and parent part treeId.
  // ipads get max 8 lights and 4 of them are used for hardcoded scene lights
  // so only 4 lights in total in any position of tree are available for now.
  const lights = useMemo(() => {
    return lightsToRender.map(lightDef => {
      const { type, name, partName = '_hardcoded', position, lookAt } = lightDef;
      const cacheKey = JSON.stringify([type, name, partName]);

      const parentPart = parts.find(({ key }) => key === partName);

      if (cache.current[cacheKey]) {
        const cachedLight = cache.current[cacheKey];

        updatePositionAndLookAt(cachedLight, position, lookAt, parentPart);

        return cachedLight;
      }

      const createLight = createThreeLight[type] || createThreeLight[LIGHTS.POINT];
      const light = createLight(lightDef);

      light.matrixAutoUpdate = false;
      light.name = `${partName}|${name}`;

      updatePositionAndLookAt(light, position, lookAt, parentPart);

      cache.current[cacheKey] = light;

      model.add(light);
      light.visible = false;

      return light;
    });
  }, [lightsToRender, model, parts]);

  // effect to manage scene lights
  /*
  All lights in memoized array are set visible in model
  All cached lights that are not visible are destroyed
  On update all lights are set to invisible.

  This enables keeping cached lights in scene but also cleaning up if lights setup changes
  */
  useEffect(() => {
    lights.forEach(light => {
      light.visible = true;
    });

    // remove and dispose all lights that were cached but not used
    Object.entries(cache.current).forEach(([key, light]) => {
      if (!light.visible) {
        model.remove(light);
        delete cache.current[key];
        light.dispose();
      }
    });

    return () => {
      lights.forEach(light => {
        light.visible = false;
      });
    };
  }, [lights, model]);
};

export default useLights;
