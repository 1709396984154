import { useCallback } from 'react';
import useEventListener from './useEventListener';

const ESC_KEY = 27;

const useEscListener = callback => {
  const escListener = useCallback(
    e => {
      if (e.keyCode === ESC_KEY) {
        callback();
      }
    },
    [callback]
  );

  useEventListener('keydown', escListener, document);
};

export default useEscListener;
