import { call, takeLatest, all } from 'redux-saga/effects';
import { API_URL } from '../../config';
import { SEND_TIME_STATS } from './statisticsActions';

function sendTime(data) {
  return fetch(`${API_URL}/stats`, {
    method: 'POST',
    credentials: 'include',
    body: data
  });
}

function* sendTimeStats(action) {
  yield call(sendTime, action.payload.data);
}

function* watchSendTimeStats() {
  yield takeLatest(SEND_TIME_STATS, sendTimeStats);
}

export default function* moduleSaga() {
  yield all([watchSendTimeStats()]);
}
