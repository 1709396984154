import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TYPE_PART_CONTROL } from '../../../../../utility/propTypes';
import { controlStyle } from '../../style';
import { Button } from '../../../../Atoms';
import { useTranslate } from '../../../../../utility/hooks';
import { CONTROL_TAGS } from '../../../../../utility/controlDefinitions';

const TAGS_MODES = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox'
};

const excludeAllTag = list => list.filter(tag => tag !== CONTROL_TAGS.ALL);

const useTags = (control, onChangeActiveTags) => {
  const [activeTags, setActiveTags] = useState([]);
  const [tagList, setTagList] = useState([]);

  const initialized = tagList.length !== 0;

  const isRadioMode = control.tags?.mode === TAGS_MODES.RADIO;

  useEffect(() => {
    if (!initialized) {
      return;
    }

    onChangeActiveTags(activeTags);
  }, [initialized, activeTags, onChangeActiveTags]);

  useEffect(() => {
    if (initialized) {
      return;
    }

    const { tags = {}, list: options = [] } = control;
    const { defaults = [], list = [], isAllTagVisible } = tags;
    const hasOtherTag = options.some(({ tags: optionTags }) => optionTags[0] === CONTROL_TAGS.OTHER);

    const newList = isAllTagVisible ? [CONTROL_TAGS.ALL, ...list] : [...list];

    if (hasOtherTag) {
      newList.push(CONTROL_TAGS.OTHER);
    }

    const defaultTags = defaults.length !== 0 ? defaults : newList;

    setTagList(newList);

    setActiveTags(isRadioMode ? [defaultTags[0]] : excludeAllTag(defaultTags));
  }, [control, initialized, isRadioMode]);

  const tagListWithoutAllTag = useMemo(() => excludeAllTag(tagList), [tagList]);

  const handleClick = useCallback(
    e => {
      const { tag } = e.currentTarget.dataset;

      const isAll = tag === CONTROL_TAGS.ALL;

      if (isRadioMode) {
        setActiveTags([tag]);

        return;
      }

      if (isAll) {
        setActiveTags(prev => (prev.length === tagListWithoutAllTag.length ? [] : tagListWithoutAllTag));

        return;
      }

      setActiveTags(previous => (previous.includes(tag) ? previous.filter(item => item !== tag) : [...previous, tag]));
    },
    [isRadioMode, tagListWithoutAllTag]
  );

  const selectedOptionTags = useMemo(() => {
    const optionTags = ((control.list || []).find(({ name }) => name === control.value) || {}).tags;

    return new Set(optionTags);
  }, [control.list, control.value]);

  return { activeTags, handleClick, isRadioMode, tagList, tagListWithoutAllTag, selectedOptionTags };
};

const ControlTags = ({ control, onChangeActiveTags }) => {
  const { activeTags, tagList, handleClick, isRadioMode, tagListWithoutAllTag, selectedOptionTags } = useTags(
    control,
    onChangeActiveTags
  );

  const translate = useTranslate();

  return (
    <div className={controlStyle('tags')}>
      {tagList.map(tag => (
        <Button
          key={tag}
          size="xs"
          color={
            activeTags.includes(tag) || (tag === CONTROL_TAGS.ALL && activeTags.length === tagListWithoutAllTag.length)
              ? 'main'
              : 'main-outline'
          }
          rounded
          onClick={handleClick}
          mix={controlStyle('tag', {
            disabled: isRadioMode && activeTags.includes(tag),
            marked: selectedOptionTags.has(tag)
          })}
          data-tag={tag}
          label={translate(tag)}
        />
      ))}
    </div>
  );
};

ControlTags.propTypes = {
  control: TYPE_PART_CONTROL.isRequired,
  onChangeActiveTags: PropTypes.func.isRequired
};

export default ControlTags;
