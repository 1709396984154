import React, { useCallback, useRef, useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useOnClickOutside, useSwitch, useTranslate } from '../../utility/hooks';
import { summarySelectors, uiSelectors } from '../../modules/ui';
import Icon from '../Icon';
import MainActions from '../MainActions';
import { REVIEW_TAB } from '../../modules/ui/tabNames';
import { dialogSelectors } from '../../modules/dialog';
import overviewPanelStyle from './style';
import OverviewPanelHeader from './OverviewPanelHeader';
import OverviewMetric from './OverviewMetric';
import './OverviewPanel.scss';

const OverviewPanel = ({ viewerRef }) => {
  const panelRef = useRef();
  const translate = useTranslate();
  const [userValue, setUserValue] = useState(undefined);
  const [maxHeight, setMaxHeight] = useState(300);
  const [activeMetricName, setActiveMetricName] = useState('');

  const handleToggleMetricMoreInfo = useCallback(e => {
    const { name } = e.currentTarget.dataset;

    setActiveMetricName(current => (current === name ? '' : name));
  }, []);

  const isMobileViewport = useSelector(uiSelectors.selectIsMobileViewport);
  const isMobileNavigation = useSelector(uiSelectors.selectIsMobileNavigation);
  const dialog = useSelector(dialogSelectors.selectDialog);
  const currentTabName = useSelector(uiSelectors.selectCurrentTabName);
  const metrics = useSelector(summarySelectors.selectMetrics);

  const { isOpen, toggle, close, set } = useSwitch(!isMobileViewport && currentTabName !== REVIEW_TAB);

  const isPanelHidden = useSelector(uiSelectors.selectCurrentTabSummaryDisabled);

  const handleClosePanel = useCallback(() => {
    if (isMobileViewport) {
      close();
    }
  }, [close, isMobileViewport]);

  const handlerSetMaxHeight = useCallback(() => {
    if (viewerRef.current) {
      setMaxHeight(viewerRef.current.clientHeight - 80); // 80 - padding and overview panel header
    }
  }, [viewerRef]);

  const handleToggle = useCallback(
    e => {
      const { open } = e.currentTarget.dataset;

      toggle();
      handlerSetMaxHeight();
      setUserValue(open === 'false');
    },
    [handlerSetMaxHeight, toggle]
  );

  useOnClickOutside(panelRef, handleClosePanel, isOpen);

  useEffect(() => {
    handlerSetMaxHeight();
  }, [handlerSetMaxHeight, viewerRef]);

  useEffect(() => {
    window.addEventListener('resize', handlerSetMaxHeight);

    return () => window.removeEventListener('resize', handlerSetMaxHeight);
  }, [handlerSetMaxHeight]);

  useEffect(() => {
    if (currentTabName !== REVIEW_TAB && userValue !== undefined && !isMobileViewport) {
      set(userValue);
    }
  }, [close, currentTabName, isMobileViewport, set, userValue]);

  /** Close panel if review tab */
  useEffect(() => {
    if (currentTabName === REVIEW_TAB) {
      close();
    }
  }, [close, currentTabName]);

  /** Close panel when opening a dialog on mobile */
  useEffect(() => {
    if (dialog && dialog.type && isMobileNavigation) {
      close();
    }
  }, [close, dialog, isMobileNavigation]);

  const activeMetricIndex = activeMetricName ? metrics.findIndex(item => item.name === activeMetricName) : -1;
  const activeMetric = activeMetricIndex === -1 ? null : metrics[activeMetricIndex];

  useEffect(() => {
    if (activeMetric && !activeMetric.caveat) {
      setActiveMetricName('');
    }
  }, [activeMetric]);

  if (isPanelHidden) {
    return null;
  }

  const insertCaveat = index => {
    if (activeMetricIndex === -1 || !activeMetric.caveat) return null;

    const isOdd = index % 2 === 0;
    const isEven = !isOdd;
    const isLast = index === metrics.length - 1;

    if (
      (isOdd && isLast && index === activeMetricIndex) ||
      (isEven && (activeMetricIndex === index || activeMetricIndex === index - 1))
    ) {
      return (
        <div className={overviewPanelStyle('caveat')}>
          {activeMetric.caveat.map((content, caveatContentIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={caveatContentIndex}>{translate(content)}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={overviewPanelStyle({ open: isOpen })}
      ref={panelRef}
      aria-label={!isOpen ? translate('Overview') : undefined}
      data-balloon-pos="left"
    >
      <div className={overviewPanelStyle('container')}>
        <button className={overviewPanelStyle('header')} onClick={handleToggle} type="button" data-open={isOpen}>
          <span className={overviewPanelStyle('header-content')}>
            <OverviewPanelHeader isOpen={isOpen} />
          </span>
          <Icon type={isOpen ? 'chevron-down-up' : 'chevron-up-down'} />
        </button>
        <div className={overviewPanelStyle('body', { open: isOpen })} style={{ maxHeight: isOpen ? maxHeight : '0px' }}>
          <div className={overviewPanelStyle('content')}>
            <div className={overviewPanelStyle('section', { type: 'metrics' })}>
              {metrics.map((metric, metricIndex) => (
                <Fragment key={metric.name}>
                  <OverviewMetric
                    onToggleMoreInfo={handleToggleMetricMoreInfo}
                    isMoreInfoActive={activeMetricName === metric.name}
                    metric={metric}
                  />
                  {
                    // if either index is even and current or previous metric has caveat open, or index is odd, last and has caveat open, insert it here
                    //
                    insertCaveat(metricIndex)
                  }
                </Fragment>
              ))}
            </div>
            <div className={overviewPanelStyle('section', { type: 'actions' })}>
              <MainActions />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OverviewPanel.propTypes = {
  viewerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
    .isRequired
};

export default OverviewPanel;
