import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TYPE_NOTIFICATION } from '../../utility/propTypes';
import { usePageReload } from '../../utility/hooks';
import Icon from '../Icon';
import toastsStyle from './style';

const ICON = {
  success: 'check',
  error: 'alert'
};

const Toast = ({ notification, onHide }) => {
  const { id, message, type, timeout = 2000, showReloadLink } = notification;
  const reload = usePageReload();

  const hide = useCallback(() => {
    onHide(id);
  }, [id, onHide]);

  useEffect(() => {
    const timer = setTimeout(hide, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [timeout, hide]);

  return (
    <div className={toastsStyle('toast', { type })}>
      <span className={toastsStyle('icon')}>
        <Icon type={ICON[type]} scale={1.2} />
      </span>
      <span className={toastsStyle('message')}>
        {message}
        {showReloadLink ? (
          <>
            {'. '}
            {/* eslint-disable-next-line */}
            <a className={toastsStyle('reload')} onClick={reload} role="button">
              (click here to reload)
            </a>
          </>
        ) : null}
      </span>
      <button className={toastsStyle('close')} onClick={hide} type="button" title="Hide notification">
        <Icon type="close" scale={1.4} />
      </button>
    </div>
  );
};

Toast.propTypes = {
  notification: TYPE_NOTIFICATION.isRequired,
  onHide: PropTypes.func.isRequired
};

export default Toast;
