import { createSelector } from 'reselect';
import { selectSettings } from './settingsSelectors';

const selectOptionStatusSettings = createSelector([selectSettings], ({ optionStatuses = [] }) =>
  Array.isArray(optionStatuses) ? optionStatuses : []
);

const selectOptionStatuses = createSelector([selectOptionStatusSettings], optionStatuses => {
  const defaultOptionStatusMap = [
    { name: 'Not released', disabledState: true },
    { name: 'Sold', label: 'Sold', lockedState: true, priceHiddenState: true },
    { name: 'Reserved', label: 'Reserved', inactiveState: true, priceHiddenState: true }
  ];

  return optionStatuses.length > 0 ? optionStatuses : defaultOptionStatusMap;
});

// Maps optionsStatuses to another format and assigns them to a map by name. Why is the re-mapping needed?
// Used by getEnvControls and getPartNodesToRender, probably a selector to keep it from evaluating this every time
// eslint-disable-next-line import/prefer-default-export
export const selectOptionStatusMap = createSelector([selectOptionStatuses], optionStatuses => {
  // Each of the following varName is queried in optionStatuses
  // If its explicitly set then targetField will be set to its value
  // Later in env controls and treeControls option status is checked against this map - if finds anything, will overwrite option's  fields
  const varNames = [
    { varName: 'disabledState', targetField: 'disabled' },
    { varName: 'lockedState', targetField: 'locked' },
    { varName: 'inactiveState', targetField: 'inactive' },
    { varName: 'priceHiddenState', targetField: 'priceHidden' },
    { varName: 'areaHiddenState', targetField: 'areaHidden' },
    { varName: 'optionHintHiddenState', targetField: 'optionHintHidden' },
    { varName: 'forceReselectState', targetField: 'forceReselect' }
  ];

  return optionStatuses.reduce((result, optionStatus) => {
    const initialStatusMap = {
      statusLabel: optionStatus.label,
      statusDescription: optionStatus.description
    };

    // eslint-disable-next-line no-param-reassign
    result[optionStatus.name] = varNames.reduce((statusMap, { varName, targetField }) => {
      // filter out true and false, anything else is not recorded to overlay
      if (optionStatus[varName] !== undefined) {
        // eslint-disable-next-line no-param-reassign
        statusMap[targetField] = optionStatus[varName];
      }

      return statusMap;
    }, initialStatusMap);

    return result;
  }, {});
});
