// functions and constants for area calculation from millimetre/inch based geometry to foot/meter based areas
import { ModelUnitsType } from 'types';
import { MODEL_UNITS } from './viewerSettings';

const UNITS = {
  IMPERIAL: {
    areaUnit: 'ft²',
    multiplier: 144
  },
  METRIC: {
    areaUnit: 'm²',
    multiplier: 1000000
  }
};

const unitMap = {
  imperial: UNITS.IMPERIAL,
  metric: UNITS.METRIC
};

const getAreaLabel = (area: number, modelUnits: ModelUnitsType = MODEL_UNITS.METRIC) => {
  const unit = modelUnits === MODEL_UNITS.METRIC ? UNITS.METRIC.areaUnit : UNITS.IMPERIAL.areaUnit;

  return `${area} ${unit}`;
};

const convertArea = ({
  area = null,
  rounding = 1,
  modelUnits = MODEL_UNITS.METRIC
}: {
  area: number | null;
  rounding: number;
  modelUnits: ModelUnitsType;
}) => {
  if (area) {
    const map = unitMap[modelUnits] ? unitMap[modelUnits] : unitMap.metric;
    const convertedArea = area / map.multiplier;
    const roundedArea = Math.round(convertedArea / (rounding || 1)) * rounding;

    return {
      label: `${roundedArea} ${map.areaUnit}`,
      area: convertedArea,
      roundedArea,
      unit: map.areaUnit
    };
  }

  return { area: null, roundedArea: null };
};

export { UNITS, convertArea, getAreaLabel };
