import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { seedSelectors } from '../../modules/seed';
import { uiActions, uiSelectors } from '../../modules/ui';
import { Notification } from '../Atoms';
import { useLinkGenerators, useTranslate } from '../../utility/hooks';

const MESSAGE =
  "We recently updated it. New version is already available for you to explore. If you keep using the old one, you won't be able to save or share your designs anymore.";

const SeedDeprecationNotification = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const visible = useSelector(uiSelectors.selectIsDeprecationNotificationVisible);
  const isDeprecated = useSelector(seedSelectors.selectIsSeedDeprecated);
  const link = useSelector(seedSelectors.selectLinkToNewProject);
  const { getConfiguratorLink } = useLinkGenerators();
  const newLink = useMemo(() => getConfiguratorLink(`/project/${link}`), [getConfiguratorLink, link]);

  const handleClose = useCallback(() => {
    dispatch(uiActions.toggleDeprecationNotification(false));
  }, [dispatch]);

  const handleOpenNewVersion = useCallback(() => {
    window.open(newLink, '_self');
  }, [newLink]);

  useEffect(() => {
    if (isDeprecated) {
      dispatch(uiActions.toggleDeprecationNotification(true));
    }
  }, [dispatch, isDeprecated]);

  return visible ? (
    <Notification
      header={translate('This is an old version of the configurator')}
      mainButtonTitle={translate('Open new version')}
      onMainButtonClick={handleOpenNewVersion}
      secondaryButtonTitle={translate('Keep using old version')}
      onSecondaryButtonClick={handleClose}
    >
      {translate(MESSAGE)}
      <br />
      {translate('Would you like to switch to the new version?')}
    </Notification>
  ) : null;
};

export default SeedDeprecationNotification;
