import { useSelector } from 'react-redux';
import { nodesSelectors } from '../../../modules/model';
import useMaterials from './useMaterials';
import useParts from './useParts';
import useLights from './useLights';
import useHoverMaterials from './useHoverMaterials';

const useScene = model => {
  const parts = useSelector(nodesSelectors.getPartNodesToRender);

  useMaterials();

  useParts(parts, model);

  useHoverMaterials(parts);

  useLights(model, parts);
};

export default useScene;
