import React from 'react';
import { useSelector } from 'react-redux';
import { MenuSection } from '../../Menu';
import { useTranslate, useResetConfiguratorHandler } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import { instanceSelectors } from '../../../modules/instance';
import { Button } from '../../Atoms';
import SavedDesign from './SavedDesign';
import savedDesign from './style';

const SavedDesignsMenu = () => {
  const translate = useTranslate();

  const userInstances = useSelector(instanceSelectors.selectUserInstancesWithoutChild);

  const currentInstance = useSelector(instanceSelectors.selectInstance);
  const isOwnInstance = useSelector(instanceSelectors.getIsOwner);
  const isInitialInstance = useSelector(instanceSelectors.selectIsInitialInstance);

  const handleCreateNew = useResetConfiguratorHandler();

  const currentHeading = isOwnInstance ? translate('Currently editing') : translate('Currently viewing');

  return (
    <MenuContent>
      {currentInstance && currentInstance._id && !isInitialInstance ? (
        <MenuSection type="designs-current" heading={currentHeading}>
          <SavedDesign
            instanceId={currentInstance._id}
            name={currentInstance.instanceName}
            updatedAt={currentInstance.updatedAt}
            seedId={currentInstance.seed?.root}
            projectName={currentInstance.populated?.projectName}
            lead={currentInstance.lead}
            active={!currentInstance.populated?.seedDeprecated}
            deprecated={currentInstance.populated?.seedDeprecated}
          />
        </MenuSection>
      ) : null}

      <MenuSection type="designs-list" heading={translate('Saved designs')}>
        {userInstances
          .filter(({ _id }) => !currentInstance || currentInstance._id !== _id)
          .map(({ _id, instanceName, updatedAt, seed, lead, populated }) => {
            return (
              <SavedDesign
                key={_id}
                instanceId={_id}
                name={instanceName}
                updatedAt={updatedAt}
                seedId={seed?.root}
                projectName={populated?.projectName}
                deprecated={populated?.seedDeprecated}
                lead={lead}
              />
            );
          })}
        <Button block rounded mix={savedDesign('create-new')} onClick={handleCreateNew}>
          {translate('Create a new design')}
        </Button>
      </MenuSection>
    </MenuContent>
  );
};

export default SavedDesignsMenu;
