import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { statisticsActions } from '../modules/statistics';
import { API_URL } from '../config';
import { authSelectors } from '../modules/auth';
import { seedSelectors } from '../modules/seed';
import { uiSelectors } from '../modules/ui';
import { useUiModes } from '../utility/hooks';
import Cookies from './Cookies';
import MenuDialogs from './MenuDialogs';
import Content from './Content';
import SplashScreen from './SplashScreen/SplashScreen';
import TabInterceptor from './TabInterceptor';
import MobileNavigationWatcher from './MobileNavigationWatcher';
import MenuButton from './MenuDialogs/MainMenu/MenuButton';
import SceneGenerator from './SceneGenerator';
import SeedDeprecationNotification from './SeedDeprecationNotification';

function sleep(ms) {
  const currentTime = new Date().getTime();

  // eslint-disable-next-line no-empty
  while (currentTime + ms >= new Date().getTime()) {}
}

// effect to log statistics
const useStatistics = () => {
  const dispatch = useDispatch();
  const seedId = useSelector(seedSelectors.selectSeedId);
  const currentUser = useSelector(authSelectors.selectUser);

  const [startTime] = useState(new Date());

  const unloadHandler = useCallback(() => {
    const time = new Date() - startTime;
    const userId = currentUser ? currentUser.uid : null;
    const data = new FormData();

    data.append('time', time);
    data.append('seedId', seedId);
    data.append('userId', userId);

    if (navigator && navigator.sendBeacon) {
      navigator.sendBeacon(`${API_URL}/stats`, data);
    } else {
      sleep(500);

      dispatch(statisticsActions.sendTimeStats(data));
    }
  }, [startTime, currentUser, seedId, dispatch]);

  useEffect(() => {
    window.addEventListener('beforeunload', unloadHandler);

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [unloadHandler]);
};

const Configurator = () => {
  useStatistics();

  const isIndexTab = useSelector(uiSelectors.getIsIndexTab);
  const isSummaryTab = useSelector(uiSelectors.getIsSummaryTab);
  const { isMinimalUi } = useUiModes();

  return (
    <>
      {isIndexTab || isSummaryTab ? null : <MenuButton type="configurator" />}
      <MenuDialogs />
      <Content isMinimalUi={isMinimalUi} />
      <SplashScreen />
      {isMinimalUi ? null : <Cookies />}
      <TabInterceptor />
      <MobileNavigationWatcher />
      <SceneGenerator />
      <SeedDeprecationNotification />
    </>
  );
};

export default Configurator;
