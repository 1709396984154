import { useCallback, useMemo, useState, useEffect, useRef, SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { PartNodeControlType, PartNodeOptionType } from 'types';
import { authSelectors } from '../../../modules/auth';
import { useScrollIntoView } from '../../../utility/hooks';
import ControlFooter from './ControlFooter';
import { controlStyle } from './style';
import BrokenControl from './BrokenControl';
import ControlHeader from './ControlHeader';
import ControlChildTabButton from './ControlChildTabButton';
import ControlWrapper from './ControlWrapper';
import ControlInstanceLoaderButton from './ControlInstanceLoaderButton';
import ControlOptions from './ControlOptions';

type OnShowExtraInfoType = (collection: string, manualTrigger?: boolean) => void;

/** Handler for Extra Info button */
const useExtraInfoHandler = (
  control: PartNodeControlType,
  onCloseExtraInfo: () => void,
  isControlGroupSelected: boolean,
  onShowExtraInfo: OnShowExtraInfoType,
  selectControlGroup: (c: PartNodeControlType) => void,
  selectedOption?: PartNodeOptionType,
  currentExtraInfoCollection?: string
) => {
  const selectedOptionEIC = selectedOption?.extraInfoCollection;

  return useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();

      if (!isControlGroupSelected) selectControlGroup(control);

      if (selectedOptionEIC === currentExtraInfoCollection) {
        onCloseExtraInfo();
      } else if (selectedOptionEIC) {
        onShowExtraInfo(selectedOptionEIC, true);
      }
    },
    [
      control,
      isControlGroupSelected,
      selectedOptionEIC,
      currentExtraInfoCollection,
      onCloseExtraInfo,
      selectControlGroup,
      onShowExtraInfo
    ]
  );
};

const useValidateControl = (
  control: PartNodeControlType,
  onOptionSelect: (c: PartNodeControlType, o: PartNodeOptionType) => void,
  selectedOption?: PartNodeOptionType
) => {
  const availableOptions = control.list.filter(option => !option.locked && !option.disabled);

  const handleFixControlValue = useCallback(() => {
    const validOption = availableOptions.find(option => option.name === control.default) || availableOptions[0];

    if (validOption) {
      onOptionSelect(control, validOption);
    }
  }, [availableOptions, control, onOptionSelect]);

  const broken = useMemo(() => {
    if (selectedOption?.locked || selectedOption?.disabled) return true;

    if (!selectedOption && control.list.length) return true;

    return false;
  }, [control.list.length, selectedOption]);

  return { broken, handleFixControlValue, hasValidOptions: Boolean(availableOptions.length) };
};

const useControlEffects = (
  isOpen: boolean,
  setOpen: (b: boolean) => void,
  control: PartNodeControlType,
  isCollapsable: boolean,
  isSelected: boolean,
  onShowExtraInfo: OnShowExtraInfoType,
  onCloseExtraInfo: () => void,
  isMobileNavigation: boolean,
  selectedOption?: PartNodeOptionType
) => {
  // effect to close collapsible on unselecting
  useEffect(() => {
    if (isOpen && isCollapsable && !isSelected) {
      setOpen(false);
    }
  }, [isCollapsable, isOpen, isSelected, onCloseExtraInfo, setOpen]);

  // effect show EI on option select & collapsible open
  const extraInfoCollection = selectedOption?.extraInfoCollection;
  const bindExtraInfo = control?.bindExtraInfo;

  /**
   * If controls becomes selected and is opened and has bindExtraInfo and has extraInfoCollection
   * then show extra info
   * clean up afterwards
   */
  useEffect(() => {
    if (isSelected && isOpen && bindExtraInfo && extraInfoCollection) {
      onShowExtraInfo(extraInfoCollection);

      return () => {
        onCloseExtraInfo();
      };
    }

    return undefined;
  }, [bindExtraInfo, isCollapsable, extraInfoCollection, isSelected, isOpen, onShowExtraInfo, onCloseExtraInfo]);

  useEffect(() => {
    if (isMobileNavigation) {
      setOpen(true);
    }
  }, [isMobileNavigation, setOpen]);
};

interface ControlProps {
  control: PartNodeControlType;
  onSelect: (c: PartNodeControlType) => void;
  isSelected?: boolean;
  onOptionSelect: (c: PartNodeControlType, o: PartNodeOptionType) => void;
  onShowExtraInfo: OnShowExtraInfoType;
  onCloseExtraInfo: () => void;
  currentExtraInfoCollection?: string;
  isMobileNavigation?: boolean;
}

const Control = ({
  control,
  onSelect,
  isSelected = false,
  onOptionSelect,
  onShowExtraInfo,
  onCloseExtraInfo,
  currentExtraInfoCollection = '',
  isMobileNavigation = false
}: ControlProps) => {
  const { displayName, description, value, list } = control;

  const isLoggedIn = useSelector(authSelectors.getLoggedIn);

  const isCollapsable = !isMobileNavigation && !!control.enableCollapse;
  const [isOpen, setOpen] = useState(isCollapsable ? isSelected : true);

  const selectedOption = list.find(({ name }) => name === value);

  const activeRef = useRef<HTMLDivElement>(null);

  const [scroll] = useScrollIntoView();

  useEffect(() => {
    setTimeout(() => {
      if (isSelected && activeRef.current) {
        scroll(activeRef.current);
      }
    }, 0);
  }, [isSelected, scroll, activeRef]);

  const {
    broken: isBroken,
    handleFixControlValue,
    hasValidOptions
  } = useValidateControl(control, onOptionSelect, selectedOption);

  useControlEffects(
    isOpen,
    setOpen,
    control,
    isCollapsable,
    isSelected,
    onShowExtraInfo,
    onCloseExtraInfo,
    isMobileNavigation,
    selectedOption
  );

  const extraInfoHandler = useExtraInfoHandler(
    control,
    onCloseExtraInfo,
    isSelected,
    onShowExtraInfo,
    onSelect,
    selectedOption,
    currentExtraInfoCollection
  );

  if (isBroken) {
    return (
      <BrokenControl
        onClick={handleFixControlValue}
        hasValidOptions={hasValidOptions}
        description={description}
        displayName={displayName}
      />
    );
  }

  return (
    <ControlWrapper className={controlStyle({ collapsable: isCollapsable })} control={control} onSelect={onSelect}>
      <div
        ref={activeRef}
        className={controlStyle('body', {
          selected: isSelected,
          collapsable: isCollapsable,
          mobile: isMobileNavigation
        })}
      >
        <ControlHeader
          selectedOption={selectedOption}
          isCollapsable={isCollapsable}
          isOpen={isOpen}
          setOpen={setOpen}
          control={control}
          onOpenExtraInfo={extraInfoHandler}
          onSelect={onSelect}
          currentExtraInfoCollection={currentExtraInfoCollection}
          isSelected={isSelected}
        />
        {isOpen ? <ControlOptions control={control} onOptionSelect={onOptionSelect} /> : null}
        <ControlFooter description={control.description} />
        {control.childControls && control.childControls.length !== 0 ? (
          <ControlChildTabButton control={control} />
        ) : null}
        {control.instanceLoader?.enabled && control.instanceLoader.enableNavigation && isLoggedIn ? (
          <ControlInstanceLoaderButton control={control} />
        ) : null}
      </div>
    </ControlWrapper>
  );
};

export default Control;
