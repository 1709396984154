import { createSelector } from 'reselect';
import { selectSettings } from './settingsSelectors';

const selectStateFooterSettings = createSelector([selectSettings], ({ footer = {} }) => footer);

function filterLinks(links = []) {
  return links.filter(link => Boolean(link.url));
}

const selectPartnerFooterLinks = createSelector([selectStateFooterSettings], footer =>
  filterLinks(footer.partnerLinks || [])
);

export const selectCreatomusFooterLinks = createSelector([selectStateFooterSettings], footer =>
  filterLinks(footer.creatomusLinks || [])
);

export const selectFooterLinks = createSelector(
  [selectCreatomusFooterLinks, selectPartnerFooterLinks],
  (creatomusLinks, partnerLinks) => [...creatomusLinks, ...partnerLinks]
);

export const selectPoweredBySettings = createSelector([selectStateFooterSettings], footer => {
  const {
    poweredByDisabled = false,
    poweredByLabel = 'Creatomus Solutions',
    poweredByLink = 'https://creatomus.com/'
  } = footer;

  return { poweredByDisabled, poweredByLabel, poweredByLink };
});
