import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SERVER_ROUTE } from '../api';

import '../css/index/index.css';
import '../css/index/nu-index.css';

const Index = ({ className, logo, header, cta, projects, footer }) => {
  return (
    <div className={`index ${className}`}>
      <div className="index__header">
        {logo ? <img src={logo} className="index__logo" alt="logo" /> : ''}
        {header}
      </div>
      <p className="index__cta">{cta}</p>
      <ul className="index__projects">
        {projects.map(project => {
          return (
            <li className="index__project" key={project.name}>
              <img src={project.images[0]} alt={project.name} className="index__project__image" />
              <Link to={`/project/${project.project}`} className="index__project__link">
                <p className="index__project__CTA">Start Customisation</p>
                <p className="index__project__name">{project.name}</p>
              </Link>
            </li>
          );
        })}
      </ul>
      <div className="index__footer">
        {footer.map(l => {
          return (
            <a target="_blank" href={l.link} key={l.name} className="index__footer__link" rel="noopener noreferrer">
              {l.name}
            </a>
          );
        })}
        <br />
        Powered by{' '}
        <a href="http://creatomus.com/" target="_blank" rel="noopener noreferrer">
          Creatomus
        </a>
      </div>
    </div>
  );
};

Index.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      project: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired,
  footer: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string
    })
  ).isRequired
};
Index.defaultProps = {
  className: ''
};

const NuIndex = props => {
  /* Hardcoded for now */
  return (
    <Index
      className="_nuliving"
      header="Beechwood houses"
      cta="Design your own home. Click on any house type to start."
      footer={[
        { name: 'Disclaimer Policy', link: 'https://www.nuliving.co.uk/legal#liability-disclaimer' },
        { name: 'Privacy Policy', link: 'https://www.nuliving.co.uk/legal/privacy-policy' },
        { name: 'Cookie Policy', link: 'https://www.nuliving.co.uk/legal#cookies' }
      ]}
      logo={SERVER_ROUTE.IMAGE('nuliving-logo.svg')}
      projects={[
        {
          name: 'Terrace',
          project: 'terrace',
          images: [SERVER_ROUTE.IMAGE('Terrace_Extr_opt.jpg', '59fb0e67fbe84a612cd7ebac')]
        },
        {
          name: 'Park Corner',
          project: 'parkcorner',
          images: [SERVER_ROUTE.IMAGE('Park_Corner_Exterior_opt.jpg', '59fb0e3dfbe84a612cd7ebab')]
        },
        {
          name: 'Avenue',
          project: 'avenue',
          images: [SERVER_ROUTE.IMAGE('AVENUE_EXTR_opt.jpg', '5dd409145e8a830010e61015')]
        },
        {
          name: 'Lane',
          project: 'lane',
          images: [SERVER_ROUTE.IMAGE('Lane_Exterior_opt.jpg', '59fb0e10fbe84a612cd7ebaa')]
        },
        {
          name: 'Fryth',
          project: 'fryth',
          images: [SERVER_ROUTE.IMAGE('Fryth_Exterior_opt.jpg', '59fb0ddffbe84a612cd7eba9')]
        }
      ]}
    />
  );
};

const CreatomusIndex = () => {
  return (
    <Index
      header="Creatomus configurators"
      cta="Design your own home. Click on any house type to start."
      footer={[
        { name: 'Terms of Use', link: 'http://www.creatomus.com/terms-of-use' },
        { name: 'Privacy Policy', link: 'http://www.creatomus.com/privacy-policy' }
      ]}
      logo={SERVER_ROUTE.IMAGE('creatomus_logo.png')}
      projects={[
        {
          name: 'Avrame',
          project: 'avrame',
          images: [SERVER_ROUTE.IMAGE('Avrame_DUO_exterior1.jpg', '59f23082ee27086062a5e566')]
        },
        { name: 'Tube', project: 'tube', images: [SERVER_ROUTE.IMAGE('Tube_01.jpg', '5dd3f6f45e8a830010e61013')] },
        { name: '3by3', project: '3by3', images: [SERVER_ROUTE.IMAGE('3x3_07.jpg', '5af7fa81a0ca4d00235fd989')] },
        {
          name: 'Sven',
          project: 'sven',
          images: [SERVER_ROUTE.IMAGE('Sven_Konfiguraator.jpg', '5c3de0f3f045f1000fe93512')]
        },
        {
          name: 'Irish house',
          project: 'iirimaja',
          images: [SERVER_ROUTE.IMAGE('Tempt_Irish_modular_3.jpg', '5c3de49df045f1000fe93517')]
        },
        {
          name: 'Tallinn House',
          project: 'tallinnamaja',
          images: [SERVER_ROUTE.IMAGE('Tallinna_maja.jpg', '5c45974e35ab860016f3d436')]
        },
        {
          name: 'Stilts',
          project: 'vaiamaja',
          images: [SERVER_ROUTE.IMAGE('Vaiamaja 1_20190312.jpg', '5c3de137f045f1000fe93516')]
        },
        {
          name: 'Dacha',
          project: 'dacha',
          images: [SERVER_ROUTE.IMAGE('Dacha V1_11 - Photo.jpg', '5c3f32bfbe24ba0018d2c79a')]
        }
      ]}
    />
  );
};

const CommonIndex = () => {
  if (window.location.hostname === 'nuliving.creatomus.com') {
    return <NuIndex />;
  }

  return <CreatomusIndex />;
};

export { NuIndex, CreatomusIndex, CommonIndex };
