import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';
import Actions from '../Actions';
import ExitFullscreenButton from '../ExitFullscreenButton';

import './ReviewButtons.scss';

const reviewButtonsStyle = b.with('review-buttons');

const ReviewButtons = ({ onExitFullscreen }) => (
  <div className={reviewButtonsStyle()}>
    <ExitFullscreenButton onClick={onExitFullscreen} type="review" />
    <div className={reviewButtonsStyle('content')}>
      <Actions group="reviewTab" mix={reviewButtonsStyle('button')} block={false} />
    </div>
  </div>
);

ReviewButtons.propTypes = {
  onExitFullscreen: PropTypes.func.isRequired
};

export default ReviewButtons;
