import React, { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useTranslate } from '../../../utility/hooks';
import Icon from '../../Icon';
import { TYPE_IMAGE_OBJECT } from '../../../utility/propTypes';
import OverviewMetricCustomIcon from '../../OverviewPanel/OverviewMetric/OverviewMetricCustomIcon';
import './CaveatItem.scss';

const caveatItemStyle = b.with('caveat-item');

const CaveatItem = ({ title, value, icon, forceCollapse, image }) => {
  const [open, setOpen] = useState(false);
  const contentRef = useRef();
  const translate = useTranslate();
  const handleClick = useCallback(() => {
    setOpen(previous => !previous);
  }, []);

  useEffect(() => {
    if (forceCollapse) {
      setOpen(false);
    }
  }, [forceCollapse]);

  const illustration = image ? (
    <div className={caveatItemStyle('image')}>
      <OverviewMetricCustomIcon image={image} alt={title} />
    </div>
  ) : (
    <Icon type={icon} />
  );

  return (
    <div className={caveatItemStyle()}>
      <div className={caveatItemStyle('header')}>
        {illustration}
        <span className={caveatItemStyle('title')}>{translate(title)}</span>
        <button type="button" onClick={handleClick} className={caveatItemStyle('button')}>
          {translate(open ? 'Hide' : 'Show')}
        </button>
      </div>
      <div
        className={caveatItemStyle('body', { open })}
        style={{ height: open ? `${contentRef.current.scrollHeight}px` : '0px' }}
      >
        <div className={caveatItemStyle('content')} ref={contentRef}>
          {value.map((text, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={i}>{translate(text)}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

CaveatItem.defaultProps = {
  forceCollapse: false,
  image: undefined
};

CaveatItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.string.isRequired,
  forceCollapse: PropTypes.bool,
  image: TYPE_IMAGE_OBJECT
};

export default CaveatItem;
