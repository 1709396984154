import { createSelector } from 'reselect';
import { CONFIGURATOR_TYPES } from './configuratorTypeReducer';

export const selectConfiguratorType = state => state.configuratorType;

export const getIsProjectConfiguratorType = createSelector(
  selectConfiguratorType,
  type => type === CONFIGURATOR_TYPES.PROJECT
);
export const getIsInstanceConfiguratorType = createSelector(
  selectConfiguratorType,
  type => type === CONFIGURATOR_TYPES.INSTANCE
);
