function round(val: number) {
  return Math.floor(val * 1000) / 1000;
}

const EASING_TYPES = {
  easeInOutQuart: (x: number) => round(x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2)
};

type EasingType = keyof typeof EASING_TYPES;

function getEasingFrames(number: number, type: EasingType = 'easeInOutQuart') {
  const list = new Array(number);
  const start = 0;
  const end = 1;

  const width = Math.abs(end - start);
  const step = width / (list.length - 1);

  const func = EASING_TYPES[type];

  for (let i = 1; i < list.length - 1; i += 1) {
    const x = i * step + start;

    list[i] = func(x);
  }

  list[0] = func(start);
  list[list.length - 1] = func(end);

  return list;
}

export default getEasingFrames;
