import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuSection } from '../../Menu';
import { useTranslate } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import { Button, Switch } from '../../Atoms';
import useMenuClose from '../../Menu/useMenuClose';
import EXPORTERS from '../../../utility/exporterTypes';
import { modelActions } from '../../../modules/model';
import { modelSettingsSelectors } from '../../../modules/settings';
import MenuItemDescription from '../../Menu/MenuItemDescription';
import { MODEL_UNITS } from '../../../utility/viewerSettings';

const ExportModelMenu = () => {
  const [fileType, setFileType] = useState(EXPORTERS.OBJ);
  const translate = useTranslate();
  const handleClose = useMenuClose();
  const modelUnits = useSelector(modelSettingsSelectors.selectModelUnits);

  const dispatch = useDispatch();

  const handleDownload = () => {
    dispatch(modelActions.exportModel(fileType));
  };

  return (
    <MenuContent>
      <MenuSection type="export-model" heading={translate('Export 3D model')}>
        <span>{translate('Select file type')}</span>
        <Switch onChange={setFileType} name={EXPORTERS.OBJ} type="radio" checked={fileType === EXPORTERS.OBJ}>
          <strong>OBJ</strong>
        </Switch>
        <Switch onChange={setFileType} name={EXPORTERS.GLTF} type="radio" checked={fileType === EXPORTERS.GLTF}>
          <strong>GLTF</strong>
        </Switch>
        <Switch onChange={setFileType} name={EXPORTERS.COLLADA} type="radio" checked={fileType === EXPORTERS.COLLADA}>
          <strong>COLLADA</strong>
        </Switch>
        <MenuItemDescription>
          {translate(`NOTE: all sizes are in ${modelUnits === MODEL_UNITS.METRIC ? 'millimeters' : 'inches'}`)}
        </MenuItemDescription>
        <br />
        <Button bold block onClick={handleDownload}>
          {translate('Download')}
        </Button>
        <Button color="text-borderless" bold block onClick={handleClose}>
          {translate('Close')}
        </Button>
      </MenuSection>
    </MenuContent>
  );
};

export default ExportModelMenu;
