import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as summarySelectors from '../../modules/ui/summarySelectors';

const useCaveats = () => {
  const metrics = useSelector(summarySelectors.selectMetrics);

  return useMemo(() => {
    const result = [];

    metrics.forEach(metric => {
      // works for both strings and arrays
      if (metric.caveat?.length > 0) {
        result.push({ title: metric.caveatTitle, icon: metric.icon, value: metric.caveat, image: metric.image });
      }
    });

    return result;
  }, [metrics]);
};

export default useCaveats;
