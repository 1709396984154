import { createSelector } from 'reselect';
import { Matrix4 } from 'three';
import { PartNodeType, PartNodeViewType } from 'types';
import { selectMainPartNodesToRender } from '../../modules/model/nodesSelectors';
import { selectSharingSettings } from '../../modules/settings/sharingSelectors';
import { selectIsCameraMoved, selectCameraViewName, selectInitialCameraViewName } from './cameraStateSelectors';
// selectors straigth from state are in a different file to be able to import them in nodesSelectors

export { selectIsCameraMoved, selectCameraViewName };

// utility function to sort tabs according to their tabIndex, used by selectVisibleTabs
const sortViews = (a: PartNodeViewType, b: PartNodeViewType) => {
  const aIndex = a.viewIndex || 0; // 0 is because older tabs dont have tab indices
  const bIndex = b.viewIndex || 0;

  if (aIndex > bIndex) return 1;

  if (aIndex < bIndex) return -1;

  return 0;
};

export const selectViews = createSelector([selectMainPartNodesToRender], (parts: PartNodeType[]) => {
  // flatten views
  const viewsMap = parts.reduce<Record<string, PartNodeViewType>>((result, part) => {
    if (part.Views && Array.isArray(part.Views.list)) {
      part.Views.list.forEach(view => {
        // eslint-disable-next-line no-param-reassign
        result[view.name] = { ...view, parentMatrix: part.matrix };
      });
    }

    return result;
  }, {});

  return Object.values(viewsMap).sort(sortViews);
});

export const selectVisibleViews = createSelector([selectViews], views => views.filter(view => !view.hidden));

export const selectCameraView = createSelector(
  [selectViews, selectCameraViewName],
  (views, viewName) =>
    views.find(({ name }) => name === viewName) || {
      name: '',
      parentMatrix: new Matrix4(),
      disableCameraAnimation: true
    }
);

export const getShareView = createSelector(
  [selectViews, selectSharingSettings],
  (views, { view }) => views.find(({ name }) => name === view) || views[0]
);

export const getSummaryGalleryViews = createSelector([selectViews], views => views.filter(view => view.summaryGallery));
export const getPdfGalleryViews = createSelector([selectViews], views => views.filter(view => view.pdfGallery));

export const selectIsViewChangedManually = createSelector(
  [selectCameraViewName, selectInitialCameraViewName],
  (currentView, initialView) => currentView !== initialView
);
