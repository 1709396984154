import React, { AnchorHTMLAttributes, ForwardedRef, HTMLAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import b from 'b_';

import './Button.scss';

const buttonStyle = b.with('button-v2');

type SizeType = 'lg' | 'md' | 'xs';

export type ButtonColorType =
  | 'main'
  | 'main-outline'
  | 'main-inverse'
  | 'accent'
  | 'accent-outline'
  | 'secondary'
  | 'secondary-hovered'
  | 'secondary-borderless'
  | 'error-borderless'
  | 'text'
  | 'text-light'
  | 'text-borderless'
  | 'icon'
  | 'link';

const getTypeModifiers = (
  size: SizeType,
  circular: boolean,
  rounded: boolean,
  color: ButtonColorType,
  bold: boolean,
  block: boolean,
  regular: boolean
) => {
  if (circular) {
    return { size: `${size}-circular`, color };
  }

  if (rounded) {
    return { size: `${size}-rounded`, color, bold, block, regular };
  }

  return { size, color, bold, block, regular };
};

export const BUTTON_TYPES = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  LINK: 'link',
  EXTERNAL_LINK: 'external-link'
} as const;

export type ButtonType = typeof BUTTON_TYPES[keyof typeof BUTTON_TYPES];

const getResultClassName = (className: string, mix: string) => (mix ? `${className} ${mix}` : className);

interface BaseButtonProps {
  label?: string;
  size?: SizeType;
  color?: ButtonColorType;
  rounded?: boolean;
  block?: boolean;
  bold?: boolean;
  regular?: boolean;
  circular?: boolean;
  disabled?: boolean;
  mix?: string;
}

interface ButtonAnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  type: typeof BUTTON_TYPES.EXTERNAL_LINK;
}

interface ButtonButtonProps extends HTMLAttributes<HTMLButtonElement> {
  type?: typeof BUTTON_TYPES.SUBMIT | typeof BUTTON_TYPES.BUTTON;
}

interface ButtonLinkProps extends LinkProps {
  type: typeof BUTTON_TYPES.LINK;
}

type ButtonProps = BaseButtonProps & (ButtonAnchorProps | ButtonButtonProps | ButtonLinkProps);

const Button = React.forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const {
    label = '',
    children,
    size = 'md',
    color = 'main',
    rounded = false,
    block = false,
    bold = false,
    circular = false,
    disabled = false,
    mix = '',
    regular = false,
    ...restProps
  } = props;

  const className = getResultClassName(
    buttonStyle(getTypeModifiers(size, circular, rounded, color, bold, block, regular)),
    mix
  );
  const content = circular ? children : label || children;

  if (restProps.type === BUTTON_TYPES.LINK) {
    const { to, ...linkProps } = restProps;

    return (
      <Link className={className} to={to} {...linkProps}>
        {content}
      </Link>
    );
  }

  if (restProps.type === BUTTON_TYPES.EXTERNAL_LINK) {
    const { href, ...rest } = restProps;

    return (
      <a className={className} target="_blank" rel="noopener noreferrer" href={href} {...rest}>
        {content}
      </a>
    );
  }

  const { type, ...rest } = restProps;

  return (
    <button
      className={className}
      disabled={disabled}
      type={type === BUTTON_TYPES.SUBMIT ? 'submit' : 'button'}
      {...rest}
      ref={ref}
    >
      {content}
    </button>
  );
});

export default Button;
