import React from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';
import { uiSelectors } from '../../../modules/ui';
import MainActions from '../../MainActions';
import ContentTitle from '../ContentTitle';
import MarkdownContent from '../../MarkdownContent';

import './AboutPageInfo.scss';

const aboutPageInfoStyle = b.with('about-page-info');

const AboutPageInfo = () => {
  const textContent = useSelector(uiSelectors.selectIntroContent);

  return (
    <div className={aboutPageInfoStyle()}>
      {textContent ? <ContentTitle title="About" /> : null}
      <MarkdownContent html={textContent} />
      <div className={aboutPageInfoStyle('actions')}>
        <MainActions />
      </div>
    </div>
  );
};

export default AboutPageInfo;
