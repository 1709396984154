export const FETCH_PLOTS = 'FETCH_PLOTS';
export const FETCH_PLOTS__SUCCESS = 'FETCH_PLOTS__SUCCESS';
export const FETCH_PLOTS__FAILURE = 'FETCH_PLOTS__FAILURE';

export const fetchPlots = () => ({
  type: FETCH_PLOTS
});

export const fetchPlotsSuccess = plots => ({
  type: FETCH_PLOTS__SUCCESS,
  payload: { plots }
});

export const fetchPlotsFailure = error => ({
  type: FETCH_PLOTS__FAILURE,
  error
});
