import ChildNavigationButtons from './ChildNavigationButtons';
import MainNavigationButtons from './MainNavigationButtons';

interface NavigationButtonsProps {
  isChildTabOpened: boolean;
  isMobileNavigation: boolean;
}

const NavigationButtons = ({ isChildTabOpened, isMobileNavigation }: NavigationButtonsProps) =>
  isChildTabOpened && isMobileNavigation ? (
    <ChildNavigationButtons />
  ) : (
    <MainNavigationButtons isMobileNavigation={isMobileNavigation} isChildTabOpened={isChildTabOpened} />
  );

export default NavigationButtons;
