import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { commonSelectors, controlSelectors } from '../../../modules/model';
import { useTabLink } from '../../TabLink';

import './ProgressBar.scss';

const progressBarStyle = b.with('progress-bar');

const ProgressBar = ({ childProgress }) => {
  const [localNavigationTree, setLocalNavigationTree] = useState([]);
  const controlNavigationTree = useSelector(controlSelectors.getControlNavigationTree);
  const filteredControlNavigationTree = useMemo(
    () => controlNavigationTree.filter(tab => tab.isControlTab),
    [controlNavigationTree]
  );
  const makeTabLink = useTabLink();
  const isChildModeEnabled = useSelector(commonSelectors.getIsChildModeEnabled);
  const disabled = isChildModeEnabled && !childProgress;

  useEffect(() => {
    if (disabled) return;

    setLocalNavigationTree(filteredControlNavigationTree);
  }, [disabled, filteredControlNavigationTree]);

  return (
    <div className={progressBarStyle({ disabled })}>
      {localNavigationTree.map(tab => (
        <Link
          className={progressBarStyle('tab', { completed: tab.completed, disabled })}
          key={tab.name}
          style={{ width: `calc((100% - 12px) / ${localNavigationTree.length})` }}
          to={makeTabLink(tab.name)}
        >
          <span className={progressBarStyle('index')}>{tab.index}</span>
        </Link>
      ))}
    </div>
  );
};

ProgressBar.defaultProps = {
  childProgress: false
};

ProgressBar.propTypes = {
  childProgress: PropTypes.bool
};

export default ProgressBar;
