import axios from 'axios';
import { ASSETS_URL, API_URL } from '../config';
import { IFRAME_PATH_PREFIX } from '../utility/location';
import ServerError from './serverError';

export const instance = axios.create({
  baseURL: API_URL,
  timeout: 13000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const assetsInstance = axios.create({
  baseURL: ASSETS_URL,
  timeout: 20000,
  headers: { 'Content-Type': 'application/json' }
});

async function callApi(method, ...args) {
  let response;
  let error;

  try {
    response = await method(...args);
  } catch (err) {
    error = ServerError.parse(err);
  }

  return { data: response ? response.data : null, error };
}

// https://github.com/axios/axios/issues/815#issuecomment-453963910
instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          // eslint-disable-next-line no-param-reassign
          error.response.data = JSON.parse(reader.result);

          resolve(Promise.reject(error));
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    }

    return Promise.reject(error);
  }
);

export function setUtmParameters(parameters) {
  instance.defaults.headers['Creatomus-Configurator-Utm'] = parameters;
}

export function setCreatomusConfiguratorOriginHeader(origin = '') {
  window.parentOrigin = origin;

  instance.defaults.headers['Creatomus-Configurator-Origin'] = window.parentOrigin;
  instance.defaults.headers['Creatomus-Configurator-Path'] = IFRAME_PATH_PREFIX;
}

const api = {
  post: (url, data, config) => callApi(instance.post, url, data, config),
  get: (url, config) => callApi(instance.get, url, config),
  put: (url, data, config) => callApi(instance.put, url, data, config),
  patch: (url, data, config) => callApi(instance.patch, url, data, config),
  delete: (url, config) => callApi(instance.delete, url, config),
  getAssets: (url, config) => callApi(assetsInstance.get, url, config),
  getAssetsUri: config => `${ASSETS_URL}/${assetsInstance.getUri(config)}`
};

export default api;
