import { SyntheticEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { PartNodeControlType } from 'types';
import { useSelectTabWithControl, useTranslate } from '../../../utility/hooks';
import { Button } from '../../Atoms';
import Icon from '../../Icon';
import { controlSelectors } from '../../../modules/model';
import navigationButtonsStyle from './navigationButtonsStyle';

const ChildNavigationButtons = () => {
  const translate = useTranslate();
  const selectTabWithControl = useSelectTabWithControl();
  const { leftControl, rightControl } = useSelector(controlSelectors.getChildControlNavigationContent) as {
    leftControl?: PartNodeControlType;
    rightControl?: PartNodeControlType;
  };

  const onClick = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      const iterator = Number(e.currentTarget.dataset.iterator);

      const nextControl = iterator === 1 ? rightControl : leftControl;

      selectTabWithControl(undefined, nextControl);
    },
    [leftControl, rightControl, selectTabWithControl]
  );

  return !leftControl && !rightControl ? null : (
    <div className={navigationButtonsStyle()}>
      <Button
        data-iterator={-1}
        onClick={onClick}
        rounded
        bold
        size="lg"
        mix={navigationButtonsStyle('button', { back: true })}
        disabled={!leftControl}
        color="main-outline"
      >
        <span className={navigationButtonsStyle('icon')}>
          <Icon type="chevron-left" scale={1.4} />
        </span>
        <span className={navigationButtonsStyle('label', { back: true })}>{translate('Back')}</span>
      </Button>
      <Button
        data-iterator={1}
        onClick={onClick}
        rounded
        bold
        size="lg"
        mix={navigationButtonsStyle('button', { next: true })}
        disabled={!rightControl}
      >
        <span className={navigationButtonsStyle('label', { next: true })}>{translate('Next')}</span>
        <span className={navigationButtonsStyle('icon')}>
          <Icon type="chevron-right" scale={1.4} />
        </span>
      </Button>
    </div>
  );
};

export default ChildNavigationButtons;
