import { all, call, put, takeLatest, select, cancel } from 'redux-saga/effects';
import api from '../../api';
import { seedSelectors } from '../seed';
import { initialStateActions } from '../initialState';
import { FETCH_PARTS, fetchPartsSuccess, fetchPartsFailure, fetchPartsRequest } from './partsActions';

function* fetchPartsSaga() {
  yield put(fetchPartsRequest());

  const seedIds = yield select(seedSelectors.selectSeedIds);
  const result = yield all([...seedIds.map(seedId => call(api.getParts, seedId))]);

  const seeds = result.map(({ data }) => data).filter(Boolean);

  if (result.length === seeds.length) {
    yield put(fetchPartsSuccess([].concat(...seeds)));

    return;
  }

  yield put(fetchPartsFailure());
}

function* doFetchPartsSaga() {
  const hasNewSeed = yield select(seedSelectors.selectHasNewSeed);

  if (!hasNewSeed) {
    yield cancel();
  }

  yield call(fetchPartsSaga);
}

function* watchFetchPartsSaga() {
  yield takeLatest([FETCH_PARTS, initialStateActions.FETCH_INITIAL_STATE__SUCCESS], doFetchPartsSaga);
}

export default function* moduleSaga() {
  yield all([watchFetchPartsSaga()]);
}
