import { Vector3, Vector2, Matrix4 } from 'three';
import { DirectionalLightType, ModelUnitsType, SunlightType } from 'types';
import calculateSunPosition from './calculateSunPosition';
import { SUN_SHADOW_METRIC, SUN_SHADOW_IMPERIAL } from './viewerSettings';

export const LIGHTS = {
  AMBIENT: 'ambient',
  DIRECTIONAL: 'directional',
  HEMI: 'hemi',
  POINT: 'point',
  RECT: 'rect',
  SPOT: 'spot'
} as const;

interface ExpandedDirectionalLightType extends DirectionalLightType {
  shadowCamera?: {
    left: number;
    right: number;
    top: number;
    bottom: number;
    far: number;
    near: number;
    visible: boolean;
  };
  depthBias?: number;
  shadowBias?: number;
}

const SHADOW_MAP_RESOLUTION = 4096;

export const LIGHTS_LIST = Object.values(LIGHTS);

const reflectVector = new Vector3(-1, -1, 0.3);
const calculateReflectPosition = (position: Vector3) => new Vector3().copy(position).multiply(reflectVector);

const getShadowCamera = (type: ModelUnitsType) => (type === 'imperial' ? SUN_SHADOW_IMPERIAL : SUN_SHADOW_METRIC);

const reusablePositonVector = new Vector3();

export const getLightsForSun = (
  sunlight: SunlightType,
  modelUnits: ModelUnitsType,
  cameraView: { isRelative?: boolean; parentMatrix?: Matrix4 } = {}
) => {
  if (!sunlight) return null;

  const {
    altitude = 0,
    azimuth = 0,
    color = 'rgb(255,255,255)',
    intensity = 1,
    castShadow = false,
    name,
    shadowBoxMultiplier = 1
  } = sunlight;

  const { sunModel, shadowCamera } = getShadowCamera(modelUnits);
  const { distance } = sunModel;

  const position = calculateSunPosition(azimuth, altitude, distance * shadowBoxMultiplier);

  const lookAtVector = new Vector3();

  lookAtVector.copy(position);
  lookAtVector.negate();

  // for relative views the base position will be translated to parent part
  const { isRelative = false, parentMatrix } = cameraView;

  if (isRelative && parentMatrix) {
    reusablePositonVector.setFromMatrixPosition(parentMatrix);
    position.add(reusablePositonVector);
  }

  const sun: ExpandedDirectionalLightType = {
    name,
    type: 'directional',
    color,
    intensity,
    castShadow,
    depthBias: 0.01,
    shadowBias: -0.001,
    shadowMapSize: new Vector2(SHADOW_MAP_RESOLUTION, SHADOW_MAP_RESOLUTION),

    // near will stay where it is, multiplier is applied to radius instead, which is used to calculate all the rest
    // sun(near) - RADIUS - 0,0,0 - RADIUS (far)
    // left RADIUS 0,0,0 RADIUS (right)
    shadowCamera: {
      ...shadowCamera,
      left: -distance * shadowBoxMultiplier,
      right: distance * shadowBoxMultiplier,
      top: distance * shadowBoxMultiplier,
      bottom: -distance * shadowBoxMultiplier,
      far: distance * shadowBoxMultiplier * 2
    },
    position,
    lookAt: lookAtVector
  };

  const through: ExpandedDirectionalLightType = {
    name: `${name}-through`,
    type: 'directional',
    color,
    intensity: 0.2,
    position,
    lookAt: lookAtVector
  };

  const reflectPosition = new Vector3();

  reflectPosition.copy(calculateReflectPosition(position));
  const reflectLookAt = reflectPosition.clone().negate();

  const reflect: ExpandedDirectionalLightType = {
    name: `${name}-reflect`,
    type: 'directional',
    color,
    position: reflectPosition,
    intensity: intensity / 2,
    lookAt: reflectLookAt
  };

  return [sun, through, reflect];
};
