import React from 'react';
import PropTypes from 'prop-types';
import menuStyle from './style';

const MenuContent = ({ children }) => <div className={menuStyle('content')}>{children}</div>;

MenuContent.propTypes = {
  children: PropTypes.node.isRequired
};

export default MenuContent;
