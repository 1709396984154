import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { uiSelectors } from '../../../../../modules/ui';
import { useTranslate } from '../../../../../utility/hooks';
import { TYPE_CONTROL_OPTION, TYPE_PART_CONTROL, TYPE_PART_PHASE } from '../../../../../utility/propTypes';
import Button from '../../../../Atoms/Button';
import Icon from '../../../../Icon';
import { useTabLink } from '../../../../TabLink';
import preferencesTableStyle from '../../preferencesTableStyle';

const PreferenceButton = props => {
  const { control, option, tab, onClick, choiceIndex, sectionIndex, empty } = props;
  const makeTabLink = useTabLink();
  const { currency, currencyFormatter } = useSelector(uiSelectors.selectPriceSettingsByPriceScheme);
  const translate = useTranslate();

  const forceReselect = option?.forceReselect;

  if (empty) return null;

  const priceLine = () => {
    if (forceReselect || !option) {
      return <span className={preferencesTableStyle('price')}>{translate('Click here to fix it')}</span>;
    }

    if (option?.totalPrice && !option?.priceHidden) {
      return (
        <span className={preferencesTableStyle('price')}>
          {currency} {currencyFormatter(option.totalPrice)}
        </span>
      );
    }

    return null;
  };

  return (
    <Button
      type="link"
      to={{
        search: makeTabLink(tab.name),
        state: { controlName: control.name, hideChildControls: false }
      }}
      onClick={onClick}
      color="main-outline"
      rounded
      mix={preferencesTableStyle('link', { locked: option?.locked || option?.inactive })}
      aria-label={translate('Change')}
      data-balloon-pos="up"
      data-choice-index={choiceIndex}
      data-section-index={sectionIndex}
    >
      <div className={preferencesTableStyle('value')}>
        <div className={preferencesTableStyle('result')}>
          <span
            className={preferencesTableStyle('option', {
              locked: option?.locked || option?.inactive,
              unavailable: !option,
              forcereselect: forceReselect
            })}
          >
            {translate(option && !forceReselect ? option.displayName : 'Unavailable')}
          </span>
          {priceLine()}
          {!forceReselect && option?.statusLabel ? (
            <span className={preferencesTableStyle('status-label')}>{option.statusLabel}</span>
          ) : null}
        </div>
      </div>
      <div>
        <Icon
          scale={option && !forceReselect ? 1.5 : 1}
          type={option && !forceReselect ? 'chevron-right' : 'alert-outlined'}
          mix={preferencesTableStyle('icon', {
            locked: option?.locked || option?.inactive,
            unavailable: !option,
            forcereselect: forceReselect
          })}
        />
      </div>
    </Button>
  );
};

PreferenceButton.propTypes = {
  control: TYPE_PART_CONTROL.isRequired,
  option: TYPE_CONTROL_OPTION.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  choiceIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  empty: PropTypes.bool.isRequired,
  tab: TYPE_PART_PHASE.isRequired
};

export default PreferenceButton;
