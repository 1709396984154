import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Button } from '../../Atoms';
import { useTranslate, useFormState } from '../../../utility/hooks';
import { dialogActions } from '../../../modules/dialog';
import GeneralConsent from '../GeneralConsent';
import { formStyle } from '../../Menu/style';
import { loaderSelectors } from '../../../modules/loader';
import { validateEmail, validatePassword, validatePassword2, validateConsent } from '../../../utility/formValidators';
import { useRegisterMutation } from '../../../store/api/api';

const initialFormState = {
  email: '',
  password: '',
  password2: '',
  generalConsent: false
};

const EmailRegister = () => {
  const [register] = useRegisterMutation();

  const dispatch = useDispatch();

  const { handleFormFieldChange, handleFormSwitchChange, formState } = useFormState(initialFormState);

  const isRegistering = useSelector(loaderSelectors.selectIsRequestingAuthorization);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault(); // do not reload whole page
      await register({
        username: formState.email,
        password: formState.password
      }).unwrap();

      dispatch(dialogActions.hideDialog());
    },
    [dispatch, register, formState.email, formState.password]
  );

  /** Checks if email is valid after something has been written into the email field */
  const emailFeedback = useMemo(() => validateEmail(formState.email), [formState]);

  const passwordFeedback = useMemo(() => validatePassword(formState.password), [formState]);
  const password2Feedback = useMemo(() => validatePassword2(formState.password2, formState), [formState]);
  const consentFeedback = useMemo(() => validateConsent(formState.generalConsent), [formState]);

  const isEnabled = useMemo(() => {
    if (isRegistering) return false;

    return ![emailFeedback, passwordFeedback, password2Feedback, consentFeedback].some(Boolean);
  }, [consentFeedback, emailFeedback, isRegistering, password2Feedback, passwordFeedback]);

  const translate = useTranslate();

  return (
    <form className={formStyle({ type: 'register' })} onSubmit={handleSubmit}>
      <Field
        id="register-email"
        type="email"
        value={formState.email}
        name="email"
        label={translate('Your email')}
        onChange={handleFormFieldChange}
        required
        feedback={emailFeedback}
      />
      <Field
        id="register-password"
        type="password"
        name="password"
        value={formState.password}
        label={translate('Password')}
        onChange={handleFormFieldChange}
        required
        feedback={passwordFeedback}
        autoComplete="new-password"
      />
      <Field
        id="register-password2"
        type="password"
        name="password2"
        value={formState.password2}
        label={translate('Confirm password')}
        onChange={handleFormFieldChange}
        required
        feedback={password2Feedback}
        autoComplete="new-password"
      />

      <GeneralConsent onChange={handleFormSwitchChange} checked={formState.generalConsent} feedback={consentFeedback} />

      <Button block type="submit" disabled={!isEnabled}>
        {isRegistering ? translate('Registering...') : translate('Register')}
      </Button>
    </form>
  );
};

export default EmailRegister;
