/** Supported control types */
export const CONTROL_TYPES = {
  RADIO: 'option',
  TOGGLE: 'toggle',
  SLIDER: 'slider',
  DROPDOWN: 'dropdown',
  COLUMN: 'column',
  COLUMN_TWO: 'twoColumn',
  COLUMN_THREE: 'threeColumn',
  MATERIALS: 'controlMaterials',
  MATERIALS_BULK: 'bulkMaterials',
  IMAGES: 'controlImages',
  VAR_SELECTED_CONTROL: 'varSelectedControl',
  VAR_SELECTED_TAB: 'varSelectedTab',
  VAR_SELECTED_VIEW: 'varSelectedView',
  EMPTY: 'empty'
} as const;

export type ControlTypes = typeof CONTROL_TYPES[keyof typeof CONTROL_TYPES];

export const CONTROL_TAGS = {
  ALL: 'All',
  OTHER: 'Other'
} as const;

export type ControlTags = typeof CONTROL_TAGS[keyof typeof CONTROL_TAGS];

export const TRANSFORM_SUFFIX = '#transform' as const;
export const INSTANCE_LOADER_SUFFIX = '#instance' as const;

/** List of supported control types */
export const CONTROL_TYPES_LIST = Object.values(CONTROL_TYPES);
