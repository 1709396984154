import { createSelector } from 'reselect';
import * as THREE from 'three';
import textureStore, { TEXTURE_TYPES, TEXTURE_TYPE } from '../textures/textureStore';
import * as settingsSelectors from '../settings/settingsSelectors';
import * as texturesSelectors from '../textures/texturesSelectors';
import * as partsSelectors from '../parts/partsSelectors';
import * as modelSettingsSelectors from '../settings/modelSettingsSelectors';
import { cameraSelectors } from '../../store/camera';
import { LIGHTS, getLightsForSun } from '../../utility/lightDefinitions';
import { getPartNodesToRender, selectMainPartNodesToRender } from './nodesSelectors';

// lights selectors

export const selectLights = createSelector([getPartNodesToRender], parts => {
  const lights = {
    customLights: [],
    skylights: [],
    sunlights: []
  };

  parts.forEach(part => {
    if (part.Lights) {
      const mapPartLightId = light => ({ ...light, partName: part.key });

      // merge lights list
      if (Array.isArray(part.Lights.customLights))
        lights.customLights.push(...part.Lights.customLights.map(mapPartLightId));

      // merge skylights list
      if (Array.isArray(part.Lights.skylights)) lights.skylights.push(...part.Lights.skylights.map(mapPartLightId));

      // merge sunlights list
      if (Array.isArray(part.Lights.sunlights)) lights.sunlights.push(...part.Lights.sunlights.map(mapPartLightId));
    }
  });

  return lights;
});

export const selectCurrentSkylight = createSelector(
  [selectLights, cameraSelectors.selectCameraView],
  ({ skylights }, view = {}) => {
    const { lights } = view;

    if (lights && lights.skylight) {
      return skylights.find(light => light.name === lights.skylight.name);
    }

    return undefined;
  }
);

export const selectCurrentSunlight = createSelector(
  [selectLights, cameraSelectors.selectCameraView],
  ({ sunlights }, view = {}) => {
    const { lights } = view;

    if (lights && lights.sunlight) {
      return sunlights.find(light => light.name === lights.sunlight.name);
    }

    return undefined;
  }
);

export const selectCurrentCustomLights = createSelector(
  [selectLights, cameraSelectors.selectCameraView],
  ({ customLights }, view = {}) => {
    const { lights } = view;

    if (lights && Array.isArray(lights.list)) {
      return customLights.filter(
        ({ disabled, name }) => !disabled && lights.list.find(lightName => lightName === name)
      );
    }

    return [];
  }
);

export const selectSkylightDisabled = createSelector(
  [selectCurrentSkylight, cameraSelectors.selectCameraView],
  (skylight, view = {}) =>
    (skylight && skylight.disabled) || (view.lights && view.lights.skylight && view.lights.skylight.disabled)
);
export const selectSunlightDisabled = createSelector(
  [selectCurrentSunlight, cameraSelectors.selectCameraView],
  (sunlight, view = {}) =>
    (sunlight && sunlight.disabled) || (view.lights && view.lights.sunlight && view.lights.sunlight.disabled)
);

const defaultSunlight = {
  name: '_defaultSunlight',
  altitude: 35,
  azimuth: 150,
  color: 0xffffff,
  intensity: 0.4,
  castShadow: true
};

const defaultSkylight = {
  name: '_defaultSkylight',
  color: 0xaaaaaa,
  intensity: 1
};

export const selectLightsToRender = createSelector(
  [
    selectCurrentSunlight,
    selectSunlightDisabled,
    selectCurrentSkylight,
    selectSkylightDisabled,
    selectCurrentCustomLights,
    modelSettingsSelectors.selectModelSettings,
    cameraSelectors.selectCameraView
  ],
  (
    sunlight = defaultSunlight,
    sunlightDisabled,
    skylight = defaultSkylight,
    skylightDisabled,
    customLights,
    { modelUnits },
    cameraView
  ) => {
    const lightsToRender = [...customLights];

    if (!sunlightDisabled) lightsToRender.push(...getLightsForSun(sunlight, modelUnits, cameraView));

    if (!skylightDisabled && skylight) lightsToRender.push({ ...skylight, type: LIGHTS.AMBIENT });

    return lightsToRender;
  }
);

/*
  Select all textures from materials.
  List of textures which to include are in TEXTURE_MAP_TYPES_LIST
*/

const selectTexturesFromParts = createSelector([partsSelectors.selectMaterialsMap], (materialsMap = {}) => {
  const materials = Object.values(materialsMap);
  // setup texture map type objects

  // these need to be objects so that duplicates get removed
  const textures = {};

  TEXTURE_TYPES.forEach(type => {
    textures[type] = {};
  });

  materials.forEach(material => {
    const { Name, Textures } = material;

    // add all new texture types
    TEXTURE_TYPES.forEach(textureType => {
      if (Textures && Textures[textureType] && Textures[textureType].Texture) {
        // put to correct object according to material name.
        const { Transform, Texture, DisableFlipY = false } = material.Textures[textureType];

        if (!textures[textureType][Name]) {
          textures[textureType][Name] = {
            materialName: Name,
            textureName: Texture.fileName,
            textureType,
            transform: new THREE.Matrix3(),
            flipY: !DisableFlipY
          };

          // apply matrix if exists
          if (Transform && Transform.Matrix && Array.isArray(Transform.Matrix.Values)) {
            textures[textureType][Name].transform.set(...Transform.Matrix.Values);
          }
        }
      }
    });
  });

  return textures;
});

const SKYBOX_MATERIAL_NAME = '_SKYBOX';

const selectEnvTexture = createSelector([settingsSelectors.selectSkyboxTextures], textureName =>
  textureName ? { materialName: SKYBOX_MATERIAL_NAME, textureName, textureType: TEXTURE_TYPE.ENVIRONMENT } : undefined
);

export const selectEnvMap = createSelector([texturesSelectors.getIsTexturesReceived], areTexturesReceived => {
  /* areTexturesReceived is just for pushing updates */
  return textureStore.getTexture(TEXTURE_TYPE.ENVIRONMENT, SKYBOX_MATERIAL_NAME);
});

export const selectModelTexturesList = createSelector(
  [selectTexturesFromParts, selectEnvTexture],
  (textures, envTexture) =>
    TEXTURE_TYPES.reduce(
      (result, type) => {
        result.push(...Object.values(textures[type]));

        return result;
      },
      envTexture ? [envTexture] : []
    )
);

export const selectPartAnimationDisabled = createSelector(
  [cameraSelectors.selectCameraView, settingsSelectors.selectSettings],
  (view = {}, settings) =>
    Boolean(!(settings.partAnimation && settings.partAnimation.enabled) || view.disablePartAnimation)
);

// Materials

export const selectIsSceneInteractive = createSelector([selectMainPartNodesToRender], partNodes => {
  return partNodes.some(({ Config = {}, Sprites = {} }) => {
    if (Config.interaction && Config.interaction.enabled) {
      return true;
    }
    const { list = [] } = Sprites;

    return list.some(sprite => sprite.interaction && sprite.interaction.enabled);
  });
});
