import { useSelector } from 'react-redux';
import { SyntheticEvent, useCallback } from 'react';
import { ControlNavigationListItemType } from 'types';
import { uiSelectors } from '../../../modules/ui';
import { REVIEW_TAB } from '../../../modules/ui/tabNames';
import Actions from '../../Actions';
import { useTabLink } from '../../TabLink';
import { controlSelectors } from '../../../modules/model';
import { useSelectTabWithControl, useActions } from '../../../utility/hooks';
import { seedSelectors } from '../../../modules/seed';
import { ParsedActionType } from '../../../modules/settings/actionsSelectors';
import navigationButtonsStyle from './navigationButtonsStyle';
import FinishButton from './FinishButton';
import { PrevButton, NextButton } from './NavButtons';

const ReviewNavigationButtons = () => (
  <div className={navigationButtonsStyle('review-buttons')}>
    <Actions group="reviewTab" size="md" rounded block />
  </div>
);

const MobileNavigationButtons = ({
  isMainSeed,
  currentControl,
  currentIndex,
  onNextControl,
  onPrevControl,
  onFinish,
  finishAction
}: {
  isMainSeed: boolean;
  currentControl?: ControlNavigationListItemType;
  currentIndex: number;
  onNextControl: () => void;
  onPrevControl: () => void;
  onFinish: (e: SyntheticEvent) => void;
  finishAction: ParsedActionType;
}) => {
  const isFinishTab = !!(isMainSeed && currentControl?.isLastControl);

  return (
    <div className={navigationButtonsStyle()}>
      <PrevButton onClick={onPrevControl} disabled={currentIndex === 0 && !currentControl?.prevTab} />
      {isFinishTab && finishAction ? (
        <FinishButton action={finishAction} onClick={onFinish} />
      ) : (
        <NextButton
          isFinishTab={isFinishTab}
          onClick={onNextControl}
          disabled={currentControl?.isLastTab && currentControl?.isLastControl}
        />
      )}
    </div>
  );
};

interface MainNavigationButtonsProps {
  isMobileNavigation: boolean;
  isChildTabOpened: boolean;
}

/** Next and prev buttons
 * If mobile mode, render next and prev control or tab
 * if desktop mode, render next and prev tab
 * If last control&tab, render next and finish
 * If Review, render ReviewButtons
 */
const MainNavigationButtons = ({ isMobileNavigation, isChildTabOpened }: MainNavigationButtonsProps) => {
  const currentTabName = useSelector(uiSelectors.selectCurrentTabName);
  const {
    controlList,
    activeControl: currentControl,
    activeIndex: currentIndex
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Selector not yet converted
  } = useSelector(controlSelectors.getControlNavigationList) as {
    controlList: ControlNavigationListItemType[];
    activeControl?: ControlNavigationListItemType;
    activeIndex: number;
  };

  const {
    actions: [finishAction],
    handleClickOnAction
  } = useActions('finishButton');

  const isMainSeed = useSelector(seedSelectors.selectIsMainSeed);

  const selectTabWithControl = useSelectTabWithControl();

  const makeTabLink: (s?: string) => string = useTabLink();

  const nextTabName = currentControl?.nextTab;

  const handleNextControl = useCallback(() => {
    const nextControl = controlList[currentIndex + 1];

    if (!nextControl) {
      selectTabWithControl(nextTabName);
    }
    selectTabWithControl(nextControl.tabName, nextControl.name);
  }, [controlList, currentIndex, selectTabWithControl, nextTabName]);

  const prevTabName = currentControl?.prevTab;
  const handlePreviousControl = useCallback(() => {
    const prevControl = controlList[currentIndex - 1];

    if (!prevControl) {
      selectTabWithControl(prevTabName);

      return;
    }

    selectTabWithControl(prevControl.tabName, prevControl.name);
  }, [controlList, currentIndex, selectTabWithControl, prevTabName]);

  // review tab just displays actions from review group
  if (currentTabName === REVIEW_TAB) {
    return <ReviewNavigationButtons />;
  }

  // if mobile mode, render next and prev control/tab
  if (isMobileNavigation) {
    return (
      <MobileNavigationButtons
        isMainSeed={isMainSeed}
        currentControl={currentControl}
        finishAction={finishAction}
        onFinish={handleClickOnAction}
        onNextControl={handleNextControl}
        onPrevControl={handlePreviousControl}
        currentIndex={currentIndex}
      />
    );
  }

  if (isChildTabOpened) return null; // not mobile navigation

  const prevDisabled = currentIndex === 0 && !currentControl?.prevTab;
  const nextDisabled = currentControl?.isLastTab && currentControl?.isLastControl;

  if (prevDisabled && nextDisabled) return null;

  // if desktop mode, render next and prev tabs
  const isFinishTab = !!currentControl?.isLastControlTab;
  const nextTo = makeTabLink(currentControl?.nextTab);
  const prevTo = makeTabLink(currentControl?.prevTab);

  return (
    <div className={navigationButtonsStyle()}>
      <PrevButton to={prevTo} type="link" disabled={!currentControl?.nextTab} />
      {isFinishTab && finishAction ? (
        <FinishButton action={finishAction} onClick={handleClickOnAction} />
      ) : (
        <NextButton isFinishTab={isFinishTab} type="link" to={nextTo} disabled={currentControl?.isLastTab} />
      )}
    </div>
  );
};

export default MainNavigationButtons;
