import PropTypes from 'prop-types';
import { CONTROL_TYPES_LIST } from './controlDefinitions';
import { LIGHTS_LIST } from './lightDefinitions';
import { DIALOG_TYPES } from './dialogs';

export const TYPE_PROJECT = PropTypes.shape({});
export const TYPE_USER = PropTypes.shape({});
export const TYPE_NUMBER_OR_STRING = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const TYPE_OPTION_REF = PropTypes.shape({
  control: PropTypes.shape({}),
  option: PropTypes.shape({})
});

export const TYPE_NET_AREA = PropTypes.shape({
  enabled: PropTypes.bool,
  unit: PropTypes.string,
  area: PropTypes.null,
  caveat: PropTypes.string
});

export const TYPE_CONTROL_TYPE = PropTypes.oneOf(CONTROL_TYPES_LIST);

export const TYPE_IMAGE_OBJECT = PropTypes.shape({
  fileName: PropTypes.string
});

export const TYPE_OPTION_MATERIAL = PropTypes.shape({
  apply: PropTypes.string,
  original: PropTypes.string,
  price_psq: PropTypes.number,
  layer: PropTypes.string,
  priceCode_psq: PropTypes.string
});

export const TYPE_VECTOR_THREE = PropTypes.shape({
  x: PropTypes.number,
  y: PropTypes.number,
  z: PropTypes.number
});

export const TYPE_VECTOR_TWO = PropTypes.shape({
  x: PropTypes.number,
  y: PropTypes.number
});

export const TYPE_CONTROL_OPTION = PropTypes.shape({
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  optionHint: PropTypes.string,
  extraInfoCollection: PropTypes.string,
  price: TYPE_NUMBER_OR_STRING,
  totalPrice: PropTypes.number,
  priceCode: PropTypes.string,
  priceCode_psq: PropTypes.string,
  fix: PropTypes.shape({}),
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  priceHidden: PropTypes.bool,
  areaHidden: PropTypes.bool,
  status: PropTypes.string,
  image: TYPE_IMAGE_OBJECT,
  material: PropTypes.arrayOf(TYPE_OPTION_MATERIAL)
});

export const TYPE_CONTROL_OPTIONS = PropTypes.arrayOf(TYPE_CONTROL_OPTION);

export const TYPE_PART_CONTROL = PropTypes.shape({
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  type: TYPE_CONTROL_TYPE,
  list: TYPE_CONTROL_OPTIONS,
  initialType: TYPE_CONTROL_TYPE,
  parameter: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  default: PropTypes.string,
  collapse: PropTypes.bool,
  bindExtraInfo: PropTypes.bool,
  isTreeNameStatic: PropTypes.bool,
  description: PropTypes.string,
  childTabName: PropTypes.string,
  childControls: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  body: PropTypes.string,
  bodyHtml: PropTypes.string,
  tags: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.string),
    defaults: PropTypes.arrayOf(PropTypes.string),
    mode: PropTypes.string,
    isAllTagVisible: PropTypes.bool
  })
});

export const TYPE_PART_CONTROLS = PropTypes.arrayOf(TYPE_PART_CONTROL);

export const TYPE_PART_PHASE = PropTypes.shape({
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  openView: PropTypes.string,
  controls: TYPE_PART_CONTROLS,
  summaryDisabled: PropTypes.bool
});

export const TYPE_PART_PHASES = PropTypes.arrayOf(TYPE_PART_PHASE);

export const TYPE_MATRIX = PropTypes.shape({
  Matrix: PropTypes.shape({
    RowCount: PropTypes.number,
    ColumnCount: PropTypes.number,
    Values: PropTypes.arrayOf(PropTypes.number)
  })
});

export const TYPE_PART_POSITION = PropTypes.shape({
  Reference: PropTypes.string.isRequired,
  Guid: PropTypes.string,
  Name: PropTypes.string,
  Type: PropTypes.string,
  Transform: PropTypes.shape({
    Matrix: PropTypes.shape({
      RowCount: PropTypes.number,
      ColumnCount: PropTypes.number,
      Values: PropTypes.arrayOf(PropTypes.number)
    })
  })
});

export const TYPE_MATERIAL_TEXTURE = PropTypes.shape({
  Texture: TYPE_IMAGE_OBJECT,
  Transform: TYPE_MATRIX
});

export const TYPE_PART_MATERIAL = PropTypes.shape({
  Name: PropTypes.string.isRequired,
  Guid: PropTypes.string,
  ColorDiffuse: PropTypes.string,
  ColorSpecular: PropTypes.string,
  ColorEmission: PropTypes.string,
  Opacity: PropTypes.number, // 0 - 1
  Gloss: PropTypes.number, // 0 - 1
  BumpScale: PropTypes.number, // double 0-1 - will be recalculated for imperial units
  DiffuseTexture: PropTypes.string, // legacy - will be used if Textures.Diffuse does not exist
  Textures: PropTypes.shape({
    Diffuse: TYPE_MATERIAL_TEXTURE,
    Bump: TYPE_MATERIAL_TEXTURE,
    Transparency: TYPE_MATERIAL_TEXTURE
  })
});

export const TYPE_PART_VIEW = PropTypes.shape({
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  hidden: PropTypes.bool,
  type: PropTypes.string,
  cameraPosition: TYPE_VECTOR_THREE,
  cameraTarget: TYPE_VECTOR_THREE,
  icon: PropTypes.string,
  clippingPlane: PropTypes.shape({
    enabled: PropTypes.bool,
    normal: TYPE_VECTOR_THREE,
    origin: TYPE_VECTOR_THREE
  }),
  sunlight: PropTypes.string,
  sunlightEnabled: PropTypes.bool,
  skylight: PropTypes.string,
  skylightEnabled: PropTypes.bool,
  customLights: PropTypes.arrayOf(PropTypes.string)
});

export const TYPE_PART_CUSTOM_LIGHT = PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(LIGHTS_LIST),
  color: TYPE_NUMBER_OR_STRING,
  intensity: PropTypes.number,
  castShadow: PropTypes.bool,
  colorSecondary: PropTypes.string,
  position: TYPE_VECTOR_THREE,
  distance: PropTypes.number,
  decay: PropTypes.number,
  width: PropTypes.number, // for rectangular
  height: PropTypes.number, // for rectangular
  lookAt: TYPE_VECTOR_THREE
});

export const TYPE_PART_CUSTOM_LIGHTS = PropTypes.arrayOf(TYPE_PART_CUSTOM_LIGHT);

export const TYPE_PART_SKYLIGHT = PropTypes.shape({
  name: PropTypes.string.isRequired,
  color: TYPE_NUMBER_OR_STRING,
  intensity: PropTypes.number
});

export const TYPE_PART_SUNLIGHT = PropTypes.shape({
  name: PropTypes.string.isRequired,
  color: TYPE_NUMBER_OR_STRING,
  intensity: PropTypes.number,
  azimuth: PropTypes.number,
  altitude: PropTypes.number
});

export const TYPE_PART_VIEWS = PropTypes.shape({
  list: PropTypes.arrayOf(TYPE_PART_VIEW)
});

export const TYPE_PART = PropTypes.shape({
  ReferenceName: PropTypes.string,
  Option: PropTypes.string,
  Materials: PropTypes.arrayOf(TYPE_PART_MATERIAL),
  Position: PropTypes.arrayOf(TYPE_PART_POSITION),
  Controls: PropTypes.arrayOf(TYPE_PART_CONTROL),
  Views: TYPE_PART_VIEWS,
  Phases: PropTypes.shape({
    list: PropTypes.arrayOf({
      TYPE_PART_PHASE
    })
  }),
  Lights: PropTypes.shape({
    sunlights: PropTypes.arrayOf(TYPE_PART_SUNLIGHT),
    customLights: PropTypes.arrayOf(TYPE_PART_CUSTOM_LIGHT),
    skylight: PropTypes.arrayOf(TYPE_PART_SKYLIGHT)
  })
});

export const TYPE_PARTS = PropTypes.arrayOf(TYPE_PART);

export const TYPE_PART_RAW = PropTypes.shape({
  FileName: PropTypes.string,
  GHDef: PropTypes.string,
  EncodedAt: PropTypes.string,
  seed: PropTypes.string,
  Type: PropTypes.string,
  Name: PropTypes.string,
  ReferenceName: PropTypes.string,
  Option: PropTypes.string,
  Materials: PropTypes.arrayOf(TYPE_PART_MATERIAL),
  Position: PropTypes.arrayOf(TYPE_PART_POSITION),
  Info: PropTypes.shape({}),
  Controls: PropTypes.shape({
    Object: PropTypes.arrayOf(TYPE_PART_CONTROL)
  }),
  Views: TYPE_PART_VIEWS,
  Phases: PropTypes.shape({
    list: PropTypes.arrayOf({
      TYPE_PART_PHASE
    })
  }),
  Lights: PropTypes.shape({
    sunlights: PropTypes.arrayOf(TYPE_PART_SUNLIGHT),
    customLights: PropTypes.arrayOf(TYPE_PART_CUSTOM_LIGHT),
    skylight: PropTypes.arrayOf(TYPE_PART_SKYLIGHT)
  })
});

export const TYPE_SEED = PropTypes.shape({});

export const TYPE_MODEL_UNITS = PropTypes.oneOf(['metric', 'imperial']);

export const TYPE_REMOVED_OPTION_SECTION = PropTypes.shape({
  list: PropTypes.arrayOf(TYPE_OPTION_REF),
  name: PropTypes.string,
  label: PropTypes.string
});

export const TYPE_DIALOG = PropTypes.shape({
  type: PropTypes.oneOf(DIALOG_TYPES)
});

export const TYPE_DIALOG_CHOICE = PropTypes.oneOfType([PropTypes.bool, TYPE_DIALOG]);

export const TYPE_INSTANCE = PropTypes.shape({});

export const TYPE_CURRENT_USER = PropTypes.shape({});

export const TYPE_EXTRA_INFO_PANEL = PropTypes.shape({
  caption: PropTypes.string,
  image: TYPE_IMAGE_OBJECT,
  name: PropTypes.string,
  text: PropTypes.string
});

export const TYPE_EXTRA_INFO_PANELS = PropTypes.arrayOf(TYPE_EXTRA_INFO_PANEL);

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning'
};

export const TYPE_NOTIFICATION = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(NOTIFICATION_TYPES)).isRequired,
  message: PropTypes.string.isRequired,
  timeout: PropTypes.number,
  hidden: PropTypes.bool,
  showReloadLink: PropTypes.bool
});

export const TYPE_NOTIFICATIONS = PropTypes.arrayOf(TYPE_NOTIFICATION);

// this should have all possible settings listed eventually
export const TYPE_SETTINGS = PropTypes.shape({});

export const TYPE_SUMMARY_SECTION = PropTypes.shape({
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      option: TYPE_CONTROL_OPTION,
      control: TYPE_PART_CONTROL
    })
  ),
  phase: TYPE_PART_PHASE
});
export const TYPE_SUMMARY_SECTIONS = PropTypes.arrayOf(TYPE_SUMMARY_SECTION);

export const TYPE_SCENE = PropTypes.shape({}); // threejs scene

export const TYPE_ROUTER_MATCH = PropTypes.shape({
  isExact: PropTypes.bool,
  params: PropTypes.objectOf(PropTypes.string),
  path: PropTypes.string,
  url: PropTypes.string
});

export const TYPE_EXTRA_INFO_DATA = PropTypes.shape({
  infoPanels: PropTypes.arrayOf(PropTypes.shape({})),
  externalLink: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string
  })
});

export const TYPE_SELECTED_OPTIONS = PropTypes.shape({});

export const TYPE_COOKIE_MANIPULATION = PropTypes.shape({
  get: PropTypes.func.isRequired,
  set: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired
});

export const TYPE_CAMERA_SETTINGS = PropTypes.shape({
  fov: PropTypes.number.isRequired,
  near: PropTypes.number.isRequired,
  far: PropTypes.number.isRequired
});

export const TYPE_FORM_STATE = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
);

export const TYPE_SELECT_OPTION = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
});

export const TYPE_SELECT_OPTIONS = PropTypes.arrayOf(TYPE_SELECT_OPTION);

export const TYPE_METRIC = PropTypes.shape({
  unit: PropTypes.string,
  value: PropTypes.number,
  unitBeforeValue: PropTypes.bool,
  formatFunc: PropTypes.func,
  image: TYPE_IMAGE_OBJECT,
  icon: PropTypes.string,
  displayName: PropTypes.string
});
