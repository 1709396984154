import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { MenuSection } from '../../Menu';
import { useTranslate } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import SocialAuth from '../../Auth/SocialAuth';
import { DIALOGS } from '../../../utility/dialogs';
import { dialogActions } from '../../../modules/dialog';
import { Button } from '../../Atoms';
import EmailRegister from './EmailRegister';

const RegisterMenu = ({ title, loginTitle }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const handleOpenLoginMenu = useCallback(() => {
    dispatch(dialogActions.showDialog(DIALOGS.LOGIN, { loginTitle, signUpTitle: title }));
  }, [dispatch, loginTitle, title]);

  return (
    <MenuContent>
      <MenuSection
        type="register-header"
        heading={translate(title)}
        description={translate('Use social media accounts')}
      >
        <SocialAuth register />
      </MenuSection>
      <MenuSection type="register-email" description={translate('Or use email and password')}>
        <EmailRegister />
      </MenuSection>
      <MenuSection type="register-login" description={translate('Already have an account?')}>
        <Button color="text-borderless" block onClick={handleOpenLoginMenu} bold>
          {translate('Log in')}
        </Button>
      </MenuSection>
    </MenuContent>
  );
};

RegisterMenu.propTypes = {
  title: PropTypes.string,
  loginTitle: PropTypes.string
};

RegisterMenu.defaultProps = {
  title: 'Register',
  loginTitle: undefined
};

export default RegisterMenu;
