import React, { useCallback } from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';
import { MenuSection } from '../../Menu';
import { useSelectTabWithControl, useTranslate } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import { Button } from '../../Atoms';
import useMenuClose from '../../Menu/useMenuClose';
import { priceSelectors } from '../../../modules/model';

import './BrokenControlsMenu.scss';

const brokenControlsMenuStyle = b.with('broken-controls-menu');

const BrokenControlsMenu = () => {
  const translate = useTranslate();
  const handleClose = useMenuClose();
  const selectTabWithControl = useSelectTabWithControl();

  const removedOptions = useSelector(priceSelectors.selectRemovedOptionSections);

  const handleSelectControl = useCallback(
    e => {
      const { tab, control } = e.currentTarget.dataset;

      if (!control || !tab) {
        return;
      }

      selectTabWithControl(tab, control);

      handleClose();
    },
    [handleClose, selectTabWithControl]
  );

  return (
    <MenuContent>
      <MenuSection
        heading={translate('Some of your choices might not be available any more.')}
        description={translate('Please revise these choices:')}
        type="broken-controls"
      >
        {removedOptions.map(({ control, option, tabName }) => (
          <div key={control.name}>
            <div className={brokenControlsMenuStyle('control')}>
              <span>{translate(control.displayName)}</span>
              <Button
                onClick={handleSelectControl}
                data-tab={tabName}
                data-control={control.name}
                color="secondary"
                rounded
                size="xs"
              >
                {translate('View')}
              </Button>
            </div>
            <small>{translate(option.statusDescription)}</small>
          </div>
        ))}
      </MenuSection>
    </MenuContent>
  );
};

export default BrokenControlsMenu;
