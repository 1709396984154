import { SyntheticEvent, useCallback, useEffect } from 'react';
import { Raycaster, Vector3, Vector2, WebGLRenderer, Object3D } from 'three';
import b from 'b_';
import { useTranslate } from '../../../utility/hooks';
import FlippableInstancedMesh from '../../../utility/MirrorableInstancedMesh';
import { Button } from '../../Atoms';
import Icon from '../../Icon';
import getThreeSetup from '../../Viewer/getThreeSetup';

import './CommentMenu.scss';

interface LocationMarkerProps {
  empty?: boolean;
  onAddPin: (key: string, position?: Vector3) => void;
  onRemovePin: () => void;
  onSetTemporaryPinVisible: (b: boolean) => void;
  addingMode: boolean;
  onSetAddingMode: (b: boolean) => void;
}

const locationMarker = b.with('location-marker');

const raycaster = new Raycaster();
const mouse = new Vector2();
const targetMouse = new Vector2();
const MAX_DELTA = 5;

const updateMouse = (e: MouseEvent) => {
  mouse.x = e.x;
  mouse.y = e.y;
};

const getFirstIntersection = (renderer: WebGLRenderer, model: Object3D) => {
  const [rendererClippingPlane] = renderer.clippingPlanes;

  return raycaster.intersectObjects(model.children, true).find(({ object, point }) => {
    if (!(object.visible && object instanceof FlippableInstancedMesh)) return false;

    if (rendererClippingPlane?.distanceToPoint(point) < 0) {
      return false;
    }

    return true;
  });
};

/**
 * Set of buttons for manipulating pin in model
 * State decides if pin is being added, replaced, removed.
 * Each has a key to remount button instead of updating when state changes
 */
const LocationMarker = ({
  onSetTemporaryPinVisible,
  empty = false,
  onAddPin,
  onRemovePin,
  addingMode,
  onSetAddingMode
}: LocationMarkerProps) => {
  const translate = useTranslate();
  const { renderer, canvas, camera, model, sceneTransformInverse } = getThreeSetup();

  const handleScroll = useCallback((e: SyntheticEvent) => {
    e.currentTarget.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const handleReplace = useCallback(
    event => {
      onSetAddingMode(true);
      // set temporary pin position to undefined to remove current pin
      onSetTemporaryPinVisible(false);
    },
    [onSetAddingMode, onSetTemporaryPinVisible]
  );

  const handleAddPin = useCallback(
    (event: MouseEvent) => {
      targetMouse.set(event.x, event.y);

      if (mouse.manhattanDistanceTo(targetMouse) > MAX_DELTA) {
        return;
      }
      onSetAddingMode(false);

      if (addingMode) {
        const { offsetX, offsetY, currentTarget } = event;

        const target = currentTarget as HTMLCanvasElement;

        // normalise coordinates
        if (!target) return;
        const { clientHeight, clientWidth } = target;
        const x = (2 * offsetX) / clientWidth - 1; // -1 .. 1
        const y = -(2 * offsetY) / clientHeight + 1;

        raycaster.setFromCamera({ x, y }, camera);
        const firstIntersection = getFirstIntersection(renderer, model);

        if (!firstIntersection) return;

        const { object, point, instanceId } = firstIntersection;

        if (object instanceof FlippableInstancedMesh && instanceId !== undefined) {
          const key = object.userData.indices[instanceId];

          onAddPin(key, point.clone().applyMatrix4(sceneTransformInverse));
        }
      }
    },
    [addingMode, camera, model, onAddPin, onSetAddingMode, renderer, sceneTransformInverse]
  );

  useEffect(() => {
    const prevCursor = canvas.style.cursor;

    if (addingMode) {
      canvas.style.cursor = 'crosshair';
    }

    return () => {
      canvas.style.cursor = prevCursor;
    };
  }, [addingMode, canvas.style]);

  useEffect(() => {
    canvas.addEventListener('mousedown', updateMouse);
    canvas.addEventListener('mouseup', handleAddPin);

    return () => {
      canvas.removeEventListener('mousedown', updateMouse);

      canvas.removeEventListener('mouseup', handleAddPin);
    };
  }, [addingMode, canvas, handleAddPin]);

  if (addingMode) {
    return (
      <div className={locationMarker()}>
        <Button
          key="placeholder"
          color="text"
          rounded
          mix={locationMarker('btn', { type: 'placeholder' })}
          onFocus={handleScroll}
          onClick={e => {
            onSetAddingMode(false);
            onSetTemporaryPinVisible(true);
          }}
        >
          <Icon scale={1.5} type="cursor-default-click" /> {translate('Click in the model')}
        </Button>
      </div>
    );
  }

  if (empty) {
    return (
      <div className={locationMarker()}>
        <Button
          key="attach"
          color="text"
          onFocus={handleScroll}
          mix={locationMarker('btn', { type: 'attach' })}
          rounded
          onClick={e => {
            onSetAddingMode(true);
          }}
        >
          <Icon scale={1.5} type="map-marker" /> {translate('Attach pin')}
        </Button>
      </div>
    );
  }

  return (
    <div className={locationMarker()}>
      <Button
        color="text"
        key="replace"
        mix={locationMarker('btn', { type: 'replace' })}
        onFocus={handleScroll}
        rounded
        onClick={handleReplace}
      >
        <Icon scale={1.5} type="map-marker-radius" /> {translate('Replace pin')}
      </Button>
      <Button
        color="text"
        key="remove"
        mix={locationMarker('btn', { type: 'remove' })}
        onFocus={handleScroll}
        rounded
        onClick={onRemovePin}
      >
        <Icon scale={1.5} type="map-marker-off" /> {translate('Remove pin')}
      </Button>
    </div>
  );
};

export default LocationMarker;
