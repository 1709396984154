import { SET_COOKIE, SET_COOKIE_CONSENT, TOGGLE_COOKIE_NOTIFICATION } from './cookiesActions';

const initialState = {
  list: {},
  consent: undefined,
  showNotification: false
};

const cookiesReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case SET_COOKIE: {
      const { cookie } = payload;

      return { ...state, list: { ...state.list, [cookie.name]: cookie } };
    }
    case SET_COOKIE_CONSENT: {
      return { ...state, consent: payload.consent };
    }
    case TOGGLE_COOKIE_NOTIFICATION: {
      const { open } = payload;

      return { ...state, showNotification: open };
    }
    default:
      return state;
  }
};

export default cookiesReducer;
