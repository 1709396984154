import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useTranslate } from '../../../utility/hooks';

import './Hint.scss';

const hintStyle = b.with('hint');

const Hint = ({ optionHint, optionHintHidden }) => {
  const translate = useTranslate();

  if (optionHintHidden || !optionHint) return null;

  return <span className={hintStyle()}>{translate(optionHint)}</span>;
};

Hint.defaultProps = {
  optionHint: '',
  optionHintHidden: false
};

Hint.propTypes = {
  optionHint: PropTypes.string,
  optionHintHidden: PropTypes.bool
};

export default Hint;
