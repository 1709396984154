import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { useDoubleTap } from 'use-double-tap/dist';
import Icon from '../../Icon';
import Button from '../Button';
import { useSwitch } from '../../../utility/hooks';
import Heap from '../../../utility/heap';
import placeholderURL from '../../../utility/galleryPlaceholderUrl';
import Panorama from '../Panorama';
import galleryStyle from './style';
import GalleryFullscreen from './GalleryFullscreen';
import GalleryNavigationControls from './GalleryNavigationControls';
import { IMAGE_LIST_PROP_TYPE } from './galleryPropTypes';

const Gallery = ({ images, showFullscreenButton, imagesMix, onToggleFullscreen, onChangeActiveIndex }) => {
  const { open: handleOpenFullscreen, close: handleCloseFullscreen, isOpen: isFullscreenActive } = useSwitch();

  const renderPanorama = useCallback(({ original }) => <Panorama src={original} />, []);

  const items = useMemo(
    () => images.map(image => ({ ...image, renderItem: image.isPanorama ? renderPanorama : undefined })),
    [images, renderPanorama]
  );

  const [activeIndex, setActiveIndex] = useState(0);
  const galleryRef = useRef();

  const handleChangeSlide = useCallback(index => {
    setActiveIndex(index);
  }, []);

  const handleClickOnDot = useCallback(e => {
    const { index } = e.currentTarget.dataset;
    const i = Number(index);

    galleryRef.current.slideToIndex(i);
    setActiveIndex(i);
  }, []);

  const handleClickOnPrevSlide = useCallback(() => {
    setActiveIndex(activeIndex === 0 ? images.length - 1 : activeIndex - 1);
  }, [activeIndex, images.length]);

  const handleClickOnNextSlide = useCallback(() => {
    setActiveIndex(activeIndex + 1 === images.length ? 0 : activeIndex + 1);
  }, [activeIndex, images.length]);

  const isNavigationAvailable = images.length > 1;

  const doubleTap = useDoubleTap(() => {
    if (showFullscreenButton) {
      handleOpenFullscreen();
    }
  });

  useEffect(() => {
    setActiveIndex(0);
  }, [images]);

  useEffect(() => {
    onToggleFullscreen(isFullscreenActive);
  }, [isFullscreenActive, onToggleFullscreen]);

  useEffect(() => {
    onChangeActiveIndex(activeIndex);

    Heap.track('GALLERY_INTERACTION');
  }, [activeIndex, onChangeActiveIndex]);

  if (images.length === 0) {
    return (
      <div className={galleryStyle()}>
        <div className={galleryStyle('placeholder')}>
          <img className="image-gallery-image" alt="placeholder" src={placeholderURL} />
        </div>
      </div>
    );
  }

  return (
    <div className={galleryStyle({ navigation: isNavigationAvailable, panorama: items[activeIndex]?.isPanorama })}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div {...doubleTap}>
        <ImageGallery
          items={items}
          showFullscreenButton={showFullscreenButton}
          showThumbnails={false}
          showNav={false}
          showPlayButton={false}
          ref={galleryRef}
          additionalClass={`${galleryStyle('images')} ${imagesMix}`}
          startIndex={activeIndex}
          onSlide={handleChangeSlide}
          useBrowserFullscreen={false}
          disableSwipe={items[activeIndex]?.isPanorama}
          renderFullscreenButton={() => (
            <Button
              circular
              onClick={handleOpenFullscreen}
              color="main-outline"
              mix={galleryStyle('button', { type: 'fullscreen' })}
            >
              <Icon type="fullscreen" />
            </Button>
          )}
          onErrorImageURL={placeholderURL}
        />
      </div>
      {isNavigationAvailable ? (
        <div className={galleryStyle('navigation')}>
          <div className={galleryStyle('dots')}>
            {images.map((_, index) => (
              /* eslint-disable jsx-a11y/control-has-associated-label, react/no-array-index-key */
              <button
                key={index}
                className={galleryStyle('dot', { active: index === activeIndex })}
                type="button"
                onClick={handleClickOnDot}
                data-index={index}
              />
            ))}
          </div>
        </div>
      ) : null}
      <GalleryNavigationControls
        onNextSlide={handleClickOnNextSlide}
        onPrevSlide={handleClickOnPrevSlide}
        images={images}
        activeIndex={activeIndex}
      />
      {showFullscreenButton ? (
        <GalleryFullscreen
          images={images}
          activeIndex={activeIndex}
          isActive={isFullscreenActive}
          onClose={handleCloseFullscreen}
          onNextSlide={handleClickOnNextSlide}
          onPrevSlide={handleClickOnPrevSlide}
        />
      ) : null}
    </div>
  );
};

Gallery.defaultProps = {
  showFullscreenButton: false,
  imagesMix: '',
  onToggleFullscreen: () => {},
  onChangeActiveIndex: () => {}
};

Gallery.propTypes = {
  images: IMAGE_LIST_PROP_TYPE.isRequired,
  showFullscreenButton: PropTypes.bool,
  imagesMix: PropTypes.string,
  onToggleFullscreen: PropTypes.func,
  onChangeActiveIndex: PropTypes.func
};

export default Gallery;
