import { useSelector } from 'react-redux';
import { Vector3, Matrix4 } from 'three';
import {
  DEFAULT_CAMERA_IMPERIAL,
  CAMERA_IMPERIAL,
  DEFAULT_CAMERA_METRIC,
  CAMERA_METRIC,
  MODEL_UNITS
} from '../../../utility/viewerSettings';
import { cameraSelectors } from '../../../store/camera';
import getThreeSetup from '../getThreeSetup';
import { modelSettingsSelectors } from '../../../modules/settings';
import { ORTHO_CAMERAS } from '../../../utility/viewTypes';

const getMetricSettings = (position, target) => {
  const cameraSettings = { ...CAMERA_METRIC };
  const cameraPosition = new Vector3().copy(position || DEFAULT_CAMERA_METRIC.cameraPosition);
  const cameraTarget = new Vector3().copy(target || DEFAULT_CAMERA_METRIC.cameraTarget);

  return { cameraSettings, cameraPosition, cameraTarget };
};

const getImperialSettings = (position, target) => {
  const cameraSettings = { ...CAMERA_IMPERIAL };
  const cameraPosition = new Vector3().copy(position || DEFAULT_CAMERA_IMPERIAL.cameraPosition);
  const cameraTarget = new Vector3().copy(target || DEFAULT_CAMERA_IMPERIAL.cameraTarget);

  return { cameraSettings, cameraPosition, cameraTarget };
};

const useCameraSettings = () => {
  const { sceneTransform } = getThreeSetup();

  const modelUnits = useSelector(modelSettingsSelectors.selectModelUnits);

  const cameraView = useSelector(cameraSelectors.selectCameraView);
  const { name = '', type = '', disableCameraAnimation = false, isRelative, parentMatrix, fov, near, far } = cameraView;
  const { cameraPosition: cameraViewPosition, cameraTarget: cameraViewtarget } = cameraView;

  const { cameraSettings, cameraPosition, cameraTarget } =
    modelUnits === MODEL_UNITS.IMPERIAL
      ? getImperialSettings(cameraViewPosition, cameraViewtarget)
      : getMetricSettings(cameraViewPosition, cameraViewtarget);

  if (fov) cameraSettings.fov = fov;

  if (near !== undefined) {
    if (ORTHO_CAMERAS[type]) {
      cameraSettings.near = near;
    } else {
      cameraSettings.near = near > 0 ? near : 0.1; // near cannot be 0 for perspective cameras
    }
  }

  if (far) cameraSettings.far = far;

  if (isRelative) {
    // trial end error
    const inverseScene = new Matrix4().copy(sceneTransform).invert();
    // apply parent matrix to inverted scene and apply scene
    const pureParent = new Matrix4().multiplyMatrices(parentMatrix, inverseScene).premultiply(sceneTransform);

    cameraTarget.applyMatrix4(pureParent);
    cameraPosition.applyMatrix4(pureParent);
  }

  return {
    cameraSettings,
    cameraTarget,
    cameraPosition,
    cameraViewName: name,
    cameraViewType: type,
    cameraViewAnimationDisabled: disableCameraAnimation
  };
};

export default useCameraSettings;
