import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSections } from '../../../modules/ui/summarySelectors';
import { uiActions } from '../../../modules/ui';
import ContentTitle from '../ContentTitle';
import PreferenceSection from './PreferenceSection';
import preferencesTableStyle, { MAX_TOTAL_CONTROLS, MAX_CONTROLS_PER_TAB } from './preferencesTableStyle';

const PreferencesTable = () => {
  const dispatch = useDispatch();
  const sections = useSelector(getSections);
  const handleClick = useCallback(
    e => {
      const { choiceIndex, sectionIndex } = e.currentTarget.dataset;
      const choice = Number(choiceIndex);
      const section = Number(sectionIndex);
      const { parents } = sections[section].choices[choice];

      if (!parents) return;

      dispatch(uiActions.showChildControls(parents, true));
    },
    [dispatch, sections]
  );

  const overMaxControls = useMemo(() => {
    return (
      MAX_TOTAL_CONTROLS >
      sections.reduce((result, { choices }) => {
        return result + choices.length;
      }, 0)
    );
  }, [sections]);

  return (
    <div className={preferencesTableStyle()}>
      <ContentTitle title="Preferences" />
      <div className={preferencesTableStyle('content')}>
        {sections.map(({ tab, choices }, sectionIndex) => (
          <PreferenceSection
            key={tab.name}
            tab={tab}
            choices={choices}
            index={sectionIndex}
            onClick={handleClick}
            initiallyClosed={overMaxControls && choices.length > MAX_CONTROLS_PER_TAB}
          />
        ))}
      </div>
    </div>
  );
};

export default PreferencesTable;
