const VERTEX_PICKING = `
attribute vec3 idcolor;
varying vec3 vidcolor;
#include <clipping_planes_pars_vertex>

void main(){
  #include <begin_vertex>

  vidcolor = idcolor;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0);
  #include <project_vertex>

  #include <clipping_planes_vertex>

}`;

const FRAGMENT_PICKING = `
varying vec3 vidcolor;
#include <clipping_planes_pars_fragment>

void main(void) {
  gl_FragColor = vec4(vidcolor,1.0);
  #include <clipping_planes_fragment>

}`;

// This is used in Model.js to hack into Phong shader - backfaces are rendered with autoSectionColor instead of their normal value to imitate clipping plane caps.
const FRAGMENT_CAPS = 'gl_FragColor = vec4( outgoingLight, diffuseColor.a );'; // shader lines to insert

// line to replace in Phong shader
const FRAGMENT_CAPS_REPLACE = `if ( gl_FrontFacing ) {
gl_FragColor = vec4( outgoingLight, diffuseColor.a );
} else {
gl_FragColor = vec4( sectionCapColor, opacity );
}`;

const shaders = {
  FRAGMENT_CAPS_REPLACE,
  FRAGMENT_CAPS,
  VERTEX_PICKING,
  FRAGMENT_PICKING
};

export default shaders;
