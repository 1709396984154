import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthResponse, Consent, ActionMeta } from 'types';
import Heap from '../utility/heap';

interface AuthState {
  user: any;
  emailSent: boolean;
  token: {
    valid: boolean;
    checked: boolean;
  };
  passwordChanged: boolean;
  localConsent?: {
    party: string;
    type: string;
    message?: string;
  };
}

const initialState: AuthState = {
  user: null,
  emailSent: false,
  token: {
    valid: false,
    checked: false
  },
  passwordChanged: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: {
      reducer(state, action: PayloadAction<AuthResponse>) {
        state.user = action.payload;

        Heap.identify(state.user.uid);
        Heap.addUserProperties(state.user);
        Heap.track('LOGIN__SUCCESS');
      },
      prepare(payload: AuthResponse, meta?: ActionMeta) {
        return { payload, meta };
      }
    },
    unsetUser: {
      reducer(state) {
        state.user = null;

        Heap.track('LOGOUT__SUCCESS');
      },
      prepare(_?: any, meta?: ActionMeta) {
        return { payload: null, meta };
      }
    },
    updateConsent: (state, action: PayloadAction<Consent>) => {
      state.localConsent = action.payload;
    },
    setTokenValid: (state, action: PayloadAction<boolean>) => {
      state.token.valid = action.payload;
    },
    setTokenChecked: (state, action: PayloadAction<boolean>) => {
      state.token.checked = action.payload;
    }
  }
});

const { reducer: auth, actions: authSliceActions } = authSlice;

export default authSlice.reducer;

export { auth, authSliceActions };
