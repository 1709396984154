import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { uiActions } from '../modules/ui';

const HEIGHT_TRIGGER = 480;
const WIDTH_TRIGGER = 640;

const MobileNavigationWatcher = () => {
  const dispatch = useDispatch();
  const isMobileHeight = useMediaQuery({ maxHeight: HEIGHT_TRIGGER });
  const isMobileWidth = useMediaQuery({ maxWidth: WIDTH_TRIGGER - 1 });

  useEffect(() => {
    dispatch(uiActions.setMobileNavigation(isMobileHeight || isMobileWidth));
  }, [dispatch, isMobileHeight, isMobileWidth]);

  useEffect(() => {
    dispatch(uiActions.setMobileViewport(isMobileWidth));
  }, [dispatch, isMobileHeight, isMobileWidth]);

  return null;
};

export default MobileNavigationWatcher;
