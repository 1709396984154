import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as THREE from 'three';
import { uiActions, uiSelectors } from '../../../modules/ui';
import { getMouseOffsets } from '../../../utility';
import { selectIsSceneInteractive } from '../../../modules/model/modelSelectors';
import { getControls } from '../../../modules/model/nodesSelectors';
import { cameraActions } from '../../../store/camera';
import { selectedOptionsActions } from '../../../modules/selectedOptions';
import getThreeSetup from '../getThreeSetup';
import { useSelectTabWithControl, useSelectTabWithControlByInteraction } from '../../../utility/hooks';
import { selectSpriteMaterials } from '../../../store/materials/materialSelectors';
import meshStore from '../../../modules/geometries/MeshStore';

const mouse = new THREE.Vector2();
const raycaster = new THREE.Raycaster();

const DRAG_THRESHOLD = 10; // manhattan px

const useCanvasCallbacks = showExtraInfoCollection => {
  const partInteractionEnabled = useSelector(uiSelectors.selectIsPartInteractionEnabled);
  const { canvas, camera, render, scene, onResize, renderer } = getThreeSetup();
  const dispatch = useDispatch();
  const spriteMaterials = useSelector(selectSpriteMaterials);

  const sceneHasClickableObjects = useSelector(selectIsSceneInteractive);

  const controls = useSelector(getControls);

  const [objectHovering, setObjectHovering] = useState(false);

  const getHoveredObject = useCallback(
    e => {
      const { x, y } = getMouseOffsets(e);

      mouse.x = (x / canvas.clientWidth) * 2 - 1;
      mouse.y = -(y / canvas.clientHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObjects(scene.children, true);

      // find first intersection whose intersection point is not clipped
      const [rendererClippingPlane] = renderer.clippingPlanes;

      if (rendererClippingPlane) {
        return intersects.find(
          ({ point, object }) =>
            rendererClippingPlane.distanceToPoint(point) >= 0 && object.visible && object.material?.visible
        );
      }

      return intersects.find(({ object }) => object.visible && object.material?.visible);
    },
    [camera, canvas.clientHeight, canvas.clientWidth, scene.children, renderer.clippingPlanes]
  );

  const canvasMouseMoveHandler = useCallback(
    e => {
      if (!partInteractionEnabled) return;

      const intersected = getHoveredObject(e);

      const hovering = meshStore.handleObjectHover(intersected, spriteMaterials);

      setObjectHovering(hovering);
      render('sprite or part hover');
    },
    [getHoveredObject, partInteractionEnabled, render, spriteMaterials]
  );
  const selectTabWithControl = useSelectTabWithControl();
  const selectTabWithControlByInteraction = useSelectTabWithControlByInteraction();

  const setCameraView = useCallback(
    cameraView => {
      dispatch(cameraActions.setView(cameraView));
      dispatch(cameraActions.setMoved(false));
    },
    [dispatch]
  );

  const setControllerValue = useCallback(
    controller => {
      const { controlName, optionName } = controller;

      if (controlName && optionName) {
        const control = controls.find(({ name }) => name === controlName);

        if (control) {
          const option = control.list && control.list.find(({ name }) => name === optionName);

          if (option) {
            dispatch(selectedOptionsActions.selectOption(control, option));
          }
        }
      }
    },
    [controls, dispatch]
  );

  const objectClickHandler = useCallback(
    (clickData, key) => {
      const { cameraView, extraInfoCollection, controller, selectTab, selectControl = {} } = clickData;

      if (extraInfoCollection) {
        /* Open extra info collection */
        showExtraInfoCollection(extraInfoCollection);
      }

      if (selectControl.name) {
        // local controls based on the part node which defined the selectControl in the first place.
        selectTabWithControlByInteraction(selectControl.name, selectControl.local, key);
      }

      if (!selectControl.name && selectTab) {
        selectTabWithControl(selectTab);
      }

      if (cameraView) {
        setCameraView(cameraView);
      }

      if (controller) {
        setControllerValue(controller);
      }
    },
    [
      selectTabWithControl,
      selectTabWithControlByInteraction,
      showExtraInfoCollection,
      setCameraView,
      setControllerValue
    ]
  );

  const delta = useRef({ x: 0, y: 0 });

  const canvasClickHandler = useCallback(
    e => {
      if (!partInteractionEnabled) return;

      if (sceneHasClickableObjects) {
        const intersected = getHoveredObject(e);
        const { object = {}, instanceId } = intersected || {};

        let action;
        let key;

        if (object.isMesh) {
          action = meshStore.getPartInteraction(object, instanceId);

          if (object.userData.isInteractionMesh) {
            key = object.userData.key;
          } else {
            key = object.userData.indices?.[instanceId];
          }
        } else if (object.isSprite) {
          action = object.userData?.interaction;
          key = object.userData.key;
        }

        if (action?.enabled) {
          objectClickHandler(action, key);
        }
      }
    },
    [getHoveredObject, objectClickHandler, partInteractionEnabled, sceneHasClickableObjects]
  );

  const canvasMouseDownHandler = useCallback(e => {
    delta.current.x = e.clientX;
    delta.current.y = e.clientY;
  }, []);

  const canvasMouseUpHandler = useCallback(
    e => {
      dispatch(uiActions.setViewerInteracted());

      const distance = Math.abs(e.clientX - delta.current.x) + Math.abs(e.clientY - delta.current.y);

      if (distance < DRAG_THRESHOLD) {
        canvasClickHandler(e);
      }
    },
    [canvasClickHandler, dispatch]
  );

  const handleResize = useCallback(rect => onResize(rect.width, rect.height), [onResize]);

  return { canvasMouseMoveHandler, canvasMouseDownHandler, canvasMouseUpHandler, objectHovering, handleResize };
};

export default useCanvasCallbacks;
