import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userInstancesActions } from '../../../modules/userInstances';
import { loaderSelectors } from '../../../modules/loader';
import { dialogActions } from '../../../modules/dialog';
import { DIALOGS } from '../../../utility/dialogs';
import ConfirmMenu from './ConfirmMenu';

const ConfirmDeleteInstanceMenu = () => {
  const dispatch = useDispatch();
  const handleSubmit = useCallback(() => {
    dispatch(userInstancesActions.deleteInstanceConfirm());
  }, [dispatch]);

  const isUserInstanceDeleting = useSelector(loaderSelectors.selectIsUserInstanceDeleting);
  const handleCancel = useCallback(() => dispatch(dialogActions.showDialog(DIALOGS.PROFILE)), [dispatch]);

  return (
    <ConfirmMenu
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      label="Delete"
      disabled={isUserInstanceDeleting}
      disabledLabel="Deleting..."
      heading="Are you sure you with to delete this design?"
      description="Click on Delete to proceed"
    />
  );
};

export default ConfirmDeleteInstanceMenu;
