import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Matrix4, Object3D } from 'three';
import { TransformControls as ThreeTransformControls } from 'three/examples/jsm/controls/TransformControls';
import b from 'b_';
import { controlSelectors } from '../../../modules/model';
import { selectOption } from '../../../modules/selectedOptions/selectedOptionsActions';
import { TRANSFORM_SUFFIX } from '../../../utility/controlDefinitions';
import { useTranslate } from '../../../utility/hooks';
import { Button } from '../../Atoms';
import ButtonGroup from '../../Atoms/ButtonGroup';
import Icon from '../../Icon';
import getThreeSetup from '../getThreeSetup';
import { uiModesSelectors } from '../../../modules/ui';
import { radians } from '../../../utility';

const transformControls = b.with('transform-controls');

/** Checks if a given THREE.Matrix4 is identity */
const isIdentityMatrix = ({ elements }) =>
  elements[0] === 1 &&
  elements[1] === 0 &&
  elements[2] === 0 &&
  elements[3] === 0 &&
  elements[4] === 0 &&
  elements[5] === 1 &&
  elements[6] === 0 &&
  elements[7] === 0 &&
  elements[8] === 0 &&
  elements[9] === 0 &&
  elements[10] === 1 &&
  elements[11] === 0 &&
  elements[12] === 0 &&
  elements[13] === 0 &&
  elements[14] === 0 &&
  elements[15] === 1;

/** Supported TransformControls states */
const MODES = {
  MOVE: 0,
  ROTATE: 1
};

/** Manages connection to THREE's TransformControls */
const useThreeTransformControls = (camera, canvas) => {
  const { txEnabled, tyEnabled, tzEnabled, rzEnabled, ryEnabled, rxEnabled, translationStep, rotationStep } =
    useSelector(controlSelectors.selectTransformControlOptions);

  const translationEnabled = Boolean(txEnabled || tyEnabled || tzEnabled);
  const rotationEnabled = Boolean(rxEnabled || ryEnabled || rzEnabled);
  const isModeSwitchEnabled = translationEnabled && rotationEnabled;

  const [mode, setMode] = useState(translationEnabled ? MODES.MOVE : MODES.ROTATE);

  const translationControls = useMemo(() => new ThreeTransformControls(camera, canvas), [camera, canvas]);

  useEffect(() => {
    translationControls.setSize(0.8);
    translationControls.space = 'local';
    translationControls.translationSnap = translationStep;
    translationControls.rotationSnap = radians(rotationStep);
  }, [rotationStep, translationControls, translationStep]);

  useEffect(() => {
    translationControls.setMode(mode === MODES.ROTATE ? 'rotate' : 'translate');

    if (mode === MODES.ROTATE) {
      translationControls.showZ = rzEnabled;
      translationControls.showY = ryEnabled;
      translationControls.showX = rxEnabled;
    } else {
      translationControls.showZ = tzEnabled;
      translationControls.showY = tyEnabled;
      translationControls.showX = txEnabled;
    }
  }, [mode, rxEnabled, ryEnabled, rzEnabled, translationControls, txEnabled, tyEnabled, tzEnabled]);

  return { translationControls, isModeSwitchEnabled, setMode, mode };
};

const reusableMatrix4 = new Matrix4();

/** Manages a dummy Object3D to access and manipulate coordinates */
const useDummy = () => {
  const control = useSelector(controlSelectors.getSelectedControlGroup);
  const transformValue = useSelector(controlSelectors.getSelectedControlGroupTransformValue);
  const dispatch = useDispatch();

  const { orbit, render } = getThreeSetup();
  const orbitHistory = useRef(orbit.enabled);

  const dummy = useMemo(() => {
    const object3d = new Object3D();

    object3d.matrixAutoUpdate = false;

    return object3d;
  }, []);

  const handleChange = useCallback(() => {
    dummy.updateMatrix();
    dummy.updateMatrixWorld();
    render();
  }, [dummy, render]);

  const handleDraggingChange = useCallback(
    event => {
      // if currently dragging, record current orbit enabled-ness and disable it
      // otherwise reset to remembered value
      if (event.value) {
        orbitHistory.current = orbit.enabled;
        orbit.enabled = false;
      } else {
        orbit.enabled = orbitHistory.current;
      }
    },
    [orbit]
  );

  useEffect(() => {
    dummy.matrix.copy(transformValue);
    // apply controls  matrix
    reusableMatrix4.copy(control.matrix);

    dummy.matrix.premultiply(reusableMatrix4);
    dummy.matrix.decompose(dummy.position, dummy.quaternion, dummy.scale); // needed because handleChange takes those as inputs
  }, [control.matrix, dummy.matrix, dummy.position, dummy.quaternion, dummy.scale, transformValue]);

  const isResetEnabled = useMemo(() => !isIdentityMatrix(transformValue), [transformValue]);

  const handleChangeEnd = useCallback(() => {
    // get matrix which removes control (and part rotation)

    reusableMatrix4.copy(control.matrix).invert();
    reusableMatrix4.multiply(dummy.matrix);

    const matrixString = JSON.stringify(reusableMatrix4.elements);

    const option = { name: matrixString };
    const dummyControl = {
      name: `${control.name}${TRANSFORM_SUFFIX}`,
      parameter: `${control.name}${TRANSFORM_SUFFIX}`,
      treeName: `${control.treeName}${TRANSFORM_SUFFIX}`,
      list: [option]
    };

    dispatch(selectOption(dummyControl, option));
  }, [control.matrix, control.name, control.treeName, dispatch, dummy.matrix]);

  const handleReset = useCallback(() => {
    reusableMatrix4.identity();
    const matrixString = JSON.stringify(reusableMatrix4.elements);
    const option = { name: matrixString };
    const dummyControl = {
      name: `${control.name}${TRANSFORM_SUFFIX}`,
      parameter: `${control.name}${TRANSFORM_SUFFIX}`,
      treeName: `${control.treeName}${TRANSFORM_SUFFIX}`,
      list: [option]
    };

    dispatch(selectOption(dummyControl, option));
  }, [control.name, control.treeName, dispatch]);

  return { dummy, handleChange, handleDraggingChange, handleChangeEnd, handleReset, isResetEnabled };
};

/** THREE's TransformControls' events that we use */
const EVENTS = {
  AXIS_CHANGED: 'axis-changed',
  DRAGGING_CHANGED: 'dragging-changed',
  CHANGE: 'change',
  MOUSE_UP: 'mouseUp'
};

/** Main logic to implement rotation and translation controls
 *
 * Uses a dummy object to attach the transform controls to. When a change event is fired, we get the new transformation matrix
 * from dummy and trigger selecting an option with control name being current control + "#transform" and value being the matrix.
 * This is later used to offset the instancedMeshes correctly in getPartNodesToRender function.
 */
const useTransformControls = () => {
  const { scene, camera, canvas, render, model } = getThreeSetup();

  const { dummy, handleChange, handleDraggingChange, handleChangeEnd, handleReset, isResetEnabled } = useDummy();

  const { mode, setMode, translationControls, isModeSwitchEnabled } = useThreeTransformControls(camera, canvas);

  const handleSetTranslationMode = useCallback(() => setMode(MODES.MOVE), [setMode]);
  const handleSetRotationMode = useCallback(() => setMode(MODES.ROTATE), [setMode]);

  useEffect(() => {
    translationControls.attach(dummy);

    return () => {
      translationControls.detach(dummy);
    };
  }, [dummy, translationControls]);

  // update settings

  useEffect(() => {
    translationControls.addEventListener(EVENTS.CHANGE, handleChange);
    translationControls.addEventListener(EVENTS.MOUSE_UP, handleChangeEnd);
    translationControls.addEventListener(EVENTS.DRAGGING_CHANGED, handleDraggingChange);
    scene.add(translationControls);
    model.add(dummy);

    return () => {
      scene.remove(translationControls);
      model.remove(dummy);
      translationControls.removeEventListener(EVENTS.MOUSE_UP, handleChangeEnd);
      translationControls.removeEventListener(EVENTS.CHANGE, handleChange);
      translationControls.removeEventListener(EVENTS.DRAGGING_CHANGED, handleDraggingChange);
    };
  }, [
    camera,
    canvas,
    dummy,
    handleChange,
    handleChangeEnd,
    handleDraggingChange,
    model,
    render,
    scene,
    translationControls
  ]);

  return {
    mode,
    handleSetRotationMode,
    handleSetTranslationMode,
    handleReset,
    isModeSwitchEnabled,
    isResetEnabled
  };
};

/** Adds buttons to switch between transform modes, reset transform and connects to THREE's TransformControls */
const TransformControls = () => {
  const { mode, handleSetTranslationMode, handleSetRotationMode, handleReset, isModeSwitchEnabled, isResetEnabled } =
    useTransformControls();
  const translate = useTranslate();
  const isMinimalMode = useSelector(uiModesSelectors.selectIsMinimalUi);
  const { isMainFullscreen } = useSelector(uiModesSelectors.selectMinimalUiSettings);

  return isMinimalMode ? null : (
    <ButtonGroup
      mix={transformControls({ hidden: !(isModeSwitchEnabled || isResetEnabled), raised: isMainFullscreen })}
    >
      {isModeSwitchEnabled ? (
        <>
          <Button onClick={handleSetTranslationMode} color={mode === MODES.MOVE ? 'main' : 'main-outline'}>
            <Icon type="cursor-move" /> {translate('Move')}
          </Button>
          <Button onClick={handleSetRotationMode} color={mode === MODES.ROTATE ? 'main' : 'main-outline'}>
            <Icon type="rotate-left" /> {translate('Rotate')}
          </Button>
        </>
      ) : null}
      {isResetEnabled ? (
        <Button color="main-outline" mix={transformControls('reset')} onClick={handleReset}>
          <Icon type="undo" /> {translate('Reset')}
        </Button>
      ) : null}
    </ButtonGroup>
  );
};

export default TransformControls;
