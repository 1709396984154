import { ReactNode } from 'react';
import b from 'b_';
import './ButtonGroup.scss';

const buttonGroup = b.with('button-group');

interface ButtonGroupProps {
  children: ReactNode;
  mix?: string;
}

const ButtonGroup = ({ children, mix = '' }: ButtonGroupProps) => (
  <div className={`${buttonGroup()} ${mix}`}>{children}</div>
);

export default ButtonGroup;
