import { all, call, put, takeLatest, select, cancel } from 'redux-saga/effects';
import api from '../../api';
import { projectSelectors } from '../project';
import { initialStateActions } from '../initialState';
import { FETCH_PLOTS, fetchPlotsSuccess, fetchPlotsFailure } from './plotsActions';

function* fetchPlotsSaga() {
  const projectId = yield select(projectSelectors.selectProjectId);
  const { data, error } = yield call(api.getPlots, projectId);

  if (error) {
    yield put(fetchPlotsFailure(error));
  } else {
    yield put(fetchPlotsSuccess(data));
  }
}

function* doFetchPlotsSaga() {
  const hasNewProject = yield select(projectSelectors.selectHasNewProject);

  if (!hasNewProject) {
    yield cancel();
  }

  yield call(fetchPlotsSaga);
}

function* watchFetchPlotsSaga() {
  yield takeLatest([FETCH_PLOTS, initialStateActions.FETCH_INITIAL_STATE__SUCCESS], doFetchPlotsSaga);
}

export default function* moduleSaga() {
  yield all([watchFetchPlotsSaga()]);
}
