/** Converts string to array while splitting by newlines.
 * If falsy input, returns undefined
 * If input is array, returns same array
 */
function convertToArray(value: string[] | string = ''): string[] | undefined {
  if (!value) return undefined;

  if (Array.isArray(value)) return value;

  return value.split('\n');
}

export default convertToArray;
