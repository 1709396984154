import { createSelector } from 'reselect';
import { selectSettings } from './settingsSelectors';

// eslint-disable-next-line import/prefer-default-export
export const selectSharingSettings = createSelector([selectSettings], ({ sharing = {} }) => ({
  title: sharing.title || 'My new home',
  description: sharing.description || 'Check out my house on Creatomus',
  twitter: sharing.twitter || 'I created a house with @Creatomus',
  view: sharing.view || 'default'
}));
