import { useSelector } from 'react-redux';
import { selectDialog } from '../../modules/dialog/dialogSelectors';
import { selectIsMobileViewport } from '../../modules/ui/uiStateSelectors';
import { DIALOGS } from '../dialogs';

/** Returns true if mobile viewport and comment dialog open */
const useIsMobileCommentDialogMode = () => {
  const mobileViewPort = useSelector(selectIsMobileViewport);
  const dialog = useSelector(selectDialog);

  return mobileViewPort && dialog && (dialog.type === DIALOGS.ADD_COMMENT || dialog.type === DIALOGS.EDIT_COMMENT);
};

export default useIsMobileCommentDialogMode;
